import AddAbatement from "./AddAbatement";
import FilterAbatement from "./FilterAbatement";

export const AbatementConstants = {
  label: {
    filterAbatement: "Initiative",
    assetId: "Asset ID",
    status: "Status",
    abatementName: "Abatement Name",
    abatementId: "Abatement ID",
    assetName: "Asset Name",
    initiative: "Initiative",
    emission: "Emission Sub-category",
    assetType: "Asset Type",
    assetDescription: "Asset Description",
    abatementCost: "Abatement Cost (USD/t CH₄)",
    percentAbated: "% Abated",
    abatementDescription: "Abatement Description",
    country: "Country",
    latitude: "Latitude",
    longitude: "Longitude",
    operated: "Operated?",
    operator: "Operator",
    volume: "Volume abated (t of CH₄)",
    comments: "Comments",
    equityPercentage: `% Equity`,
    capex: "CAPEX (MUSD)",
    equityType: "Type of equity",
    reportingLevel: "Reporting Level",
  },
  headers: {
    addAsset: "Add Asset",
    AddAbatement: "Add Abatement Curve Data",
    abatementId: "Abatement ID",
    editAsset: "Edit Asset",
    editAbatement: "Edit Abatement Curve Data",
  },
  placeholder: {
    filterAbatement: "Select Initiative",
    status: "Select Status",
    assetId: "Select Asset ID",
    abatementId: "Abatement ID",
    initiative: "Select Initiative",
    assetName: "Enter Asset Name",
    abatementCost: "Enter Abatement Cost (USD/t CH₄)",
    emission: "Select Emission Sub-category",
    assetType: "Select Asset Type",
    assetDescription: "Enter Description",
    comments: "Enter Comments",
    percentAbated: "Enter Abated Percentage",
    volume: "Enter Volume abated (t of CH₄)",
    country: "Select Country",
    latitude: "Enter Latitude",
    longitude: "Enter Longitude",
    operated: "Select Operated?",
    capex: "Enter CAPEX (MUSD)",
    operator: "Select Operator",
    equityPercentage: `Enter Value`,
    equityType: "Select Specify type of equity",
    reportingLevel: "Select Reporting Level",
  },
  button: {
    submit: "Submit",
    addAsset: "Add Asset",
    editAsset: "Edit Asset",
  },
  text: {
    addAssetAlert: "Asset added successfully.",
    addAbatementAlert: "Abatement curve data added successfully",
    editAssetAlert: "Asset updated successfully.",
    editAbatementAlert: "Abatement curve data updated successfully",
  },
  name: {
    assetId: "assetId",
    status: "status",
    abatementId: "Abatement ID",
    asset_id: "asset_id",
    assetName: "assetName",
    assetType: "assetType",
    emission: "emission",
    capex: "capex",
    volume: "volume",
    percentAbated: "percentAbated",
    initiative: "initiative",
    comments: "comments",
    abatementCost: "abatementCost",
    assetDescription: "assetDescription",
    country: "country",
    latitude: "latitude",
    longitude: "longitude",
    operated: "operated",
    operator: "operator",
    equityPercentage: `equityPercentage`,
    equityType: "equityType",
    reportingLevel: "reportingLevel",
  },
  columnName: {
    assetId: "Asset ID",
    status: "Status",
    abatementId: "Abatement ID",
    assetName: "Asset Name",
    abatementName: "Abatement Name",
    percentAbated: "% Abated",
    initiative: "Initiative",
    emissionSubCat: "Emission Sub-category",
    volume: "Volume Abated (t of CH₄)",
    abated: "% Abated",
    abatementCost: "Abatement Cost (USD/t CH₄)",
    capex: "CAPEX (MUSD)",
    comments: "Comments",
    assetType: "Asset Type",
    assetDescription: "Description",
    country: "Country",
    latitude: "Latitude",
    longitude: "Longitude",
    operated: "Operated",
    operator: "Operator",
    equityPercentage: `%Equity`,
    specificEquity: "Equity Type",
    reportingLevel: "Reporting Level",
  },
};
