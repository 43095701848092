import { createTheme } from "@mui/material/styles";
import { PaletteOptions, PaletteColorOptions } from "@mui/material/styles/createPalette";

// Extend the PaletteOptions type to include your custom colors
declare module "@mui/material/styles" {
  interface Palette {
    lightGrey: PaletteColorOptions;
    darkGrey: PaletteColorOptions;
    green: PaletteColorOptions;
    red: PaletteColorOptions;
  }

  interface PaletteOptions {
    lightGrey?: PaletteColorOptions;
    darkGrey?: PaletteColorOptions;
    green?: PaletteColorOptions;
    red?: PaletteColorOptions;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#29292A", // primary black color as the main primary color
    },
    secondary: {
      main: "#ffffff",
    },
    darkGrey: {
      main: "#777777",
    },
    lightGrey: {
      main: "#9DA3AE",
    },
    green: {
      main: "#64A70B",
    },
    red: {
      main: "#E4002B",
    },
  },
  typography: (palette) => ({
    fontFamily: "inter",

    h1: {
      fontSize: "28px",
      fontWeight: 700,
      lineHeight: "36.4px",
      color: "#3B3939"

    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "24.2px",
      color: palette.primary.main,
    },
    h3: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#3B3939"
    },
    h4: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21.78px",
      color: palette.primary.main,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19.36px",
      color: "#64A70B",
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "16.94px",
      color: "#64A70B",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      color: "#777777",
      "& .fixError": {
        fontSize: "0.875rem",
        fontWeight: 500,
        color: "#E4002B",
        wordBreak: "break-all",
      }

    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  }),
  components: {

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F4F3F4',
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: "#559700",
            borderWidth: "2px",
          },
          '&.Mui-focused ': {
            backgroundColor: '#FFF',
          },
          '&.Mui-error': {
            borderColor: "#E7563F",
            backgroundColor: '#FFEFE9',
            outline: '1px solid #E7563F'
          },
          '&.Mui-error:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: "#C8C6C7",
          },
          '&.Mui-disabled:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
            border: "1px solid #B3B0B1",
          },


        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // '&:hover': {
          //   backgroundColor: '#F4F3F4',
          // },
          '&.Mui-focused': {
            color: '#4D4B4C', // Customize the label color
            '& sup': {
              color: '#4D4B4C',
            },

          },
          '&.Mui-error': {
            color: "#4D4B4C",
            '& sup': {
              color: '#4D4B4C',
            },
          },
          '&.Mui-disabled': {
            color: "#898587",
            '& sup': {
              color: '#898587',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#559700",
            borderWidth: "2px",
            '& sup': {
              color: '#4D4B4C',
            },
          },
          '&.Mui-error': {
            backgroundColor: '#FFEFE9',
            outline: '1px solid #E7563F',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              // borderColor: "#E7563F",
            }
          },
          '&.Mui-disabled': {
            backgroundColor: "#F8FAFA !important",
            borderRadius: "10px",
          },
        },

      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#64A70B",
            borderWidth: "1px", // Customize the border color for focused TextField
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: "#E4002B", // Customize the error border color for TextField
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: "#B4B4B4", // Customize the disabled border color for TextField
          },
        },
      },
    },


    // Add more component overrides as needed
  },
});

export default theme;
