export const SiteConstants = {
  label: {
    filterAsset: "Asset ID",
    filterSIte: "Site Name",
    siteId: "Site ID",
    siteName: "Site Name",
    siteType: "Site Type",
    latitude: "Latitude",
    longitude: "Longitude",
    assetId: "Asset ID",
  },
  name: {
    siteId: "siteId",
    siteName: "siteName",
    siteType: "siteTypeId",
    siteTypeId: "siteTypeId",
    latitude: "latitude",
    longitude: "longitude",
    assetId: "assetId",
    asset_id: "asset_id",
    site_name: "site_name"

  },
  headers: {
    site: "Site Master",
    addSite: "Add Site",
    editSite: "Edit Site"
  },
  placeholder: {
    filterAsset: "Select Asset ID",
    filterSIte: "Enter Site Name",
    siteId: "Enter Site ID",
    siteName: "Enter Site Name",
    siteType: "Select Site Type",
    latitude: "Enter Latitude",
    longitude: "Enter Longitude",
    assetId: "Select Asset ID",
  },
  button: {
    addSite: "Add Site",
    filter: "FILTER BY",
    submit: "Submit",
    editSite: "Edit Site"

  },
  text: {
    addSiteAlert: "Site added successfully.",
    editSiteAlert: "Site updated successfully."

  }
}