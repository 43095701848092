import React from "react";
import {
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Stack,
  Box,
} from "@mui/material";
import { FilterModalProps } from "./interface";
import CloseIcon from "../icons/closeIcon";
import { CustomIconButton } from "../StatusModal/style";
import { CustomDialog, Footer, Wrapper } from "./style";
import { PrimaryButton, SecondaryButton } from "../button/button";
import { loaderSelector } from "../../../store/slices/loaderSlice";
import { useSelector } from "react-redux";

const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  title,
  children,
  width,
  clearFilters,
  applyFilters,
  filterDisabled = false,
  clearDisabled = false
}) => {
  const { isLoader } = useSelector(loaderSelector)

  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose(); // Close without arguments
        }
      }}
      width={width}
    >
      <form onSubmit={applyFilters}>
        <DialogTitle>
          {title}
          <CustomIconButton aria-label="close" sx={{ width: '24px', height: '24px', p: '6px' }} disableRipple onClick={onClose}>
            <CloseIcon />
          </CustomIconButton>
        </DialogTitle>
        {/* <Divider sx={{ borderColor: "#0000001A" }} /> */}
        <Wrapper>
          <DialogContent dividers>{children}</DialogContent>
        </Wrapper>
        <Footer>
          {/* <Divider /> */}
          <DialogActions>
            <Stack direction="row" spacing={"16px"}>
              <SecondaryButton
                label="Clear"
                variant="contained"
                width="76px"
                disabled={clearDisabled}
                onClick={clearFilters}
              />
              <PrimaryButton
                label="Apply"
                variant="contained"
                color="primary"
                width="80px"
                disabled={filterDisabled}
                type={'submit'}
              />
            </Stack>

          </DialogActions>
        </Footer>
      </form>
    </CustomDialog>
  );
};

export default FilterModal;
