import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, FormControl, FormLabel, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import {
  assetMasterSelector,
  getAssetListBySegment,
} from "../../store/slices/assetMaster";
import { FilterButton, PrimaryButton } from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import { AssetConstants } from "./constants";
import AddAsset from "./AddAsset";
import { useNavigate } from "react-router-dom";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";
import NewCrudTable from "../../components/atoms/DataTable/NewCrudTable";
import TableColumn from "./tableColumn";
import { filterByGroup, searchMasterList } from "../../utils/helper";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import FilterAsset from "./FilterAsset";
import PlusIcon from "../../components/atoms/icons/plusIcon";

export default function AssetMaster() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assetMasterSegmentData, totalSegment }: any =
    useSelector(assetMasterSelector);
  const { headers, button, text } = AssetConstants;
  const [addAssetVisibility, setAddAssetVisibility] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addAsset);
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { isLoader } = useSelector(loaderSelector);
  const [openAcc, setOpenAcc] = useState(0)
  const [filterVisibility, handleFilterVisibility] = useState(false)
  const [tableData, setTableData] = useState(assetMasterSegmentData)


  useEffect(() => {
    let params = `?order=DESC`;
    dispatch(getAssetListBySegment(params));
  }, []);

  useEffect(() => {
    if (assetMasterSegmentData?.length) {
      console.log(assetMasterSegmentData);
      setTableData(assetMasterSegmentData);
    }
  }, [assetMasterSegmentData]);

  useEffect(() => {
    if (searchText?.length > 1) {
      handleMasterSearch();
    } else {
      console.log(assetMasterSegmentData);
      setTableData(assetMasterSegmentData);
    }
  }, [searchText]);

  const toggleAddAsset = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string) => {
      setAddAssetVisibility(status);
      setPageTitle(headers.addAsset);
      if (showSuccessModal) {
        setAlertModalVisibility(true);
        dispatch(getAssetListBySegment(`?order=DESC`, false));
      }
    },
    []
  );

  const handleMasterSearch = () => {
    let result = searchMasterList(assetMasterSegmentData, searchText, ["asset_name", "asset_id"])
    setTableData(result)
  }

  const handleFilterData = (filterkeys: any) => {
    let result = filterByGroup(assetMasterSegmentData, filterkeys)
    setTableData(result)
  }

  const handleIconButtonClick = (row: any) => {
    navigate(`/asset-details/${row.asset_id}`);
  };

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={headers.addAsset}
        variant="contained"
        onClick={() => toggleAddAsset(true)}
        startIcon={<PlusIcon />}
        width="135px"
      />
    </Stack>
  );

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const handleHierarchyClick = (row: any) => {
    navigate(`/site-master/${row.asset_id}`);
  };

  const columns: any = new TableColumn({
    handleIconButtonClick,
    handleHierarchyClick,
  });

  if (addAssetVisibility) {
    return <AddAsset pageTitle={pageTitle} onBack={toggleAddAsset} />;
  }

  return (
    <>
      {isLoader?.isShow && isLoader.component === "assetList" && (
        <CircularLoader />
      )}
      <DefaultLayout
        renderHeaderButtons={renderHeaderButtons}
        pageTitle="Asset Master"
        activeTab="Assets"
      >
        <Box className="placeholder_casing">
          <NewCrudTable
            rows={tableData}
            columns={columns.column}
            showCheckbox={false}
            showSearch={true}
            openAcc={openAcc}
            setOpenAcc={setOpenAcc}
            setSearch={setSearchText}
            renderButtons={renderButtons}
            segmentHeader={"Segment"}
            searchPlaceholder="Search Asset ID or Name..."
          />
        </Box>
        <FilterAsset
          open={filterVisibility}
          onClose={() => {
            handleFilterVisibility(false);
          }}
          filterCB={(val: any) => {
            handleFilterData(val)
          }}
          clearFilter={() => {
            setTableData(assetMasterSegmentData)
          }}
        />
        <StatusModal
          type="success"
          bodyText={text.addAssetAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
      </DefaultLayout>
    </>
  );
}
