import { Pagination, Stack, TableBody, TableHead, styled } from "@mui/material";

export const StyledTableHead = styled(TableHead)(() => ({
  borderBottom: "1px solid #DDE3EC",

  "& .MuiTableCell-head": {
    padding: "15.5px 10px",
    color: "#2E2E2E",
    lineHeight: "16.94px",
    borderBottom: 0,
    "& .MuiTableSortLabel-root": {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "16.94px",
      color: "#2E2E2E",
    },
  },
}));

export const StyledTableBody = styled(TableBody)(() => ({
  "& .MuiTableCell-body": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16.94px",
    color: "#585962",
    padding: "21.5px 10px",
    borderBottom: 0,
    wordBreak: "break-word",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff", // light grey color for odd rows
  },
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#F9FAFD", // white color for even rows
  },
}));

export const StylePagination = styled(Pagination)(() => ({
  "& .MuiPagination-ul": {
    display: "flex",
    gap: "6px",
    "& li:first-child": {
      "& .MuiPaginationItem-root": {
        rotate: "180deg",
      }
    },
    "& .MuiPaginationItem-root": {
      display: "flex",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16.94px",
      color: "#636262b2",
      padding: "2px",
      minWidth: "20px",
      height: "20px",
      "&:hover": {
        backgroundColor: "#EFF1F7",
        borderRadius: "3px",
       
      },
      "&.Mui-selected": {
        backgroundColor: "#64a70b",
        borderRadius: "3px",
        color: "#fff",
      },
    },
  },
}));

export const StyleRowPerPage = styled(Stack)(() => ({
  alignItems: "center",
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    color: "#636262",
  },
}));
