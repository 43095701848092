import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import { EquipmentConstants } from "./constant";
import EyeOpenIcon from "../../components/atoms/icons/eyeopenIcon";
import ViewIcon from "../../components/atoms/icons/viewIcon";
import { ButtonChip, StatusChip } from "../../components/atoms/chip/chip";
import moment from "moment";

interface Payload {
  VerifyEmissionBtn: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    const { columnName } = EquipmentConstants;
    const createLink = (row: any) => {
      if (row?.sourceHierarchy === "Equipment" || row?.sourceHierarchy === "Asset") {
        let major = row?.sourceHierarchy === "Equipment" ? "equipment-details" : "asset-details";
        return <a className="linkData" href={`/${major}/${row?.sourceName}`}>
          {row?.sourceName}
        </a>
      }
      else return row?.sourceName;
    }
    this.column = [
      {
        id: "id",
        label: columnName.detectionId,
        width: "10%",
        render: (row: Row) => (
          <a className="linkData" href={`/emission-details/${row?.id}`}>
            {row?.id}
          </a>
        ),
      },
      {
        id: "siteId",
        label: columnName.detectionMethod,
        width: "10%",
        render: (row: Row) => (row?.detectionMethod && row?.detectionType && (row?.detectionMethod === 'Bottom-Up')) ? row?.detectionMethod + ` (${row?.detectionType})` : row?.detectionMethod,
      },
      {
        id: "type",
        label: columnName?.sourceHierarchy,
        width: "10%",
        render: (row: Row) => row?.sourceHierarchy,
      },
      {
        id: "sourceName",
        label: columnName?.sourceName,
        width: "10%",
        render: (row: Row) => createLink(row),
      },
      {
        id: "emissionRate",
        label: columnName?.emissionRate,
        width: "8%",
        render: (row: Row) => row?.emissionRate,
      },
      {
        id: "agentProcessed",
        label: columnName.emissionSubCategory,
        width: "10%",
        render: (row: Row) => !!row?.emissionSubcategory ? row?.emissionSubcategory : "Unassigned",
      },
      {
        id: "cumulativeOperatingHours",
        label: columnName.detectionDateTime,
        width: "12%",
        render: (row: Row) => moment(row?.detectionDate).format("DD/MM/YYYY") + " " + (row?.detectionTime === null ? '' : (row?.detectionTime).substring(0, 5)),
      },

      {
        id: "status",
        label: "Status",
        width: "7%",
        render: (row: Row) => (
          !row?.status ? <StatusChip label={row?.status ? "Verified" : "Not Verified"} type="Not Verified" /> :
            <StatusChip label="Verified" type="Verified" />
        ),
      },
      {
        id: "actions",
        label: "Actions",
        width: "7%",
        render: (row: Row) => payload.VerifyEmissionBtn(row)
      },
    ];
  }
}

export default TableColumn;
