// @ts-nocheck
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Grid, Stack } from "@mui/material";
import { GasFactorConstants } from "./constants";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/atoms/InputField/InputField";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { PrimaryButton } from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import {
  addAsset,
  assetMasterSelector,
  editAsset,
  getAssetList,
  getAssetTypes,
  getCountries,
  getEquityTypes,
  getOperators,
} from "../../store/slices/assetMaster";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validations";
import ArrowRight from "../../components/atoms/icons/arrowRight";
import TextArea from "../../components/atoms/textarea/textarea";
import CustomLabel from "../../components/atoms/label/label";
import {
  addGasFactor,
  editGasFactor,
  gasMasterSelector,
  getGasTypes,
} from "../../store/slices/gasFactorMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { AddGasProps } from "./interface";
import { AddGasSubmitProps } from "./interface";
import { FieldArray } from "./addGasFactorFIeld";
import { roundOffNumber, removeTrailingZeros } from "../../utils/helper";

const AddGasFactor = React.memo(
  ({ pageTitle, onBack, editGasData }: AddGasProps) => {
    const dispatch: any = useAppDispatch();
    const { assetMasterData } = useSelector(assetMasterSelector);
    const { gasMasterData, gasTypes } = useSelector(gasMasterSelector);
    const { label, placeholder, button, name } = GasFactorConstants;
    const { isLoader } = useSelector(loaderSelector)
    // Initialize react-hook-form
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        massContentOfMethane: "",
        densityOfGas: "",
        assetId: "",
        gasType: "",
      },
      resolver: yupResolver(validations),
    });

    const [isEdit, setEdit] = useState(editGasData ? true : false);

    useEffect(() => {
      dispatch(getGasTypes("?limit=9999"));
      dispatch(getAssetList("?limit=9999"));
      setEditData();
    }, []);

    const setEditData = () => {
      if (editGasData) {
        const editData = { ...editGasData };
        const assetId = assetMasterData?.filter(
          (val) => val?.assetId === editData?.assetId?.assetId
        );
        const gasType = gasTypes?.filter(
          (val) => val?.gasType === editData?.gasType?.gasType
        );
        for (var key in editData) {
          if (key === "assetId" && assetMasterData?.length) {
            setValue(name[key], assetId[0]?.assetId);
          } else if (key === "gasType" && gasTypes?.length) {
            setValue(name[key], gasType[0]?.id);
          } else if (key === "massContentOfMethane") {
            setValue(name[key], roundOffNumber((editData[key] * 100).toPrecision(3)));
          } else if (key === "densityOfGas") {
            setValue(name[key], roundOffNumber(removeTrailingZeros(editData[key])));
          }
        }
      }
    };

    const onSubmit: SubmitHandler<any> = (payload: AddGasSubmitProps) => {
      const reqPayload = { ...payload };
      reqPayload.massContentOfMethane = parseFloat(
        payload.massContentOfMethane / 100
      );
      reqPayload.gasType = parseInt(reqPayload?.gasType);
      reqPayload.densityOfGas = parseFloat(reqPayload?.densityOfGas);
      if (editGasData) {
        dispatch(editGasFactor(reqPayload, editGasData?.id, successCB));
      } else {
        dispatch(addGasFactor(reqPayload, successCB));
      }
    };

    const successCB = (alert: boolean) => {
      let type = editGasData?.assetId ? "edit" : "add";
      onBack(false, alert || false, type);
    };

    const renderHeaderButtons = () => (
      <Stack direction="row" spacing={2}>
        <PrimaryButton
          label={button.submit}
          variant="contained"
          type={"submit"}
          // endIcon={<ArrowRight sx={{ width: "7px" }} />}
          width="95px"
        />
      </Stack>
    );

    const { fieldArray } = new FieldArray({
      gasTypes,
      isEdit,
      assetMasterData,
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          (isLoader?.isShow && isLoader.component === "gasFactor") && <CircularLoader />
        }
        <DefaultLayout
          pageTitle={pageTitle}
          // renderHeaderButtons={renderHeaderButtons}
          backHandler={() => onBack(false)}
        >
          <Grid container spacing={"30px"} sx={{ p: "10px 0 10px" }}>
            {fieldArray?.map((field: any) => (
              <>
                {field?.type === "input" && (
                  <Grid item xs={6} md={4}>
                    <InputField
                      label={label[field?.fieldName]}
                      type={
                        field?.fieldName === "massContentOfMethane" ||
                          field?.fieldName === "densityOfGas"
                          ? "number"
                          : "text"
                      }
                      disabled={field?.disabled}
                      name={name[field?.fieldName]}
                      register={register}
                      placeholder={placeholder[field?.fieldName]}
                      errorText={errors[field?.fieldName]?.message}
                    />
                  </Grid>
                )}
                {field?.type === "dropdown" && (
                  <Grid item xs={6} md={4}>
                    <SelectField
                      selectlabel={label[field?.fieldName]}
                      placeholder={placeholder[field?.fieldName]}
                      options={field?.options}
                      name={name[field?.fieldName]}
                      disabled={field?.disabled}
                      register={register}
                      value={watch(name[field?.fieldName])}
                      errorText={errors[field?.fieldName]?.message}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Stack direction="row" spacing={2} sx={{ marginTop: '20px' }}>
            <PrimaryButton
              label={button.submit}
              variant="contained"
              type={"submit"}
              // endIcon={<ArrowRight sx={{ width: "7px" }} />}
              width="95px"
            />
          </Stack>
        </DefaultLayout>
      </form>
    );
  }
);

export default AddGasFactor;
