import { Box } from '@mui/material'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import './loader.css'

export default function Loader() {
  return (
    <Box className="loader-container">
      <CircularProgress style={{ color: "#64A70B" }} />
    </Box>
  )
}
