import React from "react";
export default function EyeCloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3662_32912)">
        <path
          d="M9.73808 8.1123L10.5332 7.35501C10.5911 7.56116 10.625 7.77668 10.625 8.00001C10.625 9.37855 9.44747 10.5 8 10.5C7.7655 10.5 7.5392 10.4677 7.32275 10.4126L8.11791 9.65532C8.98733 9.59886 9.6788 8.94032 9.73808 8.1123ZM14.9449 7.79762C14.8911 7.70543 14.1278 6.43179 12.636 5.35242L11.9993 5.95877C13.0871 6.72054 13.7764 7.60897 14.0478 8.00064C13.5292 8.75261 11.4951 11.3333 8 11.3333C7.47588 11.3333 6.9885 11.2695 6.52989 11.1676L5.81097 11.8523C6.47192 12.0453 7.19839 12.1667 8 12.1667C12.5799 12.1667 14.8505 8.36418 14.9449 8.20241C15.0184 8.07647 15.0184 7.92355 14.9449 7.79762ZM13.1218 3.71127L3.49681 12.8779C3.41139 12.9593 3.29939 13 3.1875 13C3.07561 13 2.96361 12.9593 2.87819 12.8779C2.70723 12.7151 2.70723 12.4515 2.87819 12.2888L4.10275 11.1225C2.12306 9.98428 1.11714 8.30876 1.05513 8.20241C0.981625 8.07647 0.981625 7.92345 1.05513 7.79751C1.14952 7.63585 3.42014 3.83336 8 3.83336C9.1842 3.83336 10.2099 4.09065 11.0868 4.47106L12.5032 3.12211C12.6741 2.9593 12.951 2.9593 13.1218 3.12211C13.2927 3.28492 13.2928 3.54857 13.1218 3.71127ZM4.75517 10.5012L5.86281 9.44626C5.55689 9.03751 5.375 8.53907 5.375 8.00001C5.375 6.62147 6.55253 5.50002 8 5.50002C8.56602 5.50002 9.08938 5.67325 9.51856 5.9645L10.4142 5.11148C9.70658 4.84158 8.90245 4.66669 8 4.66669C4.50492 4.66669 2.47077 7.24741 1.95233 7.99939C2.28155 8.47459 3.22786 9.67751 4.75517 10.5012ZM6.49795 8.84126L8.88331 6.5695C8.62311 6.42314 8.32353 6.33335 8 6.33335C7.03509 6.33335 6.25 7.08106 6.25 8.00001C6.25 8.30814 6.34428 8.59345 6.49795 8.84126Z"
          fill="#5E574D"
        />
      </g>
      <defs>
        <clipPath id="clip0_3662_32912">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
