import React from "react";
export default function DuplicateIcon() {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.14844 8.28516V18.5468C1.14901 19.0252 1.33932 19.4839 1.67765 19.8222C2.01597 20.1606 2.47467 20.3509 2.95312 20.3514H13.2144C13.6929 20.3508 14.1515 20.1605 14.4899 19.8222C14.8282 19.4839 15.0185 19.0252 15.0191 18.5468V8.28516C15.0185 7.80671 14.8282 7.34802 14.4899 7.00971C14.1515 6.67139 13.6929 6.48106 13.2144 6.48047H2.95312C2.47467 6.48104 2.01597 6.67136 1.67765 7.00968C1.33932 7.348 1.14901 7.8067 1.14844 8.28516ZM14.0347 8.28516V18.5468C14.0345 18.7642 13.948 18.9728 13.7942 19.1266C13.6404 19.2804 13.4319 19.3669 13.2144 19.3671H2.95312C2.73563 19.3668 2.52712 19.2803 2.37333 19.1265C2.21954 18.9728 2.13304 18.7642 2.13281 18.5468V8.28516C2.13304 8.06767 2.21954 7.85915 2.37333 7.70536C2.52712 7.55157 2.73563 7.46507 2.95312 7.46484H13.2144C13.4319 7.46505 13.6404 7.55154 13.7942 7.70533C13.948 7.85913 14.0345 8.06766 14.0347 8.28516Z" fill="#E4002B" />
      <path d="M6.96509 4.5567V3.75113C6.96548 3.4546 7.08348 3.17033 7.29319 2.96068C7.5029 2.75104 7.7872 2.63312 8.08373 2.63281H9.29192C9.42122 2.63092 9.54457 2.57824 9.63533 2.48614C9.7261 2.39404 9.77697 2.26993 9.77697 2.14062C9.77697 2.01132 9.7261 1.88721 9.63533 1.79511C9.54457 1.70301 9.42122 1.65033 9.29192 1.64844H8.08374C7.52622 1.64903 6.9917 1.87074 6.59744 2.26493C6.20319 2.65912 5.98139 3.19361 5.98071 3.75113V4.5567C5.98071 4.68724 6.03257 4.81243 6.12487 4.90473C6.21717 4.99703 6.34236 5.04889 6.4729 5.04889C6.60344 5.04889 6.72863 4.99703 6.82093 4.90473C6.91323 4.81243 6.96509 4.68724 6.96509 4.5567Z" fill="#E4002B" />
      <path d="M19.3594 11.7158C19.2289 11.7159 19.1037 11.7677 19.0114 11.86C18.9191 11.9523 18.8673 12.0775 18.8672 12.208V13.416C18.8669 13.7126 18.749 13.997 18.5392 14.2067C18.3295 14.4164 18.0452 14.5343 17.7486 14.5347H16.9434C16.8128 14.5347 16.6876 14.5865 16.5953 14.6788C16.503 14.7711 16.4512 14.8963 16.4512 15.0269C16.4512 15.1574 16.503 15.2826 16.5953 15.3749C16.6876 15.4672 16.8128 15.5191 16.9434 15.5191H17.7486C18.3062 15.5184 18.8407 15.2967 19.235 14.9024C19.6292 14.5082 19.851 13.9736 19.8516 13.416V12.208C19.8516 12.0775 19.7997 11.9523 19.7074 11.86C19.6151 11.7677 19.49 11.7159 19.3594 11.7158Z" fill="#E4002B" />
      <path d="M17.7486 1.64844H16.5406C16.4112 1.65027 16.2878 1.70293 16.197 1.79504C16.1062 1.88714 16.0553 2.01129 16.0553 2.14063C16.0553 2.26998 16.1062 2.39412 16.197 2.48623C16.2878 2.57833 16.4113 2.63099 16.5406 2.63281H17.7486C18.0451 2.63312 18.3294 2.75104 18.5391 2.96068C18.7488 3.17033 18.8668 3.4546 18.8672 3.75113V4.95949C18.8672 5.09002 18.9191 5.21521 19.0114 5.30752C19.1037 5.39982 19.2289 5.45168 19.3594 5.45168C19.49 5.45168 19.6152 5.39982 19.7075 5.30752C19.7998 5.21521 19.8516 5.09002 19.8516 4.95949V3.75113C19.8509 3.19361 19.6291 2.65913 19.2349 2.26494C18.8406 1.87074 18.3061 1.64903 17.7486 1.64844Z" fill="#E4002B" />
      <path d="M14.1245 2.63281C14.2538 2.63092 14.3771 2.57823 14.4679 2.48613C14.5586 2.39403 14.6095 2.26992 14.6095 2.14062C14.6095 2.01131 14.5586 1.8872 14.4679 1.7951C14.3771 1.70301 14.2537 1.65032 14.1244 1.64844H11.7081C11.5788 1.65033 11.4554 1.70302 11.3647 1.79512C11.2739 1.88722 11.223 2.01133 11.223 2.14063C11.223 2.26994 11.2739 2.39405 11.3647 2.48615C11.4554 2.57824 11.5788 2.63093 11.7081 2.63281H14.1245Z" fill="#E4002B" />
      <path d="M19.3594 6.88379C19.2288 6.88382 19.1037 6.93569 19.0114 7.02798C18.9191 7.12028 18.8672 7.24545 18.8672 7.37598V9.79205C18.8691 9.92134 18.9218 10.0447 19.0139 10.1355C19.106 10.2262 19.2301 10.2771 19.3594 10.2771C19.4887 10.2771 19.6128 10.2262 19.7049 10.1354C19.797 10.0447 19.8497 9.92132 19.8516 9.79203V7.37598C19.8515 7.24545 19.7997 7.12028 19.7074 7.02798C19.6151 6.93569 19.4899 6.88382 19.3594 6.88379Z" fill="#E4002B" />
    </svg>

  );
}
