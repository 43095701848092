import React from "react";
export default function ActiveMasterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
<path d="M10 9H4.35C3.78754 9 3.50631 9 3.30916 9.1432C3.24549 9.1895 3.1895 9.2455 3.14324 9.3092C3 9.5063 3 9.7875 3 10.35V12.25M10 9V12.25M10 9V5.75M10 9H15.65C16.2125 9 16.4937 9 16.6908 9.1432C16.7545 9.1895 16.8105 9.2455 16.8568 9.3092C17 9.5063 17 9.7875 17 10.35V12.25" stroke="#3C8200" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M10.05 5.75C11.1749 5.75 11.7374 5.75 12.1317 5.46353C12.259 5.37101 12.371 5.25902 12.4635 5.13168C12.75 4.73738 12.75 4.17492 12.75 3.05V2.95C12.75 1.82508 12.75 1.26262 12.4635 0.86832C12.371 0.74098 12.259 0.62899 12.1317 0.53647C11.7374 0.25 11.1749 0.25 10.05 0.25H9.95C8.8251 0.25 8.2626 0.25 7.86832 0.53647C7.74098 0.62899 7.62899 0.74098 7.53647 0.86832C7.25 1.26262 7.25 1.82508 7.25 2.95V3.05C7.25 4.17492 7.25 4.73738 7.53647 5.13168C7.62899 5.25902 7.74098 5.37101 7.86832 5.46353C8.2626 5.75 8.8251 5.75 9.95 5.75H10.05Z" fill="#3C8200"/>
<path d="M10.05 17.75C11.1749 17.75 11.7374 17.75 12.1317 17.4635C12.259 17.371 12.371 17.259 12.4635 17.1317C12.75 16.7374 12.75 16.1749 12.75 15.05V14.95C12.75 13.8251 12.75 13.2626 12.4635 12.8683C12.371 12.741 12.259 12.629 12.1317 12.5365C11.7374 12.25 11.1749 12.25 10.05 12.25H9.95C8.8251 12.25 8.2626 12.25 7.86832 12.5365C7.74098 12.629 7.62899 12.741 7.53647 12.8683C7.25 13.2626 7.25 13.8251 7.25 14.95V15.05C7.25 16.1749 7.25 16.7374 7.53647 17.1317C7.62899 17.259 7.74098 17.371 7.86832 17.4635C8.2626 17.75 8.8251 17.75 9.95 17.75H10.05Z" fill="#3C8200"/>
<path d="M17.05 17.75C18.1749 17.75 18.7374 17.75 19.1317 17.4635C19.259 17.371 19.371 17.259 19.4635 17.1317C19.75 16.7374 19.75 16.1749 19.75 15.05V14.95C19.75 13.8251 19.75 13.2626 19.4635 12.8683C19.371 12.741 19.259 12.629 19.1317 12.5365C18.7374 12.25 18.1749 12.25 17.05 12.25H16.95C15.8251 12.25 15.2626 12.25 14.8683 12.5365C14.741 12.629 14.629 12.741 14.5365 12.8683C14.25 13.2626 14.25 13.8251 14.25 14.95V15.05C14.25 16.1749 14.25 16.7374 14.5365 17.1317C14.629 17.259 14.741 17.371 14.8683 17.4635C15.2626 17.75 15.8251 17.75 16.95 17.75H17.05Z" fill="#3C8200"/>
<path d="M3.05 17.75C4.17492 17.75 4.73738 17.75 5.13168 17.4635C5.25902 17.371 5.37101 17.259 5.46353 17.1317C5.75 16.7374 5.75 16.1749 5.75 15.05V14.95C5.75 13.8251 5.75 13.2626 5.46353 12.8683C5.37101 12.741 5.25902 12.629 5.13168 12.5365C4.73738 12.25 4.17492 12.25 3.05 12.25H2.95C1.82508 12.25 1.26262 12.25 0.86832 12.5365C0.74098 12.629 0.62899 12.741 0.53647 12.8683C0.25 13.2626 0.25 13.8251 0.25 14.95V15.05C0.25 16.1749 0.25 16.7374 0.53647 17.1317C0.62899 17.259 0.74098 17.371 0.86832 17.4635C1.26262 17.75 1.82508 17.75 2.95 17.75H3.05Z" fill="#3C8200"/>
</svg>
  );
}
