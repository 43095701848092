import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import { isNumberRequired, numberIsRequied, decimalPrecisionRequired } from "../../utils/validationHelper";

export const validations = yup.object().shape({
  gasType: yup.string().required("Field is required"),
  assetId: yup.string().required("Field is required"),
  massContentOfMethane: numberIsRequied(3),
  densityOfGas: decimalPrecisionRequired(2, 8)
});
