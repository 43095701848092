import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import { useSelector } from "react-redux";
import {
  abatementMasterSelector,
  getAbatementDetails,
  getAbatementList,
} from "../../store/slices/abatementMaster";
import TableColumn from "./tableColumn";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { AbatementConstants } from "./constants";
import {
  FilterButton,
  PrimaryButton,
} from "../../components/atoms/button/button";
import PlusIcon from "../../components/atoms/icons/plusIcon";
import AddAbatement from "./AddAbatement";
import {
  loaderSelector,
  setLoderVisibility,
} from "../../store/slices/loaderSlice";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import FilterAbatement from "./FilterAbatement";
import { assetMasterSelector } from "../../store/slices/assetMaster";
import MasterService from "../../api/services/masterService";

export default function AbatementMaster() {
  const dispatch = useAppDispatch();
  const { abatementMasterData, total }: any = useSelector(
    abatementMasterSelector
  );
  const navigate = useNavigate();
  const { headers, button, text } = AbatementConstants;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [details, setDetails] = useState<any>({});
  const [editAssetOpen, setEditAssetOpen] = useState(false);
  const [addAssetVisibility, setAddAssetVisibility] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addAsset);
  const [searchText, setSearchText] = useState("");
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [successModalVisibility, setSuccessModalVisibility] =
    useState<any>(false);
  const { isLoader } = useSelector(loaderSelector);
  const [assetFilter, setAssetFilter] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);

  const getInitiativeList = async () => {
    const res = await MasterService.getInitiativeList(
      `?page=${1}&limit=${99}&order=DESC`,
      false
    );
    if (res.status === 200) {
      setFilterData(res.data.data);
    }
  };

  useEffect(() => {
    getInitiativeList();
    let params = `?page=${page}&limit=${pageSize}&order=DESC`;
    if (assetFilter?.length) {
      assetFilter?.forEach((item: any) => {
        params += `&initiative_id=${item}`;
      });
    }
    if (searchText.length > 2) {
      dispatch(getAbatementList(params + `&q=${searchText}`));
    } else {
      dispatch(getAbatementList(params));
    }
  }, [page, pageSize, searchText, successModalVisibility, assetFilter]);

  const toggleAddAsset = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string) => {
      setAddAssetVisibility(status);
      setPageTitle(headers.AddAbatement);
      if (showSuccessModal) {
        setAlertModalVisibility(true);
        setPage(page);
        dispatch(
          getAbatementList(`?page=${page}&limit=${pageSize}&order=DESC`, false)
        );
      }
    },
    []
  );

  const successCB = async (responseData: any) => {
    setDetails(responseData);
    if (details) {
      setEditAssetOpen(true);
    }
  };

  const handleFilterVisibility = (state: boolean) => {
    setOpen(state);
  };

  const handleIconButtonClick = (row: any) => {
    dispatch(getAbatementDetails(row.id, successCB));
  };

  const toggleEditAbatement = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: any) => {
      setEditAssetOpen(status);
      setSuccessModalVisibility(showSuccessModal);
      if (showSuccessModal) {
        // dispatch(getAssetDetails(type, successCB));
      }
    },
    []
  );

  if (editAssetOpen) {
    return (
      <AddAbatement
        pageTitle={headers.editAbatement}
        onBack={toggleEditAbatement}
        editAbatementData={details}
      />
    );
  }

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={headers.AddAbatement}
        variant="contained"
        onClick={() => toggleAddAsset(true)}
        startIcon={<PlusIcon />}
        // width="300px"
        className="small-btn"
      />
    </Stack>
  );

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const columns: any = new TableColumn({ handleIconButtonClick });

  if (addAssetVisibility) {
    return <AddAbatement pageTitle={pageTitle} onBack={toggleAddAsset} />;
  }

  return (
    <>
      {isLoader?.isShow && isLoader.component === "assetList" && (
        <CircularLoader />
      )}
      <DefaultLayout
        renderHeaderButtons={renderHeaderButtons}
        pageTitle="Abatement Curve Master"
      // backHandler={() => false}
      >
        <Box>
          <CrudTable
            rows={abatementMasterData}
            columns={columns.column}
            initialRowsPerPage={5}
            showCheckbox={false}
            showSearch={true}
            page={page}
            total={total}
            rowsPerPage={pageSize}
            setPage={setPage}
            setRowsPerPage={setPageSze}
            setSearch={setSearchText}
            renderButtons={renderButtons}
            searchPlaceholder="Search Asset ID..."
          />
        </Box>
        {filterData && (
          <FilterAbatement
            open={open}
            assetData={filterData}
            onClose={() => {
              handleFilterVisibility(false);
            }}
            filterCB={(val: any) => {
              setAssetFilter(val);
              setPage(1);
            }}
          />
        )}
        <StatusModal
          type="success"
          bodyText={text.addAbatementAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
        <StatusModal
          type="success"
          bodyText={text.editAbatementAlert}
          open={successModalVisibility}
          onClose={() => setSuccessModalVisibility(false)}
        />
      </DefaultLayout>
    </>
  );
}
