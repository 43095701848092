import React from "react";
export default function UplaodIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M2.24426 13.2686C2.42659 13.2686 2.60146 13.341 2.73039 13.4699C2.85933 13.5989 2.93176 13.7737 2.93176 13.9561V16.3039C2.93176 16.8573 3.15159 17.388 3.5429 17.7793C3.9342 18.1706 4.46493 18.3904 5.01832 18.3904H16.9808C17.5342 18.3904 18.0649 18.1706 18.4562 17.7793C18.8475 17.388 19.0674 16.8573 19.0674 16.3039V13.9561C19.0674 13.7737 19.1398 13.5989 19.2687 13.4699C19.3977 13.341 19.5725 13.2686 19.7549 13.2686C19.9372 13.2686 20.1121 13.341 20.241 13.4699C20.37 13.5989 20.4424 13.7737 20.4424 13.9561V16.3039C20.4415 17.2217 20.0765 18.1016 19.4275 18.7506C18.7785 19.3995 17.8986 19.7645 16.9808 19.7654H5.01832C4.10053 19.7645 3.2206 19.3995 2.57163 18.7506C1.92266 18.1016 1.55767 17.2217 1.55676 16.3039V13.9561C1.55676 13.7737 1.62919 13.5989 1.75812 13.4699C1.88705 13.341 2.06192 13.2686 2.24426 13.2686Z"
        fill="white"
      />
      <path
        d="M14.4373 7.32178L11.6873 4.57178V14.7812C11.6873 14.9635 11.6149 15.1384 11.486 15.2673C11.357 15.3962 11.1822 15.4687 10.9998 15.4687C10.8175 15.4687 10.6426 15.3962 10.5137 15.2673C10.3848 15.1384 10.3123 14.9635 10.3123 14.7812V4.58209L7.56234 7.33209C7.49846 7.39597 7.42263 7.44664 7.33917 7.48121C7.25571 7.51578 7.16626 7.53357 7.07593 7.53357C6.9856 7.53357 6.89615 7.51578 6.81269 7.48121C6.72923 7.44664 6.6534 7.39597 6.58952 7.33209C6.52565 7.26822 6.47498 7.19238 6.44041 7.10893C6.40584 7.02547 6.38805 6.93602 6.38805 6.84569C6.38805 6.75535 6.40584 6.6659 6.44041 6.58244C6.47498 6.49899 6.52565 6.42316 6.58952 6.35928L10.5117 2.43709C10.6073 2.34439 10.726 2.27909 10.8555 2.24803C10.9667 2.22755 11.0813 2.23402 11.1895 2.26692C11.2978 2.29981 11.3966 2.35817 11.4776 2.43709L15.3998 6.34897C15.509 6.4803 15.5653 6.64756 15.5579 6.81817C15.5505 6.98878 15.4799 7.15053 15.3598 7.27192C15.2397 7.39332 15.0787 7.46567 14.9082 7.47488C14.7377 7.4841 14.5698 7.42952 14.4373 7.32178Z"
        fill="white"
      />
    </svg>
  );
}
