import styled from "@emotion/styled";
import { Dialog, IconButton } from "@mui/material";
import { min } from "moment";

export const Wrapper = styled("div")(() => ({
  padding: "0 24px 24px",
}));

export const CustomDialog = styled(Dialog)<{ width?: string, minWidth?: string}>(
  ({ width,minWidth}) => ({
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      maxWidth: width || "auto",
      minWidth: minWidth || "20.125rem",      
      padding: "0px",
      boxShadow:"unset",
      "& .MuiDialogTitle-root": {       
        padding: "24px 24px 20px",       
      },
      "& .MuiDialogContent-root": {
        border: "none",
        padding: 0,
      },

      
    
    },
  })
);

export const CustomIconButton = styled(IconButton)((theme) => ({
  position: "absolute",
  right: "30px",
  top: "22px",  
}));
