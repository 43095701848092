import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ForwordArrowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M17.7604 6.41708L12.0331 0.829363C11.8788 0.683956 11.6721 0.603497 11.4576 0.605316C11.2431 0.607135 11.0379 0.691085 10.8862 0.839087C10.7345 0.987088 10.6484 1.1873 10.6466 1.3966C10.6447 1.60589 10.7272 1.80753 10.8762 1.95808L15.2069 6.1832H0.81819C0.601192 6.1832 0.393083 6.2673 0.239642 6.417C0.0862019 6.5667 0 6.76973 0 6.98144C0 7.19315 0.0862019 7.39619 0.239642 7.54589C0.393083 7.69559 0.601192 7.77969 0.81819 7.77969H15.2069L10.8762 12.0048C10.7981 12.0784 10.7357 12.1665 10.6928 12.2639C10.65 12.3613 10.6274 12.466 10.6264 12.572C10.6255 12.678 10.6462 12.7831 10.6873 12.8812C10.7285 12.9793 10.7892 13.0685 10.8661 13.1434C10.9429 13.2184 11.0342 13.2776 11.1348 13.3178C11.2353 13.3579 11.3431 13.3781 11.4517 13.3772C11.5604 13.3763 11.6677 13.3542 11.7675 13.3124C11.8674 13.2706 11.9576 13.2098 12.0331 13.1335L17.7604 7.5458C17.9138 7.39611 18 7.19311 18 6.98144C18 6.76978 17.9138 6.56678 17.7604 6.41708Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export default ForwordArrowIcon;
