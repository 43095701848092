import React, { useEffect, useState } from 'react';
import FilterModal from '../../components/atoms/FilterModal/FilterModal';
import { Grid } from '@mui/material';
import { FilterPaylod, FilterSiteProps } from './interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SiteConstants } from './constants';
import { convertArrayToObject } from '../../utils/helper';
import { useAppDispatch } from '../../store/hooks';
import MultiSelectField from '../../components/atoms/MultiSelect/MultiSelectField';

const FilterSite = ({ open, onClose, siteTypeList, filterCB, clearFilter }: FilterSiteProps) => {
  // Destructure constants from SiteConstants
  const { label, placeholder, name } = SiteConstants;
  const [clearDisabled, setClearDisabled] = useState(false)
  const [filterDisabled, setFilterDisabled] = useState(false)

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      siteType: []
    }
  });

  useEffect(() => {
    localStorage.removeItem("siteFilter")
  }, [])

  useEffect(() => {
    if (watch("siteType")) {
      const val: any = watch("siteType")
      if (!val?.length) {
        setClearDisabled(true)
        setFilterDisabled(true)
      } else {
        setClearDisabled(false)
        setFilterDisabled(false)
      }
      localStorage.setItem("siteFilter", JSON.stringify(val))
    }
  }, [watch("siteType")])

  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("siteFilter")
    reset({ siteType: [] });
    onClose();
    clearFilter()
  }


  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("siteType"))
    onClose();
  }

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title='FILTER BY'
        width='475px'
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        clearFilters={handleClearFilters}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={placeholder.siteType}
              options={convertArrayToObject(siteTypeList, "name", "name")}
              placeholder={placeholder.siteType}
              name={"siteType"}
              register={register}
              value={watch("siteType") || []}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterSite;