import { Box, Button, colors } from '@mui/material';
import { styled } from '@mui/system';
import { StylesConfig } from 'react-select';
import { Option } from './interface';



export const selectStyles: StylesConfig<Option, false> = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #64A70B !important" : "1px solid #EAEEF3",
    boxShadow: "none",
    borderRadius: "6px",
    padding: "9px 8px",
    margin: "10px 5px 6px",
    minHeight: "35px,",
    flexDirection: "row-reverse",

  }),

  valueContainer: (provided, state) => ({
    ...provided,

    padding: '0 8px 0 3px',

    fontSize: '14px'


  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: "#777777",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15px",
    textTransform: "capitalize",
    margin: 0
  }),

  input: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    color: "#29292A",
    fontWeight: "400",
    lineHeight: "15px",
    margin: 0,
    padding: 0,

  }),

  menu: (provided, state) => ({
    ...provided,
    position: state.selectProps.menuPlacement === 'top' ? 'absolute' : 'relative',
    boxShadow: state.selectProps.menuPlacement === 'bottom' ? 'none' : '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
  }),

  menuList: () => ({
    maxHeight: "200px",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D7DBE6",
      borderRadius: "50px",
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "green",
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "orange",
  }),
};

export const StyledButton = styled(Button)<{ bgcolor?: string }>(({ theme, bgcolor }) => ({
  width: "100%",
  height: "48px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  border: bgcolor === "grey" ? "transparent" : "1px solid #B3B0B1",
  borderRadius: "8px",
  padding: "12px",
  backgroundColor: bgcolor === "grey" ? "#F4F3F4" : "#fff",

  "&:hover": {
    backgroundColor: bgcolor === "grey" ? "#F4F3F4" : "#fff",
  },

  "&.Mui-disabled": {
    backgroundColor: "#F8FAFA",
    border: "none",

    "& .selectValue": {
      color: "rgba(0, 0, 0, 0.38)"

    }
  },

  ".selectValue": {
    fontSize: bgcolor === "grey" ? "12px" : "16px",
    fontWeight: 500,
    color: "#4D4B4C",
    textTransform: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "32px"
  },
  ".placeholder": {
    fontSize: bgcolor === "grey" ? "12px" : "16px",
    fontWeight: 500,
    color: bgcolor === "grey" ? "#757172" : "#898587",
    lineHeight: "24px",
    textTransform: "capitalize",
  }
}));


export const StyledMenu = styled('div')(() => ({
  position: 'absolute',
  width: "100%",
  backgroundColor: 'white',
  border: "1px solid #EAEEF3",
  borderRadius: "10px",
  boxShadow: "0px 10px 30px 0px #10182833",
  marginTop: "7px",
  zIndex: 5,


  // Remove border from last child of .custom-option
  '& .custom-option:last-child': {
    borderBottom: 'none !important',
  },
}));


export const Blanket = styled('div')({
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1,
});

export const StyleOption = styled('p')(() => ({
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "16.94px",
  color: "#000000",
  padding: "12px 11px",
  borderRadius: "6px",

  "& span": {
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal", // Allow multiline
    display: "-webkit-box",
    WebkitLineClamp: 2, // Limit to 2 lines
    WebkitBoxOrient: "vertical",

  }
}));

export const CustomOption = styled('div')` 

  &[aria-selected="true"] .select-option {
    background-color: #EFF1F7 !important; /* Add your desired gray shade here */
  } 
`;


