// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { getEquipmentModel, getEquipmentTypeList, getTypeOfSeals, getEngineType, getTypeOfWell, getAgentProcessed, equipmentMasterSelector, addEquipment, getProductionMethod, getEquipmentClusterList, updateEquipment } from '../../store/slices/equipmentMaster'
import { loaderSelector } from "../../store/slices/loaderSlice";
import DefaultLayout from '../../components/layout'
import { Grid, Stack } from '@mui/material'
import InputField from '../../components/atoms/InputField/InputField'
import SelectField from '../../components/atoms/SelectField/SelectField'
import { AddEquipmentProps } from './interface'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PrimaryButton } from '../../components/atoms/button/button'
import ArrowRight from '../../components/atoms/icons/arrowRight'
import { EquipmentConstants } from './constant'
import { FieldArray } from './addEquipmentFields'
import { useSelector } from 'react-redux'
import { getSiteData, siteMasterSelector } from '../../store/slices/siteMaster'
import { gasMasterSelector, getGasTypes } from '../../store/slices/gasFactorMaster'
import { yupResolver } from '@hookform/resolvers/yup'
import { validations } from './validations'
import { roundOffNumber } from '../../utils/helper'
import CircularLoader from "../../components/atoms/CircularLoader/Loader";

export default function AddEquipment({ pageTitle, onBack, editData }: AddEquipmentProps) {
  const dispatch: any = useAppDispatch()
  const { button, text, placeholder, label, name }: any = EquipmentConstants
  const {
    equipmentTypeList,
    typeOfSealsList,
    typeOfWellList,
    agentProcessedList,
    engineTypeList,
    productionMethodList,
    equipmentClusterList
  } = useSelector(equipmentMasterSelector)

  const { isLoader } = useSelector(loaderSelector)
  const { siteMasterData } = useSelector(siteMasterSelector)

  const { gasTypes } = useSelector(gasMasterSelector)
  const [isEdit, setEdit] = useState(editData ? true : false)

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      equipmentType: "",
      siteId: "",
      typeOfSeal: "",
      typeOfWell: "",
      agentProcessed: "",
      typeOfGasBurnt: "",
      decommissioned: "",
      lowPressure: "",
      performCasingHeadVentingApplicable: "",
      engineType: "",
      equipmentCluster: '',
      productionMethod: ""
    },
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    dispatch(getEquipmentTypeList())
    dispatch(getProductionMethod())
    dispatch(getEquipmentClusterList())
    dispatch(getTypeOfSeals())
    dispatch(getEngineType())
    dispatch(getTypeOfWell())
    dispatch(getAgentProcessed())
    dispatch(getSiteData("?limit=1000"))
    dispatch(getGasTypes())
  }, [])

  useEffect(() => {
    setEditData()
  }, [equipmentTypeList,
    typeOfSealsList,
    typeOfWellList,
    agentProcessedList,
    engineTypeList,
    productionMethodList,
    equipmentClusterList,
    gasTypes
  ])

  const setEditData = () => {
    if (editData) {
      const agent = agentProcessedList.filter(
        (val: any) => val.agentProcessed === editData?.agentProcessed?.agentProcessed
      );

      const sealType = typeOfSealsList.filter(
        (val: any) => val.sealType === editData?.typeOfSeal?.sealType
      );
      const gasType = gasTypes.filter(
        (val: any) => val.gasType === editData?.typeOfGasBurnt?.gasType
      );
      const wellType = typeOfWellList.filter(
        (val: any) => val.wellType === editData?.typeOfWell?.wellType
      );
      const equipmentType = equipmentTypeList.filter(
        (val: any) => val.equipmentType === editData?.equipmentType?.equipmentType
      );
      const engineType = engineTypeList.filter(
        (val: any) => val.engineType === editData?.engineType?.engineType
      );
      const sites = siteMasterData.filter(
        (val: any) => val.siteId === editData?.siteId?.siteId
      );

      const equipmentCluster = equipmentClusterList.filter(
        (val: any) => val.equipmentCluster === editData?.equipmentCluster?.equipmentCluster
      );

      const productionMethod = productionMethodList.filter(
        (val: any) => val.productionMethod === editData?.productionMethod?.productionMethod
      );

      const numberArray = [name.physicalVolumeOfMaintainedEquipmentM3, name.thicknessOfAWallMM, name.physicalVolumeOfEngineM3, name.residualPressureATM, name.timeToAddressLeakDays, name.diameterCM, name.volumeOfPigReceiverM3, name.lengthM, name.cumulativeOperatingHours, name.productivityM3PerHour, name.inletOperationalPressureATM, name.outletPressureATM, name.powerRatingKW, name.ageYears]

      for (var key in editData) {
        if (name[key] && editData[key]) {
          if (key === "agentProcessed" && agent.length) {
            setValue(name?.agentProcessed, agent[0].id);
          }
          else if (key === "equipmentType" && equipmentType.length) {
            setValue(name?.equipmentType, equipmentType[0].id);
          }
          else if (key === "engineType" && engineType.length) {
            setValue(name?.engineType, engineType[0].id);
          }
          else if (key === "typeOfWell" && wellType.length) {
            setValue(name?.typeOfWell, wellType[0].id);
          }
          else if (key === "typeOfSeal" && sealType.length) {
            setValue(name?.typeOfSeal, sealType[0].id);
          }
          else if (key === "equipmentCluster") {
            setValue(name?.equipmentCluster, equipmentCluster[0]?.id);
          }
          else if (key === "productionMethod") {
            setValue(name?.productionMethod, productionMethod[0]?.id);
          }
          else if (key === "productionMethod") {
            setValue(name?.productionMethod, productionMethod[0]?.id);
          }
          else if (key === "typeOfGasBurnt") {
            setValue(name?.typeOfGasBurnt, gasType[0]?.id);
          }
          else if (key === "siteId" && sites.length) {
            setValue(name?.siteId, sites[0].siteId);
          }
          else if (numberArray.includes(key) && editData[key]) {
            setValue(name[key], roundOffNumber((editData[key])));
          }
          else if ((
            key === name.standbyFactor ||
            key === name.flaringEfficiency ||
            key === name.waterCut) && editData[key]) {
            setValue(name[key], roundOffNumber((editData[key] * 100).toPrecision(3)));
          }
          else {
            setValue(name[key], editData[key]);
          }
        }
      }
    }
  };

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    const reqPayload: any = {};
    for (let key in payload) {
      if (payload[key] === "") {
        reqPayload[key] = null
      } else {
        reqPayload[key] = payload[key]
      }
    }
    const arry = [name.equipmentType, name.agentProcessed, name.productionMethod, name.equipmentCluster, name.standbyFactor, name.flaringEfficiency, name.numberOfRelevantEquipment, name.numberOfPistons, name.physicalVolumeOfMaintainedEquipmentM3, name.physicalVolumeOfEngineM3, name.residualPressureATM, name.timeToAddressLeakDays, name.diameterCM, name.lengthM, name.volumeOfPigReceiverM3, name.numberOfPistons, name.latitude, name.longitude, name.thicknessOfAWallMM, name.cumulativeOperatingHours, name.productivityM3PerHour, name.powerRatingKW, name.inletOperationalPressureATM, name.outletPressureATM, name.ageYears, name.waterCut];

    arry.forEach((key) => {
      if (key === name.standbyFactor || key === name.flaringEfficiency || key === name.waterCut) {
        if (key === name.flaringEfficiency) {
          reqPayload[key] = reqPayload[key] ? parseFloat(reqPayload[key] / 100) : reqPayload[key];
        } else {
          reqPayload[key] = reqPayload[key] ? (parseFloat(reqPayload[key] / 100)) : reqPayload[key];
        }
      } else {
        reqPayload[key] = parseFloat(reqPayload[key])
      }

    })


    if (editData) {
      dispatch(updateEquipment(reqPayload.equipmentId, reqPayload, successCB))
    } else {
      dispatch(addEquipment(reqPayload, successCB))
    }
  };

  const successCB = (alert: boolean) => {
    onBack(false, alert || false, editData?.equipmentId);
  }

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={button.submit}
        variant="contained"
        type={"submit"}
        width={"128px"}
        endIcon={<ArrowRight sx={{ width: "7px" }} />}
      />
    </Stack>
  );

  const { fieldArray }: any = new FieldArray({
    equipmentTypeList,
    typeOfSealsList,
    typeOfWellList,
    agentProcessedList,
    engineTypeList,
    siteMasterData,
    gasTypes,
    productionMethodList,
    equipmentClusterList,
    watch,
    isEdit
  });


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        (isLoader?.isShow && isLoader.component === "equipmentList") && <CircularLoader />
      }
      <DefaultLayout
        pageTitle={pageTitle}
        // renderHeaderButtons={renderHeaderButtons}
        backHandler={() => onBack(false)}
        activeTab="Equipments"
      >
        <Grid container spacing={"30px"} sx={{ p: "10px 0 10px" }}>
          {fieldArray.map((field: any) => (
            <>
              {field.type === "input" && (
                <Grid item xs={6} md={4}>
                  <InputField
                    label={label[field.fieldName]}
                    type={field?.inputType || "text"}
                    disabled={field.disabled}
                    name={name[field.fieldName]}
                    register={register}
                    placeholder={placeholder[field.fieldName]}
                    errorText={errors[field.fieldName]?.message}
                    Asterisk={!field?.mandatory || false}
                  />
                </Grid>
              )}
              {field.type === "dropdown" && (
                <Grid item xs={6} md={4}>
                  <SelectField
                    selectlabel={label[field.fieldName]}
                    placeholder={placeholder[field.fieldName]}
                    options={field?.options}
                    name={name[field.fieldName]}
                    register={register}
                    value={watch(name[field.fieldName])}
                    errorText={errors[field.fieldName]?.message}
                    Asterisk={!field?.mandatory || false}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
        <Stack direction="row" sx={{ marginTop: '10px' }}>
          <PrimaryButton
            label={button.submit}
            variant="contained"
            type={"submit"}
            width={"95px"}
          // endIcon={<ArrowRight sx={{ width: "7px" }} />}
          />
        </Stack>
      </DefaultLayout>
    </form>
  )
}
