import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import {
  FilterButton,
  PrimaryButton,
} from "../../components/atoms/button/button";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import FilterSite from "./FilterSite";
import { SiteConstants } from "./constants";
import AddSite from "./AddSite";
import { useAppDispatch } from "../../store/hooks";
import {
  getGroupSiteData,
  getSiteData,
  getSiteTypeList,
  siteMasterSelector,
} from "../../store/slices/siteMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { useSelector } from "react-redux";
import {
  assetMasterSelector,
  getAssetList,
} from "../../store/slices/assetMaster";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import NewCrudTable from "../../components/atoms/DataTable/NewCrudTable";
import TableColumn from "./tableColumn";
import { filterByGroup, searchMasterList } from "../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../components/atoms/icons/plusIcon";

export default function SiteMaster() {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const { headers, text, button } = SiteConstants;
  const { siteMasterGroupData, siteTypeList } = useSelector(siteMasterSelector);
  const [editSiteData, setEditData] = useState<any>(null);
  const [alertType, setAlertType] = useState(true);
  const [open, setOpen] = useState(false);
  const [addSiteVisibility, setAddSiteVisibility] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addSite);
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [assetId, setAssetId] = useState<any>(null);
  const [pageSize, setPageSze] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filterVisibility, handleFilterVisibility] = useState(false);

  const { isLoader } = useSelector(loaderSelector);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setAssetId(id);
      dispatch(getGroupSiteData(id));
    }
  }, []);

  useEffect(() => {
    setTableData(siteMasterGroupData);
  }, [siteMasterGroupData]);

  useEffect(() => {
    if (searchText?.length > 1) {
      handleMasterSearch();
    } else {
      setTableData(siteMasterGroupData);
    }
  }, [searchText]);

  useEffect(() => {
    let params = "?limit=99999";
    dispatch(getAssetList(params));
    dispatch(getSiteTypeList(params));
  }, []);

  const handleMasterSearch = () => {
    let result = searchMasterList(siteMasterGroupData, searchText, [
      "siteName",
      "siteId",
    ]);
    setTableData(result);
  };

  const toggleAddSite = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string) => {
      setAddSiteVisibility(status);
      setPageTitle(headers.addSite);
      if (type === "edit") {
        setAlertType(false);
      } else {
        setAlertType(true);
        setEditData(null);
      }

      if (showSuccessModal) {
        setAlertModalVisibility(true);
        const { pathname } = window.location;
        const id = pathname.split("/").pop();
        if (id) {
          dispatch(getGroupSiteData(id, false));
        }
      }
    },
    []
  );

  const handleFilterData = (filterkeys: any) => {
    console.log(filterkeys);
    let result = filterByGroup(siteMasterGroupData, filterkeys);
    setTableData(result);
  };

  const handleIconButtonClick = (row: {}) => {
    setEditData(row);
    setPageTitle(headers.editSite);
    setAddSiteVisibility(true);
  };

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="Add Site"
        variant="contained"
        onClick={() => toggleAddSite(true)}
        startIcon={<PlusIcon />}
        width="122px"
      />
    </Stack>
  );

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const handleHierarchyClick = (row: any) => {
    navigate(`/equipment-master/${row?.siteId}`);
  };

  const columns: any = new TableColumn({
    handleIconButtonClick,
    handleHierarchyClick,
  });

  let pageTitle_ = "Site Master"
  if (assetId) {
    pageTitle_ += ` (${assetId})`
  }

  return (
    <>
      {isLoader?.isShow && isLoader.component === "siteList" && (
        <CircularLoader />
      )}
      {addSiteVisibility ? (
        <AddSite
          pageTitle={pageTitle}
          onBack={(
            status: boolean,
            modalStatus?: boolean,
            alertStatus?: string
          ) => toggleAddSite(status, modalStatus, alertStatus)}
          editSiteData={editSiteData}
        />
      ) : (
        <DefaultLayout
          renderHeaderButtons={renderHeaderButtons}
          pageTitle={pageTitle_}
          activeTab={"Sites"}
          backHandler={() => navigate(-1)}
        >
          <Box className="placeholder_casing">
            <NewCrudTable
              rows={tableData}
              columns={columns.column}
              showCheckbox={false}
              showSearch={true}
              setSearch={setSearchText}
              segmentHeader={"Site Type"}
              renderButtons={renderButtons}
              searchPlaceholder="Search Site ID or Name..."
            />
          </Box>
          <FilterSite
            siteTypeList={siteTypeList}
            open={filterVisibility}
            onClose={() => {
              handleFilterVisibility(false);
            }}
            filterCB={(val: any) => {
              handleFilterData(val);
            }}
            clearFilter={() => {
              setTableData(siteMasterGroupData);
            }}
          />
          <StatusModal
            type="success"
            bodyText={alertType ? text.addSiteAlert : text.editSiteAlert}
            open={alertModalVisibility}
            onClose={() => setAlertModalVisibility(false)}
          />
        </DefaultLayout>
      )}
    </>
  );
}
