import React from "react";
export default function FilterIcon() {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="14"
    //   height="14"
    //   viewBox="0 0 14 14"
    //   fill="none"
    // >
    //   <g clipPath="url(#clip0_2171_4795)">
    //     <path
    //       d="M13.2477 1.05416e-06H0.766013C0.545869 -0.00041536 0.344048 0.122565 0.243415 0.318418C0.141393 0.516908 0.159299 0.755929 0.289636 0.937208L4.8624 7.37884C4.86393 7.38106 4.8656 7.38314 4.86712 7.38536C5.03327 7.60967 5.12308 7.88131 5.12363 8.16058V13.4124C5.12266 13.568 5.18374 13.7173 5.29339 13.8277C5.40305 13.938 5.55212 13.9999 5.70758 13.9999C5.7867 13.9999 5.86485 13.9841 5.93786 13.9539L8.50741 12.974C8.73768 12.9038 8.8905 12.6866 8.8905 12.4249V8.16058C8.89092 7.88145 8.98087 7.60967 9.14688 7.38536C9.1484 7.38314 9.15007 7.38106 9.1516 7.37884L13.7242 0.93693C13.8546 0.75579 13.8725 0.516908 13.7704 0.318418C13.6698 0.122565 13.468 -0.00041536 13.2477 1.05416e-06ZM8.57389 6.96437C8.31849 7.31096 8.18038 7.73001 8.17955 8.16058V12.3386L5.83431 13.2328V8.16058C5.83348 7.73001 5.69537 7.31096 5.43983 6.96437L1.00045 0.710679H13.0134L8.57389 6.96437Z"
    //       fill="black"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_2171_4795">
    //       <rect width="14" height="14" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.33333 8.33333L7.77875 6.06197C8.88002 4.3314 9.43065 3.46612 9.34115 2.75147C9.27786 2.2461 9.02414 1.78391 8.63176 1.45919C8.07688 1 7.05126 1 5 1V1C2.94874 1 1.92311 1 1.36824 1.45918C0.975856 1.7839 0.722139 2.2461 0.658848 2.75147C0.569348 3.46612 1.11998 4.3314 2.22125 6.06197L3.66667 8.33333M6.33333 8.33333L3.66667 8.33333M6.33333 8.33333L6.33333 10.4865C6.33333 10.7565 6.33333 10.8914 6.32579 10.9598C6.20779 12.0292 4.88359 12.4598 4.15914 11.6643C4.11285 11.6135 4.03345 11.5043 3.87466 11.286V11.286C3.85811 11.2632 3.84984 11.2519 3.84206 11.2405C3.73311 11.0817 3.67234 10.8948 3.66704 10.7023C3.66666 10.6886 3.66666 10.6745 3.66666 10.6464L3.66667 8.33333" stroke="#3C8200" stroke-width="1.2" stroke-linejoin="round" />
    </svg>

  );
}
