import { convertArrayToObject } from "../../utils/helper";

interface FieldArrayProps {
  type: "input" | "dropdown" | "textArea";
  fieldName: string;
  options: any;
  disabled?: boolean;
}

export class FieldArray {
  private fieldArray: FieldArrayProps[];

  constructor(payload: any) {
    this.fieldArray = [
      {
        type: "dropdown",
        fieldName: "assetId",
        disabled: payload?.isEdit,
        options: convertArrayToObject(
          payload?.assetMasterData,
          "assetId",
          "assetId"
        ),
      },
      {
        type: "dropdown",
        fieldName: "gasType",
        disabled: payload?.isEdit,
        options: convertArrayToObject(payload?.gasTypes, "gasType", "id"),
      },
      {
        type: "input",
        fieldName: "massContentOfMethane",
        options: null,
      },
      {
        type: "input",
        fieldName: "densityOfGas",
        options: null,
      },
    ];
  }
}
