import { createSlice } from "@reduxjs/toolkit";
import UploadDataService from "../../api/services/uploadDataService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

export const initialState = {
  files: [],
  total: 0
};

const uploadDataSlice = createSlice({
  name: "uploadData",
  initialState,
  reducers: {
    clearUploadDataState: () => initialState,
    setFiles: (state, { payload }) => {
      state.files = payload.data;
      state.total = payload.meta.itemCount
    }
  },
});

export const {
  clearUploadDataState,
  setFiles,
} = uploadDataSlice.actions;

export const uploadDataSelector = (state: any) => state.uploadData;

export default uploadDataSlice.reducer;

export const uploadDataAction = (payload: any, callBack: any) => {
  const formData = new FormData();
  formData.append('file', payload?.file);
  formData.append('activityType', payload?.activityType);
  formData.append('dataType', payload?.dataType);
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "uploadHistory" }))
    try {
      const res = await UploadDataService.uploadFile(formData);
      if (res?.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))

        callBack(res?.data);
      } else {
        callBack("false");
      }

    } catch (error) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))
        dispatch(setErrorMessage(error))
      }
    }

  };
}

export const getUploadHistories = (payload: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "uploadHistory" }))
    }

    const res = await UploadDataService.getUploadedHistories(payload);
    if (res.status === 200) {
      dispatch(setFiles(res.data));
      if (loaderShow) {
        dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))
      }
    } else {
      dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))
    }
  };
}

export const getHistoryById = (id: any, callBack: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "uploadHistory" }))
    try {
      const res = await UploadDataService.getHistory(id);
      if (res.status === 200) {
        callBack(res.data)
        dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))
      } else {
        callBack(res.data)
      }
    } catch (error) {
      if (error) {
        dispatch(setErrorMessage(error))
        dispatch(setLoderVisibility({ isShow: false, component: "uploadHistory" }))
      }
    }

  };
}