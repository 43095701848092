import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";
import { convertDataToGroup } from "../../utils/helper";
import moment from "moment";
import dayjs from "dayjs";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  assetMasterData: [],
  assetMasterSegmentData: [],
  assetTypes: [],
  equityTypes: [],
  countries: [],
  operators: [],
  total: 0,
  totalSegment: 0,
  segmentAssetList: [],
  globalFromDate: dayjs().subtract(1, "month").startOf("month"),
  globalToDate: dayjs(),
};

const assetMasterSlice: any = createSlice({
  name: "assetMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    assetMasterList: (state, { payload }) => {
      state.assetMasterData = payload.data;
      state.total = payload?.meta?.itemCount
    },
    assetMasterListSegment: (state, { payload }) => {
      state.assetMasterSegmentData = payload?.length ? convertDataToGroup(payload, "assets", "segment") : [];
      state.totalSegment = payload?.length
    },
    assetTypes: (state, { payload }) => {
      state.assetTypes = payload;
    },
    equityTypes: (state, { payload }) => {
      state.equityTypes = payload;
    },
    countries: (state, { payload }) => {
      state.countries = payload;
    },
    operators: (state, { payload }) => {
      state.operators = payload;
    },
    setGlobalToDate: (state, { payload }) => {
      state.globalToDate = payload;
    },
    setGlobalFromDate: (state, { payload }) => {
      state.globalFromDate = payload;
    },
    setSegmentAssetsList: (state, { payload }) => {
      state.segmentAssetList = payload.data;
    },
  }
});

export const {
  clearMasterState,
  assetMasterList,
  assetTypes,
  equityTypes,
  countries,
  operators,
  setSegmentAssetsList,
  assetMasterListSegment,
  setGlobalToDate,
  setGlobalFromDate,
} = assetMasterSlice.actions;

// Action creators are generated for each case reducer function
export const assetMasterSelector = (state: any) => state.assetMaster;

export default assetMasterSlice.reducer;



export const getAssetList = (params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: loaderShow, component: "assetList" }));
    }

    try {
      const res = await MasterService.getAssetList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "assetList" }));
        }
        dispatch(assetMasterList(res.data))
        if (callBack) {
          callBack("asset")
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "assetList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getAssetListBySegment = (params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: loaderShow, component: "assetList" }));
    }

    try {
      const res = await MasterService.getAssetListBySegment(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "assetList" }));
        }
        dispatch(assetMasterListSegment(res.data))
        if (callBack) {
          callBack("asset")
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "assetList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const addAsset = (payload: any, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "asset" }))
    try {
      const res = await MasterService.addAsset(payload)
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "asset" }))
        successCB(true)
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "asset" }))
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const editAsset = (payload: any, id: string, successCB?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "asset" }))
    try {
      const res = await MasterService.editAsset(payload, id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "asset" }))
        successCB(true)
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "asset" }))
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getAssetDetail = (id: string, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "assetDetails" }));
    try {
      const res = await MasterService.getAssetDetail(id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "assetDetails" }));
        successCB(res.data)
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "assetDetails" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getAssetTypes = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getAssetTypes()
      if (res.status === 200) {
        dispatch(assetTypes(res.data.data))
      }
    };
  } catch (error) { }
}

export const getEquityTypes = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getEquityTypes()
      if (res.status === 200) {
        dispatch(equityTypes(res.data.data))
      }
    };
  } catch (error) { }
}

export const getCountries = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getCountries()
      if (res.status === 200) {
        dispatch(countries(res.data.data))
      }
    };
  } catch (error) { }
}

export const getOperators = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getOperators()
      if (res.status === 200) {
        dispatch(operators(res.data.data))
      }
    };
  } catch (error) { }
}

export const getGlobalToDate = (payload: any) => {
  try {
    return async (dispatch: any) => {
      const convertedDate = moment(payload.toString()).format("YYYY-MM-DD");
      dispatch(setGlobalToDate(convertedDate))
    };
  } catch (error) { }
}

export const getGlobalFromDate = (payload: any) => {
  try {
    return async (dispatch: any) => {
      const convertedDate = moment(payload.toString()).format("YYYY-MM-DD");
      dispatch(setGlobalFromDate(convertedDate))
    };
  } catch (error) { }
}

export const getSegmentAssets = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: loaderShow, component: "dashboard" }));
    }
    try {
      const res = await MasterService.getAssetList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }));
        }
        dispatch(setSegmentAssetsList(res.data))

      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}


