import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  gasFactorMasterData: [],
  gasTypes: [],
  total: 0
};

const gasFactorMasterSlice: any = createSlice({
  name: "gasFactorMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    gasFactorMasterList: (state, { payload }) => {
      state.gasFactorMasterData = payload?.data;
      state.total = payload?.meta?.itemCount
    },
    gasTypes: (state, { payload }) => {
      state.gasTypes = payload;
    },
  }
});

export const {
  clearMasterState,
  gasTypes,
  gasFactorMasterList,
  assetMasterList,
} = gasFactorMasterSlice.actions;

// Action creators are generated for each case reducer function
export const gasMasterSelector = (state: any) => state.gasFactorMaster;

export default gasFactorMasterSlice.reducer;



export const getGasList = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "gasFactorList" }));
    }
    try {
      const res = await MasterService.getGasFactorList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "gasFactorList" }));
        }
        dispatch(gasFactorMasterList(res?.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "gasFactorList" }));
    }


  };
}

export const addGasFactor = (payload: any, successCB: any) => {

  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "gasFactor" }));
    try {
      const res = await MasterService.addGasFactor(payload)
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "gasFactor" }));
        successCB(true)
      }
    } catch (error) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "gasFactor" }));
        dispatch(setErrorMessage(error))
      }
    }
  };

}

export const editGasFactor = (payload: any, id: string, successCB?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "gasFactor" }));
    try {
      const res = await MasterService.editGasFactor(payload, id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "gasFactor" }));
        successCB(true)
      }
    } catch (error) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "gasFactor" }));
        dispatch(setErrorMessage(error))
      }
    }
  };


}

export const getGasTypes = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getGasTypeList()
      if (res.status === 200) {
        dispatch(gasTypes(res.data.data))
      }
    };
  } catch (error) { }
}

