// @ts-nocheck
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../components/layout";
import "./../EmissionDetails.scss";
import {
  Box,
  Divider,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "../../../components/atoms/button/button";
import { StatusChip } from "../../../components/atoms/chip/chip";
import SelectField from "../../../components/atoms/SelectField/SelectField";
import { useForm } from "react-hook-form";
import ChangeLog from "./../ChangeLog/ChangeLog";
import VolumeOfCH4 from "../../../components/atoms/VolumeBox/VolumeOfCH4";
import MultipleEquipment from "./../MultipleEquipment";
import EquipmentAccordion from "./../MultipleEquipment";
import { useAppDispatch } from "../../../store/hooks";
import { useSelector } from 'react-redux';
import { EmissionType } from "./../interface";
import {
  notificationSelector, getNotificationMasterData,
  setNotificationMasterData,
  getNotificationChangeLogData,
  setNotificationChangeLogs
} from "../../../store/slices/notificationSlice";
import { useParams } from "react-router-dom";
import VerifyEmission from "../Verification";
import CommonModal from "../../../components/atoms/modal/modal";
import { EquipmentConstants } from "../constant";
import StatusModal from "../../../components/atoms/StatusModal/StatusModal";
import { roundOffNumber, getDateFromDatetime, getTwoDecimalDigit, getTimeFromDatetime } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import CircularLoader from "../../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../../store/slices/loaderSlice";
import moment from "moment";

const EmissionDetails = () => {
  const dispatch: any = useAppDispatch();
  const { isLoader } = useSelector(loaderSelector)
  const { notificationMasterData } = useSelector(notificationSelector);
  const {
    register,
    formState: { errors },
  } = useForm();
  const { headers } = EquipmentConstants
  const [select1Value, setSelect1Value] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number | null>(null)
  const [alertModalVisibility, setAlertModalVisibility] = useState(false)
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(setNotificationChangeLogs({}))
      dispatch(setNotificationMasterData({}))
      dispatch(getNotificationMasterData(id))
    }
  }, [id])

  const renderHeaderButtons = () => (

    <Stack direction="row" spacing={1.5} className="flex-alignCenter headerBtn-group">
      <Stack direction="row" alignItems="center" spacing={2.5} className="btnGroup">
        <Typography variant="body1" className="verification-status">Verification Status</Typography>
        <StatusChip
          label={notificationMasterData?.status ? "Verified" : "Not Verified"}
          type={notificationMasterData?.status ? "Verified" : "Not Verified"}
          className="statusChip"
        />
      </Stack>
      <PrimaryButton
        label={notificationMasterData?.status ? "Re-Verify Source" : "Verify Source"}
        variant="contained"
        width={"181px"}
        height={"45px"}
        onClick={handleVerify}
      />
    </Stack>
  );

  const renderFotter = () => (
    <PrimaryButton
      label="Add Missing Value"
      variant="contained"
      width={"100%"}
    />
  );

  const handleVerify = () => {
    setShowModal(true);
  }


  const handleClose = () => {
    setShowModal(false)
  }

  const successCB = () => {
    dispatch(getNotificationMasterData(id, false))
    dispatch(getNotificationChangeLogData(parseInt(id)))
    setAlertModalVisibility(true)
    setShowModal(false)
  }


  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelect1Value(event.target.value as string);
  };

  const nodeWithData = (fieldName: string, fieldValue: string | number) => {
    return (
      <Stack direction="row" spacing={"10px"}>
        <p className="assetLable">{fieldName}</p>
        <p className="asssetValue">{fieldValue}</p>
      </Stack>
    )
  }

  /** Checks whether an equipment object exists in object or not */
  const isEquipmentExists = () => {
    let equipmentFlag: boolean = false;
    if (notificationMasterData?.notificationEmissions) {
      notificationMasterData?.notificationEmissions?.forEach((emission: EmissionType) => {
        if (emission?.equipmentId) {
          equipmentFlag = true;
        }
      })
    }
    return equipmentFlag;
  }

  /** Checks whether a component object exists in object or not */
  const isComponentExists: boolean = () => {
    let componentFlag: boolean = false;
    if (notificationMasterData?.notificationEmissions) {
      notificationMasterData?.notificationEmissions?.forEach((emission: EmissionType) => {
        if (emission?.componentId) {
          componentFlag = true;
        }
      })
    }
    return componentFlag;
  }

  /** Checks Emission Volumn */
  const totalEmissionVolumn = (): number | undefined => {
    const volumes: number[] = [];

    if (notificationMasterData?.notificationEmissions) {
      notificationMasterData?.notificationEmissions?.forEach((emission: EmissionType) => {
        if (emission?.totalVolume !== undefined && emission.totalVolume !== null) {
          const volume = typeof emission.totalVolume === 'string' && emission.totalVolume === "0.00"
            ? 0
            : Number(emission.totalVolume);

          volumes.push(volume);
        }
      });
    }

    if (volumes.length === 0) {
      return "NA"; // Return undefined if all volumes are null or undefined
    }

    return Math.round(volumes.reduce((acc, curr) => acc + curr, 0) * 100) / 100;
  };

  const options = [
    { value: "open", label: "Open" },
    { value: "close", label: "Close" },
  ];

  const navigateBack = () => {
    if (navigate(-1)) {
      navigate(-1);
    } else {
      navigate("/notification-center");
    }
  };


  return (
    <>
      {(isLoader?.isShow && isLoader?.component === "notificationData") && <CircularLoader />}
      <DefaultLayout pageTitle="Emission Details" renderHeaderButtons={renderHeaderButtons} backHandler={() => navigateBack()}>
        <CommonModal
          open={showModal}
          onClose={handleClose}
          title={headers.modalTile}
          minWidth="800px"
        >
          <VerifyEmission id={notificationMasterData?.id} successCB={successCB} />
        </CommonModal>
        <StatusModal
          type="success"
          bodyText={"Emission Verified Successfully."}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
        <Box className="EmissionDetails-Main">

          <Box className="header spacebetween alignItems">
            <h3>Detection ID<span className="bold">{notificationMasterData?.id}</span></h3>
            <Box className="status-Dropdown">
              <SelectField
                options={options}
                placeholder="Select Status"
                name="test"
                register={register}
                onChange={handleSelectChange}
                selectText={"LDAR Status"}
                defaultValue={options[0].value}

              />
            </Box>
          </Box>

          <Divider sx={{ borderColor: "#0000001A" }} />


          <Box sx={{ display: "flex", gap: "30px" }}>
            {/* left Box  */}
            <Box sx={{ width: "100%", mt: "30px" }}>
              {/* Source Details */}
              <Box className="GaryBox">
                {nodeWithData('Detection Method', (notificationMasterData?.detectionMethod && notificationMasterData?.detectionType && (notificationMasterData?.detectionMethod === 'Bottom-Up')) ? notificationMasterData?.detectionMethod + ` (${notificationMasterData?.detectionType})` : notificationMasterData?.detectionMethod)}
                {nodeWithData('Default Source Hierarchy', notificationMasterData?.sourceHierarchy)}
                {nodeWithData('Default Source ID', notificationMasterData?.sourceName)}
                {nodeWithData('Emission Rate (kg/hr)', !notificationMasterData?.emissionRate ? "" : `${roundOffNumber(getTwoDecimalDigit(notificationMasterData?.emissionRate))}`)}
                {nodeWithData('Emission Volume (MT)', totalEmissionVolumn())}
                {nodeWithData('Latitude', notificationMasterData?.latitude === null ? "NA" : notificationMasterData?.latitude)}
                {nodeWithData('Longitude', notificationMasterData?.longitude === null ? "NA" : notificationMasterData?.longitude)}
                {nodeWithData('Detection Date & Time', !notificationMasterData?.detectionDate ? "NA" : moment(notificationMasterData?.detectionDate).format("DD/MM/YYYY") + " " + (notificationMasterData?.detectionTime === null ? '' : ` | ` + (notificationMasterData?.detectionTime).substring(0, 5)))}

              </Box>

              <Typography variant="h4" sx={{ mt: "25px" }}>Source Details</Typography>
              <Box className="sourceDetails">
                {/* Asset Details */}
                <Box className="Details">
                  <Typography variant="h5">Asset Details</Typography>
                  <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                    {nodeWithData('Asset ID', notificationMasterData?.assetId?.assetId)}
                    {nodeWithData('Asset Name', notificationMasterData?.assetId?.assetName)}
                  </Stack>
                </Box>

                {/* Site Details */}
                {notificationMasterData?.siteId?.siteId &&
                  <Box className="Details">
                    <Typography variant="h5">Site Details</Typography>
                    <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                      {nodeWithData('Site ID', notificationMasterData?.siteId?.siteId)}
                      {nodeWithData('Site Name', notificationMasterData?.siteId?.siteName)}
                    </Stack>
                  </Box>
                }

                {/* Equipment Details */}
                {isEquipmentExists() && <Box className="Details">
                  <Typography variant="h5">Equipment Details</Typography>
                  {
                    notificationMasterData?.notificationEmissions?.map((emission: EmissionType) => (

                      <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"} className={notificationMasterData?.notificationEmissions.length > 1 && 'GaryBox'}>
                        {nodeWithData('Equipment ID', emission?.equipmentId?.equipmentId)}

                        {nodeWithData('Equipment Type', emission?.equipmentId?.equipmentType?.equipmentType)}

                        {nodeWithData('Emission Sub-Category', emission?.emissionSubcategoryId?.name)}

                      </Stack>
                    ))
                  }

                </Box>
                }

                {/* Component Details */}
                {isComponentExists() && <Box className="Details">
                  <Typography variant="h5">Component Details</Typography>
                  {
                    notificationMasterData?.notificationEmissions?.map((emission: EmissionType) => (

                      <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>

                        {nodeWithData('Component ID', emission?.componentId?.componentId)}
                        {nodeWithData('Component Type', emission?.componentId?.componentType?.componentType)}
                      </Stack>
                    ))}
                </Box>
                }
              </Box>

              <Divider sx={{ borderColor: "#0000001A" }} />

              {/* Chnage Log component*/}
              <ChangeLog />

            </Box>
            {/* <Divider sx={{ borderColor: "#0000001A",}} orientation="vertical" flexItem/> */}

            {/* Right Box  */}

            {/* <Box sx={{width: "30%", mt: "30px"}}>      

      <Box className="accordianBox">
      <Typography variant="h6" sx={{ paddingBottom: "15px", textAlign: "center" }}>VOLUME OF CH₄</Typography>
      <Stack direction="column" gap="20px">
      <EquipmentAccordion
        equipmentId="424657"
        totalEmissionVolume="6 MT CH4"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        volumeFooter= {false}
      />
      <EquipmentAccordion
        equipmentId="568219"
        totalEmissionVolume="Value Missing"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        renderFotter={renderFotter}
        valueMissing={true}
      />
      <EquipmentAccordion
        equipmentId="543262"
        totalEmissionVolume="7 MT CH4"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        volumeFooter= {false}
      />
    </Stack>
    </Box>
      <VolumeOfCH4
        emissionFactor={3.643}
        lengthOfSurfacePipe="-"
        numberOfHours={56}
        // renderFotter={renderFotter}
        valueMissing={true}
        totalEmissionVolume={"10 MT CH4"} 
      />

    </Box>   */}

          </Box>
        </Box>

      </DefaultLayout>
    </>
  );
};

export default EmissionDetails;
