import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import DefaultLayout from "../../components/layout";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { PrimaryButton } from "../../components/atoms/button/button";
import UplaodIcon from "../../components/atoms/icons/uploadIcon";
import UploadForm from "./UploadForm";
import {
  getHistoryById,
  getUploadHistories,
  uploadDataSelector,
} from "../../store/slices/uploadData";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import TableColumn from "./tableColumn";
import { errorMessages, headers } from "./staticData";
import { convertJsonToCsvAndDownload } from "../../utils/helper";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import { FixErrorChip } from "../../components/atoms/chip/chip";
import Toast from "../../components/atoms/Toast/Toast";
import Loader from "../../components/atoms/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";

const UploadData = () => {
  const dispatch = useAppDispatch();
  const { total, files } = useSelector(uploadDataSelector);
  const { isLoader } = useSelector(loaderSelector)
  const [error, setError] = useState(false);
  const [isFileUploadButton, setIsFileUploadButton] = useState<any>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [pageTitle, setPageTitle] = useState(headers.pageTitle);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>("");
  const [downloadMessage, setOpenDownloadMessage] = useState(false);
  const [orderBy, setOrderBy] = useState<"DESC" | "ASC">("DESC");
  const backendUrl = process.env.BACKEND_API_URL;
  // const backendUrl = "https://dev-bcg-methanelite-api.kellton.net";

  const toggleFileUploadButton = () => {
    setIsFileUploadButton((isFileUploadButton: any) => !isFileUploadButton);
  };

  useEffect(() => {
    dispatch(
      getUploadHistories(`?page=${page}&limit=${pageSize}&order=${orderBy}`)
    );
  }, [page, pageSize, orderBy]);

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="UPLOAD"
        variant="contained"
        onClick={toggleFileUploadButton}
        startIcon={<UplaodIcon />}
        width="150px"
      />
    </Stack>
  );

  const handleIconButtonClick = (row: {}) => {
    setPageTitle(headers.pageTitle);
  };

  const handleErrorClick = (id: number) => {
    setOpenDownloadMessage(true);
    dispatch(
      getHistoryById(id, (data: any) => {
        if (data) {
          if (data?.errorText?.length) {
            convertJsonToCsvAndDownload(data?.filename, data);
            setOpenDownloadMessage(false);
          }
        }
      })
    );
  };

  const handleFileName = (row: any) => {
    const fileName_ = (row?.filename).replace(".csv", "");
    let returnStatement = fileName_;
    if (row?.error) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1" className="fixError">
            {returnStatement}
          </Typography>
          <FixErrorChip onClick={() => handleErrorClick(row?.id)} />
        </Stack>
      );
    }
    return returnStatement;
  };

  const handleDownloadClick = (row: any) => {
    let fileUrl = `${backendUrl}/${row?.fileUrl}`;
    if (row.fileUrl.includes("https")) {
      fileUrl = row.fileUrl
    }

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", row?.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: any = new TableColumn({
    handleIconButtonClick,
    handleFileName,
    handleDownloadClick,
  });

  const handleCloseStatusModal = () => {
    setPage(1);
    dispatch(
      getUploadHistories(`?page=${1}&limit=${pageSize}&order=${orderBy}`, false)
    );
    setStatusModal(false);
  };

  const handleSort = (key: string) => {
    setOrderBy(orderBy === "DESC" ? "ASC" : "DESC");
  };

  return (
    <>
      {(isLoader?.isShow && isLoader?.component === "uploadHistory") && <CircularLoader />}

      <DefaultLayout
        pageTitle={pageTitle}
        renderHeaderButtons={renderHeaderButtons}
      >
        <Box>
          <CrudTable
            rows={files}
            columns={columns.column}
            initialRowsPerPage={5}
            showCheckbox={false}
            showSearch={false}
            page={page}
            total={total}
            rowsPerPage={pageSize}
            setPage={setPage}
            setRowsPerPage={setPageSze}
            searchApi={getUploadHistories}
            handleSort={handleSort}
          />
        </Box>
      </DefaultLayout>
      {isFileUploadButton && (
        <UploadForm
          isFileUploadButton={isFileUploadButton}
          setIsFileUploadButton={setIsFileUploadButton}
          handleClose={toggleFileUploadButton}
          setStatusModal={setStatusModal}
          setUploadMessage={setUploadMessage}
          setShowErrorMessage={setShowErrorMessage}
        />
      )}
      {statusModal && (
        <StatusModal
          type={showErrorMessage ? "error" : "success"}
          bodyText={uploadMessage}
          open={statusModal}
          onClose={handleCloseStatusModal}
          width="431px"
        />
      )}
      {downloadMessage && (
        <Toast message={errorMessages?.fileDownload} type={"info"} />
      )}
    </>
  );
};

export default UploadData;
