import React, { useEffect, useState } from "react";
import Select, {
  defaultTheme,
  MultiValue,
  ActionMeta,
  GroupBase,
  OptionProps,
  components,
} from "react-select";
import { Box, Checkbox, FormControl, styled } from "@mui/material";
import { CommonSelectProps, Option } from "./interface";
import {
  Blanket,
  selectStyles,
  StyledButton,
  StyledMenu,
  StyleOption,
} from "./style";
import ArrowDown from "../icons/arrowDown";
import CustomLabel from "../label/label";
import { FormError } from "../ErrorMessage/errorMessage";
import SelectSearchIcon from "../icons/selectSearchIcon";
import { InfoText } from "../InfoText/InfoText";
import CheckBox from "../Checkbox/CheckBOx";
import Toast from "../Toast/Toast";

const { colors } = defaultTheme;

const CustomFormControl = styled(FormControl)<{ direction?: string }>(
  ({ direction }) => ({
    flexDirection: direction === "row" ? "row" : "column",
    alignItems: direction === "row" ? "center" : "unset",
    gap: direction === "row" ? "8px" : "unset",
    "& .MuiFormHelperText-root": {
      paddingBottom: direction === "row" ? 0 : "8px",
      fontSize: direction === "row" ? "14px" : "12px",
    },
  })
);

const CustomOption = (props: OptionProps<Option, true, GroupBase<Option>>) => {
  const { data, innerProps, isFocused, isSelected } = props;
  return (
    <div
      {...innerProps}
      style={{
        padding: "5px 5px 4px",
        borderBottom: "1px solid #EAEAEA",
      }}
      className="custom-option"
    >
      <StyleOption
        className="select-option"
        style={{ backgroundColor: isFocused ? "#EFF1F7" : "transparent" }}
      >
        <CheckBox readOnly checked={isSelected} sx={{ paddingRight: "10px" }} />
        <span className="optiontext">{data.label}</span>
      </StyleOption>
    </div>
  );
};

const SearchableMultiSelect: React.FC<
  CommonSelectProps & { showInfoText?: boolean }
> = ({
  options,
  formControlProps,
  buttonPlaceholder,
  selectBoxName = 'items',
  Asterisk,
  selectlabel,
  errorText,
  menuPlacement = "bottom",
  placeholder = "Search...",
  direction = "column",
  menuWidth = "100%",
  width,
  height,
  bgcolor,
  showInfoText = false,
  selectedValue,
  name,
  register,
  onSelectChange,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState<MultiValue<Option>>(selectedValue);
    const [limitText, setLimitText] = useState(false);

    // Check if all options are selected
    const isAllSelected = value.length === options.length;

    useEffect(() => {
      setValue(selectedValue);
    }, [selectedValue]);

    const toggleOpen = () => {
      setOpen(!isOpen);
    };

    const MenuList = (props: any) => {
      const { children, showInfoText, isAllSelected, onToggleSelectAll } = props;
    
      return (
        <div>
          {/* Conditionally render InfoText above the options and below the search field */}
          {showInfoText ? (
            <Box className="flex-center">
              <InfoText Text={`Max 10 ${selectBoxName} can be selected at once.`} color="#00438B" />
            </Box>
          ) : (
            // Add a Select All checkbox
            <Box sx={{ padding: "5px 5px 4px", borderBottom: "1px solid #EAEAEA" }}>
              <StyleOption>
                <CheckBox
                  checked={isAllSelected}
                  onChange={onToggleSelectAll}
                  sx={{ paddingRight: "10px" }}
                />
                <span className="optiontext">Select All</span>
              </StyleOption>
            </Box>
          )}
          {children}
        </div>
      );
    };

    const handleSelectChange = (selectedOptions: any) => {
      if (showInfoText) {
        if (selectedOptions.length <= 10) {
          setValue(selectedOptions);
          onSelectChange(selectedOptions);
        } else {
          setLimitText(true);
          setTimeout(() => {
            setLimitText(false);
          }, 2000);
        }
      } else {
        setValue(selectedOptions);
        onSelectChange(selectedOptions);
      }
    };

    // Toggle the selection of all options
    const handleToggleSelectAll = () => {
      if (isAllSelected) {
        setValue([]);
        onSelectChange([]);
      } else {
        const allOptions = options
        setValue(allOptions);
        onSelectChange(allOptions);
      }
    };

    return (
      <>
        <CustomFormControl
          fullWidth
          variant="outlined"
          {...formControlProps}
          error={Boolean(errorText)}
          direction={direction}
        >
          {selectlabel && (
            <CustomLabel
              inputLabel={selectlabel}
              Asterisk={Asterisk}
              className={isOpen ? "Mui-focused" : ""}
            />
          )}
          <Dropdown
            menuWidth={menuWidth}
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
              <StyledButton
                onClick={toggleOpen}
                disableRipple
                className="selectFiled specific-class"
                bgcolor={bgcolor}
                sx={{
                  border: errorText
                    ? "1px solid #E4002B"
                    : isOpen
                      ? "1px solid #64A70B"
                      : "",
                  width: width,
                  height: height
                }}
              >
                <span
                  className={value?.length > 0 ? "selectValue specific-class" : "placeholder"}
                >
                  {value?.length > 0
                    ? value?.map((v) => v.label).join(", ")
                    : buttonPlaceholder}
                </span>
                <ArrowDown
                  sx={{
                    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                    position: "absolute",
                    right: "10px",
                    fontSize: "14px",
                  }}
                />
              </StyledButton>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Option: CustomOption,
                MenuList: (props) => (
                  <MenuList
                    {...props}
                    showInfoText={showInfoText}
                    isAllSelected={isAllSelected}
                    onToggleSelectAll={handleToggleSelectAll}
                  />
                ),
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              isMulti
              onChange={handleSelectChange}
              options={options}
              placeholder={placeholder}
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
              menuPlacement={menuPlacement}
            />
          </Dropdown>
        </CustomFormControl>
        {errorText && <FormError message={errorText} />}
        {limitText && <Toast message={"You can select up to 10 values."} type={"info"} />}
      </>
    );
  };

const Dropdown: React.FC<{
  children: React.ReactNode;
  isOpen: boolean;
  menuWidth: string;
  target: React.ReactNode;
  onClose: () => void;
}> = ({ children, isOpen, target, onClose, menuWidth }) => (
  <Box sx={{ position: "relative" }}>
    {target}
    {isOpen ? (
      <StyledMenu className="menuDropdown" menuWidth={menuWidth}>
        {children}
      </StyledMenu>
    ) : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Box>
);

const DropdownIndicator: React.FC = () => (
  <Box
    className="searchIcon"
    sx={{
      color: colors.neutral20,
      height: 13,
      width: 13,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SelectSearchIcon />
  </Box>
);

export default SearchableMultiSelect;
