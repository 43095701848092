import dictionary from "../dictionary";
import methods from "../methods";

const { uploadData } = dictionary;
const { get, post, patch } = methods;

const UploadDataService = {
  uploadFile: (body: {}) => {
    return post(uploadData.fileUpload(), body);
  },
  getUploadedHistories: (params: {}) => {
    return get(uploadData.uploadHistory()+ `${params}`);
  },
  getHistory: (id: number) => {
    return get(uploadData.uploadHistory()+ `/${id}`);
  },
};

export default UploadDataService;