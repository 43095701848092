import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import {
  AddComponentSubmitProps,
  AddSiteSubmitProps,
} from "../../pages/Site/interface";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  componentMasterData: [],
  componentTypeList: [],
  componentByID: [],
  total: 0,
};

const componentMasterSlice: any = createSlice({
  name: "componentMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    setComponentList: (state, { payload }) => {
      state.componentMasterData = payload.data;
      state.total = payload?.meta?.itemCount;
    },
    setComponentTypeList: (state, { payload }) => {
      state.componentTypeList = payload;
    },
    setComponentById: (state, { payload }) => {
      state.componentByID = payload;
    },
  },
});

export const {
  clearMasterState,
  setComponentList,
  setComponentTypeList,
  setComponentById,
} = componentMasterSlice.actions;

// Action creators are generated for each case reducer function
export const componentMasterSelector = (state: any) => state.componentMaster;

export default componentMasterSlice.reducer;

export const getComponentData = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(
        setLoderVisibility({ isShow: true, component: "componentList" })
      );
    }

    try {
      const res = await MasterService.getComponentList(params);
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(
            setLoderVisibility({ isShow: false, component: "componentList" })
          );
        }
        dispatch(setComponentList(res.data));
      }
    } catch (error) {
      dispatch(
        setLoderVisibility({ isShow: false, component: "componentList" })
      );
    }
  };
};

export const getComponentById = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setComponentById([]))
      dispatch(
        setLoderVisibility({ isShow: true, component: "componentList" })
      );
    }

    try {
      const res = await MasterService.getComponentById(params);
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(
            setLoderVisibility({ isShow: false, component: "componentList" })
          );
        }
        // dispatch(setComponentById(res.data));
        dispatch(
          setComponentById(
            res.data.map((item: any) => {
              return {
                ...item, // Spread the original item
                components: item.components.map((component: any) => ({
                  ...component, // Spread the component data
                  componentType: item.componentType, // Add the componentType to each component
                })),
              };
            })
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        dispatch(setComponentById([]))
      }
      dispatch(
        setLoderVisibility({ isShow: false, component: "componentList" })
      );
    }
  };
};

export const addComponent = (
  payload: AddComponentSubmitProps,
  successCB: any
) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "component" }));
    try {
      const res = await MasterService.addComponent(payload);
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "component" }));
        successCB();
      }
    } catch (error) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "component" }));
        dispatch(setErrorMessage(error));
      }
    }
  };
};

export const editComponent = (
  payload: AddComponentSubmitProps,
  id: string | number,
  successCB: any
) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "component" }));
    try {
      const res = await MasterService.editComponent(payload, id);
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "component" }));
        successCB();
      }
    } catch (error) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "component" }));
        dispatch(setErrorMessage(error));
      }
    }
  };
};

export const getComponentTypeList = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getComponentTypeList();
      if (res.status === 200) {
        dispatch(setComponentTypeList(res.data.data));
      }
    } catch (error) { }
  };
};
