import React, { useCallback, useEffect, useState } from 'react'
import DefaultLayout from '../../components/layout'
import { Box, Stack } from '@mui/material'
import CrudTable from '../../components/atoms/DataTable/CrudTable'
import { FilterButton, PrimaryButton } from '../../components/atoms/button/button'
import PlusIcon from '../../components/atoms/icons/plusIcon'
import { useAppDispatch } from '../../store/hooks'
import { equipmentMasterSelector, getEquipmentMasterData, getEquipmentMasterDataByType } from '../../store/slices/equipmentMaster'
import { useSelector } from 'react-redux'
import NewtableColumn from './NewtableColumn'
import FilterIcon from '../../components/atoms/icons/filterIcon'
import { getSiteData, siteMasterSelector } from '../../store/slices/siteMaster'
import FilterEquipment from './Filter'
import { useNavigate, useParams } from 'react-router-dom'
import { EquipmentConstants } from './constant'
import StatusModal from '../../components/atoms/StatusModal/StatusModal'
import AddEquipment from './AddEquipment'
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";
import NewCrudTable from '../../components/atoms/DataTable/NewCrudTable';
import { searchMasterList, filterByGroup } from "../../utils/helper";

export default function EquipmentMaster() {
  const dispatch: any = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate()
  const { headers, text } = EquipmentConstants
  const { equipmentMasterData, total, equipmentListByType, totalEquipmentType } = useSelector(equipmentMasterSelector)
  const { siteMasterData } = useSelector(siteMasterSelector)
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(100);
  const [addVisibility, setAddVisibility] = useState(false)
  const [pageTitle, setPageTitle] = useState(headers.addEquipment)
  const [alertModalVisibility, setAlertModalVisibility] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filterText, setFilterText] = useState<any>([])
  const { isLoader } = useSelector(loaderSelector);
  const [tableData, setTableData] = useState(equipmentListByType);
  const [filterVisibility, handleFilterVisibility] = useState(false);
  const [siteId, setSiteId] = useState<any>(null)
  const [openAcc, setOpenAcc] = useState(0)

  useEffect(() => {
    let params = `?order=DESC`
    if (filterText?.length) {
      filterText?.forEach((item: any) => {
        params += `&site_id=${item}`
      });
    }
    if (id) {
      setSiteId(id)
      dispatch(getEquipmentMasterDataByType(id, params));
      localStorage.setItem("currentSiteId", id);
    }

  }, [filterText, id]);

  useEffect(() => {
    if (searchText?.length > 1) {
      handleMasterSearch();
    } else {
      setTableData(equipmentListByType)
    }
  }, [searchText]);

  useEffect(() => {
    // if (equipmentListByType?.length) {
    setTableData(equipmentListByType)
    // }
  }, [equipmentListByType])

  useEffect(() => {
    dispatch(getSiteData("?limit=9999", false));
  }, [])

  const handleMasterSearch = () => {
    let result = searchMasterList(equipmentListByType, searchText, ["equipmentId"])
    setTableData(result)
  }

  const handleFilterData = (filterkeys: any) => {
    let result = filterByGroup(equipmentListByType, filterkeys)
    setTableData(result)
  }

  const handleIconButtonClick = (row: any) => {
    navigate(`/equipment-details/${row.equipmentId}`);
  };

  const toggleAddButton = useCallback((status: boolean, showSuccessModal?: boolean, type?: string) => {
    setAddVisibility(status)
    setPageTitle(headers.addEquipment)
    if (showSuccessModal) {
      setAlertModalVisibility(true)
      setPage(1);
      let params = `?order=DESC`;
      dispatch(getEquipmentMasterDataByType(id, params));
    }

  }, []);

  const renderHeaderButtons = () => {
    return (
      <PrimaryButton
        startIcon={<PlusIcon />}
        variant="contained"
        onClick={() => toggleAddButton(true)}
        label='Add Equipment'
        width={"169px"}
      />
    );
  };

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const handleHierarchyClick = (row: any) => {
    navigate(`/component-master/${row?.equipmentId}`);
  }

  const columns: any = new NewtableColumn({ handleIconButtonClick, handleHierarchyClick })

  if (addVisibility) {
    return (
      <AddEquipment pageTitle={pageTitle} onBack={toggleAddButton} />
    )
  }

  let pageTitle_ = "Equipment Master"
  if (siteId) {
    pageTitle_ += ` (${siteId})`
  }

  return (
    <>
      {
        (isLoader?.isShow && isLoader.component === "equipmentList") && <CircularLoader />
      }
      <DefaultLayout
        renderHeaderButtons={renderHeaderButtons}
        pageTitle={pageTitle_}
        activeTab="Equipments"
        backHandler={() => navigate(-1)}
      >
        <Box className="placeholder_casing">
          <NewCrudTable
            rows={tableData}
            columns={columns.column}
            showCheckbox={false}
            showSearch={true}
            openAcc={openAcc}
            setOpenAcc={setOpenAcc}
            setSearch={setSearchText}
            renderButtons={renderButtons}
            segmentHeader={"Equipment Type"}
            searchPlaceholder="Search Equipment ID"
          />

        </Box>
        <FilterEquipment
          open={filterVisibility}
          onClose={() => {
            handleFilterVisibility(false);
          }}
          filterCB={(val: any) => {
            handleFilterData(val)
          }}
          clearFilter={() => {
            setTableData(equipmentListByType)
          }}
        />

        <StatusModal
          type="success"
          bodyText={text.addEquipmentAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />

      </DefaultLayout>
    </>
  );
}
