import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import "../Details/style.scss";
import { Box, Divider, Grid, SelectChangeEvent, Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../components/atoms/button/button";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { useAppDispatch } from "../../store/hooks";
import { getEquipmentDetails, updateEquipmentStatus } from "../../store/slices/equipmentMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import AddEquipment from "./AddEquipment";
import { EquipmentConstants } from "./constant";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import { useNavigate } from "react-router-dom";
import { roundOffNumber } from "../../utils/helper";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { useSelector } from "react-redux";

const options11 = [
  { value: "NO", label: "Commissioned" },
  { value: "YES", label: "Decommissioned" },

];

const EquipmentDetails = () => {
  const { headers, text } = EquipmentConstants;
  const dispatch: any = useAppDispatch()
  const navigate = useNavigate()
  const [select1Value, setSelect1Value] = useState<string>("");
  const [details, setDetails] = useState<any>({});
  const [open, setOpen] = useState(false)
  const [alertModalVisibility, setAlertModalVisibility] = useState<any>(false)
  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    getEquipmentDetails_()
  }, []);

  const getEquipmentDetails_ = () => {
    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    if (id) {
      dispatch(getEquipmentDetails(id, successCB));
    }
  }

  const successCB = (data: any) => {
    setSelect1Value(data?.decommissioned)
    setDetails(data)
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelect1Value(event.target.value as string);
    dispatch(updateEquipmentStatus(details.equipmentId, { decommissioned: event.target.value }, handleSuccess))
  };

  const handleSuccess = () => {
    getEquipmentDetails_()
  }

  const handleEdit = () => {
    setOpen(true)
  }

  const toggleAdd = useCallback((status: boolean, showSuccessModal?: boolean, id?: any) => {
    setOpen(status)
    setAlertModalVisibility(showSuccessModal)
    if (showSuccessModal) {
      dispatch(getEquipmentDetails(id, successCB))
    }
  }, []);

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <SecondaryButton
        label="Edit Details"
        variant="contained"
        onClick={handleEdit}
        width={"127px"}
      />
    </Stack>
  );

  if (open) {
    return (
      <AddEquipment pageTitle={headers.editEquipment} onBack={toggleAdd} editData={details} />
    )
  }

  const valueField = (value: any) => {
    if (value && value !== 0) return value;
    return "N/A"
  }



  return (
    <>
      {
        (isLoader?.isShow && isLoader.component === "equipmentDetails") && <CircularLoader />
      }
      <DefaultLayout
        pageTitle="Equipment Details"
        renderHeaderButtons={renderHeaderButtons}
        backHandler={() => navigate(-1)}
        activeTab="Equipments"
      >
        <Box className="detailsMain ">

          <Box className="details">
            <Grid item md={12} container spacing={"30px"}>
              <Grid item md={3} >
                <span className="assetLable">Equipment ID</span>
                <span className="asssetValue">{valueField(details.equipmentId)}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Latitude</span>
                <span className="asssetValue">{valueField(details?.latitude)}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Longitude</span>
                <span className="asssetValue">{valueField(details?.longitude)}</span>
              </Grid>
              <Grid item md={3} className="status-Dropdown">
                <SelectField
                  options={options11}
                  placeholder="Equipment Status"
                  value={select1Value}
                  onChange={handleSelectChange}
                  selectText={"Equipment Status"}
                />
              </Grid>

            </Grid>
            <Divider sx={{ borderColor: "#0000001A", margin: '20px 0' }} />
            <Grid item md={12} container spacing={"30px"}>

              <Grid item md={12} container spacing={"30px"}>
                <Grid item md={3} >
                  <span className="assetLable">Equipment Type</span>
                  <span className="asssetValue">{valueField(details?.equipmentType?.equipmentType)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Equipment Subtype</span>
                  <span className="asssetValue">{valueField(details?.equipmentSubtype)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Equipment Cluster</span>
                  <span className="asssetValue">{valueField(details?.equipmentCluster?.equipmentCluster)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Number of Relevant Equipment</span>
                  <span className="asssetValue"> {valueField(details?.numberOfRelevantEquipment)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Equipment Model</span>
                  <span className="asssetValue">  {valueField(details?.equipmentModel)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Site ID</span>
                  <span className="asssetValue">  {valueField(details?.siteId?.siteId)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">   Number Of Pistons</span>
                  <span className="asssetValue"> {valueField(details?.numberOfPistons)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Type Of Seal</span>
                  <span className="asssetValue">  {valueField(details?.typeOfSeal?.sealType)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Physical Volume Of Maintained Equipment (m³)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.physicalVolumeOfMaintainedEquipmentM3))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">   Physical Volume Of Engine (m³)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.physicalVolumeOfEngineM3))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Residual Pressure (ATM)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.residualPressureATM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Time To Address Leak (days)</span>
                  <span className="asssetValue">{valueField(roundOffNumber(details?.timeToAddressLeakDays))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Diameter (cm)</span>
                  <span className="asssetValue">  {valueField(roundOffNumber(details?.diameterCM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Volume Of Pig Receiver (m³)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.volumeOfPigReceiverM3))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Length (m)</span>
                  <span className="asssetValue">{valueField(roundOffNumber(details?.lengthM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Type Of Well</span>
                  <span className="asssetValue">{valueField(details?.typeOfWell?.wellType)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Decommissioned</span>
                  <span className="asssetValue"> {valueField(details?.decommissioned)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Production Method</span>
                  <span className="asssetValue">{valueField(details?.productionMethod?.productionMethod)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Low Pressure?</span>
                  <span className="asssetValue">{valueField(details?.lowPressure)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Perform Casing Head Venting Applicable?</span>
                  <span className="asssetValue"> {valueField(details?.performCasingHeadVentingApplicable)}</span>
                </Grid>
                {/* <Grid item md={3}  >
                  <span className="assetLable"> Flaring Efficiency (%)</span>
                  <span className="asssetValue"> {
                    details?.flaringEfficiency ? roundOffNumber((details?.flaringEfficiency * 100).toFixed(2)) : ""
                  }</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">Standby Factor (%)</span>
                  <span className="asssetValue">{valueField(roundOffNumber((details?.standbyFactor * 100).toFixed(3)) || ""}</span>
                </Grid> */}
                <Grid item md={3}  >
                  <span className="assetLable"> Agent Processed</span>
                  <span className="asssetValue">  {valueField(details?.agentProcessed?.agentProcessed)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Type Of Gas Burnt</span>
                  <span className="asssetValue">  {valueField(details?.typeOfGasBurnt?.gasType)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Engine Type</span>
                  <span className="asssetValue"> {valueField(details?.engineType?.engineType)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Thickness Of A Wall (mm)</span>
                  <span className="asssetValue">{valueField(roundOffNumber(details?.thicknessOfAWallMM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Cumulative Operating Hours</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.cumulativeOperatingHours))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Productivity (m³/hour)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.productivityM3PerHour))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Power Rating (Kw)</span>
                  <span className="asssetValue">{valueField(roundOffNumber(details?.powerRatingKW))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Inlet/Operational Pressure (ATM)</span>
                  <span className="asssetValue">  {valueField(roundOffNumber(details?.inletOperationalPressureATM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable">  Outlet Pressure (ATM)</span>
                  <span className="asssetValue">{valueField(roundOffNumber(details?.outletPressureATM))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Water Cut</span>
                  <span className="asssetValue"> {valueField(roundOffNumber((details?.waterCut * 100).toFixed(3))) || ""}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Age (Years)</span>
                  <span className="asssetValue"> {valueField(roundOffNumber(details?.ageYears))}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"> Other (Dimension related data field)</span>
                  <span className="asssetValue">{valueField(details?.otherDimensions)}</span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"></span>
                  <span className="asssetValue"></span>
                </Grid>
                <Grid item md={3}  >
                  <span className="assetLable"></span>
                  <span className="asssetValue"></span>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <StatusModal
          type="success"
          bodyText={text.editEquipmentAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
      </DefaultLayout>
    </>
  );
};

export default EquipmentDetails;
