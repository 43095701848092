import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  color: 'transparent',
  '&.Mui-checked': {
    color: 'transparent',
  },
}));

const CheckboxIcon = styled('span')(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  border: '1px solid #BAB7B3',
  backgroundColor: '#fff',
}));

const CheckboxCheckedIcon = styled('span')(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: '#64A70B', // Green background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    color: '#fff', // White check mark
    fontSize: '16px',
  },
}));

interface CommonCheckboxProps extends CheckboxProps {
  isSelected?: boolean;
}

const CheckBox: React.FC<CommonCheckboxProps> = ({ isSelected, ...props }) => {
  return (
    <StyledCheckbox
      readOnly
      checked={isSelected}
      disableRipple
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon><CheckIcon /></CheckboxCheckedIcon>}
      {...props}
    />
  );
};

export default CheckBox;
