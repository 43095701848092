import React, { useEffect, useState } from 'react';
import FilterModal from '../../components/atoms/FilterModal/FilterModal';
import { Grid } from '@mui/material';
import { FilterPaylod, FilterProps } from './interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EquipmentConstants } from './constant';
import { convertArrayToObject } from '../../utils/helper';
import { useAppDispatch } from '../../store/hooks';
import MultiSelectField from '../../components/atoms/MultiSelect/MultiSelectField';
import { getEquipmentTypeList, equipmentMasterSelector } from '../../store/slices/equipmentMaster';
import { useSelector } from 'react-redux';

const FilterEquipment = ({ open, onClose, filterCB, clearFilter }: FilterProps) => {
  // Destructure constants from SiteConstants
  const [clearDisabled, setClearDisabled] = useState(false)
  const [filterDisabled, setFilterDisabled] = useState(false)
  const { label, placeholder, name } = EquipmentConstants;

  const { equipmentTypeList } = useSelector(equipmentMasterSelector)

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      site_id: [],
      equipmentType: []
    }
  });

  const equipmentType: any = "equipmentType"

  useEffect(() => {
    localStorage.removeItem("equipFilter")
    // dispatch(getEquipmentTypeList());
  }, [])

  useEffect(() => {
    if (watch(equipmentType)) {
      const val: any = JSON.stringify(watch(equipmentType))
      if (val?.length <= 2) {
        setClearDisabled(true)
        setFilterDisabled(true)
      } else {
        setClearDisabled(false)
        setFilterDisabled(false)
      }
      localStorage.setItem("equipFilter", JSON.stringify(val));
    }

  }, [watch(equipmentType)])

  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("equipFilter")
    reset({ equipmentType: [] });
    onSubmit()
    clearFilter();
  }


  // Handle form submission
  const onSubmit = () => {
    filterCB(watch(equipmentType))
    onClose();
  }

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title='FILTER BY'
        width='475px'
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        clearFilters={handleClearFilters}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={label.equipmentType}
              options={convertArrayToObject(equipmentTypeList, "equipmentType", "equipmentType")}
              placeholder={placeholder.filterEquipmentType}
              name={"equipmentType"}
              register={register}
              value={watch(equipmentType) || []}
              Asterisk={false}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterEquipment;