export const GasFactorConstants = {
  label: {
    assetId: "Asset ID",
    assetName: "Asset Name",
    gasType: "Gas Type",
    massContentOfMethane: "Mass Content of CH₄ (%)",
    densityOfGas: "Density of Gas (kg/m3)",
    filterAsset: "Asset ID",
    filterGasType: "Gas Type"
  },
  headers: {
    addGasFactor: "Add Gas Factor",
    editGasFactor: "Edit Gas Factor"
  },
  placeholder: {
    assetId: "Select Asset ID",
    assetName: "Select Asset Name",
    gasType: "Select Gas Type",
    massContentOfMethane: "Enter value",
    densityOfGas: "Enter value",
    assetID: "Select Asset ID"
  },
  button: {
    submit: "Submit",
    editGasFactor: "Edit Gas Factor",
    addGasFactor: "Add Gas Factor"
  },
  text: {
    addGasFactorAlert: "Gas Factor added successfully.",
    editGasFactorAlert: "Gas Factor updated successfully."
  },
  name: {
    assetId: "assetId",
    gasType: "gasType",
    massContentOfMethane: "massContentOfMethane",
    densityOfGas: "densityOfGas",
    asset_id: "assetId"
  },
  columnName: {
    assetId: "Asset ID",
    assetName: "Asset Name",
    gasType: "Gas Type",
    massContentOfMethane: "Mass Content of CH₄ (%)",
    densityOfGas: "Density of Gas (kg/m3)"
  }
}