import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Checkbox,
  Select,
  MenuItem,
  Stack,
  Box,
  Typography,
  TableBody,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import PaginationItem from "@mui/material/PaginationItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputField from "../InputField/InputField";
import SearchIcon from "../icons/searchIcon";
import { DataTableProps, Row, DetailRow } from "./interface";
import TableSortIcon from "../icons/tableSortIcon";
import {
  getTwoDecimalDigit,
  getDateFromDatetime,
  roundOffNumber,
} from "../../../utils/helper";
import {
  StyledTableHead,
  StyledTableBody,
  StylePagination,
  StyleRowPerPage,
  StyleAccodian,
} from "./style";
import { PrimaryButton, SecondaryButton } from "../button/button";
import { useAppDispatch } from "../../../store/hooks";
import TableArrow from "../icons/tableArrow";
import RenderNoDataRows from "./NoDataTable";
import { loaderSelector } from "../../../store/slices/loaderSlice";
import { useSelector } from "react-redux";

const CollapsibleTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  rowsPerPageOptions = [10, 20, 50, 100],
  initialRowsPerPage = 10,
  showCheckbox = true,
  showSearch = true,
  seachField = true,
  pageTitle,
  renderButtons,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  searchApi,
  total,
  renderHeaderButtons,
  fixDuplicateEmissions,
  keepDuplicateEmissions
}) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>(
    rows?.map((row) => row.id)
  );
  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    setExpandedRowIds([]);
  }, []);

  const getFieldValue = (column: string, columnValues: any) => {
    if (column == "detectionDate")
      return columnValues["detectionTime"]
        ? `${getDateFromDatetime(columnValues[column])} | ${columnValues["detectionTime"]}`
        : `${getDateFromDatetime(columnValues[column])}`;
    else if (
      column == "detectionMethod" &&
      columnValues["detectionMethod"] == "Bottom-Up" &&
      columnValues["detectionType"]
    )
      return `${columnValues["detectionMethod"]} (${columnValues["detectionType"]})`;
    else return columnValues[column];
  };

  const fixDuplicates = (id: number) => {
    if (fixDuplicateEmissions) fixDuplicateEmissions(id);
  };

  const keepDuplicates = (id: number) => {
    if (keepDuplicateEmissions) keepDuplicateEmissions(id);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows?.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    // Check if the click target is a checkbox
    const target = event.target as HTMLInputElement;
    if (target.type === "checkbox") {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((itemId) => itemId !== id);
      }

      setSelected(newSelected);
    }
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRowsPerPage(event.target.value as number);
    setPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (event?.target?.value?.length > 2) {
      dispatch(searchApi(`?q=${event.target.value}`));
    } else {
      dispatch(searchApi(`?page=1&limit=${rowsPerPage}`));
    }
    setPage(1);
  };

  const handleAccordionChange = (id: number) => {
    setExpandedRowIds((prev) =>
      prev.includes(id)
        ? prev.filter((expandedId) => expandedId !== id)
        : [...prev, id]
    );
  };

  const stableSort = (array: Row[], comparator: (a: Row, b: Row) => number) => {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [Row, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const getComparator = (order: "asc" | "desc", orderBy: string) => {
    return order === "desc"
      ? (a: Row, b: Row) => descendingComparator(a, b, orderBy)
      : (a: Row, b: Row) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: Row, b: Row, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filteredRows = rows?.filter((row) =>
    columns.some((column) =>
      row[column.id] ? row[column.id].toString().toLowerCase() : false
    )
  );

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));

  const paginatedRows = sortedRows;

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box className="CrudTable">
      <Box className="dataTable">
        {showSearch && (
          <Box className="filterRow flex spacebetween">
            {seachField && (
              <InputField
                type="text"
                name={""}
                value={searchText}
                placeholder="Search"
                onChange={handleSearchChange}
                startIcon={<SearchIcon />}
                serachBox={true}
                width={290}
              />
            )}
            {renderButtons && renderButtons()}
          </Box>
        )}
        <TableContainer>
          <Table>
            <StyledTableHead>
              <TableRow>
                {showCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected?.length > 0 &&
                        selected?.length < filteredRows?.length
                      }
                      checked={
                        filteredRows?.length > 0 &&
                        selected?.length === filteredRows?.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{ width: column.width, textAlign: column.align }} // Apply the width here
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                        IconComponent={TableSortIcon}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {paginatedRows?.length === 0 ? (
                <RenderNoDataRows
                  columns={columns}
                  showCheckbox={showCheckbox}
                />
              ) : (
                paginatedRows?.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      ></TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length + (showCheckbox ? 1 : 0)}
                        >
                          <StyleAccodian
                            expanded={expandedRowIds.includes(row.id)}
                            onChange={() => handleAccordionChange(row.id)}
                            className="accordian-summary"
                          >
                            <Box className="accordianSummaryBox">
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-summary"
                              >
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    colSpan={columns?.length}
                                  >
                                    <Box className="accordian-header ">
                                      {column.id === "id" ? (
                                        <>
                                          <span className="linkData">
                                            {column?.render
                                              ? column?.render(row)
                                              : row?.equipmentId?.equipmentId}
                                          </span>
                                          <Box className="duplicates-record">
                                            {row?.duplicates?.length > 0 &&
                                              `${row.duplicates?.length} Duplicates Found`}
                                          </Box>
                                        </>
                                      ) : column.render ? (
                                        column.render(row)
                                      ) : (
                                        row[column.id]
                                      )}
                                    </Box>
                                  </TableCell>
                                ))}
                              </AccordionSummary>
                              {expandedRowIds.includes(row.id) && (
                                <Stack direction={"row"} gap={"10px"} sx={{ mt: "8px", mr: "10px" }}>
                                  <SecondaryButton
                                    onClick={() => keepDuplicates(row.id)}
                                    label="Keep All Detections"
                                    width={"157px"}
                                    height={"38px"}
                                  />
                                  <PrimaryButton
                                    onClick={() => fixDuplicates(row.id)}
                                    label="Keep One Detection"
                                    width={"157px"}
                                    height={"38px"}
                                  />
                                </Stack>
                              )}
                            </Box>
                            <AccordionDetails className="accordian-details">
                              <Table aria-label="details">
                                <TableBody>
                                  {row?.duplicates?.map(
                                    (detailRow: DetailRow) => (
                                      <TableRow key={detailRow?.detectionId}>
                                        {/* {showCheckbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                        )} */}
                                        {columns.map((column) => (
                                          <TableCell
                                            key={column.id}
                                            className={column.class}
                                            style={{ width: column.width }}
                                          >
                                            {column.id == "id"
                                              ? <a className="linkData" href={`/emission-details/${detailRow?.detectionId}`}>{detailRow?.detectionId}</a>
                                              : ""}
                                            {column.render
                                              ? column.render(detailRow)
                                              : column.id == "emissionRate"
                                                ? roundOffNumber(
                                                  parseFloat(
                                                    detailRow[column.id]
                                                  )
                                                )
                                                : getFieldValue(
                                                  column.id,
                                                  detailRow
                                                )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </AccordionDetails>
                          </StyleAccodian>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              )}
            </StyledTableBody>
          </Table>
        </TableContainer>
        {paginatedRows?.length > 0 && (
          <Box className="paginationRow flex-alignCenter spacebetween">
            <StyleRowPerPage spacing={0.75} direction="row">
              <Typography>Show</Typography>
              <Select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                sx={{ height: "28px" }}
              >
                {rowsPerPageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <Typography>Entries</Typography>
            </StyleRowPerPage>
            <Stack>
              <StylePagination
                count={Math.ceil(total / rowsPerPage)}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
                renderItem={(item: any) => (
                  <PaginationItem
                    slots={{ previous: TableArrow, next: TableArrow }}
                    {...item}
                  />
                )}
                sx={{ gap: "15px" }}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CollapsibleTable;
