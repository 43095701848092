import * as yup from "yup";
import { isRequired } from "../../../utils/validationHelper";
import { Messages } from "../../../constants/form.validations.message";

export const validations = yup.object().shape({
  assetId: isRequired,
  siteId: isRequired,
  equipmentId: yup.object().test(
    "is-not-empty",
    Messages.required,
    (value) => value && Object.keys(value).length > 0
  ).required(Messages.required),
  // componentId: isRequired,
  // emissionSubcategoryId: isRequired,
  comment: yup.string()
    .nullable()
    .trim()
    .max(200, Messages.maxLength(200))
})
