// @ts-nocheck
import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CommonDatePickerProps } from "./interface";
import { StyledDatePicker } from "./style";
import CalendarIcon from "../icons/calendarIcon";
import { FormError } from "../ErrorMessage/errorMessage";

const DatePicker: React.FC<CommonDatePickerProps> = ({
  value,
  onChange,
  formControlProps,
  selectlabel,
  Asterisk,
  direction = "column",
  Width = "100%",
  errorText,
  defaultPicker,
  ...props
}) => {
  const [cleared, setCleared] = React.useState<boolean>(false);
  let format = "DD/MM/YYYY"
  let placeHolder = "DD/MM/YYYY"
  const pickerProps = {}


  if (!defaultPicker) {
    pickerProps.views = props?.views ? props?.views : ["year", "month"]
    format = props?.format ? props?.format : "MMM YYYY"
    placeHolder = "MM/YY"
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          {...props}
          value={value}
          onChange={onChange}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          {...pickerProps}

          format={format} // Format to display "Jan 2024"
          slotProps={{
            field: { clearable: false, onClear: () => setCleared(true) },
            openPickerButton: {
              disableRipple: true,
            },
            textField: {
              InputProps: {
                readOnly: true, // This makes the input field read-only
              },
              inputProps: {
                disabled: true, // This disables typing in the field
                placeholder: placeHolder
              },
              helperText: selectlabel && (
                <>
                  {selectlabel}
                  {Asterisk && <sup>*</sup>}
                </>
              ),
              error: Boolean(errorText),
            },
          }}

          direction={direction}
          Width={Width}

        />
      </LocalizationProvider>
      {errorText && <FormError message={errorText} />}
    </>
  );
};

export default DatePicker;
