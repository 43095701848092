import React, { ChangeEvent } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./button.scss";
import { CommonButtonProps, UploadButtonProps } from "./interface";
import { colors, Stack } from "@mui/material";
import { EventAvailableSharp } from "@mui/icons-material";
import CircularLoader from "../CircularLoader/Loader";

const BaseButton = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  borderRadius: "8px",
  boxShadow: "none",
  textTransform: "none",
  ".MuiButton-startIcon": {
    marginRight: "8px",
    marginLeft: "unset",
  },
  ".MuiButton-endIcon": {
    marginLeft: "8px",
    marginRight: "unset",
  },
}));

const PrimaryBtn = styled(BaseButton)(({ }) => ({
  backgroundColor: "#3C8200",
  color: "#ffffff",
  textTransform: "capitalize",
  padding: 0,
  "&:hover": {
    backgroundColor: "#296D00",
    boxShadow: "0px 4px 8px 0px #0000001A",
  },
  "&.Mui-disabled": {
    backgroundColor: "#F4F3F4",
    color: "#615D5F",
  },
}));

export const PrimaryButton: React.FC<CommonButtonProps> = ({
  label,
  startIcon,
  endIcon,
  width,
  disabled,
  loading,
  height = "48px",
  ...props
}) => {
  return (
    <PrimaryBtn
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
      sx={{ width, height }}
      disableRipple
      disabled={disabled}
    >
      {label}
    </PrimaryBtn>
  );
};

const SecondaryBtn = styled(BaseButton)(({ }) => ({
  backgroundColor: "transparent",
  color: "#3C8200",
  border: "1px solid #3C8200",
  textTransform: "capitalize",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    color: "#296D00",
    border: "1px solid #296D00",
    boxShadow: "0px 4px 8px 0px #0000001A",
  },
  "&.Mui-disabled": {
    backgroundColor: "#ffffff",
    border: "1px solid #615D5F",
    color: "#615D5F",
  },
  "& svg": {
    "& path": {
      fill: "#64A70B",
    },
  },
}));

export const SecondaryButton: React.FC<CommonButtonProps> = ({
  label,
  startIcon,
  endIcon,
  width,
  height = "48px",
  disabled,
  loading,
  ...props
}) => {
  return (
    <SecondaryBtn
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
      sx={{ width, height }}
      disableRipple
      disabled={disabled}
    > {label}

    </SecondaryBtn>
  );
};

const FilterBtn = styled(BaseButton)(({ }) => ({
  backgroundColor: "#fff",
  color: "#3C8200",
  padding: 0,
  borderRadius: "8px",
  minWidth: "82px",
  border: '1px solid #3C8200',
  fontSize: '14px',
  lineHeight: '22.4px',

  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 8px 0px #0000001A",
  },
  ".MuiButton-startIcon": {
    marginRight: "5px",
  },
  "& svg": {
    "& path": {
      // fill: "#000000",
    },
  },
}));

export const FilterButton: React.FC<CommonButtonProps> = ({
  label,
  startIcon,
  endIcon,
  width = "99px",
  height = "48px",
  ...props
}) => {
  return (
    <FilterBtn
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
      sx={{ width, height }}
      disableRipple
    >
      {label}
    </FilterBtn>
  );
};

const UploadBtn = styled(Stack)(({ }) => ({
  "& .MuiButton-root": {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19.36px",
    backgroundColor: "#64A70B",
    color: "#ffffff",
    padding: "13px 26px",
    borderRadius: "10px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4a7c07",
    },
    "&.Mui-disabled": {
      backgroundColor: "#CACACA", // Disabled background color
      color: "#fff", // Disabled font color
    },
  },
}));

export const UploadButton: React.FC<UploadButtonProps> = ({
  onChange,
  accept = ".png,.pdf,.doc,.docx",
  buttonText = "Upload",
  startIcon,
  width,
  loading,
  height = "48px",
  disabled = false,
  value,
  ...props
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files?.length > 0) {
      onChange(files[0], event);
    }
  };

  return (
    <UploadBtn>
      <input
        type="file"
        accept={accept}
        style={{ display: "none" }}
        onChange={handleFileChange}
        id="file-upload"
        disabled={disabled}
        value={value}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={!loading && startIcon}
          {...props}
          sx={{ width, height }}
          disableRipple
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </label>
    </UploadBtn>
  );
};
