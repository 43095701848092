import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
<path d="M1 10.5L4.73569 6.6145C4.90498 6.43737 5 6.19776 5 5.948C5 5.69825 4.90498 5.45864 4.73569 5.28151L1.09998 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="bevel"/>
</svg>
  </SvgIcon>
);

export default ArrowRight;
