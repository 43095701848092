import * as yup from "yup";

export const validations = yup.object().shape({
  assetId: yup.string().trim().required("This field is Required"),

  initiative: yup.string().trim().required("This field is Required"),

  emission: yup.string().trim().required("This field is Required"),

  volume: yup
    .string()
    .required("This field is Required")
    .matches(/^\d+$/, "This field must be greater than or equal to 0")
    .min(1, "This field must have at least 1 characters")
    .max(6, "This field must have less than or equal to 6 characters")
    .test("isValidNumber", "Must be a valid number", (value) => {
      const parsedValue = Number(value);
      return !isNaN(parsedValue);
    }),

  percentAbated: yup
    .number()
    .typeError("This field is Required")
    .required("This field is Required")
    .min(0, "This field cannot be greater than or equal to 0")
    .max(999, "This field must have less than or equal to 3 characters")
    .test(
      "isInteger",
      "Must be an integer and less than or equal to 999",
      (value) => {
        return Number.isInteger(value); // Ensures it's an integer
      }
    ),

  abatementCost: yup
    .number()
    .typeError("This field is Required")
    .required("This field is Required")
    .min(-999999, "This field must have at least 1 characters")
    .max(
      999999,
      "This field must have up to 6 digits total with up to 2 after the decimal"
    )
    .test(
      "maxDecimalPlaces",
      "This field must have up to 6 digits total with up to 2 after the decimal",
      (value) => {
        if (value === undefined || value === null) return true; // If value is empty, skip this validation
        const decimalPlaces = value.toString().split(".")[1];
        return !decimalPlaces || decimalPlaces.length <= 2;
      }
    ),

  capex: yup
    .number() // Change capex to number for easier validation
    .typeError("This field is Required")
    .required("This field is Required")
    .min(0, "This field must be greater than or equal to 0")
    .max(99999, "This field must have less than or equal to 5 characters")
    .test("isValidNumber", "Must be a valid number", (value) => {
      const parsedValue = Number(value);
      return !isNaN(parsedValue);
    }),

  comments: yup
    .string()
    .test(
      "is-at-least-5-characters",
      "Comments must be at least 5 characters long",
      (value) => {
        if (!value || value.trim() === "") return true; // Optional field
        return value.trim().length >= 5;
      }
    ),

  status: yup.string().trim().required("This field is Required"),
});
