import React from "react";
export default function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3678_33402)">
        <path
          d="M15.7449 12.8559L9.62933 2.26332C9.28927 1.67432 8.68018 1.32263 7.99999 1.32263C7.31984 1.32263 6.71071 1.67432 6.37065 2.26332L0.25507 12.8558C-0.0850233 13.4449 -0.0850233 14.1482 0.25507 14.7372C0.595163 15.3263 1.20422 15.6779 1.88441 15.6779H14.1156C14.7957 15.6779 15.4048 15.3262 15.7449 14.7373C16.085 14.1482 16.085 13.4449 15.7449 12.8559ZM14.9331 14.2686C14.7625 14.5641 14.4569 14.7406 14.1156 14.7406H1.88441C1.5431 14.7406 1.23747 14.5641 1.06685 14.2686C0.896225 13.973 0.896225 13.6201 1.06685 13.3246L7.18249 2.73204C7.35312 2.43647 7.65874 2.26004 8.00002 2.26004C8.34127 2.26004 8.64693 2.43647 8.81755 2.73204L14.9332 13.3246C15.1038 13.6201 15.1038 13.973 14.9331 14.2686Z"
          fill="#E4002B"
        />
        <path d="M8.46862 6H7.53125V10.6869H8.46862V6Z" fill="#E4002B" />
        <path
          d="M7.99994 11.625C7.65534 11.625 7.375 11.9053 7.375 12.2499C7.375 12.5945 7.65534 12.8749 7.99994 12.8749C8.3445 12.8749 8.62487 12.5945 8.62487 12.2499C8.62487 11.9053 8.34453 11.625 7.99994 11.625Z"
          fill="#E4002B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3678_33402">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
