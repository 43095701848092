import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const SelectArrowDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
<path d="M0.832031 0.916992L4.9987 5.08366L9.16536 0.916992" stroke="#615D5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </SvgIcon>
);

export default SelectArrowDown;
