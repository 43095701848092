import React, { ReactNode } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container, Stack, Typography } from "@mui/material";
import Header from "./header";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CustomSeparator from "../../pages/asset/breadcrum";

interface LayoutProps {
  children: ReactNode;
  renderHeaderButtons?: any;
  pageTitle?: string;
  backHandler?: any;
  activeTab?: any;
}

const DefaultLayout: React.FC<LayoutProps> = ({ children, renderHeaderButtons, pageTitle, backHandler, activeTab }) => {
  return (
    <>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh" >
        <Header />
        <Container
          maxWidth="xl"
          component="main"
          sx={{
            flexGrow: 1,
            paddingBottom: "3rem",
            paddingTop: '70px'
          }}
        >
          {activeTab ?
            <Box sx={{ marginTop: '24px ' }}>
              <CustomSeparator activeTab={activeTab} />
            </Box> :
            null
          }
          <Box sx={{ py: "20px" }} className="flex alignItems spacebetween">
            {pageTitle && (
              <Box className="backPageIcon">
                {backHandler && <span className="flex" onClick={backHandler}><ArrowBackIosNewRoundedIcon /></span>}
                <Typography
                  variant="h1"
                >
                  {pageTitle}
                </Typography>
              </Box>

            )}
            {renderHeaderButtons && renderHeaderButtons()}
          </Box>
          <Box className="Wrapper">
            {children}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DefaultLayout;
