import React, { useState, useEffect } from "react";
import { Box, Button, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import "./ChangeLog.scss";
import ArrowDown from "../../../components/atoms/icons/arrowDown";
import { useAppDispatch } from "../../../store/hooks";
import { useSelector } from "react-redux";
import { getNotificationData, getNotificationChangeLogData, notificationSelector } from "../../../store/slices/notificationSlice";
import { useParams } from "react-router-dom";
import { getDateFromDatetime, getTimeFromDatetime } from "../../../utils/helper";

const ChangeLog = () => {
  const dispatch: any = useAppDispatch();
  const [expanded, setExpanded] = useState(false);
  const { notificationChangeLogs, notificationMasterData } = useSelector(notificationSelector);

  const { id } = useParams();

  useEffect(() => {
    if (id) dispatch(getNotificationChangeLogData(parseInt(id)))
  }, [id])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <>
      {notificationMasterData && notificationChangeLogs && <Typography variant="h4" sx={{ mt: "25px" }}>Change Log</Typography>}
      <Box width="100%" className="change-log-container">
        <TableContainer>
          <Table>
            <TableBody>
              {!expanded && notificationChangeLogs?.map((log: any, index: number) => (
                index < 4 &&
                <TableRow
                  key={index}
                  style={{
                    opacity: index == 2 && !expanded ? 0.3 : index == 3 && !expanded ? 0.2 : 1,
                    transition: "opacity 0.3s"
                  }}
                >
                  <TableCell className="leftColumn">
                    <p>{getDateFromDatetime(log.createdAt)} | {getTimeFromDatetime(log.createdAt)}</p>
                  </TableCell>
                  <Divider className="divider"
                    sx={{
                      borderColor: index == 2 && !expanded ? "rgba(0, 0, 0, 0.04)" : index == 3 && !expanded ? "rgba(0, 0, 0, 0.03)" : "rgba(0, 0, 0, 0.1)",
                    }}
                    orientation="vertical"
                  />
                  <TableCell className="rightColumn">
                    <p key={index} dangerouslySetInnerHTML={{ __html: (log?.message)?.replaceAll("\n", "<br/><br/>") }} />
                  </TableCell>
                </TableRow>
              ))}
              {expanded && notificationChangeLogs?.map((log: any, index: number) => (
                <TableRow
                  key={index + 4}
                  style={{
                    opacity: 1,
                    transition: "opacity 0.3s"
                  }}
                >
                  <TableCell className="leftColumn">
                    <p>{getDateFromDatetime(log.createdAt)} | {getTimeFromDatetime(log.createdAt)}</p>
                  </TableCell>
                  <Divider className="divider"
                    sx={{
                      borderColor: "#0000001A",
                    }}
                    orientation="vertical"
                  />
                  <TableCell className="rightColumn">
                    <p key={index} dangerouslySetInnerHTML={{ __html: (log?.message)?.replaceAll("\n", "<br/><br/>") }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {notificationChangeLogs?.length > 2 && (
          <Box className="ViewMore" sx={{ pb: "8px" }}>
            <Button
              variant="text"
              onClick={handleExpandClick}
              disableRipple
              endIcon={<ArrowDown sx={{ width: "9.52px", transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }} />}
              sx={{ textTransform: 'none' }}
            >
              {expanded ? 'View Less' : 'View More'}
            </Button>
          </Box>
        )}
      </Box>
    </>

  );
};

export default ChangeLog;
