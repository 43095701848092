import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import DownloadIcon from "../../components/atoms/icons/downloadIcon";
import EditIcon from "../../components/atoms/icons/editIcon";
import { useAppDispatch } from "../../store/hooks";
import {
  detectionOptions,
  masterOptions,
  operationalOptions,
  tabs,
} from "./staticData";
import moment from "moment";
import { getHistoryById } from "../../store/slices/uploadData";

interface Payload {
  handleIconButtonClick: any;
  handleFileName: any;
  handleDownloadClick: any;
}

const mergedOptions = masterOptions.concat(
  ...detectionOptions,
  ...operationalOptions
);

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    this.column = [
      {
        id: "filename",
        label: "File Name",
        width: "23%",
        render: (row: Row) => payload.handleFileName(row),
      },
      {
        id: "group",
        label: "Data Group",
        width: "18%",
        render: (row: Row) => {
          return tabs.find((ele) => ele.value === row.activityType)?.label || row?.activityType;
        },
      },
      {
        id: "type",
        label: "Data Type",
        width: "18%",
        render: (row: Row) => {
          return mergedOptions.find((ele) => ele.value === row.dataType)?.label;
        },
      },
      {
        id: "upload",
        label: "Upload Date",
        sortable: true,
        width: "18%",

        render: (row: Row) =>
          row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "N/A",
      },
      {
        id: "uploaded",
        label: "Uploaded By",
        width: "13%",
        render: (row: Row) => row?.createdBy?.firstName,
      },
      {
        id: "download",
        label: "Download",
        align: "center",
        width: "10%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box
              className="downloadIcon flex-center"
              onClick={() => payload.handleDownloadClick(row)}
            >
              <DownloadIcon />
            </Box>
          </Box>
        ),
      },
    ];
  }
}

export default TableColumn;
