import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomSeparator({ activeTab }: any) {
  let bread = ["Assets", "Sites", "Equipments", "Components"]
  // const breadcrumbs = [
  //   <Link underline="none" key="1" color="inherit" href="/" onClick={handleClick}
  //     sx={{ color: '#3C8200', fontSize: '14px', lineHeight: '22.4px' }}>
  //     Asset
  //   </Link>,
  //   <Link
  //     underline="none"
  //     key="2"
  //     color="inherit"
  //     href="/material-ui/getting-started/installation/"
  //     onClick={handleClick}
  //     sx={{ color: '#3C8200', fontSize: '14px', lineHeight: '22.4px' }}>
  //     Core
  //   </Link>,
  //   <Typography key="3" sx={{ color: '#898587', fontSize: '14px', lineHeight: '22.4px' }}>
  //     Breadcrumb
  //   </Typography>,
  // ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs sx={{ margin: '0 4px' }}
        separator={<NavigateNextIcon fontSize="small" sx={{ color: '#898587', width: '16px', height: '16px', }} />}
        aria-label="breadcrumb"
      >
        {bread.map((item) => (
          <Typography key="3" sx={{ color: activeTab === item ? '#3C8200' : '#898587', fontSize: '14px', lineHeight: '22.4px' }}>
            {item}
          </Typography>
        ))}
        {/* {breadcrumbs} */}
      </Breadcrumbs>
    </Stack>
  );
}
