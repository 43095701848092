import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Checkbox,
  Select,
  MenuItem,
  Stack,
  Box,
  Typography,
  Accordion, AccordionSummary, AccordionDetails,
  FormControl,
  FormLabel
} from "@mui/material";

import PaginationItem from "@mui/material/PaginationItem";
import InputField from "../InputField/InputField";
import SearchIcon from "../icons/searchIcon";
import { DataTableProps, NewDataTableProps, Row } from "./interface";
import TableSortIcon from "../icons/tableSortIcon";
import {
  StyledTableHead,
  StyledTableBody,
  StylePagination,
  StyleRowPerPage,
} from "./style";
import { useAppDispatch } from "../../../store/hooks";
import TableArrow from "../icons/tableArrow";
import RenderNoDataRows from "./NoDataTable";
import SelectField from "../SelectField/SelectField";
import { useForm } from "react-hook-form";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.scss';
import styled from "@emotion/styled";


const NewCrudTable: React.FC<NewDataTableProps> = ({
  rows,
  columns,
  showCheckbox = true,
  showSearch = true,
  renderButtons,
  searchPlaceholder,
  handleSort,
  setSearch,
  seachField = true,
  segmentHeader,
  setOpenAcc,
  openAcc,
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const handleRequestSort = (property: string) => {
    handleSort(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    // Check if the click target is a checkbox
    const target = event.target as HTMLInputElement;
    if (target.type === "checkbox") {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((itemId) => itemId !== id);
      }

      setSelected(newSelected);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setSearch(event.target.value);
  };

  const stableSort = (array: Row[], comparator: (a: Row, b: Row) => number) => {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [Row, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const getComparator = (order: "asc" | "desc", orderBy: string) => {
    return order === "desc"
      ? (a: Row, b: Row) => descendingComparator(a, b, orderBy)
      : (a: Row, b: Row) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: Row, b: Row, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filteredRows = rows?.filter((row) =>
    columns?.some((column) =>
      row[column.id] ? row[column.id]?.toString().toLowerCase() : false
    )
  );

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));

  const paginatedRows = sortedRows;

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const CustomAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: '#559700',
    borderRadius: '12px  !important',
    color: '#fff',
    marginBottom: '20px',
    '&::before': {
      display: 'none',
    },
  }));
  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    color: '#fff', margin: '0',
    '& .MuiTypography-root': { color: '#fff', fontWeight: '700', },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(180deg)', // Rotate the expand icon
    },
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': { minHeight: '48px !important', margin: '0 !important' },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)', // Rotate the expand icon
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important;', // Add spacing
    },
  }));

  const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: '#fff', // Set details background
    padding: '0',
  }));

  return (
    <Box className="NewCrudTable scrollTable">
      <Box className="dataTable">
        {showSearch && (
          <Box className={`filterRow flex newSerach ${seachField ? 'spacebetween' : 'flexend'}`} sx={{ alignItems: 'flex-end' }} >
            {seachField && (
              <FormControl>
                <FormLabel
                  sx={{ color: '#4D4B4C', fontWeight: '700', marginBottom: '8px', fontSize: '14px' }}>Search</FormLabel>
                <InputField
                  type="text"
                  name={""}
                  value={searchText}
                  placeholder={searchPlaceholder || ""}
                  onChange={handleSearchChange}
                  startIcon={<SearchIcon />}
                  serachBox={true}
                  width={290}
                />
              </FormControl>
            )
            }

            {renderButtons && renderButtons()}
          </Box>
        )}
        {rows?.length === 0 &&
          <TableContainer>
            <Table>
              <StyledTableBody>
                <RenderNoDataRows
                  columns={columns}
                  showCheckbox={showCheckbox}
                />
              </StyledTableBody>
            </Table>
          </TableContainer>
        }
        {rows?.map((tableRow: any, index: number) => (
          <>
            <CustomAccordion defaultExpanded={index === 0}>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
              >
                <Typography>{segmentHeader}: {tableRow?.type}</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <TableContainer>
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        {showCheckbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              indeterminate={
                                selected?.length > 0 &&
                                selected?.length < filteredRows?.length
                              }
                              checked={
                                filteredRows?.length > 0 &&
                                selected?.length === filteredRows?.length
                              }
                              onChange={handleSelectAllClick}
                            />
                          </TableCell>
                        )}
                        {columns?.map((column: any) => (
                          <TableCell
                            key={column.id}
                            sortDirection={orderBy === column.id ? order : false}
                            style={{ width: column.width, textAlign: column.align }} // Apply the width here
                          >
                            {column.sortable ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : "asc"}
                                onClick={() => handleRequestSort(column.id)}
                                IconComponent={TableSortIcon}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {rows?.length === 0 ? (
                        <RenderNoDataRows
                          columns={columns}
                          showCheckbox={showCheckbox}
                        />
                      ) : (
                        tableRow?.columns?.map((row: any) => {
                          const isItemSelected = isSelected(row.id);
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {showCheckbox && (
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isItemSelected} />
                                </TableCell>
                              )}
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className={column.class}
                                  style={{
                                    width: column.width,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.render ? column.render(row) : row[column.id]}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                      )}
                    </StyledTableBody>
                  </Table>
                </TableContainer>
              </CustomAccordionDetails>
            </CustomAccordion>
          </>
        ))
        }

        {/* {paginatedRows?.length > 0 && (
          !removePagination && (
            <Box className="paginationRow flex-alignCenter spacebetween">
              <StyleRowPerPage spacing={0.75} direction="row">
                <Typography>Show</Typography>
                <SelectField
                  value={rowsPerPage?.toString()}
                  onChange={handleChangeRowsPerPage}
                  options={rowsPerPageOptions}
                  sx={{
                    height: "28px",
                    minWidth: "60px",
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "4px",
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "10px",
                        "& .MuiMenu-list": {
                          padding: 0,
                          "& .MuiMenuItem-root": {
                            "& p": {
                              padding: "8px",
                            },
                          },
                        },
                      },
                    },
                  }}
                />

                <Typography>Entries</Typography>
              </StyleRowPerPage>
              <Stack>
                <StylePagination
                  count={Math.ceil(total / rowsPerPage)}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                  renderItem={(item: any) => (
                    <PaginationItem
                      slots={{ previous: TableArrow, next: TableArrow }}
                      {...item}
                    />
                  )}
                  sx={{ gap: "15px" }}
                />
              </Stack>
            </Box>
          )
        )} */}
      </Box>
    </Box >
  );
};

export default NewCrudTable;
