import React from "react";
export default function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M13.4857 10.3829L13.4857 10.383C13.4851 11.0357 13.2255 11.6615 12.764 12.123C12.3025 12.5846 11.6767 12.8441 11.024 12.8448H11.0239H2.86174H2.86164C2.20894 12.8441 1.58316 12.5846 1.12163 12.123C0.660102 11.6615 0.400531 11.0357 0.399883 10.383V10.3829V8.78099C0.399883 8.63006 0.459842 8.48531 0.566567 8.37859C0.673291 8.27186 0.818041 8.2119 0.968972 8.2119C1.1199 8.2119 1.26465 8.27186 1.37138 8.37859C1.4781 8.48531 1.53806 8.63006 1.53806 8.78099V10.3829C1.53806 10.734 1.67752 11.0707 1.92576 11.3189C2.174 11.5672 2.51068 11.7066 2.86174 11.7066H11.0239C11.3749 11.7066 11.7116 11.5672 11.9599 11.3189C12.2081 11.0707 12.3476 10.734 12.3476 10.3829V8.78099C12.3476 8.63006 12.4075 8.48531 12.5142 8.37859C12.621 8.27186 12.7657 8.2119 12.9167 8.2119C13.0676 8.2119 13.2123 8.27186 13.3191 8.37859C13.4258 8.48531 13.4857 8.63006 13.4857 8.78099L13.4857 10.3829Z"
        fill="#29292A"
        stroke="#29292A"
        stroke-width="0.2"
      />
      <path
        d="M9.22509 6.26553L9.22475 6.26512L9.21747 6.27241L7.51182 7.97805V1.25351C7.51182 1.10258 7.45187 0.957826 7.34514 0.851101C7.23842 0.744376 7.09367 0.684419 6.94274 0.684419C6.7918 0.684419 6.64705 0.744376 6.54033 0.851102C6.4336 0.957826 6.37365 1.10258 6.37365 1.25351V7.97101L4.668 6.26537C4.61514 6.2125 4.55237 6.17056 4.48329 6.14195C4.41422 6.11334 4.34018 6.09861 4.26541 6.09861C4.19065 6.09861 4.11661 6.11334 4.04753 6.14195C3.97846 6.17056 3.91569 6.2125 3.86282 6.26537C3.80995 6.31824 3.76802 6.381 3.7394 6.45008C3.71079 6.51916 3.69606 6.59319 3.69606 6.66796C3.69606 6.74273 3.71079 6.81676 3.7394 6.88584C3.76802 6.95492 3.80995 7.01768 3.86282 7.07055L6.53897 9.7467L6.53896 9.74671L6.54006 9.74777C6.61813 9.8235 6.71513 9.87685 6.8209 9.90223L6.82086 9.90237L6.82612 9.90334C6.91776 9.9202 7.0121 9.91487 7.10125 9.88778C7.1904 9.86069 7.27175 9.81264 7.33851 9.74764L7.33852 9.74764L7.33937 9.74679L10.0155 7.07768L10.0158 7.07799L10.0218 7.07079C10.1122 6.96208 10.1588 6.82363 10.1527 6.6824C10.1466 6.54118 10.0881 6.40729 9.98868 6.3068C9.88926 6.20632 9.756 6.14643 9.61485 6.1388C9.4737 6.13117 9.33476 6.17635 9.22509 6.26553Z"
        fill="#29292A"
        stroke="#29292A"
        stroke-width="0.2"
      />
    </svg>
  );
}
