import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import DownloadIcon from "../../components/atoms/icons/downloadIcon";
import EditIcon from "../../components/atoms/icons/editIcon";
import { removeTrailingZeros, roundOffNumber } from "../../utils/helper";


interface Payload {
  handleIconButtonClick: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    this.column = [
      {
        id: "subCategory",
        label: "Emission Sub-Category",
        sortable: false,
        width: "13%",
        render: (row: Row) => row?.emissionSubCategoryId?.name,
      },
      {
        id: "equipmentType",
        label: "Equipment/Component Type",
        sortable: false,
        width: "15%",
        render: (row: Row) => row.equipmentTypeId?.equipmentType ? row.equipmentTypeId?.equipmentType : row.componentTypeId?.componentType,
      },
      {
        id: "cluster",
        label: "Cluster",
        sortable: false,
        width: "8%",
        render: (row: Row) => row.clusterId?.equipmentCluster,
      },
      {
        id: "clusterDetail",
        label: "Cluster Details",
        sortable: false,
        width: "12%",
        render: (row: Row) => row.clusterDetail,
      },
      {
        id: "unit",
        label: "Units",
        sortable: false,
        width: "12%",
        render: (row: Row) => row.units,
      },
      {
        id: "emissionFactor",
        label: "Emission Factor",
        sortable: false,
        width: "10%",
        render: (row: Row) => roundOffNumber(removeTrailingZeros(row?.emissionFactor))
      },
      {
        id: "ogmp",
        label: "OGMP Level",
        sortable: false,
        width: "10%",
        render: (row: Row) => row.ogmpLevel,
      },
      {
        id: "source",
        label: "Asset ID",
        sortable: false,
        width: "13%",
        render: (row: Row) => row.assetId?.assetId,
      },
      {
        id: "edit",
        label: "Edit",
        sortable: false,
        width: "7%",
        align: "center",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleIconButtonClick(row)}>
              <EditIcon />
            </Box>
          </Box>
        ),
      },
    ]
  }
}

export default TableColumn;