import React from "react";
export default function ErrorModalIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
    <g clipPath="url(#clip0_3017_22421)">
    <path d="M43.7986 33.9766L26.9807 4.84714C26.0455 3.22739 24.3705 2.26025 22.5 2.26025C20.6296 2.26025 18.9545 3.22739 18.0193 4.84714L1.20144 33.9765C0.266186 35.5965 0.266186 37.5306 1.20144 39.1504C2.1367 40.7702 3.81162 41.7373 5.68213 41.7373H39.3178C41.1883 41.7373 42.8633 40.7701 43.7986 39.1505C44.7339 37.5306 44.7339 35.5965 43.7986 33.9766ZM41.5661 37.8616C41.0968 38.6744 40.2564 39.1596 39.3178 39.1596H5.68213C4.74352 39.1596 3.90305 38.6744 3.43384 37.8616C2.96462 37.0488 2.96462 36.0784 3.43384 35.2656L20.2519 6.13611C20.7211 5.32332 21.5615 4.83812 22.5001 4.83812C23.4385 4.83812 24.2791 5.32332 24.7483 6.13611L41.5662 35.2656C42.0353 36.0784 42.0353 37.0488 41.5661 37.8616Z" fill="#E4002B"/>
    <path d="M23.7887 15.125H21.2109V28.0141H23.7887V15.125Z" fill="#E4002B"/>
    <path d="M22.4998 30.5938C21.5522 30.5938 20.7812 31.3647 20.7812 32.3123C20.7812 33.26 21.5522 34.0309 22.4998 34.0309C23.4474 34.0309 24.2184 33.26 24.2184 32.3123C24.2184 31.3647 23.4475 30.5938 22.4998 30.5938Z" fill="#E4002B"/>
    </g>
    <defs>
    <clipPath id="clip0_3017_22421">
    <rect width="44" height="44" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
    </svg>
  );
}
