import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import {
  isRequiredAlph,
  isRequired,
  isNumberRequired,
  numberIsRequied,
} from "../../utils/validationHelper";

const rangeRegex = /^(-?([1-8]?[0-9](\.\d{1,6})?|90(\.0{1,6})?))$/;
const rangeRegex180 =
  /^-?(180(\.0{1,6})?|((1[0-7][0-9])|([1-9]?[0-9]))(\.\d{1,6})?)$/;

export const validations = yup.object().shape({
  assetName: isRequiredAlph(2, 100),
  assetType: isRequired,
  assetId: isRequiredAlph(2, 50),
  operator: isRequired,
  country: isRequired,
  latitude: yup
    .string()
    .required(Messages.required)
    .matches(
      rangeRegex,
      "Latitude must be in between -90 to 90 up to 6 decimal."
    ),
  longitude: yup
    .string()
    .required(Messages.required)
    .matches(
      rangeRegex180,
      "Longitude must be in between -180 to 180 up to 6 decimal."
    ),
  operated: isRequired,
  equityPercentage: numberIsRequied(3),
  assetDescription: yup
    .string()
    .trim()
    .required(Messages.required)
    .min(10, Messages.minLength(10))
    .max(250, Messages.maxLength(250)),
  equityType: isRequired,
  reportingLevel: isRequired,
});
