// @ts-nocheck
import React, { useEffect } from 'react';
import DefaultLayout from '../../components/layout';
import { AddSiteProps, AddSiteSubmitProps } from './interface';
import { Grid, Stack } from '@mui/material';
import { SiteConstants } from './constants';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputField from '../../components/atoms/InputField/InputField';
import SelectField from '../../components/atoms/SelectField/SelectField';
import { PrimaryButton } from '../../components/atoms/button/button';
import ForwordArrowIcon from '../../components/atoms/icons/forwordArrowIcon';
import { useAppDispatch } from '../../store/hooks';
import { addSite, editSite, getSiteTypeList, siteMasterSelector } from '../../store/slices/siteMaster';
import { assetMasterSelector, getAssetList } from '../../store/slices/assetMaster';
import { convertArrayToObject } from '../../utils/helper';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { validations } from './validations';
import ArrowRight from '../../components/atoms/icons/arrowRight';
import { loaderSelector } from "../../store/slices/loaderSlice";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";


const AddSite = React.memo(({ pageTitle, onBack, editSiteData }: AddSiteProps) => {
  const dispatch: any = useAppDispatch();
  const { assetMasterData } = useSelector(assetMasterSelector);
  const { siteTypeList } = useSelector(siteMasterSelector);
  const { isLoader } = useSelector(loaderSelector)
  const { label, placeholder, button, name } = SiteConstants;

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      siteName: "",
      siteId: "",
      siteTypeId: "",
      latitude: "",
      longitude: "",
      assetId: ""
    },
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    setEditData();
  }, [siteTypeList]);

  const setEditData = () => {
    if (editSiteData) {
      const editData = { ...editSiteData };

      // Find the site type id based on the name
      const siteTypeId = siteTypeList.filter(val => val.name === editData?.siteTypeId?.name)
      for (var key in editData) {
        if (name[key] || name[key] === "") {
          if (key === "assetId") {
            setValue(name[key], editData?.assetId);
          } else if (key === "siteTypeId" && siteTypeId.length) {
            setValue(name[key], siteTypeId[0].id);
          } else {
            setValue(name[key], editData[key]);
          }
        }
      }
    }
  };

  const onSubmit: SubmitHandler<any> = (payload: AddSiteSubmitProps) => {
    const reqPayload = { ...payload };
    reqPayload.latitude = parseFloat(payload.latitude);
    reqPayload.longitude = parseFloat(payload.longitude);
    reqPayload.siteTypeId = parseFloat(payload.siteTypeId);
    if (editSiteData) {
      dispatch(editSite(reqPayload, reqPayload.siteId, successCB));
    } else {
      dispatch(addSite(reqPayload, successCB));
    }
  };

  const successCB = () => {
    const alertType = editSiteData ? "edit" : "add";
    onBack(false, true, alertType);
  };

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={button.submit}
        variant="contained"
        type={"submit"}
        endIcon={<ArrowRight sx={{ width: "7px" }} />}
        width="128px"
      />
    </Stack>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        (isLoader?.isShow && isLoader.component === "site") && <CircularLoader />
      }
      <DefaultLayout
        pageTitle={pageTitle}
        // renderHeaderButtons={renderHeaderButtons}
        backHandler={() => onBack(false)}
        activeTab={"Sites"}
      >
        <Grid container spacing={"30px"} sx={{ p: "10px 0 27px" }}>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.siteName}
              type="text"
              name={name.siteName}
              register={register}
              placeholder={placeholder.siteName}
              errorText={errors?.siteName?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.siteId}
              type="text"
              name={name.siteId}
              register={register}
              disabled={editSiteData ? true : false}
              placeholder={placeholder.siteId}
              errorText={errors?.siteId?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.siteType}
              placeholder={placeholder.siteType}
              options={convertArrayToObject(siteTypeList)}
              name={name.siteType}
              register={register}
              value={watch(name.siteType)}
              errorText={errors?.siteTypeId?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.latitude}
              type="number"
              name={name.latitude}
              register={register}
              placeholder={placeholder.latitude}
              errorText={errors?.latitude?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.longitude}
              type="number"
              name={name.longitude}
              register={register}
              placeholder={placeholder.longitude}
              errorText={errors?.longitude?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.assetId}
              placeholder={placeholder.assetId}
              options={convertArrayToObject(assetMasterData, "assetId", "assetId")}
              name={name.assetId}
              value={watch('assetId')}
              register={register}
              errorText={errors?.assetId?.message}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2}>
          <PrimaryButton
            label={button.submit}
            variant="contained"
            type={"submit"}
            // endIcon={<ArrowRight sx={{ width: "7px" }} />}
            width="95px"
          />
        </Stack>
      </DefaultLayout>
    </form>
  );
});

export default AddSite;
