import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import { isRequiredAlph, isRequired, decimalPrecisionRequired, maxLengthCheck } from "../../utils/validationHelper";


export const validations: any = yup.object().shape({
  emissionSubCategoryId: isRequired,
  equipmentTypeId: isRequired,
  clusterId: isRequired,
  clusterDetail: yup
    .string()
    .nullable()
    .notRequired()
    .trim()
    .max(100, Messages.maxLength(100))
    .min(5, Messages.minLength(5))
    .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value)),
  units: yup.string()
    .trim()
    .required(Messages.required)
    .max(100, Messages.maxLength(100))
    .min(1, Messages.minLength(1)),
  emissionFactor: decimalPrecisionRequired(5, 10),
  ogmpLevel: isRequiredAlph(1, 10),
  assetId: isRequired,
})
