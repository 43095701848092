// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/inter-v18-latin-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/inter-v18-latin-500.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/inter-v18-latin-600.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/inter-v18-latin-700.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../assets/fonts/inter-v18-latin-900.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* fonts.css */


@font-face {
  font-display: swap; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'); 
}
/* inter-500 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2'); 
}
/* inter-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
}
/* inter-700 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2'); 
}
/* inter-900 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'); 
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA,cAAc;;;AAGd;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAyE;AAC3E;AACA,sBAAsB;AACtB;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE;AACA,sBAAsB;AACtB;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE;AACA,sBAAsB;AACtB;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE;AACA,sBAAsB;AACtB;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,4DAAqE;AACvE","sourcesContent":["/* fonts.css */\n\n\n@font-face {\n  font-display: swap; \n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  src: url('../assets/fonts/inter-v18-latin-regular.woff2') format('woff2'); \n}\n/* inter-500 - latin */\n@font-face {\n  font-display: swap; \n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 500;\n  src: url('../assets/fonts/inter-v18-latin-500.woff2') format('woff2'); \n}\n/* inter-600 - latin */\n@font-face {\n  font-display: swap;\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 600;\n  src: url('../assets/fonts/inter-v18-latin-600.woff2') format('woff2');\n}\n/* inter-700 - latin */\n@font-face {\n  font-display: swap; \n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 700;\n  src: url('../assets/fonts/inter-v18-latin-700.woff2') format('woff2'); \n}\n/* inter-900 - latin */\n@font-face {\n  font-display: swap; \n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 900;\n  src: url('../assets/fonts/inter-v18-latin-900.woff2') format('woff2'); \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
