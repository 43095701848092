import styled from "@emotion/styled";
import { FormControl, IconButtonProps } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";



export const StyledDatePicker = styled(DesktopDatePicker)<{ direction: string, Width: string }>(({ theme, direction, Width }) => ({
  display: 'flex',
  flexDirection: direction === 'row' ? 'row-reverse' : 'column-reverse',
  alignItems: direction === 'row' ? 'center' : 'unset',
  gap: direction === 'row' ? '8px' : 'unset',
  width: "100%",

  "& .MuiFormHelperText-root": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#4D4B4C",
    lineHeight: "20px",
    paddingBottom: direction === 'row' ? 0 : '8px',
    margin: 0,
    "& sup": {
      color: "#29292A",
      fontSize: "12px",
      verticalAlign: "text-top",
      lineHeight: "19px"
    }
  },
  "& .Mui-focused": {
    color: "#64A70B !important",
    "& sup": {
      color: "#64A70B",
      verticalAlign: "text-top",
      lineHeight: "19px"
    }
  },
  "& .Mui-error": {
    color: "#E4002B !important",
    "& sup": {
      color: "#E4002B",
      verticalAlign: "text-top",
      lineHeight: "19px"

    }
  },


  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: Width,

    '& .MuiOutlinedInput-input': {
      fontSize: "16px",
      fontWeight: 500,
      color: "#4D4B4C",
      height: "3rem",
      padding: "0 12px",
      // border: "1px solid #9DA3AE",

      "&::placeholder": {
        color: "#898587",
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 500,
        lineHeight: "24",
        opacity: 1,
      },

    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#888B8D",
      },

    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B3B0B1",
    },

    '& .MuiInputAdornment-root': {
      color: 'blue', // Custom adornment color
    },

  },



  '& .MuiPickersDay-root': {
    '&.Mui-selected': {
      backgroundColor: 'green !important', // Important to override MUI defaults
      color: "white!important", // Important to override MUI defaults
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'green !important', // Important to override MUI defaults
    },
  },
  // Override default MUI styles for today and disabled states if needed
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: `green !important`,
    color: `white !important`,
  },


}));



