

import React, { useState } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { NoDataRowsProps } from "./interface";



const RenderNoDataRows: React.FC<NoDataRowsProps>= ({columns, showCheckbox}) => {
  
  const noDataRows = new Array(6).fill(null).map((_, index) => {
  return (
  <TableRow key={index} sx={{height: "60px"}}>
  <TableCell colSpan={columns.length + (showCheckbox ? 1 : 0)} style={{ textAlign: 'center' }}>
  <Typography variant="body1">{index === 3 && `No data available at the moment.`}</Typography>
  </TableCell>
  </TableRow>
  );
  
  });
  return <>{noDataRows}</>;
  
  };
   export default RenderNoDataRows;
  