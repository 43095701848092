import React from "react";
import { CommonTextAreaProps } from "./interface";
import { FormError } from "../ErrorMessage/errorMessage";
import {
  CharacterLimitText,
  CustomFormControl,
  StyledLabel,
  StyledTextareaAutosize,
} from "./style";

const TextArea: React.FC<CommonTextAreaProps> = ({
  label,
  Asterisk,
  minHeight,
  maxHeight,
  maxLength,
  placeholder,
  minRows,
  width,
  maxRows,
  value,
  register,
  errorText,
  name,
  ...props
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === " " && e?.target?.value?.length === 0) {
      e.preventDefault();
    }
  };

  return (
    <CustomFormControl
      fullWidth
      className={errorText && "customError"}
      width={width}
    >
      {label && (
        <StyledLabel
          className="custom-label"
          inputLabel={label}
          Asterisk={Asterisk}
        />
      )}
      <StyledTextareaAutosize
        value={value}
        placeholder={placeholder}
        minRows={minRows}
        maxRows={maxRows}
        {...register(name)}
        style={{ minHeight, maxHeight }}
        maxLength={maxLength}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <CharacterLimitText style={{ bottom: errorText ? "35px" : "16px" }}>
        Max {maxLength} Characters
      </CharacterLimitText>
      {errorText && <FormError message={errorText} />}
    </CustomFormControl>
  );
};

export default TextArea;
