import React from "react";
export default function ChatSendIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_14172_79753)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.84744 9.27184C5.86081 9.25542 5.87513 9.2395 5.89041 9.22425L11.7238 3.39092C11.9679 3.14684 12.3635 3.14684 12.6076 3.39092C12.8517 3.635 12.8517 4.03073 12.6076 4.2748L6.77427 10.1082C6.76219 10.1203 6.74977 10.1317 6.73702 10.1426C7.39077 13.2518 7.72652 14.8143 8.27794 15.3073C9.08011 16.0244 10.2731 16.0869 11.1458 15.4576C11.7556 15.0178 12.2594 13.4555 13.2672 10.3309L14.4173 6.76475C15.3681 3.81627 15.8436 2.34203 15.2677 1.43422C15.0874 1.15014 14.8469 0.90922 14.5631 0.72857C13.6562 0.151287 12.1813 0.624461 9.23127 1.5708L5.7049 2.70209C2.524 3.72253 0.93354 4.23275 0.491207 4.86189C-0.112918 5.72114 -0.0555514 6.88109 0.630399 7.67659C1.12312 8.24792 2.70407 8.59634 5.84744 9.27184Z" fill="#559700"/>
</g>
<defs>
<clipPath id="clip0_14172_79753">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
   
  );
}
