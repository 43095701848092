import React from "react";
export default function MasterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M10 9H4.35C3.78754 9 3.50631 9 3.30916 9.1432C3.24549 9.1895 3.1895 9.2455 3.14324 9.3092C3 9.5063 3 9.7875 3 10.35V13M10 9V13M10 9V5M10 9H15.65C16.2125 9 16.4937 9 16.6908 9.1432C16.7545 9.1895 16.8105 9.2455 16.8568 9.3092C17 9.5063 17 9.7875 17 10.35V13" stroke="#757172" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M3.65 17C4.21246 17 4.49369 17 4.69084 16.8568C4.75451 16.8105 4.8105 16.7545 4.85676 16.6908C5 16.4937 5 16.2125 5 15.65V14.35C5 13.7875 5 13.5063 4.85676 13.3092C4.8105 13.2455 4.75451 13.1895 4.69084 13.1432C4.49369 13 4.21246 13 3.65 13H2.35C1.78754 13 1.50631 13 1.30916 13.1432C1.24549 13.1895 1.1895 13.2455 1.14324 13.3092C1 13.5063 1 13.7875 1 14.35V15.65C1 16.2125 1 16.4937 1.14324 16.6908C1.1895 16.7545 1.24549 16.8105 1.30916 16.8568C1.50631 17 1.78754 17 2.35 17H3.65Z" stroke="#757172" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M10.65 17C11.2125 17 11.4937 17 11.6908 16.8568C11.7545 16.8105 11.8105 16.7545 11.8568 16.6908C12 16.4937 12 16.2125 12 15.65V14.35C12 13.7875 12 13.5063 11.8568 13.3092C11.8105 13.2455 11.7545 13.1895 11.6908 13.1432C11.4937 13 11.2125 13 10.65 13H9.35C8.7875 13 8.5063 13 8.3092 13.1432C8.2455 13.1895 8.1895 13.2455 8.1432 13.3092C8 13.5063 8 13.7875 8 14.35V15.65C8 16.2125 8 16.4937 8.1432 16.6908C8.1895 16.7545 8.2455 16.8105 8.3092 16.8568C8.5063 17 8.7875 17 9.35 17H10.65Z" stroke="#757172" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M10.65 5C11.2125 5 11.4937 5 11.6908 4.85676C11.7545 4.8105 11.8105 4.75451 11.8568 4.69084C12 4.49369 12 4.21246 12 3.65V2.35C12 1.78754 12 1.50631 11.8568 1.30916C11.8105 1.24549 11.7545 1.1895 11.6908 1.14324C11.4937 1 11.2125 1 10.65 1H9.35C8.7875 1 8.5063 1 8.3092 1.14324C8.2455 1.1895 8.1895 1.24549 8.1432 1.30916C8 1.50631 8 1.78754 8 2.35V3.65C8 4.21246 8 4.49369 8.1432 4.69084C8.1895 4.75451 8.2455 4.8105 8.3092 4.85676C8.5063 5 8.7875 5 9.35 5H10.65Z" stroke="#757172" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M17.65 17C18.2125 17 18.4937 17 18.6908 16.8568C18.7545 16.8105 18.8105 16.7545 18.8568 16.6908C19 16.4937 19 16.2125 19 15.65V14.35C19 13.7875 19 13.5063 18.8568 13.3092C18.8105 13.2455 18.7545 13.1895 18.6908 13.1432C18.4937 13 18.2125 13 17.65 13H16.35C15.7875 13 15.5063 13 15.3092 13.1432C15.2455 13.1895 15.1895 13.2455 15.1432 13.3092C15 13.5063 15 13.7875 15 14.35V15.65C15 16.2125 15 16.4937 15.1432 16.6908C15.1895 16.7545 15.2455 16.8105 15.3092 16.8568C15.5063 17 15.7875 17 16.35 17H17.65Z" stroke="#757172" stroke-width="1.5" stroke-linejoin="round"/>
    </svg>
  );
}
