export const EmissionConstants = {
  label: {
    emissionSubCategory: "Emission Sub-Category",
    equipmentType: "Equipment/Component Type",
    componentType: "Component Type",
    equipmentOnlyType: "Equipment Type",
    cluster: "Cluster",
    clusterDetails: "Cluster Details",
    units: "Units",
    emissionFactor: "Emission Factor",
    OGMPLevel: "OGMP Level",
    assetId: "Asset ID"
  },
  name: {
    emissionSubCategoryId: "emissionSubCategoryId",
    equipmentTypeId: "equipmentTypeId",
    componentTypeId: "componentTypeId",
    typeHierarchy: "typeHierarchy",
    equipment_type: "equipment_type",
    component_type: "component_type",
    clusterId: "clusterId",
    clusterDetail: "clusterDetail",
    units: "units",
    emissionFactor: "emissionFactor",
    ogmpLevel: "ogmpLevel",
    assetId: "assetId"
  },
  headers: {
    emission: "Emission Factor Master",
    addEmissionFactor: "Add Emission Factor",
    editEmissionFactor: "Edit Emission Factor"
  },
  placeholder: {
    emissionSubCategory: "Select Emission Sub-Category",
    equipmentType: "Select Equipment/Component Type",
    equipmentOnlyType: "Select Equipment Type",
    componentType: "Select Component Type",
    cluster: "Select Cluster",
    clusterDetails: "Enter Value",
    units: "Enter Value",
    emissionFactor: "Enter Value",
    OGMPLevel: "Select OGMP Level",
    assetId: "Select Asset ID",
  },
  button: {
    filter: "FILTER BY",
    submit: "Submit",

  },
  text: {
    addEmissionFactorAlert: "Emission Factor added successfully.",
    editEmissionFactorAlert: "Emission Factor updated successfully."

  }
}

export const OGMPLevelOptions = [
  { label: 'Level 1', value: 'Level 1' },
  { label: 'Level 2', value: 'Level 2' },
  { label: 'Level 3', value: 'Level 3' },
  { label: 'Level 4', value: 'Level 4' },
  { label: 'Level 5', value: 'Level 5' },
]
export const maxLimit = 100;