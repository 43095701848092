import React, { useState } from "react";
import { Box, Divider } from "@mui/material";

export default function LoginBackground(props: any) {
  return (
    <Box className="loginWrapper">
      <Box className="login-Main">
        {props.children}
      </Box>
    </Box>
  );
}
