import React, { useEffect, useState } from "react";
import FilterModal from "../../components/atoms/FilterModal/FilterModal";
import { Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { convertArrayToObject } from "../../utils/helper";
import { useAppDispatch } from "../../store/hooks";
import MultiSelectField from "../../components/atoms/MultiSelect/MultiSelectField";
import { SiteConstants } from "../Site/constants";
import { FilterSiteProps } from "../Site/interface";
import { AbatementConstants } from "./constants";

const FilterAbatement = ({
  open,
  onClose,
  assetData,
  filterCB,
}: FilterSiteProps) => {
  // Destructure constants from SiteConstants
  const { label, placeholder, name } = AbatementConstants;
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      asset_id: [],
    },
  });

  useEffect(() => {
    localStorage.removeItem("siteFilter");
  }, []);

  useEffect(() => {
    if (watch("asset_id")) {
      const val: any = watch("asset_id");
      localStorage.setItem("siteFilter", JSON.stringify(val));
    }
  }, [watch("asset_id")]);
  useEffect(() => {
    const assetIdValue = watch("asset_id");
    const hasSelectedValues = assetIdValue?.length > 0;
    setClearDisabled(!hasSelectedValues);
    setFilterDisabled(!hasSelectedValues);
    localStorage.setItem("siteFilter", JSON.stringify(assetIdValue));
  }, [watch("asset_id")]);
  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("siteFilter");
    reset({ asset_id: [] });
    onSubmit();
  };

  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("asset_id"));
    onClose();
  };

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title="FILTER BY"
        width="475px"
        clearFilters={handleClearFilters}
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={label.filterAbatement}
              options={convertArrayToObject(assetData, "name", "id")}
              placeholder={placeholder.filterAbatement}
              name={name.asset_id}
              register={register}
              value={watch("asset_id") || []}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterAbatement;
