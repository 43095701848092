import { FormControl } from "@mui/material";
import { CommonTextAreaProps } from "./interface";
import CustomLabel from "../label/label";
import styled from "@emotion/styled";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

export const CustomFormControl = styled(FormControl)<{ width?: number }>(
  ({ theme, width }) => ({
    margin: 0,
    width: width ? `${width}px` : "100%",
    "&:hover textarea": {
      backgroundColor: '#F4F3F4',
    },
    "&:focus-within textarea": {
      backgroundColor: '#fff',
      borderColor: "#559700",
      outline: '1px solid #559700'
    },
    "&.customError": {
      "& .MuiFormHelperText-root": {
        // color: "#E4002B !important",
        "& sup": {
          // color: "#E4002B",
          verticalAlign: "text-top",
          lineHeight: "19px"

        }

      },

      "& textarea": {
        borderColor: "#E4002B",
        backgroundColor: '#FFEFE9',
        outline: '1px solid #E7563F'
      },
    },
    "&:focus-within": {
      "& .custom-label": {
        //  color: "#64A70B", // Change this to your desired focus color
      },
      "& sup": {
        // color: "#64A70B",
        verticalAlign: "text-top",
        lineHeight: "19px"
      }
    },
  })
);

export const StyledTextareaAutosize = styled(BaseTextareaAutosize, {
  shouldForwardProp: (prop) => prop !== "minHeight" && prop !== "maxHeight",
})<CommonTextAreaProps>(({ minHeight, maxHeight }) => ({
  width: "100%",
  fontSize: "16px",
  fontWeight: 500,
  color: "#4D4B4C",
  minHeight: minHeight ? `${minHeight}px` : "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "auto",
  overflow: "auto",
  border: "1px solid #B3B0B1",
  borderRadius: "8px",
  padding: "15px 12px",
  resize: "none",
  "&::placeholder": {
    fontSize: "16px",
    fontWeight: 500,
    color: "#898587",
    lineHeight: "24px",
    fontFamily: "Inter",
    textTransform: "capitalize",
    opacity: 1,
  },
  "&:hover": {
    borderColor: "#888B8D",
  },
  "&:focus": {
    borderColor: "#64A70B",
    outline: "none",
  },
}));

export const StyledLabel = styled(CustomLabel)(({ theme }) => ({
  "&.custom-label": {
    color: "#000000", // Initial label color
    transition: "color 0.3s ease",
  },
}));

export const CharacterLimitText = styled("div")({
  position: "absolute",
  right: "12px", // Adjust as per your design
  fontSize: "14px",
  color: "#898587",
});