import React, { ReactNode } from "react";
import { Column, Row } from "../../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../../components/atoms/icons/editIcon";
import moment from "moment";
import SelectField from "../../../components/atoms/SelectField/SelectField";
import { convertArrayToObject } from "../../../utils/helper";

class TableColumn {
  private column: Column[];

  constructor(payload: any) {
    this.column = [
      {
        id: "id",
        label: "Equipment ID",
        width: "20%",
        render: (row: Row) => row?.equipmentId?.equipmentId || row?.equipmentId,
      },
      {
        id: "siteId",
        label: "Equipment Type",
        width: "20%",
        render: (row: Row) => row?.equipmentId?.equipmentType?.equipmentType || row?.equipmentType?.equipmentType,
      },
      {
        id: "type",
        label: "Emission Sub-Category",
        width: "30%",
        render: (row: Row) => payload?.EmissionCategory(row, row?.equipmentId?.equipmentId || row?.equipmentId),
      },
      {
        id: "sourceName",
        label: "Emission Volume (%)",
        width: "23%",
        render: (row: Row) => payload?.EmissionVolume(row)
      }
    ];
  }
}

export default TableColumn;
