import dictionary from "../dictionary";
import methods from "../methods";

const { master } = dictionary;
const { get, post, patch, deleteData } = methods;

const MasterService: any = {
  // Notification center services
  getNotificationChangeLogs: (params: number) => {
    return get(master.changeLogs(params));
  },

  getNotificationEmissionDetails: (params: number) => {
    return get(master.notifications() + `/${params}`);
  },

  verifyNotification: (id: number | string, body: any) => {
    return patch(master.verifyNotification(id), body);
  },

  getDuplicateNotifications: (params: string) => {
    return get(master.duplicateNotifications() + `${params}`);
  },

  getDuplicateEmissionsCount: () => {
    return get(master.duplicateEmissionsCount());
  },

  fixDuplicateEmissions: (id: number, payload?: any) => {
    return deleteData(master.fixDuplicateEmissions(id), payload);
  },

  keepDuplicateEmissions: (id: number, payload?: any) => {
    return patch(master.keepDuplicateEmissions(id), payload);
  },

  // Emission master services
  getEmissionFactorsList: (params: string) => {
    return get(master.emissionFactor() + `${params}`);
  },

  getEmissionSubCategoryList: () => {
    return get(master.emissionSubCategory());
  },

  getEquipmentTypeByCategory: (id: string | number) => {
    return get(master.getEquipmentTypeByCategory(id));
  },

  getEquipmentBySite: (id: string | number) => {
    return get(master.getEquipmentTypeBySite(id));
  },

  addEmissionFactor: (body: {}) => {
    return post(master.emissionFactor(), body);
  },

  editEmissionFactor: (body: {}, id: string | number) => {
    return patch(master.emissionFactorByID(id), body);
  },

  // Site Master Services
  getSiteList: (params: string) => {
    return get(master.site() + `${params}`);
  },

  getGroupSiteData: (id: string) => {
    return get(master.groupSiteData(id));
  },

  addSite: (body: {}) => {
    return post(master.site(), body);
  },
  editSite: (body: {}, id: string | number) => {
    return patch(master.siteByID(id), body);
  },

  // Asset Master Services
  getAssetList: (params: string) => {
    return get(master.asset() + `${params}`);
  },
  getAssetListBySegment: (params: string) => {
    return get(master.assetList() + `${params}`);
  },
  getComponentById: (params: string) => {
    return get(master.componentList(params));
  },
  addAsset: (body: {}) => {
    return post(master.asset(), body);
  },
  editAsset: (body: {}, id: string | number) => {
    return patch(master.assetByID(id), body);
  },

  getAssetDetail: (id: string | number) => {
    return get(master.assetByID(id));
  },

  // Site Type Master Services
  getSiteTypeList: (params: string) => {
    return get(master.siteType() + `${params}`);
  },

  // Asset Type Master Services
  getAssetTypeList: () => {
    return get(master.assetType());
  },

  // Asset Type Master Services
  getGasTypeList: () => {
    return get(master.gasType());
  },

  // Country Master Services
  getCountries: () => {
    return get(master.country());
  },

  // Operator Master Services
  getOperators: () => {
    return get(master.operator());
  },

  // Asset Type Master Services
  getAssetTypes: () => {
    return get(master.assetType());
  },

  // Equity Type Master Services
  getEquityTypes: () => {
    return get(master.equityType());
  },

  // Asset Type Master Services
  getGasList: () => {
    return get(master.assetType());
  },
  //  Equipment Master Services
  getEquipmentMaster: (params: string) => {
    return get(master.equipmentMaster() + `${params}`);
  },

  // Equipment Master Services
  addEquipment: (body: {}) => {
    return post(master.equipmentMaster(), body);
  },

  editEquipment: (body: {}, id: string | number) => {
    return patch(master.equipmentMaster() + `/${id}`, body);
  },
  updateEquipmentStatus: (body: {}, id: string | number) => {
    return patch(master.updateEquipmentStatus(id), body);
  },

  getEquipmentDetail: (id: string | number) => {
    return get(master.equipmentMaster() + `/${id}`);
  },

  //  Equipment Master Services
  getEquipmentSubCategoryBySiteOrEquip: (id: string, params: string) => {
    return get(master.getEquipmentSubCategoryBySiteOrEquip(id) + `${params}`);
  },

  getEquipmentMasterListByType: (id: string, params: string) => {
    return get(master.getEquipmentMasterListByType(id, params));
  },

  // Gas Master Services
  getGasFactorList: (params: string) => {
    return get(master.gasFactor() + `${params}`);
  },

  addGasFactor: (body: {}) => {
    return post(master.gasFactor(), body);
  },

  editGasFactor: (body: {}, id: string | number) => {
    return patch(master.gasFactorByID(id), body);
  },

  getEquipmentTypeList: () => {
    return get(master.equipmentType());
  },

  getEquipmentModel: () => {
    return get(master.equipmentModel());
  },

  getEquipmentSubType: () => {
    return get(master.equipmentSubType());
  },
  getEngineType: () => {
    return get(master.engineType());
  },

  getTypeOfSeals: () => {
    return get(master.typeOfSeals());
  },

  getTypeOfWell: () => {
    return get(master.typeOfWell());
  },

  getAgentProcessed: () => {
    return get(master.agentProcessed());
  },

  getProductionMethod: () => {
    return get(master.productionMethod());
  },

  // Equipment Cluster Services
  getEquipmentClusterList: () => {
    return get(master.equipmentCluster());
  },

  // Component Master Services
  getComponentList: (params: string) => {
    return get(master.component() + `${params}`);
  },

  addComponent: (body: {}) => {
    return post(master.component(), body);
  },
  editComponent: (body: {}, id: string | number) => {
    return patch(master.componentByID(id), body);
  },
  getComponentTypeList: () => {
    return get(master.componentType());
  },

  // Cluster Services
  getClusterList: (params: string) => {
    return get(master.cluster() + `${params}`);
  },

  //Notification Services
  getNotifications: (params: string) => {
    return get(master.notifications() + `${params}`);
  },

  getNotificationNotVerified: () => {
    return get(master.notificationNotVerified());
  },

  getNotificationProportional: (body: {}) => {
    return post(master.proportionalNotification(), body);
  },
  //Dashboard

  getAssetEmission: (params: string) => {
    return get(master.assetEmissions() + `${params}`);
  },
  getTotalEmission: (params: {}) => {
    return get(master.dashboardTotalEmissions() + `${params}`);
  },

  getOgmpReport: (params: string) => {
    return get(master.ogmpReport() + `${params}`);
  },

  getOgmpMidReport: (params: string) => {
    return get("dashboard/ogmp-midstream-report" + `${params}`);
  },

  getEmissionCategory: (params: string) => {
    return get(master.emissionCategory() + `?${params}`);
  },
  getDashboardMapDetails: (params: string) => {
    return get(master.mapDetails() + `${params}`);
  },
  getAbatementChartData: (params: string) => {
    return get(master.abatementChartGraphDetails() + `${params}`);
  },

  getWaterfallChartData: (params: string) => {
    return get(master.waterfallChartData() + `${params}`);
  },

  getTopDetectionData: (params: string) => {
    return get(master.topDetectionData() + `${params}`);
  },

  getSocarTotals: (params: string) => {
    return get(master.socarTotalDetails() + `${params}`);
  },

  // Abatement Master Services
  getAbatementList: (params: string) => {
    return get(master.abatement() + `${params}`);
  },

  // Initiative list
  addAbatement: (body: {}) => {
    return post(master.abatement(), body);
  },
  editAbatement: (body: {}, id: string | number) => {
    return patch(master.abatementByID(id), body);
  },
  getAbatementDetail: (id: string | number) => {
    return get(master.abatementByID(id));
  },
  getInitiativeList: (params: string) => {
    return get(master.initiative() + `${params}`);
  },
  getchatList: (params: string) => {
    return get(master.chat() + `${params}`);
  },
  sendChat: (body: {}) => {
    return post(master.chat(), body);
  },
};

export default MasterService;
