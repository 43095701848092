import { convertArrayToObject } from "../../utils/helper";


interface FieldArrayProps {
  type: "input" | "dropdown" | "textArea";
  fieldName: string;
  options: any;
  disabled?: boolean;
  mandatory?: boolean;
  inputType?: "number" | "text";
}

const Options = [{ label: "YES", value: "YES" }, { label: "NO", value: "NO" }]


export class FieldArray {
  private fieldArray: FieldArrayProps[];

  constructor(payload: any) {
    this.fieldArray = [
      {
        type: "dropdown",
        fieldName: "equipmentType",
        options: convertArrayToObject(payload?.equipmentTypeList, "equipmentType", "id"),
      },
      {
        type: "input",
        fieldName: "equipmentSubtype",
        options: null,
        mandatory: true
      },
      {
        type: "dropdown",
        fieldName: "equipmentCluster",
        options: convertArrayToObject(payload?.equipmentClusterList, "equipmentCluster", "id"),
      },
      {
        type: "input",
        fieldName: "numberOfRelevantEquipment",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "equipmentModel",
        options: null,
      },
      {
        type: "dropdown",
        fieldName: "siteId",
        options: convertArrayToObject(payload.siteMasterData, "siteId", "siteId")
      },
      {
        type: "input",
        fieldName: "equipmentId",
        options: null,
        disabled: payload?.isEdit
      },
      {
        type: "input",
        fieldName: "numberOfPistons",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "dropdown",
        fieldName: "typeOfSeal",
        options: convertArrayToObject(payload?.typeOfSealsList, "sealType", "id"),
        mandatory: true
      },
      {
        type: "input",
        fieldName: "physicalVolumeOfMaintainedEquipmentM3",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "physicalVolumeOfEngineM3",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "residualPressureATM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "timeToAddressLeakDays",
        options: null,
        inputType: "number"
      },
      {
        type: "input",
        fieldName: "diameterCM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "volumeOfPigReceiverM3",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "lengthM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "dropdown",
        fieldName: "typeOfWell",
        options: convertArrayToObject(payload?.typeOfWellList, "wellType", "id"),
        mandatory: true
      },
      // custom
      {
        type: "dropdown",
        fieldName: "decommissioned",
        options: [
          { value: "NO", label: "NO" },
          { value: "YES", label: "YES" },
        ]
      },
      {
        type: "dropdown",
        fieldName: "productionMethod",
        options: convertArrayToObject(payload?.productionMethodList, "productionMethod", "id"),
        mandatory: true
      },
      // custom yes no
      {
        type: "dropdown",
        fieldName: "lowPressure",
        options: Options,
        mandatory: true
      },
      // custom yes no
      {
        type: "dropdown",
        fieldName: "performCasingHeadVentingApplicable",
        options: Options,
        mandatory: true
      },
      {
        type: "input",
        fieldName: "flaringEfficiency",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "standbyFactor",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "dropdown",
        fieldName: "agentProcessed",
        options: convertArrayToObject(payload?.agentProcessedList, "agentProcessed", "id"),
        mandatory: true
      },
      // custom
      {
        type: "dropdown",
        fieldName: "typeOfGasBurnt",
        options: convertArrayToObject(payload?.gasTypes, "gasType", "id"),
        mandatory: true
      },
      {
        type: "dropdown",
        fieldName: "engineType",
        options: convertArrayToObject(payload?.engineTypeList, "engineType", "id"),
        mandatory: true
      },
      {
        type: "input",
        fieldName: "latitude",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "longitude",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "thicknessOfAWallMM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "cumulativeOperatingHours",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "productivityM3PerHour",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "powerRatingKW",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "inletOperationalPressureATM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "outletPressureATM",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "waterCut",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "ageYears",
        options: null,
        inputType: "number",
        mandatory: true
      },
      {
        type: "input",
        fieldName: "otherDimensions",
        options: null,
        mandatory: true
      }
    ]
  }
}