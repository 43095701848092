import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import {
  isNumberRequired,
  isRequired,
  isRequiredAlph,
  numberIsRequiredOptional,
  isFloatOptional,
  isAlphaOptional,
  decimalPrecisionRequired,
  decimalPrecisionWithoutRequired,
  maxLengthCheck,
} from "../../utils/validationHelper";

const rangeRegex = /^(-?([1-8]?[0-9](\.\d{1,6})?|90(\.0{1,6})?))$/;
const rangeRegex180 =
  /^-?(180(\.0{1,6})?|((1[0-7][0-9])|([1-9]?[0-9]))(\.\d{1,6})?)$/;

export const validations = yup.object().shape({
  equipmentType: isRequired,
  equipmentSubtype: yup
    .string()
    .nullable()
    .notRequired()
    .max(100, Messages.maxLength(100))
    .min(5, Messages.minLength(5))
    .transform((value, originalValue) =>
      originalValue?.trim() === "" ? null : value
    ),
  equipmentCluster: isRequired,
  numberOfPistons: decimalPrecisionWithoutRequired(4, 2),
  numberOfRelevantEquipment: decimalPrecisionWithoutRequired(4, 2),
  equipmentModel: yup
    .string()
    .trim()
    .required(Messages.required)
    .max(100, Messages.maxLength(100))
    .min(1, Messages.minLength(1))
    .transform((value, originalValue) =>
      originalValue?.trim() === "" ? null : value
    ),
  siteId: isRequired,
  equipmentId: isRequiredAlph(2, 50),
  physicalVolumeOfMaintainedEquipmentM3: decimalPrecisionWithoutRequired(4, 2),
  physicalVolumeOfEngineM3: decimalPrecisionWithoutRequired(4, 2),
  residualPressureATM: decimalPrecisionWithoutRequired(4, 2),
  timeToAddressLeakDays: decimalPrecisionRequired(3, 1),
  diameterCM: decimalPrecisionWithoutRequired(4, 2),
  volumeOfPigReceiverM3: decimalPrecisionWithoutRequired(4, 2),
  lengthM: decimalPrecisionWithoutRequired(5, 2),
  decommissioned: isRequired,
  flaringEfficiency: numberIsRequiredOptional(),
  standbyFactor: numberIsRequiredOptional(),
  latitude: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Transform empty string to null
    .matches(
      rangeRegex,
      "Latitude must be in between -90 to 90 up to 6 decimal."
    ),
  longitude: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Transform empty string to null
    .matches(
      rangeRegex180,
      "Longitude must be in between -180 to 180 up to 6 decimal."
    ),
  thicknessOfAWallMM: decimalPrecisionWithoutRequired(5, 2),
  cumulativeOperatingHours: decimalPrecisionWithoutRequired(8, 2),
  productivityM3PerHour: decimalPrecisionWithoutRequired(8, 2),
  powerRatingKW: decimalPrecisionWithoutRequired(8, 2),
  inletOperationalPressureATM: decimalPrecisionWithoutRequired(8, 2),
  outletPressureATM: decimalPrecisionWithoutRequired(8, 2),
  waterCut: numberIsRequiredOptional(),
  ageYears: decimalPrecisionWithoutRequired(3, 2),
  otherDimensions: yup
    .string()
    .nullable()
    .notRequired()
    .trim()
    .max(250, Messages.maxLength(250))
    .min(1, Messages.minLength(1))
    .transform((value, originalValue) =>
      originalValue?.trim() === "" ? null : value
    ),
});
