import React, { useEffect, useState } from "react";
import FilterModal from "../../components/atoms/FilterModal/FilterModal";
import { Grid } from "@mui/material";
import { FilterComponentProps, FilterPaylod } from "./interface";
import { SubmitHandler, useForm } from "react-hook-form";
import { ComponentConstants } from "./constants";
import { convertArrayToObject } from "../../utils/helper";
import { useAppDispatch } from "../../store/hooks";
import {
  equipmentMasterSelector,
  getEquipmentMasterData,
} from "../../store/slices/equipmentMaster";
import { useSelector } from "react-redux";
import MultiSelectField from "../../components/atoms/MultiSelect/MultiSelectField";
import {
  componentMasterSelector,
  getComponentTypeList,
} from "../../store/slices/componentMaster";

const FilterComponent = ({
  open,
  onClose,
  componentData,
  filterCB,
}: FilterComponentProps) => {
  const dispatch: any = useAppDispatch();

  // Destructure constants from SiteConstants
  const { label, placeholder, name } = ComponentConstants;
  const { equipmentMasterData } = useSelector(equipmentMasterSelector);
  const { componentTypeList } = useSelector(componentMasterSelector);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      componentType: [],
    },
  });

  let component_type = "componentType";

  useEffect(() => {
    localStorage.removeItem("componentFilter");
    //dispatch(getEquipmentMasterData("?limit=9999"));
    dispatch(getComponentTypeList());
    console.log(componentTypeList);
  }, []);

  useEffect(() => {
    if (watch("componentType")?.length) {
      const val: any = watch("componentType");
      localStorage.setItem("componentFilter", val);
    }
  }, [watch("componentType")]);
  useEffect(() => {
    const hasSelection = watch("componentType")?.length > 0;
    setClearDisabled(!hasSelection);
    setFilterDisabled(!hasSelection);
  }, [watch("componentType")]);
  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("componentFilter");
    reset({ componentType: [] });
    onSubmit();
  };

  // Handle form submission
  const onSubmit = () => {
    console.log(filterCB(watch("componentType")));
    onClose();
  };

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title="FILTER BY"
        width="475px"
        clearFilters={handleClearFilters}
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={label.componentType}
              options={convertArrayToObject(
                componentTypeList,
                "componentType",
                "componentType"
              )}
              placeholder={placeholder.componentType}
              name={component_type}
              register={register}
              value={watch("componentType") || []}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterComponent;
