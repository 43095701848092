import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
  size?: number; 
  thickness?: number;
  color? : string; 
}

const CircularLoader: React.FC<LoaderProps> = ({ size = 40, thickness = 3.6, color = "#64A70B"}) => {
  return (
    <Box className="loaderBackdrop">
      <CircularProgress size={size} thickness={thickness} sx={{ color: color }} />
    </Box>
  );
};

export default CircularLoader;
