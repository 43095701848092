import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import InputField from "../../components/atoms/InputField/InputField";
import SelectField from "../../components/atoms/SelectField/SelectField";
import {
  FilterButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/atoms/button/button";
import PlusIcon from "../../components/atoms/icons/plusIcon";
import TextArea from "../../components/atoms/textarea/textarea";
import CommonModal from "../../components/atoms/modal/modal";
import DefaultLayout from "../../components/layout";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import Toast from "../../components/atoms/Toast/Toast";
import { useForm } from "react-hook-form";
import { ButtonChip, StatusChip } from "../../components/atoms/chip/chip";
import FilterModal from "../../components/atoms/FilterModal/FilterModal";
import ArrowRight from "../../components/atoms/icons/arrowRight";
import SearchableSelect from "../../components/atoms/searchableSelect/searchableSelect";
import MultiSelectField from "../../components/atoms/MultiSelect/MultiSelectField";
import { Option } from "../../components/atoms/MultiSelect/interface";
import SerachableMultiSelect from "../../components/atoms/SerachableMultiSelect/SerachableMultiSelect";




// Move options declaration here
const options = ["Option One", "Option Two", "Option Three", "Option Four"];

const FormControl = () => {
  const [selectedOption, setSelectedOption] = useState<string>(options[0]);
  const [select1Value, setSelect1Value] = useState<string>("");
  const [multiSelectValue, setMultiSelectValue] = useState<string[]>([]);
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState('');
  const {
    register,
    formState: { errors },
  } = useForm();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelect1Value(event.target.value as string);
  };

  const handleMultiSelectChange = (values: string[]) => {
    console.log('Selected values:', values);
    setMultiSelectValue(values);
  };
  /* modal state and methods  start*/
  const [open, setOpen] = useState(false);
  const [filteropen, setFilterOpen] = useState(false);

  const handleOpen = () => {
    setFilterOpen(true);
  };
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSelect = (option: any) => {
    console.log('Selected option:', option);
  };

  const handleSubmit = () => {
    console.log("Form submitted");
    setOpen(false);
  };

  const handleChange1 = (value: string) => {
    setSelectedValue(value);
  };
  /* modal state and methods  end*/
  const options11 = [
    { value: "assetMaster", label: "Asset Master Asset Master Asset Master Asset MasterAsset MasterAsset MasterAsset MasterAsset Master" },
    { value: "siteMaster", label: "Site Master" },
    { value: "equipmentMaster", label: "Equipment Master" },
    { value: "componentMaster", label: "Component Master" },
    { value: "gasFactors", label: "Gas Factors" },
    { value: "emissionFactorMaster", label: "Emission Factor Master" },
    { value: "other", label: "Other" },
    { value: "gasFactorsss", label: "gasFactorsssss" },
    { value: "gasFactorsss", label: "gasFactorsssss" },
    { value: "gasFactorsss", label: "gasFactorsssss" },
    { value: "gasFactorsss", label: "gasFactorsssss" },
    { value: "gasFactorsss", label: "gasFactorsssss" },
  ];


  const options12 = [
    { value: "assetMaster", label: "gaurav gaurav gaurav gaurav gaurav gaurav gaurav gaurav gaurav gaurav gaurav" },
    { value: "siteMaster", label: "Site Master" },
    { value: "equipmentMaster", label: "Equipment Master" },
    { value: "componentMaster", label: "Component Master" },
    { value: "gasFactors", label: "Gas Factors" },
    { value: "emissionFactorMaster", label: "Emission Factor Master" },
    { value: "other", label: "Other" },
  ];

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);


  const multiOptions: Option[] = [

    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' }
  ];



  const handleSeachSelect = (selectedOption: { value: string; label: string } | null) => {
    console.log('Selected option:', selectedOption);
  };


  const handleMulti = (selected: any) => {
    setSelectedOptions(selected);
  };

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="Filter Modal"
        variant="contained"
        onClick={handleFilterOpen}
      />
    </Stack>
  );

  return (
    <DefaultLayout>


      <Box className="flex spacebetween">
        <FilterModal
          open={filteropen}
          onClose={handleFilterClose}
          title="Common Modal Title"
          width="475px"
          clearFilters={() => { }}
        >
          <Grid container spacing={"20px"}>
            <Grid item md={12}>
              <SelectField
                selectlabel="Select"
                options={options11}
                placeholder="Select Master"
                name="test"
                register={register}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item md={12}>
              <SelectField
                selectlabel="Select"
                options={options11}
                placeholder="Select Master"
                name="test"
                register={register}
                onChange={handleSelectChange}
              />
            </Grid>
            <Grid item md={12}>
              <SelectField
                selectlabel="Select"
                options={options11}
                placeholder="Select Master"
                name="test"
                register={register}
                onChange={handleSelectChange}
              />
            </Grid>
          </Grid>
        </FilterModal>
      </Box>
      <Box>
        {/* <Divider sx={{ mt: 10 }} /> */}
        <Box>
          {/* Add your main content here */}

          <InputField
            label="Name"
            name="name"
            type="password"
            value={value}
            placeholder="Enter your name"
            onChange={handleChange}
            disabled={false}

          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <SelectField
            selectlabel="Select"
            options={options11}
            placeholder="Select Master"
            name="test"
            register={register}
            onChange={handleSelectChange}
          // disabled={true}
          />
        </Box>
        <Typography variant="h3" sx={{ mt: 5, mb: 3 }}>Seachable Select </Typography>
        <Box sx={{ mb: 3 }}>
          <SearchableSelect
            selectlabel="Select"
            options={options11}
            onSelectChange={handleSeachSelect}
            buttonPlaceholder={"Select Category"}
          />
        </Box>
        <Typography variant="h3" sx={{ mt: 5, mb: 3 }}>Multi Select </Typography>
        <Box sx={{ mb: 3 }}>
          <MultiSelectField
            options={multiOptions}
            value={multiSelectValue}
            onChange={handleMultiSelectChange}
            selectlabel="Select Options"
            placeholder="Select"
            showCheckbox={false}
          />
        </Box>



        <Box sx={{ mb: 3 }}>
          <Box sx={{ mb: 3 }}>Multi Searchable Example</Box>
          <SerachableMultiSelect
            options={multiOptions}
            buttonPlaceholder="Select Options"
            selectlabel="Select Options"
            onSelectChange={handleMulti}

          />
        </Box>





        <Box>
          <PrimaryButton
            label="Click Me"
            variant="contained"
            color="primary"
            onClick={() => alert("Button clicked!")}
            startIcon={<PlusIcon />}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <SecondaryButton
            label="Click Me"
            variant="contained"
            color="primary"
            onClick={() => alert("Button clicked!")}
            startIcon={<PlusIcon />}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <FilterButton
            label="Click Me"
            variant="contained"
            color="primary"
            onClick={() => alert("Button clicked!")}
            startIcon={<PlusIcon />}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <TextArea
            placeholder="Enter Description"
            minRows={6.1}
            name="test"
            register={register}
          // value={value}
          />

        </Box>
        {/*modal box start  */}
        <Box>
          <PrimaryButton
            label="Common Modal"
            variant="contained"
            color="primary"
            onClick={handleOpen}
            startIcon={<PlusIcon />}
          />

          <CommonModal
            open={open}
            onClose={handleClose}
            title="Common Modal Title"
          >
            <div>
              Your content goes here. You can place form fields, text, or any
              other elements inside this modal.
            </div>
          </CommonModal>
          {/* <StatusModal type="error" bodyText="File uploaded successfully, but errors were found. Please download the error file, update it, and upload again." open={open}
          onClose={handleClose} width="431px"/> */}
        </Box>

        <Toast
          type="info"
          message="File is being downloaded. Please update the file and upload again."
        />




        <Divider sx={{ my: 6 }} />
        <StatusChip label="Verified" type="Verified" />
        <ButtonChip label="Re-Verify" type="Re-Verify" />
        <Divider sx={{ my: 10 }} />





        <Grid container spacing={"30px"}>
          <Grid item xs={6} md={4}>
            <Box sx={{ width: "100%" }}>
              <MultiSelectField
                options={multiOptions}
                value={multiSelectValue}
                onChange={handleMultiSelectChange}
                selectlabel="Select Multi"
                placeholder="Select Multi"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <SelectField
              selectlabel="Select"
              placeholder="Select Master"
              options={options12}
              name="option"
              register={register}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel="Select"
              placeholder="Select Master"
              options={options11}
              name="option"
              register={register}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel="Select"
              placeholder="Select Master"
              options={options11}
              name="option"
              register={register}
              errorText="jhjjjhjh"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SearchableSelect
              selectlabel="Select"
              options={options11}
              onSelectChange={handleSeachSelect}
              buttonPlaceholder={"Select Category"}
              menuPlacement="top"

            />
          </Grid>


        </Grid>
      </Box>
    </DefaultLayout>
  );
};

export default FormControl;
