import React, { useRef, useEffect, useState } from "react";
import { MenuItem, FormControl, Select, styled } from "@mui/material";
import { CommonSelectProps } from "./interface";
import CustomLabel from "../label/label";
import { FormError } from "../ErrorMessage/errorMessage";
import SelectArrowDown from "../icons/selectArrowDown";

{/* Style Start */ }

const CustomFormControl = styled(FormControl)<{ direction?: string }>(({ direction }) => ({

  flexDirection: direction === 'row' ? 'row' : 'column',
  alignItems: direction === 'row' ? 'center' : 'unset',
  gap: direction === 'row' ? '8px' : 'unset',
  "& .MuiFormHelperText-root": {
    paddingBottom: direction === 'row' ? 0 : '8px',
    fontSize: "14px"
  }
}));

const CustomMenu = styled("div")<{ width?: number }>(({ width }) => ({
  marginTop: "7px",
  borderRadius: "10px !important",
  border: "1px solid #EAEEF3",
  boxShadow: "0px 10px 30px 0px #10182833",
  maxHeight: "255px !important",
  overflowY: "auto",
  width: width ? `${width}px` : "auto",
  "& ul.MuiList-root": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "50px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D7DBE6",
    borderRadius: "50px",
  },
}));

const CustomSelect = styled(Select)<{ bgcolor?: string }>(({ theme, bgcolor }) => ({
  "&.MuiOutlinedInput-root": {
    backgroundColor: bgcolor === "grey" && "#F4F3F4",
    borderRadius: "8px",
    "& fieldset": {
      borderColor: bgcolor === "grey" ? "transparent" : "#B3B0B1",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: bgcolor === "grey" ? "transparent" : "#888B8D",
    },
    "& .MuiSelect-select": {
      fontSize: bgcolor === "grey" ? "12px" : "16px",
      fontWeight: 500,
      color: "#4D4B4C",
      padding: "12.5px 12px",

      "& em": {
        color: bgcolor === "grey" ? "#757172" : "#898587",
        fontSize: bgcolor === "grey" ? "12px" : "16px",
        fontWeight: 500,
        lineHeight: "24px",

      },
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "5px 5px 4px",
  borderBottom: "1px solid #EAEAEA",
  "&:last-child": {
    borderBottom: "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent",
    "& p": {
      backgroundColor: "#EFF1F7",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: "transparent !important",
    "& p": {
      backgroundColor: "#EFF1F7",
    },
  },
  "& p": {
    width: "100%",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16.94px",
    color: "#29292A",
    padding: "12px 11px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#EFF1F7",
    },
    "& span": {
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal", // Allow multiline
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limit to 2 lines
      WebkitBoxOrient: "vertical",

    }
  },
}));

const StyledArrowDown = styled(SelectArrowDown)({
  "&.MuiSelect-icon": {
    right: "10px",
    fontSize: "12px",
    transition: "transform 0.2s ease-in-out",
  },
});
{/* Style End */ }

const SelectField: React.FC<CommonSelectProps> = ({
  options,
  selectlabel,
  placeholder = "Select",
  value,
  onChange,
  formControlProps,
  classes,
  defaultOpen = false,
  defaultValue,
  name,
  register,
  errorText,
  Asterisk,
  disabled,
  selectText,
  bgcolor,
  direction = "column",
  ...selectProps
}) => {
  const selectRef = useRef<HTMLDivElement>(null); // Ref to get width of Select
  const [selectWidth, setSelectWidth] = useState<number>(0);

  useEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current.offsetWidth);
    }
  }, [selectRef.current]);

  let registerProps = {};
  if (register) {
    registerProps = register(name);
  }

  const MenuProps = {
    PaperProps: {
      component: CustomMenu,
      style: { width: direction === "column" ? selectWidth : "auto" }, // Pass width to CustomMenu
    },
  };

  return (
    <>
      <CustomFormControl
        fullWidth
        variant="outlined"
        {...formControlProps}
        error={Boolean(errorText)}
        disabled={disabled}
        ref={selectRef}
        direction={direction}

      >
        {selectlabel && (
          <CustomLabel inputLabel={selectlabel} Asterisk={Asterisk} />
        )}
        <CustomSelect
          value={value}
          displayEmpty
          onChange={onChange}
          renderValue={(selected: any) => {
            if (selected?.length === 0 || selected === undefined) {
              return <em>{placeholder}</em>;
            }

            const selectedOption = options?.find(
              (ele) => ele.value === selected
            );
            return (
              <>
                {selectText ? (
                  <span className="selectText">
                    <span>{selectText}: </span>
                    {selectedOption?.label}
                  </span>
                ) : (
                  selectedOption?.label
                )}
              </>
            );
          }}
          MenuProps={MenuProps}
          IconComponent={StyledArrowDown}
          classes={classes}
          defaultOpen={defaultOpen}
          defaultValue={defaultValue}
          bgcolor={bgcolor}
          {...selectProps}
          {...registerProps}
        >
          {options && options.length > 0 ? (
            options?.map((option: any) => (
              <CustomMenuItem
                key={option?.value}
                value={option?.value}
                disableRipple
              >
                <p><span>{option?.label}</span></p>
              </CustomMenuItem>
            ))
          ) : (
            <CustomMenuItem disabled>
              <p style={{ textAlign: "center", fontSize: "16px" }}>No options</p>
            </CustomMenuItem>
          )}

        </CustomSelect>
      </CustomFormControl>
      {errorText && <FormError message={errorText} />}
    </>
  );
};

export default SelectField;
