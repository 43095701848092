import { convertArrayToObject } from "../../utils/helper";

interface FieldArrayProps {
  type: "input" | "dropdown" | "textArea";
  fieldName: any;
  options: any;
  inputType?: string;
  disabled?: boolean;
}

const status = [
  {
    label: "High-level assessment",
    value: "High-level assessment",
  },
  {
    label: "Detail assessment",
    value: "Detail assessment",
  },
  {
    label: "Budgeted",
    value: "Budgeted",
  },
];

export class FieldArray {
  private fieldArray: FieldArrayProps[];

  constructor(payload: any) {
    this.fieldArray = [
      {
        type: "dropdown",
        fieldName: "assetId",
        options: convertArrayToObject(payload.assetList, "assetId", "assetId"),
        disabled: payload.isEdit,
      },
      {
        type: "dropdown",
        fieldName: "initiative",
        options: convertArrayToObject(payload.initiativeList, "name", "id"),
      },
      {
        type: "dropdown",
        fieldName: "emission",
        options: convertArrayToObject(payload.emissionList, "name", "id"),
      },
      {
        type: "input",
        fieldName: "volume",
        options: null,
        inputType: "number",
      },
      {
        type: "input",
        fieldName: "percentAbated",
        options: null,
        inputType: "number",
      },
      {
        type: "input",
        fieldName: "abatementCost",
        options: null,
        inputType: "number",
      },
      {
        type: "input",
        fieldName: "capex",
        options: null,
        inputType: "number",
      },
      {
        type: "dropdown",
        fieldName: "status",
        options: status,
      },
      {
        type: "textArea",
        fieldName: "comments",
        options: null,
      },
    ];
  }
}
