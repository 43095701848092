import * as yup from "yup";
import {
  isNumberRequired,
  isRequired,
  isRequiredAlph,
} from "../../utils/validationHelper";
import { Messages } from "../../constants/form.validations.message";

const rangeRegex = /^(-?([1-8]?[0-9](\.\d{1,6})?|90(\.0{1,6})?))$/;
const rangeRegex180 =
  /^-?(180(\.0{1,6})?|((1[0-7][0-9])|([1-9]?[0-9]))(\.\d{1,6})?)$/;

export const validations: any = yup.object().shape({
  siteName: isRequiredAlph(2, 100),
  siteTypeId: isRequired,
  assetId: isRequired,
  latitude: yup
    .string()
    .required(Messages.required)
    .matches(
      rangeRegex,
      "Latitude must be in between -90 to 90 up to 6 decimal."
    ),
  longitude: yup
    .string()
    .required(Messages.required)
    .matches(
      rangeRegex180,
      "Longitude must be in between -180 to 180 up to 6 decimal."
    ),
  siteId: isRequiredAlph(2, 50),
});
