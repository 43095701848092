import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import "./style.scss";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { PrimaryButton } from "../../components/atoms/button/button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { getAbatementDetails } from "../../store/slices/abatementMaster";
import AddAbatement from "./AddAbatement";
import { AbatementConstants } from "./constants";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import { roundOffNumber } from "../../utils/helper";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { useSelector } from "react-redux";

const AbatementDetails = () => {
  const dispatch: any = useAppDispatch();
  const { headers, button, text } = AbatementConstants;
  const navigate = useNavigate();
  const [details, setDetails] = useState<any>({});
  const [editAssetOpen, setEditAssetOpen] = useState(false);
  const [alertModalVisibility, setAlertModalVisibility] = useState<any>(false);
  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    if (id) {
      dispatch(getAbatementDetails(id, successCB));
    }
  }, []);

  const successCB = (responseData: any) => {
    setDetails(responseData);
  };

  const handleEdit = () => {
    setEditAssetOpen(true);
  };

  const toggleAddAsset = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: any) => {
      setEditAssetOpen(status);
      setAlertModalVisibility(showSuccessModal);
      if (showSuccessModal) {
        // dispatch(getAssetDetails(type, successCB));
      }
    },
    []
  );

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="Edit Details"
        variant="contained"
        onClick={handleEdit}
        width="158px"
      />
    </Stack>
  );

  if (editAssetOpen) {
    return (
      <AddAbatement
        pageTitle={headers.editAbatement}
        onBack={toggleAddAsset}
        editAbatementData={details}
      />
    );
  }

  return (
    <>
      {isLoader?.isShow && isLoader.component === "abatementDetails" && (
        <CircularLoader />
      )}
      <DefaultLayout
        pageTitle="Abatement Details"
        renderHeaderButtons={renderHeaderButtons}
        backHandler={() => navigate(-1)}
      >
        <Box className="detailsMain ">
          <Box className="header spacebetween alignItems" sx={{ pt: "5px" }}>
            <h3>
              Abatement ID<span className="bold">{details.id}</span>
            </h3>
            <Stack direction="row" spacing={"30px"} alignItems="center">
              <Box className="assetLable">
                Asset Name
                <span className="asssetValue margin10">
                  {details?.assetId?.assetName}
                </span>
              </Box>
            </Stack>
          </Box>
          <Divider sx={{ borderColor: "#0000001A" }} />
          <Box className="details">
            <Grid item md={12} container spacing={"30px"}>
              <Grid item md={9} container spacing={"30px"}>
                <Grid item md={3} className="assetLable">
                  Emission Sub-category
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {details?.emissionSubcategoryId?.name}
                </Grid>
                <Grid item md={3} className="assetLable">
                  Initiative
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {details?.initiativeId?.name}
                </Grid>
              </Grid>
              <Grid item md={9} container spacing={"30px"}>
                <Grid item md={3} className="assetLable">
                  Volume abated (t of CH₄)
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {details?.volumneAbatedtOfCh4}
                </Grid>
                <Grid item md={3} className="assetLable">
                  % Abated
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {roundOffNumber(
                    (details?.percentageAbated * 100).toPrecision(3)
                  )}
                  %
                </Grid>
              </Grid>
              <Grid item md={9} container spacing={"30px"}>
                <Grid item md={3} className="assetLable">
                  Abatement Cost (USD/ t CH₄)
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {details?.abatementCost}
                </Grid>
                <Grid item md={3} className="assetLable">
                  CAPEX (MUSD)
                </Grid>
                <Grid item md={3} className="asssetValue">
                  {details?.capexMusd}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ borderColor: "#0000001A" }} />
          <Box className="description">
            <h2>Comment</h2>
            <p>{details?.Comments}</p>
          </Box>
        </Box>

        <StatusModal
          type="success"
          bodyText={text.editAssetAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
      </DefaultLayout>
    </>
  );
};

export default AbatementDetails;
