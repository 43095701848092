import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import { EquipmentConstants } from "./constant";
import EyeOpenIcon from "../../components/atoms/icons/eyeopenIcon";
import ViewIcon from "../../components/atoms/icons/viewIcon";
import { roundOffNumber } from "../../utils/helper";
import Hierachy from "../../components/atoms/icons/heirarchy";

interface Payload {
  handleIconButtonClick: any;
  handleHierarchyClick: any;
}

class NewtableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    const { columnName } = EquipmentConstants;
    const siteId = localStorage.getItem("currentSiteId") ? localStorage.getItem("currentSiteId") : 0;
    this.column = [
      {
        id: "id",
        label: 'Hierarchy',
        sortable: false,
        width: "6%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleHierarchyClick(row)}>
           
              <Hierachy />
            </Box>
          </Box>
        ),
      },
      {
        id: "id",
        label: columnName.equipmentId,
        width: "10%",
        render: (row: Row) => row?.equipmentId,
      },
      {
        id: "type",
        label: columnName.siteId,
        width: "10%",
        render: (row: Row) => siteId,
      },
      // {
      //   id: "type",
      //   label: columnName.equipmentType,
      //   width: "10%",
      //   render: (row: Row) => row?.equipmentType?.equipmentType,
      // },
      {
        id: "model",
        label: columnName.equipmentModel,
        width: "10%",
        render: (row: Row) => row?.equipmentModel,
      },
      {
        id: "ageYears",
        label: columnName.ageYears,
        width: "8%",
        render: (row: Row) => roundOffNumber(row?.ageYears),
      },
      {
        id: "agentProcessed",
        label: columnName.agentProcessed,
        width: "10%",
        render: (row: Row) => row?.agentProcessed?.agentProcessed,
      },
      {
        id: "cumulativeOperatingHours",
        label: columnName.cumulativeOperatingHours,
        width: "12%",
        render: (row: Row) => roundOffNumber(row?.cumulativeOperatingHours),
      },

      {
        id: "timeToAddressLeakDays",
        label: columnName.timeToAddressLeakDays,
        width: "12%",
        render: (row: Row) => roundOffNumber(row?.timeToAddressLeakDays),
      },
      {
        id: "inletOperationalPressureATM",
        label: columnName.inletOperationalPressureATM,
        width: "8%",
        render: (row: Row) => roundOffNumber(row?.inletOperationalPressureATM),
      },

      {
        id: "view",
        label: "View",
        sortable: false,
        align: "center",
        width: "7%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleIconButtonClick(row)}>
              <ViewIcon />
            </Box>
          </Box>
        ),
      },
    ]
  }
}

export default NewtableColumn;