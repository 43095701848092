import React, { useState } from "react";
import { Tab, Box, Typography } from "@mui/material";
import { CommonTabsProps, TabPanelProps } from "./ineterface";
import { StyledTabs } from "./style";

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography sx={{ mt: "30px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const CommonTabs: React.FC<CommonTabsProps> = ({
  tabs,
  detectionData,
  handleChange,
  value,
  size="small"
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example" 
        size={size} 
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            disableRipple
            
          />
        ))}
      </StyledTabs>
      <TabPanel key={value} value={value} index={value}>
        {detectionData()}
      </TabPanel>
    </Box>
  );
};

export default CommonTabs;
