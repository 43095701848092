import React from "react";
export default function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8 16.5C7.7928 16.5 7.59409 16.4177 7.44757 16.2712C7.30106 16.1247 7.21875 15.926 7.21875 15.7188V1.28125C7.21875 1.07405 7.30106 0.875336 7.44757 0.728823C7.59409 0.58231 7.7928 0.5 8 0.5C8.2072 0.5 8.40591 0.58231 8.55243 0.728823C8.69894 0.875336 8.78125 1.07405 8.78125 1.28125V15.7188C8.78125 15.926 8.69894 16.1247 8.55243 16.2712C8.40591 16.4177 8.2072 16.5 8 16.5Z"
        fill="white"
      />
      <path
        d="M15.2188 9.28125H0.78125C0.57405 9.28125 0.375336 9.19894 0.228823 9.05243C0.08231 8.90591 0 8.7072 0 8.5C0 8.2928 0.08231 8.09409 0.228823 7.94757C0.375336 7.80106 0.57405 7.71875 0.78125 7.71875H15.2188C15.426 7.71875 15.6247 7.80106 15.7712 7.94757C15.9177 8.09409 16 8.2928 16 8.5C16 8.7072 15.9177 8.90591 15.7712 9.05243C15.6247 9.19894 15.426 9.28125 15.2188 9.28125Z"
        fill="white"
      />
    </svg>
  );
}
