import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import { AssetConstants } from "./constants";
import EyeOpenIcon from "../../components/atoms/icons/eyeopenIcon";
import ViewIcon from "../../components/atoms/icons/viewIcon";
import { roundOffNumber } from "../../utils/helper";
import Hierachy from "../../components/atoms/icons/heirarchy";

interface Payload {
  handleIconButtonClick: any;
  handleHierarchyClick: any;
}

class TableColumn {
  private column: Column[];


  constructor(payload: Payload) {
    const { columnName } = AssetConstants;
    this.column = [
      {
        id: "id",
        label: columnName?.hierachy,
        sortable: false,
        width: "5%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleHierarchyClick(row)}>
           
              <Hierachy />
            </Box>
          </Box>
        ),
      },
      {
        id: "id",
        label: columnName.assetId,
        sortable: false,
        width: "7%",
        render: (row: Row) => row?.asset_id,
      },
      {
        id: "name",
        label: columnName.assetName,
        sortable: false,
        width: "9%",
        render: (row: Row) => row?.asset_name,
      },
      {
        id: "type",
        label: columnName.assetType,
        sortable: false,
        width: "9%",
        render: (row: Row) => row?.asset_type,
      },
      {
        id: "description",
        label: columnName.assetDescription,
        sortable: false,
        width: "12%",
        render: (row: Row) => row?.asset_description,
      },
      {
        id: "latitude",
        label: columnName.latitude,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.latitude,
      },
      {
        id: "longitude",
        label: columnName.longitude,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.longitude,
      },
      {
        id: "operated",
        label: columnName.operated,
        sortable: false,
        width: "6%",
        render: (row: Row) => row?.operated,
      },

      {
        id: "equityPercentage",
        label: columnName.equityPercentage,
        sortable: false,
        width: "6%",
        render: (row: Row) => (roundOffNumber((row?.equity_percentage * 100).toPrecision(3)) + "%"),
      },
      {
        id: "specificEquity",
        label: columnName.specificEquity,
        sortable: false,
        width: "9%",
        render: (row: Row) => row?.equity_type,
      },
      {
        id: "reportingLevel",
        label: columnName.reportingLevel,
        sortable: false,
        width: "7%",
        render: (row: Row) => row?.reporting_level,
      },
      {
        id: "operator",
        label: columnName.operator,
        sortable: false,
        width: "6%",
        render: (row: Row) => row?.operator,
      },
      {
        id: "country",
        label: columnName.country,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.country,
      },
      {
        id: "view",
        label: "View",
        sortable: false,
        align: "center",
        width: "7%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleIconButtonClick(row)}>
              <ViewIcon />
            </Box>
          </Box>


        ),
      },
    ]
  }
}

export default TableColumn;
