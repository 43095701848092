// @ts-nocheck
import React, { useEffect } from 'react'
import { ogmpData } from './data'
import { firstLevel, orderArray, tableCellKeys, ventingKeyOrder, ventingOrder } from './constants'

export default function Excel() {

  // useEffect(() => {
  //   ogmpData.map((row) => {
  //     console.log("row", row)
  //     orderArray.includes(Object.keys(row).find((key => row)))
  //   })
  // }, [])

  const BlankCell = () => (
    <td class="s0"
      data-b-t-c="FFFFFF"
      data-b-l-c="FFFFFF"
      data-b-r-c="FFFFFF"
      data-b-b-s="thin"
      data-b-l-s="thin"
      data-b-r-s="thin"
      data-b-b-c="FFFFFF"
    ></td>
  )

  const ColSpan4 = ({ label_, colspan = "4", fillColor = "66ffcc", fontColor = "black", rowspan = "2" }) => {
    return (
      <td class="s2"
        data-b-b-c="black"
        data-b-t-c="black"
        data-b-l-c="black"
        data-b-r-c="black"
        data-b-b-s="thin"
        data-b-l-s="thin"
        data-b-r-s="thin"
        data-a-h="center"
        data-a-v="middle"
        data-f-bold="true"
        data-a-wrap="true"
        data-fill-color={fillColor}
        data-f-color={fontColor}
        colspan={colspan}
        rowspan={rowspan}

      >
        {label_}
      </td>)
  }

  const DataCell = ({ value }) => {
    return (
      <td class="s2"
        data-a-h="center"
        data-a-v="middle"
        data-a-wrap="true"
      >
        {value}
      </td>
    )
  }

  const renderDataCells = (data) => {
    const dataCell = []
    let keys = Object.keys(data);
    tableCellKeys.forEach((tableKey) => {
      if (keys.includes(tableKey)) {
        if (tableKey === "Stationary combustion") {
          //4
          for (var key1 in data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey][key1]} />)
          }
        } else if (tableKey === "Flaring (Incomplete Combustion)") {
          // 4
          for (var key1 in data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey][key1]} />)
          }
        } else if (tableKey === "Fugitive Component and Equipment Leaks") {
          // 4
          for (var key1 in data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey][key1]} />)
          }
        } else if (tableKey === "Other Sources") {
          // 5
          for (var key1 in data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey][key1]} />)
          }
        } else if (tableKey === "Level 5 - Total emissions") {
          // 3
          for (var key1 in data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey][key1]} />)
          }
        } else if (tableKey === "Venting") {
          // 36
          ventingKeyOrder.forEach((ventingKey) => {
            if (data[tableKey]?.hasOwnProperty(ventingKey)) {
              for (var key1 in data[tableKey][ventingKey]) {
                dataCell.push(<DataCell value={data[tableKey][ventingKey][key1]} />)
              }
            }
          })
        } else {
          if (data[tableKey]) {
            dataCell.push(<DataCell value={data[tableKey]} />)
          } else {
            dataCell.push(<DataCell value={""} />)
          }
        }
      } else {
        dataCell.push(<DataCell value={""} />)
      }
    })
    return dataCell
  }


  return (
    <table id="ogmp-table"
      style={{ display: "none" }}
      data-cols-width={"15,16,20,15,15,15,15,15,15,15,15,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,20,17,17,20,20,20,17,17,17,25"}
    >
      <tbody>
        {/* Fixed columns start */}
        <tr data-height="40">
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <ColSpan4 label_="Stationary Combustion" />
          <ColSpan4 label_="Flaring (Incomplete Combustion)" />
          <ColSpan4 label_="Fugitive Component and Equipment Leaks" />
          <ColSpan4 label_="Venting Sources" colspan={"36"} rowspan="1" />
          <ColSpan4 label_="Other Sources" colspan={"5"} />
          <ColSpan4 label_="Total emissions" colspan={"1"} rowspan={"4"} />
          <ColSpan4 label_="Uncertainty" colspan={"1"} rowspan={"4"} />
          <ColSpan4 label_="Comments" colspan={"1"} rowspan={"4"} fillColor='006600' fontColor='ffffff' />
          <ColSpan4 label_="Comments if reported zero emissions for one or more sources" colspan={"1"} rowspan={"4"} fillColor='006600' fontColor='ffffff' />
          <ColSpan4 label_="Comments on variation from prior year" colspan={"1"} rowspan={"4"} fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Level 5 - Total emissions" colspan={"3"} rowspan={"2"} fillColor='ffd965' />
          <ColSpan4 label_="Investments/divestments of facilities
            in the asset (Yes/No)" colspan={"1"} rowspan={"4"} fillColor='002060' fontColor='ffffff' />

        </tr>
        <tr data-height="40">
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <BlankCell />
          <ColSpan4 label_="Natural gas driven pneumatic equipment" colspan="4" rowspan='1' />
          <ColSpan4 label_="Centrifugal compressor shaft seals" colspan="4" rowspan='1' />
          <ColSpan4 label_="Reciprocating compressor rod packing" colspan="4" rowspan='1' />
          <ColSpan4 label_="Glycol dehydrators" colspan="4" rowspan='1' />
          <ColSpan4 label_="Tanks" colspan="4" rowspan='1' />
          <ColSpan4 label_="Well liquids unloading" colspan="4" rowspan='1' />
          <ColSpan4 label_="Well casinghead venting" colspan="4" rowspan='1' />
          <ColSpan4 label_="Hydraulic fracture completions" colspan="4" rowspan='1' />
          <ColSpan4 label_="Venting - Other" colspan="4" rowspan='1' />
        </tr>

        <tr data-height="50">
          <ColSpan4 label_="Asset/ Venture Name" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Asset id" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Type of Asset" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Description of the Asset" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Country" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Latitude" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Longitude" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Operated?" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Operator" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="% Equity (Note- Ownership of
              emissions)" colspan="1" fillColor='002060' fontColor='ffffff' />
          <ColSpan4 label_="Specify type of equity" colspan="1" fillColor='002060' fontColor='ffffff' />

          {/* Stationary Combustion */}

          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Flaring (Incomplete Combustion) */}

          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Fugitive Component and Equipment Leaks */}

          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Natural gas driven pneumatic equipment */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Centrifugal compressor shaft seals */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Reciprocating compressor rod packing */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Glycol dehydrators */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Tanks */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Well liquids unloading */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Well casinghead venting */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Hydraulic fracture completions */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Venting - Other */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />

          {/* Other Sources */}
          <ColSpan4 label_="Methane emissions in metric tonnes" colspan="1" />
          <ColSpan4 label_="Reporting level" colspan="1" />
          <ColSpan4 label_="L4 Uncertainty when reporting at L5" colspan="1" />
          <ColSpan4 label_="L4 method" colspan="1" />
          <ColSpan4 label_="Others - Please provide additional information about the other sources" colspan="1" />


          {/* Level 5 - Total emissions */}
          <ColSpan4 label_="Level 5 - Total emissions" colspan="1" fillColor='ffd965' />
          <ColSpan4 label_="Uncertainty" colspan="1" fillColor='ffd965' />
          <ColSpan4 label_="Please feel free to provide additional information" colspan="1" fillColor='ffd965' />
        </tr>

        {/* Fixed columns end */}
        <tr></tr>

        {
          ogmpData.map((item) => (
            <tr>
              {renderDataCells(item)}
            </tr>
          ))
        }


      </tbody>
    </ table>
  )
}
