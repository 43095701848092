import React from "react";
export default function Hierachy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2 7.24992C1.58579 7.24992 1.25 7.58571 1.25 7.99992C1.25 8.41413 1.58579 8.74992 2 8.74992L2 7.24992ZM14 8.74992C14.4142 8.74992 14.75 8.41413 14.75 7.99992C14.75 7.58571 14.4142 7.24992 14 7.24992V8.74992ZM4 8.74992L8 8.74992V7.24992L4 7.24992L4 8.74992ZM3.25 7.99992L3.25 10.1666H4.75L4.75 7.99992L3.25 7.99992ZM8.75 7.99992V5.83325L7.25 5.83325L7.25 7.99992H8.75ZM8 8.74992L12 8.74992V7.24992L8 7.24992V8.74992ZM11.25 7.99992L11.25 10.1666H12.75L12.75 7.99992L11.25 7.99992ZM4 7.24992H2L2 8.74992H4L4 7.24992ZM12 8.74992H14V7.24992H12V8.74992Z" fill="#4D4B4C"/>
    <path d="M8.7 5.83341C9.44995 5.83341 9.82492 5.83341 10.0878 5.64243C10.1727 5.58075 10.2473 5.50609 10.309 5.4212C10.5 5.15833 10.5 4.78336 10.5 4.03341V3.96675C10.5 3.2168 10.5 2.84183 10.309 2.57896C10.2473 2.49407 10.1727 2.41941 10.0878 2.35773C9.82492 2.16675 9.44995 2.16675 8.7 2.16675H7.3C6.55005 2.16675 6.17508 2.16675 5.91221 2.35773C5.82732 2.41941 5.75266 2.49407 5.69098 2.57896C5.5 2.84183 5.5 3.2168 5.5 3.96675V4.03341C5.5 4.78336 5.5 5.15833 5.69098 5.4212C5.75266 5.50609 5.82732 5.58075 5.91221 5.64243C6.17508 5.83341 6.55005 5.83341 7.3 5.83341H8.7Z" fill="#4D4B4C"/>
    <path d="M4.7 13.8334C5.44995 13.8334 5.82492 13.8334 6.08779 13.6424C6.17268 13.5808 6.24734 13.5061 6.30902 13.4212C6.5 13.1583 6.5 12.7834 6.5 12.0334V11.9667C6.5 11.2168 6.5 10.8418 6.30902 10.579C6.24734 10.4941 6.17268 10.4194 6.08779 10.3577C5.82492 10.1667 5.44995 10.1667 4.7 10.1667H3.3C2.55005 10.1667 2.17508 10.1667 1.91221 10.3577C1.82732 10.4194 1.75266 10.4941 1.69098 10.579C1.5 10.8418 1.5 11.2168 1.5 11.9667V12.0334C1.5 12.7834 1.5 13.1583 1.69098 13.4212C1.75266 13.5061 1.82732 13.5808 1.91221 13.6424C2.17508 13.8334 2.55005 13.8334 3.3 13.8334H4.7Z" fill="#4D4B4C"/>
    <path d="M12.7 13.8334C13.4499 13.8334 13.8249 13.8334 14.0878 13.6424C14.1727 13.5808 14.2473 13.5061 14.309 13.4212C14.5 13.1583 14.5 12.7834 14.5 12.0334V11.9667C14.5 11.2168 14.5 10.8418 14.309 10.579C14.2473 10.4941 14.1727 10.4194 14.0878 10.3577C13.8249 10.1667 13.4499 10.1667 12.7 10.1667H11.3C10.5501 10.1667 10.1751 10.1667 9.91222 10.3577C9.82732 10.4194 9.75266 10.4941 9.69098 10.579C9.5 10.8418 9.5 11.2168 9.5 11.9667V12.0334C9.5 12.7834 9.5 13.1583 9.69098 13.4212C9.75266 13.5061 9.82732 13.5808 9.91222 13.6424C10.1751 13.8334 10.5501 13.8334 11.3 13.8334H12.7Z" fill="#4D4B4C"/>
    </svg>

  );
}
