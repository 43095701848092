import React from "react";
import { SvgIcon } from "@mui/material";

const TableSortIcon = (props: any) => (
  <SvgIcon width="7" height="12" viewBox="0 0 10 16" fill="none" {...props}>
    <path
      d="M8.98314 5.25034L5.55814 1.82534C5.402 1.67013 5.19079 1.58301 4.97064 1.58301C4.75048 1.58301 4.53927 1.67013 4.38314 1.82534L1.0498 5.15867"
      stroke="#3A3A3A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      fill="none"
    />
    <path
      d="M1.0166 10.75L4.4416 14.175C4.59774 14.3302 4.80895 14.4173 5.0291 14.4173C5.24926 14.4173 5.46047 14.3302 5.6166 14.175L8.94994 10.8417"
      stroke="#3A3A3A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      fill="none"
    />
  </SvgIcon>
);

export default TableSortIcon;
