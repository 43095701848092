// src/components/Header.tsx

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import ArrowDownIcon from "../atoms/icons/arrowDown"; // Ensure the correct path to your ArrowDown icon
import { CustomAppBar, CustomButton, CustomMenu, RightNav } from "./style";
import { NavLink } from "react-router-dom"; // Import NavLink from React Router
import { pageRoutes, masterRoutes, settingRoutes, MasterRouteKeys, SettingRouteKeys } from "./pageRoute"; // Import routes
import { Stack } from "@mui/material";
import ActiveNotificationIcon from "../atoms/icons/activeNotificationIcon";
import NotificationIcon from "../atoms/icons/notificationIcon";
import MasterIcon from "../atoms/icons/masterIcon";
import ActiveMasterIcon from "../atoms/icons/activeMasterIcon";
import AdminIcon from "../atoms/icons/adminIcon";
import ActiveAdminIcon from "../atoms/icons/activeAdminIcon";
import ActiveDimaIcon from "../atoms/icons/activeDimaIcon";
import DimaIcon from "../atoms/icons/dimaIcon";

import { v4 as uuidv4 } from 'uuid';
import ChatBot from "./ChatBot/ChatBot";
import ReconciliationIcon from "../atoms/icons/reconciliationIcon";
import ReconciliationActiveIcon from "../atoms/icons/reconciliationActiveIcon";

// Extend the ArrowDownIcon component props
interface CustomArrowDownProps {
  isOpen: boolean;
}

interface HeaderProps {
  chatOpen: boolean;
  onOpenChat: () => void; // Function to open the chatbot
}

// Apply the new props interface
const CustomArrowDown = styled(
  (
    props: CustomArrowDownProps & React.ComponentProps<typeof ArrowDownIcon>
  ) => <ArrowDownIcon {...props} />
)(({ isOpen }) => ({
  width: "15px",
  marginLeft: "8px",
  transition: "transform 0.3s",
  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
}));


const CustomToolbar = styled(Toolbar)({
  minHeight: "68px !important",
});

function Header() {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] =
    React.useState<null | HTMLElement>(null);
  const [anchorElMasters, setAnchorElMasters] =
    React.useState<null | HTMLElement>(null);
  const [isMastersMenuOpen, setIsMastersMenuOpen] = React.useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
  const [isDIMAMenuOpen, setIsDIMAMenuOpen] = React.useState(false);
  const [chatOpen, setChatOpen] = React.useState(false);
  const [uuid, setUuid] = React.useState<string | null>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleCloseNavMenu();
    handleCloseMastersMenu();
    setAnchorElUser(event.currentTarget);
    setIsUserMenuOpen(true);
    setIsDIMAMenuOpen(false);
  };

  React.useEffect(() => {
    const existingUUID = localStorage.getItem('uuid');
    if (existingUUID) {
      setUuid(existingUUID); // Load from cache if exists
    }
  }, []);

  const handleOpenDIMAMenu = (event: React.MouseEvent<HTMLElement>) => {
    setChatOpen(true); // Toggle chat visibility
    setIsDIMAMenuOpen((prev) => !prev);
    setIsUserMenuOpen(false);
    if (!localStorage.getItem('uuid')) {
      const newUUID = uuidv4(); // Generate a new UUID
      localStorage.setItem('uuid', newUUID); // Store in cache
      setUuid(newUUID); // Update state
    }
  };

  const handleOpenMastersMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleCloseNavMenu();
    handleCloseUserMenu();
    setAnchorElMasters(event.currentTarget);
    setIsMastersMenuOpen(true);
    setIsDIMAMenuOpen(false);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setIsUserMenuOpen(false);

  };

  const handleCloseMastersMenu = () => {
    setAnchorElMasters(null);
    setIsMastersMenuOpen(false);
  };


  const handleOpenChat = () => setChatOpen(true);
  const handleCloseChat = () => setChatOpen(false);
  const stateUrls: { [key: string]: string[] } = {
    "MasterPage": ["/emission-master", "/gas-factor-master", "/abatement-master", "/asset-master", "/site-master", "/equipment-maste", "/component-master"],
    // Add more states and their URLs here
  };

  const getStateUrls = (state: string): string[] | undefined => stateUrls[state];

  const [urlMatch, setUrlMatch] = useState(false);
  const selectedState = "MasterPage";

  useEffect(() => {
    const urls = getStateUrls(selectedState);
    const currentPageUrl = window.location.pathname; // Get the pathname

    if (urls) {
      // Check for a match with dynamic URLs
      const matchFound = urls.some(url => {
        const baseUrl = url.replace(/-[\w]+$/, ""); // Remove dynamic part
        console.log(baseUrl)
        return currentPageUrl.startsWith(baseUrl); // Check if current URL starts with base URL
      });

      setUrlMatch(matchFound); // Set the state based on the match
    }
  }, [selectedState]);

  return (
    <>
      <CustomAppBar>
        <Container maxWidth="xl">
          <CustomToolbar
            disableGutters
            sx={{ justifyContent: "space-between", minHeight: "100px" }}
          >
            <Box className="flex-alignCenter">
              <Box className="flex-alignCenter header-logo">
                <img
                  src={require("../../assets/images/logo.svg")}
                  alt="SOCAR"
                  style={{ marginRight: 60, height: 24 }}
                />
                <span className="header-text">Demo</span>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  disableRipple
                >
                  <MenuIcon />
                </IconButton>
                <CustomMenu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pageRoutes.map((route: any, index: any) => (
                    <NavLink
                      key={index} // Unique key for each NavLink
                      to={route.path} // Correctly use the path from route
                      style={{ textDecoration: "none", color: "inherit" }}
                      className={({ isActive }) => (isActive && !isMastersMenuOpen ? "activeheadertab" : "")}
                    >
                      {({ isActive }) => (
                        <CustomButton
                          onClick={handleCloseNavMenu}
                          variant="text"
                          disableRipple
                        >
                          {/* Conditionally render the correct icon */}
                          {isActive && !isMastersMenuOpen ? (
                            <route.activeIcon style={{ marginRight: 8 }} />
                          ) : (
                            <route.icon style={{ marginRight: 8 }} />
                          )}
                          {route.item}
                        </CustomButton>
                      )}
                    </NavLink>
                  ))}
                  <MenuItem onClick={handleOpenMastersMenu}>
                    <Typography textAlign="center">Masters</Typography>
                    <CustomArrowDown isOpen={isMastersMenuOpen} />
                  </MenuItem>
                </CustomMenu>
              </Box>

              <Box sx={{ display: { xs: "none", md: "flex" }, gap: "20px" }} className="alignItems">
                {pageRoutes.map((route: any, index: any) => (

                  <NavLink
                    key={index} // Unique key for each NavLink
                    to={route.path} // Correctly use the path from route
                    style={{ textDecoration: "none", color: "inherit" }}
                    className={({ isActive }) => (isActive ? "activeheadertab" : "")}
                  >
                    {({ isActive }) => (
                      <CustomButton
                        onClick={handleCloseNavMenu}
                        variant="text"
                        disableRipple
                      >
                        {/* Conditionally render the correct icon */}
                        {isActive ? (
                          <route.activeIcon />
                        ) : (
                          <route.icon />
                        )}
                        {route.item} {/* Display page name */}
                      </CustomButton>
                    )}
                  </NavLink>))}

                <CustomButton
                  onClick={handleOpenMastersMenu}
                  disableRipple
                  className={isMastersMenuOpen || urlMatch ? "activeMenu" : ""}
                >
                  {isMastersMenuOpen || urlMatch ? (
                    <ActiveMasterIcon />
                  ) : (
                    <MasterIcon />
                  )}
                  Masters
                  <CustomArrowDown isOpen={isMastersMenuOpen} />
                </CustomButton>
                {/* <CustomButton
                  // onClick={handleOpenMastersMenu}
                  disableRipple
                  className={isMastersMenuOpen || urlMatch ? "activeMenu" : ""}
                >
                  {isMastersMenuOpen || urlMatch ? (
                    <ReconciliationActiveIcon />
                  ) : (
                    <ReconciliationIcon />
                  )}
                  Reconciliation
                </CustomButton> */}





              </Box>

              <CustomMenu
                id="menu-masters"
                anchorEl={anchorElMasters}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElMasters)}
                onClose={handleCloseMastersMenu}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                {Object.keys(masterRoutes).map((option) => (
                  <NavLink
                    to={masterRoutes[option as MasterRouteKeys]}
                    style={{ textDecoration: "none", color: "inherit" }}
                    className={({ isActive }) => (isActive ? "activeheadertab" : "")}
                  >
                    <MenuItem key={option} onClick={handleCloseMastersMenu} disableRipple>

                      <Typography textAlign="center">{option}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
              </CustomMenu>
            </Box>

            <RightNav className="rightNav">
              <Stack direction="row" spacing={1.5} className={chatOpen ? "activeMenu" : ""}>
                <CustomButton
                  onClick={handleOpenDIMAMenu}
                  disableRipple
                >
                  {chatOpen ? <ActiveDimaIcon /> : <DimaIcon />} AILA
                </CustomButton>
              </Stack>

              <Stack direction="row" spacing={1.5} className={isUserMenuOpen ? "activeMenu" : ""}>
                <CustomButton
                  onClick={handleOpenUserMenu}
                  disableRipple
                >
                  {isUserMenuOpen ? (
                    <ActiveAdminIcon />
                  ) : (
                    <AdminIcon />
                  )}
                  Admin
                  <CustomArrowDown isOpen={isUserMenuOpen} />
                </CustomButton>
              </Stack>
              <CustomMenu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {Object.keys(settingRoutes).map((setting) => (
                  <NavLink
                    to={settingRoutes[setting as SettingRouteKeys]}
                    style={{ textDecoration: "none", color: "inherit" }}
                    className={({ isActive }) => (isActive ? "activeheadertab" : "")}
                    onClick={(e) => localStorage.removeItem('uuid, dashboardView')}
                  >
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
              </CustomMenu>
            </RightNav>
          </CustomToolbar>
        </Container>
      </CustomAppBar>

      {/* ChatBot Drawer */}
      <ChatBot open={chatOpen} onClose={handleCloseChat} />
    </>
  );
}

export default Header;
