import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Checkbox,
  Select,
  MenuItem,
  Stack,
  Box,
  Typography,
} from "@mui/material";

import PaginationItem from "@mui/material/PaginationItem";
import InputField from "../InputField/InputField";
import SearchIcon from "../icons/searchIcon";
import { DataTableProps, Row } from "./interface";
import TableSortIcon from "../icons/tableSortIcon";
import {
  StyledTableHead,
  StyledTableBody,
  StylePagination,
  StyleRowPerPage,
} from "./style";
import { useAppDispatch } from "../../../store/hooks";
import TableArrow from "../icons/tableArrow";
import RenderNoDataRows from "./NoDataTable";
import SelectField from "../SelectField/SelectField";
import { useForm } from "react-hook-form";

const CrudTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  initialRowsPerPage = 5,
  showCheckbox = true,
  showSearch = true,
  pageTitle,
  renderButtons,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  searchApi,
  total,
  renderHeaderButtons,
  searchPlaceholder,
  handleSort,
  setSearch,
  seachField = true,
  removePagination = false,
}) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  // const  rowsPerPageOptions = [10, 20, 50, 100],
  const rowsPerPageOptions = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  const handleRequestSort = (property: string) => {
    handleSort(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    // Check if the click target is a checkbox
    const target = event.target as HTMLInputElement;
    if (target.type === "checkbox") {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((itemId) => itemId !== id);
      }

      setSelected(newSelected);
    }
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: any = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log("event ", event.target.value)
    setRowsPerPage(event.target.value as number);
    setPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setSearch(event.target.value);
    setPage(1);
  };

  const stableSort = (array: Row[], comparator: (a: Row, b: Row) => number) => {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [Row, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const getComparator = (order: "asc" | "desc", orderBy: string) => {
    return order === "desc"
      ? (a: Row, b: Row) => descendingComparator(a, b, orderBy)
      : (a: Row, b: Row) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: Row, b: Row, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filteredRows = rows?.filter((row) =>
    columns?.some((column) =>
      row[column.id] ? row[column.id]?.toString().toLowerCase() : false
    )
  );

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));

  const paginatedRows = sortedRows;

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box className="CrudTable">
      <Box className="dataTable">
        {showSearch && (
          <Box className={`filterRow flex ${seachField ? 'spacebetween' : 'flexend'}`}>
            {seachField && (
              <InputField
                type="text"
                name={""}
                value={searchText}
                placeholder={searchPlaceholder ?? ""}
                onChange={handleSearchChange}
                startIcon={<SearchIcon />}
                serachBox={true}
                width={290}
                classes="textTransform"
              />
            )
            }

            {renderButtons && renderButtons()}
          </Box>
        )}
        <TableContainer>
          <Table>
            <StyledTableHead>
              <TableRow>
                {showCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected?.length > 0 &&
                        selected?.length < filteredRows?.length
                      }
                      checked={
                        filteredRows?.length > 0 &&
                        selected?.length === filteredRows?.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{ width: column.width, textAlign: column.align }} // Apply the width here
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                        IconComponent={TableSortIcon}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {paginatedRows?.length === 0 ? (
                <RenderNoDataRows
                  columns={columns}
                  showCheckbox={showCheckbox}
                />
              ) : (
                paginatedRows?.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {showCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} />
                        </TableCell>
                      )}
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          className={column.class}
                          style={{
                            width: column.width,
                            textAlign: column.align,
                          }}
                        >
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </StyledTableBody>
          </Table>
        </TableContainer>
        {paginatedRows?.length > 0 && (
          !removePagination && (
            <Box className="paginationRow flex-alignCenter spacebetween">
              <StyleRowPerPage spacing={0.75} direction="row">
                <Typography>Show</Typography>
                <SelectField
                  value={rowsPerPage?.toString()}
                  onChange={handleChangeRowsPerPage}
                  options={rowsPerPageOptions}
                  sx={{
                    height: "28px",
                    minWidth: "60px",
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "4px",
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "10px",
                        "& .MuiMenu-list": {
                          padding: 0,
                          "& .MuiMenuItem-root": {
                            "& p": {
                              padding: "8px",
                            },
                          },
                        },
                      },
                    },
                  }}
                />

                <Typography>Entries</Typography>
              </StyleRowPerPage>
              <Stack>
                <StylePagination
                  count={Math.ceil(total / rowsPerPage)}
                  page={page}
                  shape="rounded"
                  onChange={handleChangePage}
                  renderItem={(item: any) => (
                    <PaginationItem
                      slots={{ previous: TableArrow, next: TableArrow }}
                      {...item}
                    />
                  )}
                  sx={{ gap: "15px" }}
                />
              </Stack>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default CrudTable;
