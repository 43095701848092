import styled from "@emotion/styled";
import { Chip } from "@mui/material";

export const StyledStatusChip = styled(Chip)<{type: string}>(({ type }) => ({
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    borderRadius: '4rem',
    padding: '8px 13px',  
    backgroundColor: type === "Verified" ? "#EDF7DE" : "#FFD2D2",
    '& .MuiChip-label': {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '14.52px',
      color: type === "Verified" ? "#64A70B" : "#E4002B",
      padding: 0,
      textTransform:'capitalize'
    },
    '& .MuiChip-icon': {
      marginLeft: '6px',
    },
}));

export const StyledButtonChip = styled(Chip)<{type: string, showIcon: string, Size: string}>(({ type, showIcon, Size}) => ({
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: showIcon ? 'space-between': 'center',
    borderRadius: '10px',
    border: type === "Verify" ? "transparent" : "1px solid #64A70B",
    padding: '11px 13px',    
    minWidth: "7.188rem",    
    cursor: 'pointer',
    backgroundColor: type === "Verify" ? "#64A70B" : "#FFFFFF",
    
    "&:hover": {
      backgroundColor: type === "Verify" ? "#64A70B" : "#FFFFFF",
    },

    '&.MuiChip-clickable': {
    WebkitTapHighlightColor: 'transparent',
    // Customize styles here if needed
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
    
    
    '& .MuiChip-label': {
      fontSize: Size === 'small' ? '12px' : '14px',
      lineHeight: '14.52px',
      color: type === "Verify" ? "#FFFFFF" : "#64A70B",
      padding: 0,
      textTransform:'capitalize'
    },
    '& .MuiChip-icon': {
      marginLeft: '4px',
      marginRight:'-5px',
      fontSize: '12px',
      '& path': {
        stroke: type === "Verify" ? "#fff" : "#64A70B",
      }
    },
}));

export const StylederrorChip = styled(Chip)<{Size: string, bgcolor: string, cursor: string}>(({Size, bgcolor, cursor}) => ({
  backgroundColor: bgcolor,
  borderRadius: '10px',
  padding: '8px 9px',
  cursor: cursor,  
'& .MuiChip-label': {
    padding: 0,
    "& span": {
    fontSize: Size === 'small' ? '12px' : '14px',
    lineHeight: 'normal',
    color: '#E4002B',
    },
  },
  '&:hover, &:focus, &:active': {
    boxShadow: 'none',
    backgroundColor: bgcolor === 'transparent' ? 'transparent' : '#FFEBEF80 !important',
    
  },
  '& .MuiSvgIcon-root': {
    fontSize: Size === 'small' ? '9px' : '0px',
    '& path': {
      stroke: '#E4002B',
    }
  },
  "& .errorIcon": {
    display: 'flex',
  '& svg': {
    width: Size === 'small' ? 'unset' : '20px',
    height: Size === 'small' ? 'unset' : '20px',
  }
  },
}));

