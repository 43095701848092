import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { AddSiteSubmitProps } from "../../pages/Site/interface";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  isTwoColumnLayout: ""  
};

const TwoColumnLayout: any = createSlice({
  name: "twoColumn",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,    


    getView: (state, { payload }) => {      
      state.isTwoColumnLayout = payload;
    },
 
    


  },
});

export const {
  clearMasterState,
  getView,
  
 
} = TwoColumnLayout.actions;

// Action creators are generated for each case reducer function
export const twoColumnSelector = (state: any) => state.chatBot;

export default TwoColumnLayout.reducer;

export const getColumnView = (data: any) => {
  return async (dispatch: any) => {
  
        dispatch(getView(data))
       
      }
    } 





    export const getGrisView = (data: any) => {
      return async (dispatch: any) => {
      
            dispatch(getView(data))
           
          }
        } 

