import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import './VolumeOfCH4.scss';

interface VolumeOfCH4Props {
  emissionFactor: number;
  lengthOfSurfacePipe: string;
  numberOfHours: number;
  renderFotter?: () => React.ReactNode;
  showHeading?: boolean;
  valueMissing?: boolean;
  totalEmissionVolume?: string;
  volumeFooter?: boolean;
}

const VolumeOfCH4: React.FC<VolumeOfCH4Props> = ({ emissionFactor, 
  lengthOfSurfacePipe, 
  showHeading = true, 
  numberOfHours, 
  valueMissing = false, 
  totalEmissionVolume,
  volumeFooter=true,
  renderFotter 
}) => {
  return (
    <Box className="volumeBox">
      {showHeading && (
        <Typography variant="h6" sx={{ paddingBottom: "15px", textAlign: "center" }}>VOLUME OF CH₄</Typography>
      )}
      
      <Box className="volumeHeder">
        <p style={{ color: "#00438B" }}>Volume of CH₄</p>
        <p style={{ color: "#777" }}>=</p>
        <p>Emission Factor</p>
        <p style={{color: "#777" }}>X</p>
        <p>Length of Surface Pipe</p>
        <p style={{ color: "#777" }}>X</p>
        <p>Number of Hours</p>
      </Box>

      <Box className="volumeBody">
        <Box className="bodyBox">
        <Stack direction="row" justifyContent="space-between" gap="30px">
          <p>Emission Factor</p>
          <p>{emissionFactor}</p>
        </Stack>
        <Stack direction="row" justifyContent="space-between" gap="30px">
          <p style={{color: valueMissing ? "#E4002B": "#29292A"}}>Length of Surface Pipe (m){valueMissing && '*'}
            {valueMissing && <span style={{ display: "block" }}>Value Missing</span>}
            </p>
          <p style={{color: valueMissing ? "#E4002B": "#29292A"}}>{lengthOfSurfacePipe}</p>
        </Stack>
        <Stack direction="row" justifyContent="space-between" gap="30px">
          <p>Number of Hours</p>
          <p>{numberOfHours}</p>
        </Stack>
        </Box>
        {volumeFooter && 
        <Divider sx={{ border: "1px dashed #0000001A", mt: "15px", mb: "20px"}} />
        }
      </Box>
     
      {volumeFooter &&
      <Box className="volumeFooter">
        {renderFotter && renderFotter()}
        {totalEmissionVolume &&
        <Stack direction="row" justifyContent="space-between" gap="30px">
        <p style={{color: "#00438B"}}>Total Emission Volume</p>
        <p style={{ color: "#64A70B", fontWeight: 600}}>{totalEmissionVolume}</p>
       </Stack>
}
      </Box>
}
    </Box>
  );
};

export default VolumeOfCH4;
