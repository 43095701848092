import React from "react";
export default function EyeOpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99994 3.53845C11.806 3.53845 14.786 7.5566 14.911 7.72769C15.0297 7.88984 15.0297 8.11015 14.911 8.27243C14.786 8.44336 11.806 12.4615 7.99994 12.4615C4.19393 12.4615 1.21379 8.44338 1.08885 8.27229C0.9704 8.10998 0.9704 7.88984 1.08885 7.72752C1.21379 7.5566 4.19393 3.53845 7.99994 3.53845ZM2.04947 7.99968C2.76826 8.87153 5.19641 11.5385 7.99994 11.5385C10.8095 11.5385 13.2326 8.8729 13.9504 8.0003C13.2313 7.12798 10.8033 4.46152 7.99994 4.46152C5.1904 4.46152 2.76733 7.12705 2.04947 7.99968ZM5.23048 8.00002C5.23048 6.47308 6.47279 5.23077 7.99973 5.23077C9.52666 5.23077 10.769 6.47308 10.769 8.00002C10.769 9.52695 9.52666 10.7693 7.99973 10.7693C6.47279 10.7693 5.23048 9.52695 5.23048 8.00002ZM6.15358 8.00002C6.15358 9.018 6.98172 9.84616 7.99973 9.84616C9.01774 9.84616 9.84587 9.018 9.84587 8.00002C9.84587 6.98203 9.01771 6.15387 7.99973 6.15387C6.98174 6.15387 6.15358 6.98203 6.15358 8.00002Z"
        fill="#5E574D"
      />
    </svg>
  );
}
