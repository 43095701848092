import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import { GasFactorConstants } from "./constants";
import EyeOpenIcon from "../../components/atoms/icons/eyeopenIcon";
import ViewIcon from "../../components/atoms/icons/viewIcon";
import { roundOffNumber, removeTrailingZeros } from "../../utils/helper";

interface Payload {
  handleIconButtonClick: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    const { columnName } = GasFactorConstants;
    this.column = [
      {
        id: "assetName",
        label: columnName?.assetName,
        width: "18%",
        render: (row: Row) => row?.assetId?.assetName,
      },
      {
        id: "assetId",
        label: columnName?.assetId,
        width: "18%",
        render: (row: Row) => row?.assetId?.assetId,
      },
      {
        id: "gasType",
        label: columnName?.gasType,
        width: "18%",
        render: (row: Row) => row?.gasType?.gasType,
      },
      {
        id: "massContentOfMethane",
        label: columnName?.massContentOfMethane,
        width: "20%",
        render: (row: Row) => roundOffNumber((row?.massContentOfMethane * 100)?.toPrecision(3)) + "%",
      },
      {
        id: "densityOfGas",
        label: columnName?.densityOfGas,
        width: "18%",
        render: (row: Row) => roundOffNumber(removeTrailingZeros(row?.densityOfGas)),
      },
      {
        id: "Edit",
        label: "Edit",
        align: "center",
        width: "10%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box
              className="downloadIcon flex-center"
              onClick={() => payload?.handleIconButtonClick(row)}
            >
              <EditIcon />
            </Box>
          </Box>
        ),
      },
    ];
  }
}

export default TableColumn;
