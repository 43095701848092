import * as yup from "yup";
import { Messages } from "../constants/form.validations.message";

export const isRequiredAlph = (minLimit: number, maxLimit: number) => {
  return yup
    .string()
    .trim()
    .required(Messages.required)
    .matches(/^[a-zA-Z0-9 _-]*$/, Messages.alphanumeric)
    .min(minLimit, Messages.minLength(minLimit))
    .max(maxLimit, Messages.maxLength(maxLimit))
    .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value));
}

export const maxLengthCheck = (minLimit: number, maxLimit: number) => {
  return yup
    .string()
    .nullable()
    .notRequired()
    .trim()
    .matches(/^[a-zA-Z0-9 _-]*$/, Messages.alphanumeric)
    .max(maxLimit, Messages.maxLength(maxLimit))
    .min(minLimit, Messages.minLength(minLimit))
    .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value));
}

export const isAlphaOptional = () => {
  return yup
    .string()
    .trim()
    .nullable()
    .matches(/^[a-zA-Z0-9 _-]*$/, Messages.alphanumeric)
    .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value));
};

export const isRequired = yup.string().required(Messages.required);

export const isNumberRequired = (limit: number) => {
  return yup.number()
    .required(Messages.required)
    .test(
      'is-valid-number',
      Messages.validNumber,
      (value) => checkNumberLimitRegex(value, limit)
    )
    .typeError(Messages.required)
    .test('is-decimal', Messages.validNumber, (value: any) =>
      value === undefined || value === '' || !isNaN(value));
}

export const isFloatRequired = yup.number()
  .required(Messages.required)
  .typeError(Messages.validNumber)
  .nullable() // Allows the value to be null
  .transform((value: any, originalValue: any) => (originalValue === '' ? null : value))
  .test('is-decimal', Messages.validNumber, (value: any) => value === null || !isNaN(value))

export const isFloatOptional = (limit: number) => {
  return yup.number()
    .typeError(Messages.validNumber)
    .test(
      'is-valid-number',
      Messages.validNumber,
      (value) => checkNumberLimitRegex(value, limit)
    )
    .nullable() // Allows the value to be null
    .transform((value: any, originalValue: any) => (originalValue === '' ? null : value))
    .test('is-decimal', Messages.validNumber, (value: any) => value === null || !isNaN(value))
};

export const numberIsRequiredOptional: any = () => {
  return yup
    .string()
    .nullable()
    .trim()
    .test('is-decimal', Messages.positiveNumber, (value: any) => {
      if (value) {
        let value_ = value?.split(".")
        if (value_[1]?.length < 1) {
          return false
        }
      }
      if (value && value < 0) return false;
      return value === null || !isNaN(value)
    })
    .matches(/^(?:[0-9]{1,3})$/, Messages.integerNumber)
    .transform((value, originalValue) => (originalValue === '' ? null : value))
}

export const numberIsRequied: any = (limit: number) => {
  return yup
    .string()
    .required(Messages.required)
    .trim()
    .test('is-decimal', Messages.positiveNumber, (value: any) => {
      if (value) {
        let value_ = value?.split(".")
        if (value_[1]?.length < 1) {
          return false
        }
      }
      if (value && value < 0) return false;
      return value === null || !isNaN(value)
    })
    .matches(/^(?:[0-9]{1,3})$/, Messages.integerNumber)
    .transform((value, originalValue) => (originalValue === '' ? null : value))
}


export const decimalPrecisionRequired = (numberPrecision: number, limit: number) => {
  return yup.string()
    .required(Messages.required)
    .typeError(Messages.required)
    .test('is-decimal', Messages.positiveNumber, (value: any) => {
      if (value) {
        let value_ = value?.split(".")
        if (value_[1]?.length < 1) {
          return false
        }
      }
      if (value && value < 0) return false;
      return value === null || !isNaN(value)
    })
    .test(
      "not-scientific-notation",
      Messages.decimalMaxLengthExceed(numberPrecision, limit),
      (value) => {
        return !/e/i.test(value);  // Reject if the input contains 'e' or 'E'
      }
    )
    .test(
      'is-decimal',
      Messages.decimalMaxLengthExceed(numberPrecision, limit),
      (value) => decimalPrecision(value, numberPrecision, limit)
    )
}

export const decimalPrecisionWithoutRequired = (numberPrecision: number, limit: number) => {
  return yup.string()
    .test('is-decimal', Messages.positiveNumber, (value: any) => {
      if (value) {
        let value_ = value?.split(".")
        if (value_[1]?.length < 1) {
          return false
        }
      }
      if (value && value < 0) return false;
      return value === null || !isNaN(value)
    })
    .test(
      "not-scientific-notation",
      Messages.decimalMaxLengthExceed(numberPrecision, limit),
      (value: any) => {
        return !/e/i.test(value);  // Reject if the input contains 'e' or 'E'
      }
    )
    .test(
      'is-decimal',
      Messages.decimalMaxLengthExceed(numberPrecision, limit),
      (value) => value ? decimalPrecision(value, numberPrecision, limit) : true
    )
}

const decimalPrecision = (value: any, numberPrecision: any, precision: any) => {
  if (typeof value === 'string') {
    const regex = new RegExp(`^\\d{1,${numberPrecision}}(\\.\\d{1,${precision}})?$`);
    // @ts-ignore
    return regex.test(value);
  }
  return false;
};

const checkNumberLimitRegex = (value: any, max: number) => {
  if (value && (max < 0 || value > max)) {
    return false;
  }
  if ((value === '') || (value === null)) {
    return true;
  }

  let pattern = '^';

  // Handle numbers from 0 to 9
  if (max < 10) {
    pattern += `[0-${max}]`;
  } else {
    // Numbers from 0 to 9
    pattern += '([0-9]|';

    // Numbers from 10 to 99
    if (max < 100) {
      pattern += `[1-${Math.floor(max / 10)}][0-${max % 10}]`;
    } else {
      pattern += '[1-9][0-9]|'; // Numbers from 10 to 99

      // Numbers from 100 to max
      if (max < 1000) {
        pattern += `[1-9][0-9]{2,2}`;
        if (max >= 100) {
          pattern += `|${max}`;
        }
      } else {
        // Handle numbers from 1000 and above
        pattern += `[1-9][0-9]{2,3}`;
        pattern += `|[1-9][0-9]{3,}`;
      }
    }

    // Close the capturing group
    pattern += ')';
  }

  pattern += '$';
  const regex = new RegExp(pattern);
  return regex.test(value);
}
