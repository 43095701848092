import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Interceptor from "./api/interceptor/interceptor";
import ProtectedRoute from "./components/layout/ProtectedRoute";
import theme from "./components/atoms/theme/theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { errorSelector, setError } from "./store/slices/errorSlice";
import Toast from "./components/atoms/Toast/Toast";
import Loader from "./components/atoms/Loader";
import { useAppDispatch } from "./store/hooks";

// Lazy-loaded components
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

import GasMaster from "./pages/gasMaster";
import Login from "./pages/Login";
import FormControl from "./testComponent/FormControl";
import UploadData from "./pages/UploadData";
import SiteMaster from "./pages/Site";
import AssetDetails from "./pages/asset/AssetDetails";
import AssetMaster from "./pages/asset";
import AbatementDetails from "./pages/abatement/AbatementDetails";
import AbatementMaster from "./pages/abatement";
import EquipmentMaster from "./pages/equipment";
import EquipmentDetails from "./pages/equipment/EquipmentDetails";
import SignOut from "./pages/Login/SignOut";
import ComponentMaster from "./pages/Component";
import NotificationDetail from "./pages/Notification/NotificationDetail/NotificationDetail";
import NotificationCenter from "./pages/Notification";
import DuplicateEmissions from "./pages/Notification/DuplicateEmissions";
import EmissionMaster from "./pages/EmissionMaster";

import MultiEquipmentDetails from "./pages/Notification/MultiEquipmentDetails";
import SingleEquipmentDetails from "./pages/Notification/SingleEquipmentDetails copy";
import Test from "./pages/test";

function App() {
  const dispatch: any = useAppDispatch();
  const { error, type } = useSelector(errorSelector);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(setError({ message: null }));
      }, 2000)
    }
  }, [location?.href]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Interceptor />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
          </Routes>
        </Suspense>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            {/* <Route path="/site-master" element={<SiteMaster />} /> */}
            <Route path="/site-master/:id" element={<SiteMaster />} />
            <Route path="/asset-master" element={<AssetMaster />} />
            <Route path="/asset-details/:id" element={<AssetDetails />} />
            <Route
              path="/abatement-details/:id"
              element={<AbatementDetails />}
            />
            <Route path="/abatement-master" element={<AbatementMaster />} />
            <Route path="/upload-data" element={<UploadData />} />
            <Route path="/equipment-master/:id" element={<EquipmentMaster />} />
            <Route path="/component-master/:id" element={<ComponentMaster />} />
            <Route
              path="/equipment-details/:id"
              element={<EquipmentDetails />}
            />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/gas-factor-master" element={<GasMaster />} />
            <Route path="/emission-master" element={<EmissionMaster />} />
            <Route
              path="/notification-center"
              element={<NotificationCenter />}
            />
            <Route
              path="/duplicate-emissions"
              element={<DuplicateEmissions />}
            />
            <Route
              path="/emission-details/:id"
              element={<NotificationDetail />}
            />
          </Route>

          <Route path="/control" element={<FormControl />} />
          <Route path="/test" element={<Test />} />
          <Route path="/asset-details" element={<AssetDetails />} />
          <Route path="/abatement-details" element={<AbatementDetails />} />
          <Route
            path="/single-equipment"
            element={<SingleEquipmentDetails />}
          />
          <Route path="/multi-equipment" element={<MultiEquipmentDetails />} />
        </Routes>
      </Router>
      {error && <Toast message={error} type={type} />}
    </ThemeProvider>
  );
}

export default App;
