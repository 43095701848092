import React, { useEffect, useState } from 'react';
import FilterModal from '../../components/atoms/FilterModal/FilterModal';
import { Grid } from '@mui/material';
import { FilterPaylod, FilterEmissionFactorProps } from './interface';
import MultiSelectField from '../../components/atoms/MultiSelect/MultiSelectField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EmissionConstants } from './constants';
import { convertArrayToObject } from '../../utils/helper';
import { useAppDispatch } from '../../store/hooks';
import { useSelector } from 'react-redux';
import { equipmentMasterSelector, getEquipmentTypeList } from '../../store/slices/equipmentMaster'
import {
  componentMasterSelector,
  getComponentTypeList,
} from "../../store/slices/componentMaster";

const FilterEmissionFactor = ({ open, onClose, filterCB }: FilterEmissionFactorProps) => {
  const { equipmentTypeList } = useSelector(equipmentMasterSelector)
  const { componentTypeList } = useSelector(
    componentMasterSelector
  );
  const dispatch: any = useAppDispatch();

  // Destructure constants from EmissionConstants
  const { label, placeholder, name } = EmissionConstants;
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: {
      equipment_type: [],
      component_type: []
    }
  });

  useEffect(() => {
    dispatch(getEquipmentTypeList())
    dispatch(getComponentTypeList())
  }, [])

  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("siteFilter")
    reset({ equipment_type: [] });
    reset({ component_type: [] });
    onSubmit()
  }
  useEffect(() => {
    const equipmentSelected = watch("equipment_type").length > 0;
    const componentSelected = watch("component_type").length > 0;

    const hasSelection = equipmentSelected || componentSelected;
    setClearDisabled(!hasSelection);
    setFilterDisabled(!hasSelection);
  }, [watch("equipment_type"), watch("component_type")]);

  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("equipment_type"), watch("component_type"));
    onClose();
  }

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title='FILTER BY'
        width='475px'
        clearFilters={handleClearFilters}
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render MultiSelectField component */}

            <MultiSelectField
              selectlabel={label.equipmentOnlyType}
              placeholder={placeholder.equipmentOnlyType}
              options={convertArrayToObject(equipmentTypeList, "equipmentType", "id")}
              name={name.equipment_type}
              register={register}
              Asterisk={false}
              value={watch("equipment_type") || []}
            />
          </Grid>
          <Grid item md={12}>
            {/* Render MultiSelectField component */}

            <MultiSelectField
              selectlabel={label.componentType}
              placeholder={placeholder.componentType}
              options={convertArrayToObject(componentTypeList, "componentType", "id")}
              name={name.component_type}
              register={register}
              Asterisk={false}
              value={watch("component_type") || []}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterEmissionFactor;