import React, { useRef, useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  styled,
  SelectChangeEvent,
  Checkbox,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { CommonSelectProps } from "./interface";
import ArrowDown from "../icons/arrowDown";
import CustomLabel from "../label/label";
import { FormError } from "../ErrorMessage/errorMessage";

const CustomMenu = styled("div")<{ width?: number }>(({ width }) => ({
  marginTop: "7px",
  borderRadius: "10px !important",
  border: "1px solid #EAEEF3",
  boxShadow: "0px 10px 30px 0px #10182833",
  maxHeight: "255px !important",
  overflowY: "auto",
  width: width ? `${width}px` : "auto",
  "& ul.MuiList-root": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "50px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D7DBE6",
    borderRadius: "50px",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B3B0B1",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#888B8D",
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontWeight: 500,
      color: "#4D4B4C",
      padding: "12px",

      "& em": {
        color: "#898587",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
      },
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "5px 5px 4px",
  borderBottom: "1px solid #EAEAEA",
  "&:last-child": {
    borderBottom: "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& p": {
      backgroundColor: "#EFF1F7",
    }
  },
  "&.Mui-focusVisible": {
    backgroundColor: "transparent !important",
    "& p": {
      backgroundColor: "#EFF1F7",
    },
  },
  "& p": {
    width: "100%",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16.94px",
    color: "#29292A",
    padding: "12px 11px",
    borderRadius: "6px",   
    "&:hover": {
      backgroundColor: "#EFF1F7",
    },
    "& span": {
      fontSize: "14px",
      overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal", // Allow multiline
    display: "-webkit-box",
    WebkitLineClamp: 2, // Limit to 2 lines
    WebkitBoxOrient: "vertical",

    }

  },
}));

const StyledArrowDown = styled(ArrowDown)({
  "&.MuiSelect-icon": {
    right: "10px",
    fontSize: "14px",
    transition: "transform 0.2s ease-in-out",
  },
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: "0 10px 0 0",
  color: "transparent",
  '&.Mui-checked': {
    color: "transparent",
  },
}));

const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  border: "1px solid #BAB7B3",
  backgroundColor: "#fff",
}));

const CheckboxCheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,  
  backgroundColor: "#64A70B", // Green background
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    color: "#fff", // White check mark
    fontSize: "16px",
  },
}));

const MultiSelectField: React.FC<CommonSelectProps> = ({
  options,
  selectlabel,
  placeholder = "Select",
  value = [],
  onChange,
  formControlProps,
  classes,
  defaultOpen = false,
  defaultValue,
  name,
  register,
  errorText,
  Asterisk,
  disabled,
  selectText,
  showCheckbox = false, // New prop to conditionally display checkboxes
  ...selectProps
}) => {
  const selectRef = useRef<HTMLDivElement>(null); // Ref to get width of Select
  const [selectWidth, setSelectWidth] = useState<number>(0);

  useEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current.offsetWidth);
    }
  }, [selectRef.current]);

  let registerProps = {};
  if (register) {
    registerProps = register(name);
  }

  const MenuProps = {
    PaperProps: {
      component: CustomMenu,
      style: { width: selectWidth }, // Pass width to CustomMenu
    },
  };

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const selectedValues = event.target.value as string[];
    if (onChange) {
      onChange(selectedValues);
    }
  };

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        {...formControlProps}
        error={Boolean(errorText)}
        disabled={disabled}
        ref={selectRef}
      >
        {selectlabel && (
          <CustomLabel inputLabel={selectlabel} Asterisk={Asterisk} />
        )}
        <CustomSelect
          multiple
          value={value}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return <em>{placeholder}</em>;
            }

            const selectedOptions = options.filter((option) =>
              selected.includes(option.value)
            );

            return (
              <>
                {selectText ? (
                  <span className="selectText">
                    <span>{selectText}: </span>
                    {selectedOptions.map((option) => option.label).join(", ")}
                  </span>
                ) : (
                  selectedOptions.map((option) => option.label).join(", ")
                )}
              </>
            );
          }}
          MenuProps={MenuProps}
          IconComponent={StyledArrowDown}
          classes={classes}
          defaultOpen={defaultOpen}
          defaultValue={defaultValue}
          {...selectProps}
          {...registerProps}
        >
           {options && options.length > 0 ? (
          options?.map((option) => (
            <CustomMenuItem
              key={option.value}
              value={option.value}
              disableRipple
            >
              <p>
                {showCheckbox && (
                  <StyledCheckbox
                    checked={value.includes(option.value)}
                    disableRipple
                    icon={<CheckboxIcon />}
                    checkedIcon={
                      <CheckboxCheckedIcon>
                        <CheckIcon />
                      </CheckboxCheckedIcon>
                    }
                  />
                )}
                <span>{option.label}</span>
              </p>
            </CustomMenuItem>
          ))
        ) : (
          <CustomMenuItem disabled>
            <p style={{textAlign: "center", fontSize: "16px"}}>No options</p>
          </CustomMenuItem>
        )}
        </CustomSelect>
      </FormControl>
      {errorText && <FormError message={errorText} />}
    </>
  );
};

export default MultiSelectField;
