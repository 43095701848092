import React from "react";
import { Box, Typography } from "@mui/material";
import { InfoProps } from "./interface";
import { styled } from "@mui/system";
import InfoIcon from "../icons/infoIcon";


// Styled components
const InfoBox = styled(Box)<{color: string}>(({color}) => ({
  display: "flex",
  alignItems: "center",
  gap: "4px", 
  "& svg": {
    "& path": {
      fill: color,
    }
  }

}));

const TextStyle = styled(Typography)<{color: string, fontSize: string}>(({color, fontSize}) => ({
  fontSize: fontSize,
  fontWeight: 400,
  lineHeight: "14.52px",
  color: color,
 
}));

export const InfoText: React.FC<InfoProps> = ({
  Text,
  icon = <InfoIcon />,  
  color="#00438B",
  fontSize="12px",
}) => {
  return (
    <InfoBox color={color}>
      {icon}
      <TextStyle variant="body2" color={color} fontSize={fontSize}>{Text}</TextStyle>
    </InfoBox>
  );
};



