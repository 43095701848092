import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { AlertBoxProps } from "./interface";
import ToastCloseIcon from "../icons/toastCloseIcon";
import { StyledAlert, StyledSnackbar } from "./style";
import { useSelector } from "react-redux";
import { errorSelector, setError } from "../../../store/slices/errorSlice";
import { useAppDispatch } from "../../../store/hooks";

const Toast: React.FC<AlertBoxProps> = ({
  message,
  type,
  autoHideDuration = 2000,
}) => {
  const dispatch: any = useAppDispatch()
  const [open, setOpen] = useState(true);
  const { error } = useSelector(errorSelector)

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(setError({ message: null }))
      }, 2000)
    }
  }, [error])

  const handleClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <StyledAlert
        severity={type}
        action={
          <IconButton size="small" aria-label="close" onClick={handleClose}>
            <ToastCloseIcon />
          </IconButton>
        }
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default Toast;
