import React, { useEffect, useState } from "react";
import FilterModal from "../../components/atoms/FilterModal/FilterModal";
import { Box, Grid, Typography } from "@mui/material";
import { FilterNotificationProps, FilterPaylod } from "./interface";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/atoms/InputField/InputField";
import { EquipmentConstants } from "./constant";
import {
  addOrUpdateSearchParamsInUrl,
  convertArrayToObject,
} from "../../utils/helper";
import { useAppDispatch } from "../../store/hooks";
import { getEquipmentMasterData } from "../../store/slices/equipmentMaster";
import {
  assetMasterSelector,
  getAssetList,
} from "../../store/slices/assetMaster";
import { useSelector } from "react-redux";
import MultiSelectField from "../../components/atoms/MultiSelect/MultiSelectField";
import {
  emissionMasterSelector,
  getEmissionSubCategoryList,
} from "../../store/slices/emissionMaster";
import { getNotificationData } from "../../store/slices/notificationSlice";
import DatePicker from "../../components/atoms/DatePicker/DatePicker";
import dayjs from "dayjs";

const FilterEquipment = ({
  open,
  onClose,
  assetData,
  filterCB,
}: FilterNotificationProps) => {
  const dispatch: any = useAppDispatch();
  const { emissionSubCategoryList } = useSelector(emissionMasterSelector);
  // Destructure constants from SiteConstants
  const { label, placeholder, name, detectionOptions, verificationStatus } =
    EquipmentConstants;
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      detection_method: [],
      emission_subcategory_id: [],
      status: null,
      date: ""
    },
  });

  const filterDetectionMethod: any = "detection_method";
  const filterEmissionSubCategory: any = "emission_subcategory_id";
  const filterVerificationStatus: any = "status";

  useEffect(() => {
    dispatch(getEmissionSubCategoryList("limit=9999&order=DESC"));
    getFiltersData();
  }, [dispatch, setValue]);  //
  useEffect(() => {
    const hasSelectedValues =
      watch("detection_method")?.length > 0 ||
      watch("emission_subcategory_id")?.length > 0 ||
      watch("status") ||
      watch("date");

    setClearDisabled(!hasSelectedValues);
    setFilterDisabled(!hasSelectedValues);
  }, [watch("detection_method"), watch("emission_subcategory_id"), watch("status"), watch("date")]);

  const getFiltersData = () => {
    // Retrieve data from localStorage on page load
    const notificationFilters = localStorage.getItem("notificationFilters");
    if (notificationFilters) {
      try {
        const parsedFilters = JSON.parse(notificationFilters);

        // Set default values using setValue
        if (parsedFilters.detection_method) {
          setValue("detection_method", parsedFilters.detection_method);
        }
        if (parsedFilters.emission_subcategory_id) {
          setValue("emission_subcategory_id", parsedFilters.emission_subcategory_id);
        }
        if (parsedFilters.status) {
          setValue("status", parsedFilters.status);
        }
        // Check if the date is valid, if not, set it to null or some default value
        const dateValue = parsedFilters.date;
        if (dateValue) {
          // Ensure that date is a valid dayjs object before setting
          const validDate = dayjs(dateValue);  // Create a dayjs object
          if (validDate.isValid()) {
            setValue("date", validDate.toISOString());  // Set the dayjs object in the form state
          } else {
            setValue("date", "");  // If invalid date, reset to empty string
          }
        }

      } catch (error) {
        console.error("Error parsing notificationFilters:", error);
      }
    }
    filterCB(watch("status"), watch("detection_method"), watch("emission_subcategory_id"), watch("date"));
  };

  const handleClearFilters = () => {
    localStorage.removeItem("siteFilter");
    localStorage.removeItem("notificationFilters");
    reset({ detection_method: [] });
    reset({ emission_subcategory_id: [] });
    reset({ status: null });
    setValue("date", "")
    onSubmit()
  };

  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("status"), watch("detection_method"), watch("emission_subcategory_id"), watch("date"));

    let dateValue = watch("date");
    if (dateValue && dayjs(dateValue).isValid()) {
      dateValue = dayjs(dateValue).toISOString();
    }

    if (watch("detection_method") || watch("emission_subcategory_id") || dateValue || watch("status")) {
      let notificationFilters = {
        "detection_method": watch("detection_method"),
        "emission_subcategory_id": watch("emission_subcategory_id"),
        "status": watch("status"),
        "date": dateValue
      };
      localStorage.setItem("notificationFilters", JSON.stringify(notificationFilters));
    }

    onClose();
  };

  const onDateChange = (date: any) => {
    const validDate = dayjs(date);
    if (validDate.isValid()) {
      setValue("date", validDate.toISOString());  // Set the valid dayjs object
    } else {
      setValue("date", "");  // If invalid, reset to empty string
    }
  }

  return (
    <div>
      <FilterModal
        onClose={onClose}
        open={open}
        title="FILTER BY"
        width="475px"
        clearFilters={handleClearFilters}
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            <MultiSelectField
              selectlabel={label.filterDetectionMethod}
              options={convertArrayToObject(detectionOptions, "label", "value")}
              placeholder={placeholder.filterDetectionMethod}
              name={filterDetectionMethod}
              register={register}
              value={watch("detection_method") || []}
              Asterisk={false}
            />
          </Grid>
          <Grid item md={12}>
            <MultiSelectField
              selectlabel={label.filterEmissionSubCategory}
              options={convertArrayToObject(
                emissionSubCategoryList,
                "name",
                "id"
              )}
              placeholder={placeholder.filterEmissionSubCategory}
              name={filterEmissionSubCategory}
              register={register}
              value={watch("emission_subcategory_id") || []}
              Asterisk={false}
            />
          </Grid>
          <Grid item md={12}>
            <SelectField
              selectlabel={label.filterVerificationStatus}
              options={convertArrayToObject(
                verificationStatus,
                "label",
                "value"
              )}
              placeholder={placeholder.filterVerificationStatus}
              name={name.filterVerificationStatus}
              register={register}
              value={watch(filterVerificationStatus) || ""}
              Asterisk={false}
            />
          </Grid>
          <Grid item md={12}>
            <DatePicker
              selectlabel={label.selectDate}
              name="date"
              defaultPicker={true}
              value={dayjs(watch("date"))}
              onChange={onDateChange}
              Asterisk={false}
              className="filterDatepicker"
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterEquipment;
