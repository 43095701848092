import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { AddSiteSubmitProps } from "../../pages/Site/interface";


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  clusterData: [],
  total: 0
};

const clusterSlice: any = createSlice({
  name: "cluster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    setClusterList: (state, { payload }) => {
      state.clusterData = payload.data;
      state.total = payload?.meta?.itemCount
    }
  },
});

export const {
  clearMasterState,
  setClusterList,
} = clusterSlice.actions;

// Action creators are generated for each case reducer function
export const clusterSelector = (state: any) => state.cluster;

export default clusterSlice.reducer;

export const getClusterData = (params: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getClusterList(params)
      if (res.status === 200) {
        dispatch(setClusterList(res?.data))
      }
    } catch (error) {

    }
  };
}
