import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Divider, Stack } from "@mui/material";
import CommonModal from "../../components/atoms/modal/modal";
import {
  bottomUpArray,
  downloadTemplateTitle,
  droneArray,
  errorMessages,
  fileTemplateNames,
  headers,
  mandatoryField,
  satelliteArray,
  tabs,
  tabsInformation,
} from "./staticData";
import SelectField from "../../components/atoms/SelectField/SelectField";
import {
  SecondaryButton,
  UploadButton,
} from "../../components/atoms/button/button";
import UplaodIcon from "../../components/atoms/icons/uploadIcon";
import { SelectChangeEvent } from "@mui/material";
import CommonTabs from "../../components/atoms/Tabs/commonTabs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../store/hooks";
import { validations } from "./validation";
import { uploadDataAction } from "../../store/slices/uploadData";
import { parseAndValidateCSV } from "../../utils/helper";
import { ErrorMessage } from "../../components/atoms/ErrorMessage/errorMessage";
import { useSelector } from "react-redux";
import { loaderSelector } from "../../store/slices/loaderSlice";
import Loader from "../../components/atoms/Loader";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";

const UploadForm: any = ({
  isFileUploadButton,
  handleClose,
  setStatusModal,
  setUploadMessage,
  setShowErrorMessage,
}: any) => {
  const dispatch = useAppDispatch();
  const { isLoader } = useSelector(loaderSelector)
  const [selectValue, setSelectValue] = useState<string>("");
  const [value, setTabValue] = useState(0);
  const [isError, setIsError] = useState<string | null>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [fileInputValue, setFileInputValue] = useState("");
  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validations),
  });

  const handleDownloadTemplate = useCallback(() => {
    let fileIndex: any = getValues("selectedOption");
    const fileName = fileTemplateNames[fileIndex];
    let fileUrl = require(`../../assets/templates/${fileName}`);
    if (fileName?.includes("bottomUp") || fileName?.includes("drone") || fileName?.includes("satellite")) {
      let data_: any = []
      if (fileName?.includes("bottomUp")) {
        data_ = bottomUpArray
      }
      if (fileName?.includes("drone")) {
        data_ = droneArray
      }
      if (fileName?.includes("satellite")) {
        data_ = satelliteArray
      }

      const csvContent = data_.map((row: any) => row?.join(',')).join('\n');
      // Add BOM to ensure UTF-8 encoding is recognized by various editors and programs
      const bom = '\uFEFF';

      // Write the CSV file with UTF-8 encoding and BOM
      // fs.writeFileSync(filePath, bom + csvContent, { encoding: 'utf8' });

      const blob = new Blob([bom + csvContent], {
        type: 'text/csv;charset=utf-8;',
      });

      fileUrl = URL.createObjectURL(blob);
    }

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSelectValue("");
    setValue("selectedOption", "");
  };

  const handleFileChange = (file: any, event: any) => {
    if (file) {
      setIsError(null);
      parseAndValidateCSV(file, mandatoryField[getValues("selectedOption")])
        .then((data_: any) => {
          if (data_?.data) {
            const payload = {
              file: file,
              activityType: tabsInformation[value]?.labelToSend,
              dataType: getValues("selectedOption"),
            };
            dispatch(
              uploadDataAction(payload, (data: any) => {
                setUploadMessage(data?.message);
                if (data?.error) {
                  handleClose();
                  setStatusModal(true);
                  setShowErrorMessage(true);
                } else if (!data?.error) {
                  handleClose();
                  setStatusModal(true);
                  setShowErrorMessage(false);
                } else {
                  setFileInputValue("");
                  event.target.value = "";
                  setIsError(errorMessages.fileMissmatch);
                }
              })
            );
          } else {
            if (data_?.isEmpty) {
              setIsError(errorMessages.emptySheet);
            } else if (data_?.isDuplicate) {
              setIsError(data_?.message);
            } else {
              setIsError(errorMessages.fileMissmatch);
            }
          }
        })
        .catch((error: any) => {
          console.error("Error reading file:", error);
        });
    }
  };


  const renderBrowseUploadButtons = () => {
    return (
      <>
        <UploadButton
          onChange={handleFileChange}
          startIcon={<UplaodIcon />}
          width="100%"
          accept=".csv"
          buttonText="Browse and Upload"
          title="Browse and Upload"
          disabled={disabledButton}
          value={fileInputValue}
        />
      </>
    )
  }

  const downloadTemplate = useMemo(
    () => (
      <SecondaryButton
        label={downloadTemplateTitle}
        variant="contained"
        onClick={handleDownloadTemplate}
        width="100%"
        height="48px"
        disabled={disabledButton}
      />
    ),
    [handleDownloadTemplate, disabledButton]
  );

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectValue(event.target.value as string);
  };

  useEffect(() => {
    if (!!watch("selectedOption")) {
      setDisabledButton(false);
      setIsError(null);
    } else {
      setDisabledButton(true);
    }
  }, [watch("selectedOption")]);

  const detectionData = useCallback(() => {
    return (
      <Stack direction="column" spacing={2.5}>
        <Box>
          <p className="tabBodyTitle">
            {tabsInformation[value]?.dropDownLabel}
          </p>
          <SelectField
            options={tabsInformation[value]?.dropDownOptions}
            placeholder={tabsInformation[value]?.placeHolder}
            name="selectedOption"
            register={register}
          />
        </Box>
        <Stack direction="column" spacing={"25px"}>
          {renderBrowseUploadButtons()}
          {isError && <ErrorMessage message={isError || ""} />}
          <Divider />
          {downloadTemplate}
        </Stack>
      </Stack>
    );
  }, [value, selectValue, isError, disabledButton]);

  return (
    <>
      <CommonModal
        open={isFileUploadButton}
        onClose={handleClose}
        title={headers.pageTitle}
        minWidth="417px"
      >
        <CommonTabs
          tabs={tabs}
          handleSelectChange={handleSelectChange}
          detectionData={detectionData}
          handleChange={handleChange}
          value={value}
        />
      </CommonModal>
    </>
  );
};

export default UploadForm;
