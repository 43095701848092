import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from "@mui/material";

import VolumeOfCH4 from '../../components/atoms/VolumeBox/VolumeOfCH4';
import { PrimaryButton } from '../../components/atoms/button/button';
import styled from '@emotion/styled';
import ArrowDown from '../../components/atoms/icons/arrowDown';

interface EquipmentAccordionProps {
  equipmentId: string;
  totalEmissionVolume: string;
  showHeading: boolean;
  emissionFactor: number;
  lengthOfSurfacePipe: string;
  numberOfHours: number;
  renderFotter?: () => React.ReactNode;
  valueMissing?: boolean;
  volumeFooter?: boolean;
}



const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: "1px solid #0000001A",
  borderRadius: "10px !important",   
  boxShadow: "0px 4px 4px 0px #00000012",
  padding: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
    border: "1px solid #64A70B"
  }
  
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: "0 10px",
  '& .Item': {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.52px",
    color: "#00000099"
  },
  '& .value': {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",    

  },
  
  '& .MuiAccordionSummary-expandIconWrapper': {
    
  },
  '& .MuiAccordionSummary-content': {
    
    '&.Mui-expanded': {
      margin: 0,
    }
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  "& .volumeBox": {
    border: "none",
    padding: "0 0 20px",
    boxShadow: "0px 4px 4px 0px #00000012",
    "& .volumeHeder": {
      padding: "15px 10px",
    },
    "& .volumeBody": {
      padding: "15px 10px 0",
    }
  },
  
}));

const EquipmentAccordion: React.FC<EquipmentAccordionProps> = ({
  equipmentId,
  totalEmissionVolume,
  showHeading,
  emissionFactor,
  lengthOfSurfacePipe,
  numberOfHours,
  renderFotter,
  valueMissing,
  volumeFooter,
}) => {
  return (
   
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ArrowDown sx={{ fontSize: "14px"}}/>}
        aria-controls={`${equipmentId}-content`}
        id={`${equipmentId}-header`}
      >
        <Stack direction="row" gap="34px">
          <Stack direction="column" gap="4px">
            <p className='Item'>Equipment ID</p>
            <p className='value'>{equipmentId}</p>
          </Stack>
          <Stack direction="column" gap="4px">
            <p className='Item' style={{fontWeight: 500, color: "#00438B"}}>Total Emission Volume</p>
            <p className='value' style={{fontWeight:  valueMissing ? 400 : 600, color: valueMissing ? "#E4002B" : "#64A70B"}}>{totalEmissionVolume}</p>
          </Stack>
        </Stack>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <VolumeOfCH4
          showHeading={showHeading}
          emissionFactor={emissionFactor}
          lengthOfSurfacePipe={lengthOfSurfacePipe}
          numberOfHours={numberOfHours}
          renderFotter={renderFotter}
          valueMissing={valueMissing}
          volumeFooter= {volumeFooter}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
   
  );
};

export default EquipmentAccordion;
