import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../../api/services/authService";
import MasterService from "../../api/services/masterService";
import { setError, setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";
import dayjs, { Dayjs } from 'dayjs';
// import { setLoderVisibility } from "./loaderSlice";


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
interface DashboardProps {
  trendLineData: null;
  assetEmission: null;
  ogmpDataList: [];
  ogmpMidDataList: [];
  dashboardMapData: [];
  abatementChartData: [];
  initiativeList: [];
  waterfallChartData: null;
  socarTotalData: null;
  selectedMonthsList: Dayjs[];
  heatmapToggleStatus: boolean;
}


export const initialState: DashboardProps = {
  trendLineData: null,
  assetEmission: null,
  ogmpDataList: [],
  ogmpMidDataList: [],
  dashboardMapData: [],
  abatementChartData: [],
  initiativeList: [],
  waterfallChartData: null,
  socarTotalData: null,
  selectedMonthsList: [],
  heatmapToggleStatus: false
};

const dashboardSlice: any = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearTendLineDataState: () => initialState,
    setTendLineData: (state, { payload }) => {
      state.trendLineData = payload;
    },
    setAssetEmission: (state, { payload }) => {
      state.assetEmission = payload
    },
    setOgmpData: (state, { payload }) => {
      state.ogmpDataList = payload;
    },
    setOgmpMidData: (state, { payload }) => {
      state.ogmpMidDataList = payload;
    },
    setDashboardMapData: (state, { payload }) => {
      state.dashboardMapData = payload;
    },
    setAbatementChartData: (state, { payload }) => {
      state.abatementChartData = payload;
    },
    setInitiativeList: (state, { payload }) => {
      state.initiativeList = payload.data;
    },
    setWaterfallChartData: (state, { payload }) => {
      state.waterfallChartData = payload;
    },
    setSocarTotal: (state, { payload }) => {
      state.socarTotalData = payload;
    },
    setSelectedMonths: (state, { payload }) => {
      state.selectedMonthsList = payload;
    },
    setHeatMapToggleStatus: (state, { payload }) => {
      state.heatmapToggleStatus = !state.heatmapToggleStatus;
    },
  },
});

export const {
  setAssetEmission,
  clearTendLineDataState,
  setTendLineData,
  setOgmpData,
  setAbatementChartData,
  setOgmpMidData,
  setDashboardMapData,
  setInitiativeList,
  setWaterfallChartData,
  setSocarTotal,
  setSelectedMonths,
  setHeatMapToggleStatus
} = dashboardSlice.actions;

// Action creators are generated for each case reducer function
export const tendLineSeletor = (state: any) => state.dashboard;
export const dashboardSelector = (state: any) => state.dashboard;

export default dashboardSlice.reducer;

export const getTendLineData = (params: string, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }
    try {
      const res = await MasterService.getTotalEmission(params);
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setTendLineData(res.data));
        // callBack(true)
      } else {
        // callBack(false)
      }
    } catch (error) {
      if (loaderShow) {
        dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      }
      //   callBack(false)
    }

  };
}

export const getOGMPReport = (params: string) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "OGMPReport" }))
    try {
      const res = await MasterService.getOgmpReport(params);
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "OGMPReport" }))
        if (res?.data?.length) {
          dispatch(setOgmpData(res?.data))
        } else {
          dispatch(setError({ message: "Data not available for selected date range." }))
        }
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "OGMPReport" }))
      dispatch(setErrorMessage(error))
    }

  };
}

export const getOGMPMidStreamReport = (params: string, callBack?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "OGMPReport" }))
    try {
      const res = await MasterService.getOgmpMidReport(params);
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "OGMPReport" }))
        if (res?.data?.length) {
          dispatch(setOgmpMidData(res?.data))
          callBack(res?.data)
        } else {
          dispatch(setError({ message: "Data not available for selected date range." }))
        }
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "OGMPReport" }))
      dispatch(setErrorMessage(error))
    }

  };
}

export const getAssetEmission = (params: any, loaderShow?: boolean) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: loaderShow, component: "dashboard" }))
    dispatch(setAssetEmission([]));
    try {
      const res = await MasterService.getAssetEmission(params);
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        dispatch(setAssetEmission(res.data));

        // callBack(true)
      } else {
        // callBack(false)
      }

    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      //   callBack(false)
    }

  };
}

export const getDashboardMapDetails = (params: string, loaderShow = true) => {

  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }

    try {
      console.log('-------------------------------emissionDetHeatmapParams---------------------------------');
      
      const res = await MasterService.getDashboardMapDetails(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setDashboardMapData(res.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      dispatch(setErrorMessage(error))
    }
  };

}

export const getAbatementChartData = (params: string, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }
    try {
      const res = await MasterService.getAbatementChartData(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setAbatementChartData(res.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      dispatch(setErrorMessage(error))
    }
  };

}

export const getInitiativeData = (params: string, loaderShow = true) => {

  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }
    try {
      const res = await MasterService.getInitiativeList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setInitiativeList(res.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      dispatch(setErrorMessage(error))
    }
  };
}

export const getWaterfallChartData = (params: string, loaderShow = true) => {

  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }
    try {
      const res = await MasterService.getWaterfallChartData(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setWaterfallChartData(res.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      dispatch(setErrorMessage(error))
    }
  };
}

export const getSocarTotalData = (params: any, loaderShow?: boolean) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "dashboard" }))
    }

    try {
      const res = await MasterService.getSocarTotals(params);

      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
        }
        dispatch(setSocarTotal(res.data))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "dashboard" }))
      dispatch(setErrorMessage(error))
    }
  }
}
