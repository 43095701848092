import AuthDictionary from "./AuthDictionary";
import { MasterDictionary } from "./MasterDictionary";
import UploadDataDictionary from "./UploadDataDictionary";

const Dictionary = {
  auth: AuthDictionary,
  master: MasterDictionary,
  uploadData: UploadDataDictionary
  // dashboard: DashboardDictionary,
};

export default Dictionary;
