import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import DownloadIcon from "../../components/atoms/icons/downloadIcon";
import EditIcon from "../../components/atoms/icons/editIcon";
import { roundOffNumber } from "../../utils/helper";
import Hierachy from "../../components/atoms/icons/heirarchy";

interface Payload {
  handleIconButtonClick: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    this.column = [
      {
        id: "id",
        label: "Component ID",
        sortable: false,
        width: "15%",
        render: (row: Row) => row?.componentId,
      },
      // {
      //   id: "type",
      //   label: "Component Type",
      //   sortable: false,
      //   width: "20%",
      //   render: (row: Row) => row?.componentType?.componentType,
      // },
      {
        id: "equipment",
        label: "Equipment ID",
        sortable: false,
        width: "20%",
        render: (row: Row) => row?.equipmentId,
      },
      {
        id: "cluster",
        label: "Cluster",
        sortable: false,
        width: "15%",
        render: (row: Row) => row?.equipmentCluster,
      },
      {
        id: "timeToAddress",
        label: "Time to Address Leak (Days)",
        sortable: false,
        width: "20%",
        render: (row: Row) => roundOffNumber(row?.timeToAddressLeakDays),
      },
      {
        id: "edit",
        label: "Edit",
        sortable: false,
        width: "10%",
        align: "center",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box
              className="downloadIcon flex-center"
              onClick={() => payload.handleIconButtonClick(row)}
            >
              <EditIcon />
            </Box>
          </Box>
        ),
      },
    ];
  }
}

export default TableColumn;
