import React from "react";
import {
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import { StatusModalProps } from "./interface";
import { PrimaryButton } from "../button/button";
import ErrorModalIcon from "../icons/errorModalIcon";
import { CustomDialog, CustomDialogActions, Wrapper } from "./style";
import successModalIcon from "../icons/successModalIcon copy";


const StatusModal: React.FC<StatusModalProps> = ({
  open,
  onClose,
  type,
  bodyText,
  buttonText = 'Confirm',
  width,

}) => {

  const IconComponent = type === 'success' ? successModalIcon : ErrorModalIcon;
  return (
    <CustomDialog open={open} onClose={(event, reason) => {
      if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
        onClose(); // Close without arguments
      }
    }}
      width={width}>
      <Wrapper>
        <DialogContent>
          <Box className={`statusModalIcon flex-center ${type}`}>
            <IconComponent />
          </Box>
          <Typography>{bodyText}</Typography>
        </DialogContent>
        <CustomDialogActions>
          <PrimaryButton
            label={buttonText}
            onClick={onClose}
            width="100%"
            height="48px"
          />
        </CustomDialogActions>
      </Wrapper>
    </CustomDialog>
  );
}
export default StatusModal;
