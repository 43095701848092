

export const tableCellKeys = [
  "assetName",
  "assetId",
  "assetType",
  "assetDescription",
  "assetCountry",
  "assetLatitude",
  "assetLongitude",
  "assetOperated",
  "assetOperator",
  "asseteQuityPercentage",
  "assetEquityType",
  "Stationary combustion",
  "Flaring (Incomplete Combustion)",
  "Fugitive Component and Equipment Leaks",
  "Venting",
  "Other Sources",
  "totalEmisson",
  "uncertainty",
  "comments",
  "commentsZeroEmissions",
  "commentsOnVariation",
  "Level 5 - Total emissions",
  "investments"
]

export const ventingKeyOrder = [
  'Venting: Pneumatics',
  'Venting Centrifugal Compressor seal emission',
  'Venting Reciprocating compressor emissions',
  'Venting: Monoethylen glychol emissions',
  'Venting: Unstabilized liquid tanks',
  'Venting: Liquids unloading',
  'Venting: Well casinghead',
  'Hydraulic fracture completions',
  'Venting - Other'
]

export const categoryOrder = [
  "totalMethaneEmission",
  "assetReportingLevel",
  "uncertainty",
  "L4Method"
]

export const othersKeyOrder = [

]
export const orderArray = [
  "assetname",
  "assetid",
  "assettype",
  "assetdescription",
  "assetcountry",
  "assetlatitude",
  "assetlongitude",
  "assetoperated",
  "assetoperator",
  "assetequitypercentage",
  "assetequitytype",
  "Stationary combustion",
  "Flaring (Incomplete Combustion)",
  "Fugitive Component and Equipment Leaks",
  "Venting",
  "Other Sources",
  "totalEmisson",
  "Uncertainty",
  "Comments",
  "Comments if reported zero emissions for one or more sources",
  "Level 5 - Total emissions",
  "Investments/divestments of facilities in the asset (Yes/No)"
]

export const ventingOrder = [
  "Natural gas driven pneumatic equipment",
  "Centrifugal compressor shaft seals",
  "Reciprocating compressor rod packing",
  "Glycol dehydrators",
  "Tanks",
  "Well liquids unloading",
  "Well casinghead venting",
  "Hydraulic fracture completions",
  "Venting - Other"
]

export const firstLevel = [
  "Stationary combustion",
  "Flaring (Incomplete Combustion)",
  "Fugitive Component and Equipment Leaks",
  "Other Sources",
  "Level 5 - Total emissions",
]