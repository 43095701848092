import styled from "@emotion/styled";
import { Dialog, DialogActions, IconButton } from "@mui/material";

export const Wrapper = styled.div({ 
  padding: "24px",
});

export const CustomDialog = styled(Dialog)<{ width?: string }>(
  ({ width }) => ({
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      maxWidth: width || "auto",
      minWidth: "20.125rem",
      padding: "0",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "none",
      padding: "0",
      marginBottom: "20px",
      "& .statusModalIcon": {
        width: "48.75px",
        height: "48.75px",
        borderRadius: "50%",  
        marginTop: "6px",       
        marginBottom: "18px",  
              
      }, 
      /*
      "& .success": {
          backgroundColor: "rgba(196, 236, 140, 0.3)",
        },
        "& .error": {
          backgroundColor: "rgba(255, 235, 239, 0.3)",
        },
        */
      "& .MuiTypography-root": {        
        fontWeight: 500,
        lineHeight: "25.6px",
        textAlign: "center",
        color: "#29292A"
      },
    },
   
  })
);

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "30px",
  top: "22px",
}));

export const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: "center",
  padding: 0,
}));


