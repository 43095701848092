import React from 'react'
import CommonModal from '../../components/atoms/modal/modal'
import { Box } from '@mui/material'
import { forgetPasswordConstants } from './constant'


interface ForgetPasswordProps {
  open: boolean,
  handleClose: any
}

export default function ForgetPassword({
  open,
  handleClose,
}: ForgetPasswordProps) {
  return (
    <CommonModal open={open} onClose={handleClose} title="Forgot Password" width="417px">
      <Box sx={{ textAlign: "center", fontWeight: 500}}>
        {forgetPasswordConstants?.modalText}
      </Box>
    </CommonModal>
  )
}
