// @ts-nocheck
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { AddAssetProps, AddAssetSubmitProps } from "./interface";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { AssetConstants } from "./constants";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/atoms/InputField/InputField";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { PrimaryButton } from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import {
  addAsset,
  assetMasterSelector,
  editAsset,
  getAssetTypes,
  getCountries,
  getEquityTypes,
  getOperators,
} from "../../store/slices/assetMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validations";
import ArrowRight from "../../components/atoms/icons/arrowRight";
import TextArea from "../../components/atoms/textarea/textarea";
import { FieldArray } from "./addAssetFIeld";
import CustomLabel from "../../components/atoms/label/label";
import { roundOffNumber } from "../../utils/helper";

const AddAsset = React.memo(
  ({ pageTitle, onBack, editAssetData }: AddAssetProps) => {
    const dispatch: any = useAppDispatch();
    const { assetTypes, equityTypes, countries, operators } =
      useSelector(assetMasterSelector);

    const { label, placeholder, button, name } = AssetConstants;
    const { isLoader } = useSelector(loaderSelector);
    // Initialize react-hook-form
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
    } = useForm({
      defaultValues: {
        country: "",
        assetType: "",
        operator: "",
        equityType: "",
        operated: "",
        reportingLevel: "",
      },
      resolver: yupResolver(validations),
    });

    const [isEdit, setEdit] = useState(editAssetData ? true : false);

    useEffect(() => {
      // Fetch asset types, countries, operators, and equity types
      Promise.all([
        dispatch(getAssetTypes()),
        dispatch(getCountries()),
        dispatch(getOperators()),
        dispatch(getEquityTypes()),
      ]);
    }, []);

    useEffect(() => {
      setEditData();
    }, [assetTypes, equityTypes, countries, operators]);

    const setEditData = () => {
      if (editAssetData) {
        const editData = { ...editAssetData };
        const assetTypeId = assetTypes.filter(
          (val) => val.assetType === editData.assetType.assetType
        );
        const countryId = countries.filter(
          (val) => val.name === editData.country.name
        );
        const equityTypeId = equityTypes.filter(
          (val) => val.equityType === editData.equityType.name
        );
        const operatorId = operators.filter(
          (val) => val.name === editData.operator.name
        );
        for (var key in editData) {
          if (name[key] || key === "equityPercentage") {
            if (key === "assetType" && assetTypeId.length) {
              setValue(name[key], assetTypeId[0].id);
            } else if (key === "country" && countryId.length) {
              setValue(name[key], countryId[0].id);
            } else if (key === "equityType" && equityTypeId.length) {
              setValue(name[key], equityTypeId[0].id);
            } else if (key === "operator" && operatorId.length) {
              setValue(name[key], operatorId[0].id);
            } else if (key === "equityPercentage") {
              setValue(
                name[key],
                roundOffNumber((editData[key] * 100).toPrecision(3))
              );
            } else {
              setValue(name[key], editData[key]);
            }
          }
        }
      }
    };

    const onSubmit: SubmitHandler<any> = (payload: AddAssetSubmitProps) => {
      const reqPayload = { ...payload };
      reqPayload.latitude = parseFloat(payload.latitude);
      reqPayload.longitude = parseFloat(payload.longitude);
      reqPayload.equityPercentage = parseFloat(payload.equityPercentage / 100);
      reqPayload.country = parseFloat(payload.country);
      reqPayload.assetType = parseFloat(payload.assetType);
      reqPayload.operator = parseFloat(payload.operator);
      reqPayload.equityType = parseFloat(payload.equityType);
      reqPayload.assetDescription = payload.assetDescription.trim();
      reqPayload.assetId = payload.assetId.trim();
      if (editAssetData) {
        dispatch(editAsset(reqPayload, reqPayload.assetId, successCB));
      } else {
        dispatch(addAsset(reqPayload, successCB));
      }
    };

    const successCB = (alert: boolean) => {
      onBack(false, alert || false, editAssetData?.assetId);
    };

    const renderHeaderButtons = () => (
      <Stack direction="row" spacing={2}>
        <PrimaryButton
          label={button.submit}
          variant="contained"
          type={"submit"}
          width="128px"
        />
      </Stack>
    );

    const { fieldArray } = new FieldArray({
      operators,
      countries,
      equityTypes,
      assetTypes,
      isEdit,
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoader?.isShow && isLoader.component === "asset" && (
          <CircularLoader />
        )}
        <DefaultLayout
          pageTitle={pageTitle}
          // renderHeaderButtons={renderHeaderButtons}
          backHandler={() => onBack(false)}
          activeTab={"Assets"}
        >
          <Grid container spacing={"30px"} sx={{ p: "10px 0 10px" }}>
            {fieldArray.map((field: any) => (
              <>
                {field.type === "input" && (
                  <Grid item xs={6} md={4}>
                    <InputField
                      label={label[field.fieldName]}
                      type={field?.inputType || "text"}
                      disabled={field.disabled}
                      name={name[field.fieldName]}
                      register={register}
                      placeholder={placeholder[field.fieldName]}
                      errorText={errors[field.fieldName]?.message}
                    />
                  </Grid>
                )}
                {field.type === "dropdown" && (
                  <Grid item xs={6} md={4}>
                    <SelectField
                      selectlabel={label[field.fieldName]}
                      placeholder={placeholder[field.fieldName]}
                      options={field?.options}
                      name={name[field.fieldName]}
                      register={register}
                      value={watch(name[field.fieldName])}
                      errorText={errors[field.fieldName]?.message}
                    />
                  </Grid>
                )}

                {field.type === "textArea" && (
                  <Grid item xs={12} md={12}>
                    <Divider sx={{ borderColor: "#0000001A", mb: "30px" }} />
                    <TextArea
                      label={label[field.fieldName]}
                      type="text"
                      name={name[field.fieldName]}
                      minRows={5}
                      register={register}
                      placeholder={placeholder[field.fieldName]}
                      errorText={errors[field.fieldName]?.message}
                      maxLength={250}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Stack direction="row" spacing={2} sx={{ marginTop: '10px' }}>
            <PrimaryButton
              label={button.submit}
              variant="contained"
              type={"submit"}
              width="95px"
            />
          </Stack>
        </DefaultLayout>
      </form>
    );
  }
);

export default AddAsset;
