import React from "react";
export default function TableArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<g clip-path="url(#clip0_2116_3779)">
<path d="M1.50016 9.74957L14.6897 9.74957L10.7199 13.7193C10.6483 13.7885 10.5911 13.8713 10.5518 13.9628C10.5125 14.0543 10.4918 14.1527 10.491 14.2523C10.4901 14.3519 10.5091 14.4506 10.5468 14.5428C10.5845 14.635 10.6402 14.7187 10.7106 14.7891C10.781 14.8595 10.8648 14.9152 10.9569 14.9529C11.0491 14.9907 11.1479 15.0096 11.2475 15.0088C11.347 15.0079 11.4455 14.9872 11.537 14.9479C11.6285 14.9086 11.7112 14.8515 11.7804 14.7798L17.0304 9.52982C17.171 9.38918 17.25 9.19844 17.25 8.99957C17.25 8.8007 17.171 8.60997 17.0304 8.46932L11.7804 3.21932C11.639 3.0827 11.4495 3.00711 11.2529 3.00881C11.0562 3.01052 10.8681 3.0894 10.729 3.22846C10.59 3.36751 10.5111 3.55562 10.5094 3.75227C10.5077 3.94892 10.5833 4.13837 10.7199 4.27982L14.6897 8.24957L1.50016 8.24957C1.30125 8.24957 1.11048 8.32859 0.969831 8.46924C0.829177 8.6099 0.750162 8.80066 0.750162 8.99957C0.750162 9.19849 0.829177 9.38925 0.969831 9.5299C1.11048 9.67056 1.30125 9.74957 1.50016 9.74957Z" fill="black" fill-opacity="0.5"/>
</g>
<defs>
<clipPath id="clip0_2116_3779">
<rect width="18" height="18" fill="white" transform="translate(18 18) rotate(180)"/>
</clipPath>
</defs>
</svg>
  );
}
