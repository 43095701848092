import styled from "@emotion/styled";
import { Alert, Snackbar } from "@mui/material";


export const StyledSnackbar = styled(Snackbar)(() => ({
  bottom: "76px !important",  
}));


export const StyledAlert = styled(Alert)<{severity: string}>(({ severity }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "24px",
  backgroundColor: severity === "error" ? "#E4002B" : severity === "warning" ? "#E1822A" : severity === "info" ? "#323232" : "#64A70B",
  borderRadius: "10px",
  boxShadow: "0px 10px 15px 0px #00000040",  
  padding: "15px 20px",
  minWidth: "13.438rem",
  maxWidth: "33.125rem",
  "& .MuiAlert-message": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    color: "#FFFFFF",
    padding: 0,    
  },
  "& .MuiAlert-icon": {
    display: "none",
  },
"& .MuiAlert-action": {
    padding: 0,
    margin:0,
    marginTop: "2px",
   
    "& .MuiIconButton-root": {
      padding: 0,
      width: "unset !important;"
    }
}
}));