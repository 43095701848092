import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { AddSiteSubmitProps } from "../../pages/Site/interface";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";
import { convertDataToGroup } from "../../utils/helper";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  siteMasterData: [],
  siteMasterGroupData: [],
  siteTypeList: [],
  addSiteVisibility: false,
  total: 0

};

const siteMasterSlice: any = createSlice({
  name: "siteMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    addSiteHandler: (state, { payload }) => {
      state.addSiteVisibility = payload;
    },

    setSiteList: (state, { payload }) => {
      state.siteMasterData = payload.data;
      state.total = payload?.meta?.itemCount
    },
    setSiteDataByGroup: (state, { payload }) => {
      console.log("payload length", payload)
      state.siteMasterGroupData = payload?.length ? convertDataToGroup(payload, "sites", "siteType") : [];
    },
    setSiteTypeList: (state, { payload }) => {
      state.siteTypeList = payload;
    }


  },
});

export const {
  clearMasterState,
  addSiteHandler,
  setSiteList,
  setSiteTypeList,
  setSiteDataByGroup
} = siteMasterSlice.actions;

// Action creators are generated for each case reducer function
export const siteMasterSelector = (state: any) => state.siteMaster;

export default siteMasterSlice.reducer;

export const getSiteData = (params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "siteList" }));
    }
    try {
      const res = await MasterService.getSiteList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        }
        dispatch(setSiteList(res.data))
        if (callBack) {
          callBack("site")
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getGroupSiteData = (id: string, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setSiteDataByGroup([]))
      dispatch(setLoderVisibility({ isShow: true, component: "siteList" }));
    }
    try {
      const res = await MasterService.getGroupSiteData(id)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        }
        dispatch(setSiteDataByGroup(res.data))
        if (callBack) {
          callBack("site")
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}


export const addSite = (payload: AddSiteSubmitProps, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "site" }))
    try {
      const res = await MasterService.addSite(payload)
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "site" }))
        successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "site" }))
        dispatch(setErrorMessage(error))
      }
    }

  };

}


export const editSite = (payload: AddSiteSubmitProps, id: string | number, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "site" }))
    try {
      const res = await MasterService.editSite(payload, id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "site" }))
        successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "site" }))
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getSiteTypeList = (params: string) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "siteList" }))
    try {
      const res = await MasterService.getSiteTypeList(params)
      if (res.status === 200) {
        dispatch(setSiteTypeList(res.data.data))
        dispatch(setLoderVisibility({ isShow: false, component: "siteList" }))
      }

    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "siteList" }))

    }
  };
}