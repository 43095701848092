import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import {
  isNumberRequired,
  isRequired,
  isRequiredAlph,
  decimalPrecisionRequired,
} from "../../utils/validationHelper";

export const validations = yup.object().shape({
  componentType: isRequired,
  componentId: isRequiredAlph(2, 50),
  equipmentId: isRequired,
  equipmentCluster: isRequired,
  timeToAddressLeakDays: decimalPrecisionRequired(3, 1),
});

const decimalPrecision = (value: any, precision: any) => {
  if (typeof value === "string") {
    if (/[eE]/.test(value)) {
      return false;
    }
    const regex = new RegExp(`^-?\\d*(\\.\\d{0,${precision}})?$`);
    // @ts-ignore
    return regex.test(value);
  }
  return false;
};
