import React, { useState } from "react";
import DefaultLayout from "../../components/layout";
import "./EmissionDetails.scss";
import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { PrimaryButton } from "../../components/atoms/button/button";
import { StatusChip } from "../../components/atoms/chip/chip";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { useForm } from "react-hook-form";
import ChangeLog from "./ChangeLog/ChangeLog";
import VolumeOfCH4 from "../../components/atoms/VolumeBox/VolumeOfCH4";
import MultipleEquipment from "./MultipleEquipment";
import EquipmentAccordion from "./MultipleEquipment";


const MultiEquipmentDetails = () => {

  const {
    register,
    formState: { errors },
  } = useForm(); 
  const [select1Value, setSelect1Value] = useState<string>("");   

  const renderHeaderButtons = () => (
   
    <Stack direction="row" spacing={1.5} className="flex-alignCenter headerBtn-group">
      <Stack direction="row" alignItems="center" spacing={2.5} className="btnGroup">
       <Typography variant="body1" className="verification-status">Verification Status</Typography>
       <StatusChip 
        label="Verified" 
        type="Verified" 
        className="statusChip"
        width={"85px"}
        />
      </Stack>
      <PrimaryButton
        label="Verify Source"
        variant="contained"
        width={"181px"}   
        height={"45px"}         
      />
    </Stack>
  );

  const renderFotter = () => (
    <PrimaryButton
    label="Add Missing Value"
    variant="contained"
    width={"100%"}          
  />
  );

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelect1Value(event.target.value as string);
  };

  const options = [
    { value: "open", label: "Open" },
    { value: "close", label: "Close" },
  ];

  return (
    <DefaultLayout pageTitle="Emission Details" renderHeaderButtons={renderHeaderButtons}>
    <Box className="EmissionDetails-Main">
       
      <Box className="header spacebetween alignItems">
          <h3>Detection ID<span className="bold">0009331</span></h3>
          <Box className="status-Dropdown">
                <SelectField
                  options={options}
                  placeholder="Select Status"
                  name="test"
                  register={register}
                  onChange={handleSelectChange}
                  selectText={"LDAR Status"}
                  defaultValue={options[0].value}
                
                />
              </Box>
      </Box>

      <Divider sx={{ borderColor: "#0000001A" }} />
    

      <Box sx={{display: "flex", gap: "30px"}}>
      {/* left Box  */}
      <Box sx={{width: "70%", mt: "30px"}}>
      <Box className="GaryBox">   
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Detection Method</p>
                    <p className="asssetValue">Satellite (Indirect)</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Source Hierarchy</p>
                    <p className="asssetValue">Asset</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Source Location</p>
                    <p className="asssetValue">Narimanov</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Emission Rate</p>
                    <p className="asssetValue">120 kg/h</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Detection Time</p>
                    <p className="asssetValue">11/05/2024 | 10:49:20</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Source Hierarchy</p>
                    <p className="asssetValue">Asset</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Source Location</p>
                    <p className="asssetValue">Narimanov</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Emission Rate</p>
                    <p className="asssetValue">120 kg/h</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Detection Time</p>
                    <p className="asssetValue">11/05/2024 | 10:49:20</p>
                  </Stack>               
              
      </Box>     

      <Typography variant="h4" sx={{mt: "25px"}}>Source Details</Typography>
      <Box className="sourceDetails">              
              {/* Asset Details */}
                <Box className="Details">
                <Typography variant="h5">Asset Details</Typography>
                <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Asset ID</p>
                    <p className="asssetValue">1110</p>
                  </Stack>
                  
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Asset Name</p>
                    <p className="asssetValue">Narimanov</p>
                  </Stack>
                </Stack>
                </Box>

                {/* Site Details */}
                <Box className="Details">
                <Typography variant="h5">Site Details</Typography>
                <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Site ID</p>
                    <p className="asssetValue">53718</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Site Name</p>
                    <p className="asssetValue">Platform 1778</p>
                  </Stack>
                </Stack>
                </Box>

                {/* Equipment Details */}
                <Box className="Details">
                <Typography variant="h5">Equipment Details</Typography>
                <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Equipment ID</p>
                    <p className="asssetValue">42465</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Equipment Type</p>
                    <p className="asssetValue">Turbine</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Latitude</p>
                    <p className="asssetValue">42.5000</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Longitude</p>
                    <p className="asssetValue">50.5000</p>
                  </Stack>
                  
                </Stack>
                </Box>


                 {/* Component Details */}
                 <Box className="Details">
                <Typography variant="h5">Component Details</Typography>
                <Stack direction="row" flexWrap={"wrap"} rowGap={"15px"} columnGap={"30px"}>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Component ID</p>
                    <p className="asssetValue">42465</p>
                  </Stack>
                  <Stack direction="row" spacing={"10px"}>
                    <p className="assetLable">Component Type</p>
                    <p className="asssetValue">Valve</p>
                  </Stack>                
                  
                </Stack>
                </Box>
      
      </Box>

      <Divider sx={{ borderColor: "#0000001A" }} />

      {/* Chnage Log component*/}
     <ChangeLog />

      </Box>
      <Divider sx={{ borderColor: "#0000001A",}} orientation="vertical" flexItem/>

       {/* Right Box  */}

      <Box sx={{width: "30%", mt: "30px"}}>  
      <Box className="accordianBox">
      <Typography variant="h6" sx={{ paddingBottom: "15px", textAlign: "center" }}>VOLUME OF CH₄</Typography>
      <Stack direction="column" gap="20px">
      <EquipmentAccordion
        equipmentId="424657"
        totalEmissionVolume="6 MT CH4"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        volumeFooter= {false}
      />
      <EquipmentAccordion
        equipmentId="568219"
        totalEmissionVolume="Value Missing"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        renderFotter={renderFotter}
        valueMissing={true}
      />
      <EquipmentAccordion
        equipmentId="543262"
        totalEmissionVolume="7 MT CH4"
        showHeading={false}
        emissionFactor={3.643}
        lengthOfSurfacePipe="44"
        numberOfHours={56}
        volumeFooter= {false}
      />
    </Stack>
    </Box>
    </Box>  
    </Box> 
    </Box>        
       
    </DefaultLayout>
  );
};

export default MultiEquipmentDetails;
