export const EquipmentConstants = {
  label: {
    filterSite: "Select Site",
    filterDetectionMethod: "Detection Method",
    filterVerificationStatus: "Verification Status",
    filterEmissionSubCategory: "Emission sub-category",
    selectDate: "Detection Date"
  },
  headers: {
    modalTile: "Verify Emission",
  },
  placeholder: {
    equipmentId: "Enter Equipment ID",
    equipmentType: "Select Equipment type",
    equipmentSubtype: "Select Equipment Subtype",
    equipmentModel: "Select Equipment Model",
    siteId: "Select Site ID",
    filterSite: "Select Site",
    equipmentCluster: "Select Equipment Cluster",
    filterDetectionMethod: "Select Detection Method",
    filterVerificationStatus: "Select Verification Status",
    filterEmissionSubCategory: "Select Emission sub-category"
  },
  button: {
    submit: "Submit",
    addEquipment: "Add Equipment",
    ediEquipment: "Edit Equipment"
  },
  text: {
    addEquipmentAlert: "Equipment added successfully.",
    editEquipmentAlert: "Equipment updated successfully.",
    searchPlaceHolder: "Search Detection ID..."
  },
  name: {
    equipmentId: "equipmentId",
    equipmentType: "equipmentType",
    equipmentSubtype: "equipmentSubtype",
    equipmentCluster: "equipmentCluster",
    equipmentModel: "equipmentModel",
    siteId: "siteId",
    filterDetectionMethod: "detection_method",
    filterVerificationStatus: "status",
    filterEmissionSubCategory: "emission_subcategory_id"
  },
  columnName: {
    detectionId: "Detection ID",
    detectionMethod: "Detection Method",
    sourceHierarchy: "Default Source Hierarchy",
    sourceName: "Default Source ID",
    emissionRate: "Emission Rate (kg/hr)",
    emissionSubCategory: "Emission Sub-Category",
    detectionDateTime: "Detection Date & Time",
  },
  detectionOptions: [
    { value: "Satellite", label: "Satellite" },
    { value: "Drone", label: "Drone" },
    { value: "Bottom-Up", label: "Bottom-up" },
    { value: "Manual", label: "Manual Input" },
  ],
  verificationStatus: [
    { value: "verified", label: "Verified" },
    { value: "not-verified", label: "Not Verified" }
  ]
}