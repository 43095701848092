// @ts-nocheck
import React, { useEffect } from "react";
import DefaultLayout from "../../components/layout";
import { AddComponentProps, AddComponentSubmitProps } from "./interface";
import { Grid, Stack } from "@mui/material";
import { ComponentConstants, SiteConstants } from "./constants";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/atoms/InputField/InputField";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { PrimaryButton } from "../../components/atoms/button/button";
import ForwordArrowIcon from "../../components/atoms/icons/forwordArrowIcon";
import { useAppDispatch } from "../../store/hooks";
import { convertArrayToObject, roundOffNumber } from "../../utils/helper";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validations";
import ArrowRight from "../../components/atoms/icons/arrowRight";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import {
  addComponent,
  componentMasterSelector,
  editComponent,
  getComponentData,
  getComponentTypeList,
} from "../../store/slices/componentMaster";
import equipmentMaster, {
  equipmentMasterSelector,
  getEquipmentMasterData,
} from "../../store/slices/equipmentMaster";
import { clusterSelector, getClusterData } from "../../store/slices/cluster";
import { loaderSelector } from "../../store/slices/loaderSlice";

const AddComponent = React.memo(
  ({ pageTitle, onBack, editComponentData }: AddComponentProps) => {
    const dispatch: any = useAppDispatch();
    const { componentTypeList, componentMasterData } = useSelector(
      componentMasterSelector
    );
    const { clusterData } = useSelector(clusterSelector);
    const { equipmentMasterData } = useSelector(equipmentMasterSelector);
    const { label, placeholder, button, name } = ComponentConstants;
    const { isLoader } = useSelector(loaderSelector);

    // Initialize react-hook-form
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      getValues,
    } = useForm({
      defaultValues: {
        componentType: "",
        componentId: "",
        equipmentId: "",
        equipmentCluster: "",
        timeToAddressLeakDays: "",
      },
      resolver: yupResolver(validations),
    });

    useEffect(() => {
      // Fetch asset list and site type list on component mount
      dispatch(getComponentData(""));
      dispatch(getEquipmentMasterData("?limit=9999"));
      dispatch(getComponentTypeList());
      dispatch(getClusterData(""));
      // dispatch(getCluster());
    }, []);

    useEffect(() => {
      setEditData();
    }, [componentTypeList, equipmentMasterData, clusterData]);

    const setEditData = () => {
      if (editComponentData) {
        const editData = { ...editComponentData };
        // Find the site type id based on the name
        const componentType = componentTypeList?.filter(
          (val) => val?.componentType === editData?.componentType
        );
        const equipmentId = equipmentMasterData?.filter(
          (val) => val?.equipmentId === editData?.equipmentId
        );
        const equipmentCluster = clusterData?.filter(
          (val) => val?.equipmentCluster === editData?.equipmentCluster
        );
        console.log(equipmentCluster[0]?.id);
        for (var key in editData) {
          if (name[key] || name[key] === "") {
            if (key === "componentType" && componentTypeList?.length) {
              setValue(name[key], componentType[0]?.id);
            } else if (key === "equipmentId" && equipmentMasterData?.length) {
              console.log("Rnn");
              setValue(name[key], equipmentId[0]?.equipmentId);
            } else if (key === "equipmentCluster" && clusterData?.length) {
              console.log("Running");
              setValue(name[key], equipmentCluster[0]?.id);
            } else if (key === "timeToAddressLeakDays") {
              setValue(
                name[key],
                roundOffNumber(editData?.timeToAddressLeakDays)
              );
            } else {
              setValue(name[key], editData[key]);
            }
          }
        }
      }
    };

    const onSubmit: SubmitHandler<any> = (payload: AddComponentSubmitProps) => {
      const reqPayload = { ...payload };
      reqPayload.timeToAddressLeakDays = +reqPayload?.timeToAddressLeakDays;
      reqPayload.componentType = +reqPayload?.componentType;
      reqPayload.cluster = +reqPayload?.equipmentCluster;
      if (editComponentData) {
        dispatch(editComponent(reqPayload, reqPayload?.componentId, successCB));
      } else {
        dispatch(addComponent(reqPayload, successCB));
      }
    };

    const successCB = () => {
      const alertType = editComponentData ? "edit" : "add";
      onBack(false, true, alertType);
    };

    const renderHeaderButtons = () => (
      <Stack direction="row" spacing={2}>
        <PrimaryButton
          label={button.submit}
          variant="contained"
          type={"submit"}
          // endIcon={<ArrowRight sx={{ width: "7px" }} />}
          width={"95px"}
        />
      </Stack>
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoader?.isShow && isLoader.component === "component" && (
          <CircularLoader />
        )}
        <DefaultLayout
          pageTitle={pageTitle}
          // renderHeaderButtons={renderHeaderButtons}
          backHandler={() => onBack(false)}
          activeTab={"Components"}
        >
          <Grid container spacing={"30px"} sx={{ p: "10px 0 27px" }}>
            <Grid item xs={6} md={4}>
              <InputField
                label={label.componentId}
                type="text"
                name={name.componentId}
                register={register}
                placeholder={placeholder.componentId}
                errorText={errors?.componentId?.message}
                disabled={editComponentData ? true : false}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectField
                selectlabel={label.componentType}
                placeholder={placeholder.componentType}
                options={convertArrayToObject(
                  componentTypeList,
                  "componentType",
                  "id"
                )}
                name={name.componentType}
                register={register}
                value={watch(name?.componentType)}
                errorText={errors?.componentType?.message}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectField
                selectlabel={label.equipmentId}
                placeholder={placeholder.equipmentId}
                options={convertArrayToObject(
                  equipmentMasterData,
                  "equipmentId",
                  "equipmentId"
                )}
                name={name.equipmentId}
                register={register}
                value={watch(name?.equipmentId)}
                errorText={errors?.equipmentId?.message}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectField
                selectlabel={label.equipmentCluster}
                placeholder={placeholder.equipmentCluster}
                options={convertArrayToObject(
                  clusterData,
                  "equipmentCluster",
                  "id"
                )}
                name={name.equipmentCluster}
                register={register}
                value={watch(name.equipmentCluster)}
                errorText={errors?.equipmentCluster?.message}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <InputField
                label={label.timeToAddressLeakDays}
                type="number"
                name={name.timeToAddressLeakDays}
                register={register}
                placeholder={placeholder.timeToAddressLeakDays}
                errorText={errors?.timeToAddressLeakDays?.message}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2}>
            <PrimaryButton
              label={button.submit}
              variant="contained"
              type={"submit"}
              // endIcon={<ArrowRight sx={{ width: "7px" }} />}
              width={"95px"}
            />
          </Stack>
        </DefaultLayout>
      </form>
    );
  }
);

export default AddComponent;
