import React, { useEffect, useState } from "react";
import FilterModal from "../../components/atoms/FilterModal/FilterModal";
import { Grid } from "@mui/material";
import { FilterPaylod, FilterGasFactorProps } from "./interface";
import { SubmitHandler, useForm } from "react-hook-form";
import { GasFactorConstants } from "./constants";
import {
  convertArrayToObject,
} from "../../utils/helper";
import { useAppDispatch } from "../../store/hooks";
import {
  gasMasterSelector,
  getGasTypes,
} from "../../store/slices/gasFactorMaster";
import { useSelector } from "react-redux";
import {
  assetMasterSelector,
  getAssetList,
} from "../../store/slices/assetMaster";
import MultiSelectField from "../../components/atoms/MultiSelect/MultiSelectField";

const FilterGasFactor = ({
  open,
  onClose,
  assetData,
  filterCB
}: FilterGasFactorProps) => {
  const dispatch: any = useAppDispatch();

  // Destructure constants from SiteConstants
  const { label, placeholder, name } = GasFactorConstants;
  const { gasTypes } = useSelector(gasMasterSelector);
  const { assetMasterData } = useSelector(assetMasterSelector);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [filterDisabled, setFilterDisabled] = useState(true);
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      asset_id: [],
      gas_type: [],
    },
  });

  let asset_id = "asset_id";
  let gas_type = "gas_type";

  useEffect(() => {
    localStorage.removeItem("gasFactorFilter");
    localStorage.removeItem("gasTypeFilter");
    dispatch(getGasTypes());
    dispatch(getAssetList("?limit=9999"));
  }, []);


  useEffect(() => {
    if (!!watch("gas_type")) {
      const val: any = watch("gas_type");
      localStorage.setItem("gasTypeFilter", JSON.stringify(val));
    }
  }, [watch("gas_type")]);

  useEffect(() => {
    if (watch("asset_id")) {
      const val: any = watch("asset_id");
      localStorage.setItem("gasFactorFilter", JSON.stringify(val));
    }
  }, [watch("asset_id")]);
  useEffect(() => {
    const gasTypeValue = watch("gas_type");
    const assetIdValue = watch("asset_id");
    const hasSelectedValues = gasTypeValue.length > 0 || assetIdValue.length > 0;
    setClearDisabled(!hasSelectedValues);
    setFilterDisabled(!hasSelectedValues);
    localStorage.setItem("gasTypeFilter", JSON.stringify(gasTypeValue));
    localStorage.setItem("gasFactorFilter", JSON.stringify(assetIdValue));
  }, [watch("gas_type"), watch("asset_id")]);
  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("gasFactorFilter");
    localStorage.removeItem("gasTypeFilter");
    reset({ asset_id: [] });
    reset({ gas_type: [] });
    onSubmit()
  };

  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("gas_type"), watch("asset_id"))
    onClose();
  };

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title="FILTER BY"
        width="475px"
        clearFilters={handleClearFilters}
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={label.filterGasType}
              options={convertArrayToObject(gasTypes, "gasType", "id")}
              placeholder={placeholder.gasType}
              name={gas_type}
              register={register}
              value={watch("gas_type") || []}
              Asterisk={false}
            />
          </Grid>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={label.assetId}
              options={convertArrayToObject(
                assetMasterData,
                "assetId",
                "assetId"
              )}
              placeholder={placeholder.assetID}
              name={asset_id}
              register={register}
              value={watch("asset_id") || []}
              Asterisk={false}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterGasFactor;
