export const MasterDictionary = {
  site: () => "/site",
  groupSiteData: (id: string) => `/site/all-list/${id}`,
  siteByID: (id: string | number) => `/site/${id}`,
  siteType: () => "/site-type",
  siteTypeByID: (id: string | number) => `/site-type/${id}`,
  asset: () => "/asset",
  assetList: () => "/asset/asset-list",
  componentList: (id: string | number) => `/component/all-list/${id}`,
  assetByID: (id: string | number) => `/asset/${id}`,
  abatementByID: (id: string | number) => `abatement/${id}`,
  assetType: () => "/asset-type",
  gasType: () => "/gas-type",
  country: () => "/country",
  operator: () => "/operator",
  equityType: () => "/equity-type",
  gasFactor: () => "/gas-factor",
  gasFactorByID: (id: string | number) => `/gas-factor/${id}`,
  equipmentMaster: () => "/equipment-static",
  equipmentType: () => "/equipment-type?limit=9999",
  equipmentModel: () => "/equipment-model?limit=9999",
  equipmentSubType: () => "/equipment-subtype?limit=9999",
  equipmentLocation: () => "/equipment-location",
  equipmentSubTypeMaster: () => "/equipment-subtype",
  engineType: () => "/engine-type?limit=9999",
  typeOfSeals: () => "/seal-type?limit=9999",
  typeOfWell: () => "/well-type?limit=9999",
  agentProcessed: () => "/agent-processed?limit=9999",
  productionMethod: () => "/Production-Method?limit=9999",
  equipmentCluster: () => "/Equipment-Cluster?limit=9999",
  equipmentById: () => (id: string | number) => `/equipment-static/${id}`,
  updateEquipmentStatus: (id: string | number) =>
    `/equipment-static/status/${id}`,
  component: () => "/component",
  componentByID: (id: string | number) => `/component/${id}`,
  componentType: () => "/component-type",
  componentTypeByID: (id: string | number) => `/component-type/${id}`,
  cluster: () => "/Equipment-Cluster",
  emissionFactor: () => "/emission-factor",
  emissionSubCategory: () => "/emission-subcategory?limit=9999",
  emissionFactorByID: (id: string | number) => `/emission-factor/${id}`,
  notifications: () => "/notification-center",
  notificationNotVerified: () => "/duplicate-emission/count",
  getEquipmentTypeByCategory: (id: string | number) =>
    `/emission-subcategory/${id}?limit=9999`,
  getEquipmentTypeBySite: (id: string | number) =>
    `/equipment-static/subcategory/${id}?limit=9999`,
  changeLogs: (id: number) => `/change-log/${id}`,
  verifyNotification: (id: string | number) =>
    `notification-center/verify/${id}`,
  getEquipmentSubCategoryBySiteOrEquip: (id: string) =>
    `equipment-static/subcategory/${id}`,
  proportionalNotification: () =>
    `notification-center/proportional-distribution`,
  duplicateNotifications: () => "/duplicate-emission",
  duplicateEmissionsCount: () => "/duplicate-emission/count",
  fixDuplicateEmissions: (id: number) => `/duplicate-emission/${id}`,
  keepDuplicateEmissions: (id: number) => `/duplicate-emission/${id}`,
  assetEmissions: () => `/dashboard/assets-emission`,
  dashboardTotalEmissions: () => `/dashboard/total-emission`,
  ogmpReport: () => `dashboard/ogmp-report`,
  ogmpMidReport: () => "dashboard/ogmp-report",
  emissionCategory: () => `emission-category`,
  mapDetails: () => `dashboard/map-view`,
  abatement: () => `/abatement`,
  abatementChartDetails: () => `dashboard/abatement-chart-view`,
  abatementChartGraphDetails: () => `dashboard/abatement-curve-report`,
  initiative: () => `/initiative`,
  waterfallChartData: () => `dashboard/waterfall-graph`,
  topDetectionData: () => `dashboard/top-detection-list`,
  socarTotalDetails: () => `dashboard/info-graphic-details`,
  chat: () => "/chat",
  getEquipmentMasterListByType: (id: string, params: string) => `equipment-static/list-all/${id}${params}`,
};
