import React from "react";
import { FormHelperText, styled } from "@mui/material";
import { toLineHeight } from "chart.js/helpers";
interface LabelProps {
  inputLabel: string;  
  Asterisk?: boolean;
  error?: boolean;
  className?: string;
  disabled?:boolean
}

const CustomLabelComponent = styled(FormHelperText)(
  ({ theme }) => ({
    fontSize: "14px",   
    fontWeight: 700, 
    color: "#4D4B4C",
    lineHeight: "20px",
    marginBottom: theme.spacing(0.5),    
    paddingBottom: "8px",
    textTransform: "capitalize",
    margin: 0,
    "& sup": {
      color: "#29292A",
      fontSize: "12px",
      verticalAlign: "text-top",
      lineHeight:  "19px"
      
    }
  })
);

const CustomLabel: React.FC<LabelProps> = ({
  inputLabel,  
  Asterisk = true,
  error,
  disabled,
  ...rest
}) => {
  return (
    <CustomLabelComponent {...rest} error={error} disabled={disabled}>
      {inputLabel}{Asterisk && <sup>*</sup>}
    </CustomLabelComponent>
  );
};

export default CustomLabel;
