import { convertArrayToObject } from "../../utils/helper";


interface FieldArrayProps {
  type: "input" | "dropdown" | "textArea";
  fieldName: string;
  options: any;
  inputType?: string,
  disabled?: boolean
}


const reportingLevel = [
  {
    label: "Level 1",
    value: "Level 1"
  },
  {
    label: "Level 2",
    value: "Level 2"
  },
  {
    label: "Level 3",
    value: "Level 3"
  },
  {
    label: "Level 4",
    value: "Level 4"
  },
  {
    label: "Level 5",
    value: "Level 5"
  },
]

export class FieldArray {
  private fieldArray: FieldArrayProps[];

  constructor(payload: any) {
    this.fieldArray = [
      {
        type: "input",
        fieldName: "assetName",
        options: null
      },
      {
        type: "input",
        fieldName: "assetId",
        options: null,
        disabled: payload.isEdit
      },
      {
        type: "dropdown",
        fieldName: "assetType",
        options: convertArrayToObject(payload.assetTypes, "assetType", "id")
      },
      {
        type: "dropdown",
        fieldName: "country",
        options: convertArrayToObject(payload.countries, "name", "id")
      },
      {
        type: "input",
        fieldName: "latitude",
        options: null,
        inputType: "number"
      },
      {
        type: "input",
        fieldName: "longitude",
        options: null,
        inputType: "number"
      },
      {
        type: "dropdown",
        fieldName: "operated",
        options: [{ label: "Yes", value: "YES" }, { label: "No", value: "NO" }]
      },
      {
        type: "dropdown",
        fieldName: "operator",
        options: convertArrayToObject(payload.operators)
      },
      {
        type: "input",
        fieldName: "equityPercentage",
        options: null,
        inputType: "number"
      },
      {
        type: "dropdown",
        fieldName: "equityType",
        options: convertArrayToObject(payload.equityTypes, "equityType", "id")
      },
      {
        type: "dropdown",
        fieldName: "reportingLevel",
        options: reportingLevel
      },
      
      {
        type: "textArea",
        fieldName: "assetDescription",
        options: null
      },
    ]
  }
}