import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      fontSize="16"
    >
      <path
        d="M0.813477 0.799805L4.92348 4.9098C5.11084 5.09606 5.36429 5.2006 5.62848 5.2006C5.89266 5.2006 6.14611 5.09606 6.33348 4.9098L10.3335 0.909804"
        stroke="#3A3A3A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="bevel"
      />
    </svg>
  </SvgIcon>
);

export default ArrowDown;
