import ActiveDashboardIcon from "../atoms/icons/activeDashboardIcon";
import ActiveNotificationIcon from "../atoms/icons/activeNotificationIcon";
import ActiveUploadDataIcon from "../atoms/icons/activeUploadDataIcon";
import DashboardIcon from "../atoms/icons/dashboardIcon";
import NotificationIcon from "../atoms/icons/notificationIcon";
import UploadDataIcon from "../atoms/icons/uploadDataIcon";


export type MasterRouteKeys =
  | "Abatement Curve Master"
  | "Asset Master"
  // | "Site Master"
  // | "Equipment Master"
  // | "Component Master"
  | "Emission Factor Master"
  | "Gas Factor Master";


export type SettingRouteKeys = "Sign Out";

export const pageRoutes: any = [
  {
    item: "dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    dropdown: [
      {
        item: "dashboard",
        path: "/dashboard",
      }
    ]
  },
  {
    item: "upload data",
    path: "/upload-data",
    icon: UploadDataIcon,
    activeIcon: ActiveUploadDataIcon,
  },
  {
    item: "notification center",
    path: "/notification-center",
    icon: NotificationIcon,
    activeIcon: ActiveNotificationIcon,
  },


]

export const masterRoutes: Record<MasterRouteKeys, string> = {
  "Asset Master": "/asset-master",
  // "Site Master": "/site-master",
  // "Equipment Master": "/equipment-master",
  // "Component Master": "/component-master",
  "Emission Factor Master": "/emission-master",
  "Gas Factor Master": "/gas-factor-master",
  "Abatement Curve Master": "/abatement-master",
};

export const settingRoutes: Record<SettingRouteKeys, string> = {
  // Settings: "/settings",
  "Sign Out": "/signout",
};
