import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import {
  FilterButton,
  PrimaryButton,
} from "../../components/atoms/button/button";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import PlusIcon from "../../components/atoms/icons/plusIcon";
import TableColumn from "./tableColumn";
import { ComponentConstants } from "./constants";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { getAssetList } from "../../store/slices/assetMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import {
  componentMasterSelector,
  getComponentData,
  getComponentById,
} from "../../store/slices/componentMaster";
import FilterComponent from "./FilterComponent";
import AddComponent from "./AddComponent";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import NewCrudTable from "../../components/atoms/DataTable/NewCrudTable";
import { useNavigate, useParams } from "react-router-dom";
import { filterByGroup } from "../../utils/helper";

export default function ComponentMaster() {
  const dispatch: any = useAppDispatch();
  const navigate: any = useNavigate();
  const { headers, text, button } = ComponentConstants;
  const { componentMasterData, total } = useSelector(componentMasterSelector);
  const [editComponentData, setEditComponentData] = useState<any>(null);
  const [alertType, setAlertType] = useState(true);
  const [open, setOpen] = useState(false);
  const [addComponentVisibility, setAddComponentVisibility] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addComponent);
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [searchText, setSearchText] = useState(""); // Search text for filtering
  const [equipmentFilter, setEquipmentFilter] = useState<any>([]);
  const { isLoader } = useSelector(loaderSelector);
  const { componentByID } = useSelector(componentMasterSelector);
  const [tableData, setTableData] = useState<any>([]); // Filtered data for the table
  const [originalData, setOriginalData] = useState<any>([]); // Original data without filtering
  const [EquipmentId, setEquipmentId] = useState<any>(null);

  const { id } = useParams();

  // Fetch and store original data when the componentByID changes
  useEffect(() => {
    if (alertModalVisibility === false) {
      setEquipmentId(id);
      dispatch(getAssetList("?limit=9999", false));
      dispatch(getComponentById(id));
    }
  }, [dispatch, id, alertModalVisibility]);

  useEffect(() => {
    if (componentByID?.length > 0) {
      const transformedData = componentByID?.map((item: any) => ({
        type: item?.componentType,
        columns: item?.components,
      }));
      setOriginalData(transformedData); // Store the original data
      setTableData(transformedData); // Initially set tableData as originalData
    } else {
      setTableData([]);
      setOriginalData([]);
    }
  }, [componentByID]);

  // Local search filtering for componentId and componentType with exact matches
  useEffect(() => {
    if (originalData?.length) {
      const filteredData = originalData
        .map((item: any) => {
          const matchesComponentType = item.type
            .toLowerCase()
            .includes(searchText.toLowerCase());
          const filteredColumns = item.columns.filter((component: any) =>
            component.componentId
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );

          return matchesComponentType || filteredColumns.length > 0
            ? { ...item, columns: filteredColumns }
            : null;
        })
        .filter(Boolean); // Remove null entries from items that don't match

      setTableData(filteredData); // Set the refined filtered data for the table
    }
  }, [searchText, originalData]);

  const toggleAddComponent = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string) => {
      setAddComponentVisibility(status);
      setPageTitle(headers.addComponent);
      if (type === "edit") {
        setAlertType(false);
      } else {
        setAlertType(true);
        setEditComponentData(null);
      }

      if (showSuccessModal) {
        setPage(1);
        setAlertModalVisibility(true);
        dispatch(getComponentData(`?page=${page}&limit=${pageSize}`, false));
      }
    },
    [dispatch, headers, page, pageSize]
  );

  const handleFilterData = (filterkeys: any) => {
    if (filterkeys.length) {
      let result = filterByGroup(originalData, filterkeys);
      setTableData(result);
    } else {
      setTableData(originalData);
    }
  };

  const handleIconButtonClick = (row: {}) => {
    setEditComponentData(row);
    setPageTitle(headers.editComponent);
    setAddComponentVisibility(true);
  };

  const handleFilterVisibility = (state: boolean) => {
    setOpen(state);
  };

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="Add Component"
        variant="contained"
        onClick={() => toggleAddComponent(true)}
        startIcon={<PlusIcon />}
        width="175px"
      />
    </Stack>
  );

  const columns: any = new TableColumn({ handleIconButtonClick });
  let pageTitle_ = "Component Master";
  if (EquipmentId) {
    pageTitle_ += ` (${EquipmentId})`;
  }

  const fetchData = () => {
    // Dispatch action to fetch updated component data
    dispatch(getComponentData(`?page=${page}&limit=${pageSize}`, false));
  };

  return (
    <>
      {isLoader?.isShow && isLoader.component === "componentList" && (
        <CircularLoader />
      )}

      {addComponentVisibility ? (
        <AddComponent
          pageTitle={pageTitle}
          onBack={(
            status: boolean,
            modalStatus?: boolean,
            alertStatus?: string
          ) => toggleAddComponent(status, modalStatus, alertStatus)}
          editComponentData={editComponentData}
        />
      ) : (
        <DefaultLayout
          renderHeaderButtons={renderHeaderButtons}
          pageTitle={pageTitle_}
          activeTab={"Components"}
          backHandler={() => navigate(-1)}
        >
          <Box className="placeholder_casing">
            <NewCrudTable
              rows={tableData} // Display filtered table data
              columns={columns.column}
              showCheckbox={false}
              showSearch={true}
              segmentHeader="Component Type"
              setSearch={setSearchText} // Update search text state
              renderButtons={renderButtons}
              searchPlaceholder="Search Component ID or Type"
            />
          </Box>
          <FilterComponent
            open={open}
            componentData={componentMasterData}
            onClose={() => {
              handleFilterVisibility(false);
            }}
            filterCB={(val: any) => {
              handleFilterData(val);
            }}
          />
          <StatusModal
            type="success"
            bodyText={
              alertType ? text.addComponentAlert : text.editComponentAlert
            }
            open={alertModalVisibility}
            onClose={() => {
              setAlertModalVisibility(false);
              fetchData(); // Fetch updated data when the modal closes
            }}
          />
        </DefaultLayout>
      )}
    </>
  );
}
