import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import client from "../client";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";
import { clearAuthenticationState } from "../../store/slices/authenticationSlice";
import { getSessionData } from "../../utils/helper";
import Loader from "../../components/atoms/Loader";
import { useAppDispatch } from "../../store/hooks";
import { setLoderVisibility } from "../../store/slices/loaderSlice";

let requestInterceptorRef: any;
let responseInterceptorRef: any;

const hideToast = ["leaderboards"];

const Interceptor = () => {
  const { api } = client;
  const dispatch: any = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setshowLoader] = useState(true);
  const navigate = useNavigate();
  const state = store.getState();
  const history = createBrowserHistory();


  useEffect(
    () =>
      history.listen(() => {
        window.scrollTo(0, 0);
      }),
    [history]
  );

  useEffect(() => {
    const authenticationState = state?.authentication;
    const loaderState = state?.loader
    const Loader = loaderState.isLoader
    const AUTH_TOKEN = authenticationState?.access_token
      ? authenticationState?.access_token
      : getSessionData("access_token");
    api.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
    api.interceptors.request.eject(requestInterceptorRef);
    requestInterceptorRef = api.interceptors.request.use(
      (config) => {
        setIsLoading(Loader);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    api.interceptors.request.eject(responseInterceptorRef);
    responseInterceptorRef = api.interceptors.response.use(
      (response) => {
        if (
          response.config.method !== "get" &&
          hideToast.indexOf(response?.config.url || "") === -1
        ) {
        }
        setTimeout(() => {
          // dispatch(setLoderVisibility(false))
          setIsLoading(false);
        }, 1000);
        return response;
      },
      (error) => {
        setTimeout(() => {
          // dispatch(setLoderVisibility(false))
          setIsLoading(false);
        }, 1000);

        return Promise.reject(error);
      }
    );
  }, [navigate]);

  return (
    <>
      {/* {isLoading && <Loader />} */}
    </>
  );
};

export default Interceptor;
