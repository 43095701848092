import React, { useEffect } from 'react'
import DefaultLayout from '../../components/layout'
import { useNavigate } from 'react-router-dom'

export default function SignOut() {
  const navigate: any = useNavigate()

  useEffect(() => {
    Promise.all([
      localStorage.clear(),
      navigate("/")
    ])

  }, [])

  return (
    <DefaultLayout pageTitle='' renderHeaderButtons={() => { }}>
      <div />
    </DefaultLayout>
  )
}
