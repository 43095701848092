import React from 'react'
import DefaultLayout from '../../components/layout'
import { Box, Button } from '@mui/material';
// import TableToExcel from "@linways/table-to-excel";
import Excel from './Excel';

export default function Test() {

  const handleDownloadExcel = () => {
    // const table = document.getElementById("myTable");
    TableToExcel.convert(document.getElementById("ogmp-table"));
  }

  return (
    <DefaultLayout pageTitle='Test'>
      <Excel />
      <Box margin={"30px"} height={"500px"}>
        <Button variant="contained" onClick={handleDownloadExcel}>
          Download Excel
        </Button>
      </Box>
    </DefaultLayout>
  )
}
