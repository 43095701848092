import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { AddEmissionFactorSubmitProps } from "../../pages/EmissionMaster/interface";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  emissionMasterData: [],
  emissionSubCategoryList: [],
  emissionCategory: [],
  equipmentType: [],
  clusterList: [],
  ogmpLevel: [],
  sourceList: [],
  addEmissionVisibility: false,
  total: 0

};

const emissionMasterSlice: any = createSlice({
  name: "emissionMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    addEmissionFactorHandler: (state, { payload }) => {
      state.addEmissionVisibility = payload;
    },

    setEmissionFactorList: (state, { payload }) => {
      state.emissionMasterData = payload?.data;
      state.total = payload?.meta?.itemCount
    },

    setEmissionSubCategoryList: (state, { payload }) => {
      state.emissionSubCategoryList = payload;
    },

    setEmissionCategory: (state, { payload }) => {
      state.emissionCategory = payload;
    },
  },
});

export const {
  clearMasterState,
  addEmissionFactorHandler,
  setEmissionFactorList,
  setEmissionSubCategoryList,
  setEmissionSubCategoryListSite,
  setEmissionCategory
} = emissionMasterSlice.actions;

// Action creators are generated for each case reducer function
export const emissionMasterSelector = (state: any) => state.emissionMaster;

export default emissionMasterSlice.reducer;

export const getEmissionMasterData = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "emissionMasterList" }));
    }
    try {
      const res = await MasterService.getEmissionFactorsList(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "emissionMasterList" }));
        }
        dispatch(setEmissionFactorList(res.data))
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "emissionMasterList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };

}


export const addEmissionFactor = (payload: AddEmissionFactorSubmitProps, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "emission" }))
    try {
      const res = await MasterService.addEmissionFactor(payload)
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "emission" }))
        successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "emission" }))
        dispatch(setErrorMessage(error))
      }
    }

  };

}


export const editEmissionFactor = (payload: AddEmissionFactorSubmitProps, id: string | number, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "emission" }))
    try {
      const res = await MasterService.editEmissionFactor(payload, id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "emission" }))
        successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "emission" }))
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getEmissionSubCategoryList = (params: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEmissionSubCategoryList(params)
      if (res.status === 200) {
        dispatch(setEmissionSubCategoryList(res?.data?.data))
      }

    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  };
}


export const getEmissionCategoryList = (params: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEmissionCategory(params)
      if (res.status === 200) {
        dispatch(setEmissionCategory(res?.data?.data))
      }
    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  };
}