// src/components/ChatBot/ChatBot.tsx

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalCloseIcon from "../../atoms/icons/modalCloseIcon";
import ExpandChatBot from "../../atoms/icons/expandChatBot";
import NormalExpandChatBot from "../../atoms/icons/normalExpandChatBot";
import InputField from "../../atoms/InputField/InputField";
import { SubmitHandler, useForm } from "react-hook-form";
import "./ChatBot.scss";
import { useSelector } from "react-redux";
import {
  addchatBotData,
  chatBotSelector,
  getchatBotData,
  resetChatBotAns,
} from "../../../store/slices/chatBot";
import { useAppDispatch } from "../../../store/hooks";
import BouncingDotsLoader from "./BouncingDotsLoader";
import ChatAnswerLogo from "../../atoms/icons/chatAnswerLogo";
import ChatQuesLogo from "../../atoms/icons/chatQuesLogo";
import SendIcon from "@mui/icons-material/Send";
import CustomLabel from "../../atoms/label/label";
import remarkGfm from "remark-gfm";
import ChatSendIcon from "../../atoms/icons/chatSendIcon";

interface ChatBotProps {
  open: boolean;
  onClose: () => void;
}

interface chatSubmitProps {
  q: string;
  session_id: any;
}

const StyleList = styled(List)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  padding: "24px",
});

const MessageContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const LogoBox = styled(Box)({
  width: "44px",
  height: "44px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "22px",
  boxShadow: "0px 8px 16px 0px #58596214",
});

const SpeechBubble = styled(Box)(({ theme }) => ({
  backgroundColor: "#71AB2B",
  color: "#fff",
  padding: "12px",
  borderRadius: "8px",
  maxWidth: "350px",
  fontSize: "16px",
  fontWeight: 500,
  position: "relative",
  marginLeft: "8px",
  boxShadow: "0px 8px 16px 0px #58596214",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-7px",
    top: "50%",
    transform: "translateY(-50%)",
    width: 0,
    height: 0,
    borderTop: "8px solid transparent",
    borderBottom: "8px solid transparent",
    borderRight: "8px solid #71AB2B",
  },
}));

const ChatBot: React.FC<ChatBotProps> = ({ open, onClose }) => {
  const dispatch: any = useAppDispatch();
  const { chatBotData } = useSelector(chatBotSelector);
  const { chatBotAns } = useSelector(chatBotSelector);
  const [expand, setExpand] = useState(false);
  const [messageList, setMessageList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const messageListRef = useRef<HTMLDivElement>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const session_id = localStorage.getItem("uuid");

  useEffect(() => {
    if (chatBotData && chatBotData.length > 0) {
      const reverseChatBotData = [...chatBotData].reverse();
      setMessageList(reverseChatBotData);
    }
  }, [chatBotData]);

  useEffect(() => {
    let params = `?session_id=${session_id}`;
    dispatch(getchatBotData(params));
  }, []);

  useEffect(() => {
    if (chatBotAns && chatBotAns.length > 0) {
      setMessageList((prevMessages) =>
        prevMessages?.map((msg, index) =>
          index === prevMessages.length - 1
            ? { ...msg, modelResponse: chatBotAns }
            : msg
        )
      );
      setIsLoading(false);
    }
  }, [chatBotAns]);

  useEffect(() => {
    if (open && messageListRef.current) {
      const { scrollHeight, clientHeight } = messageListRef.current;
      messageListRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [open, messageList]);

  const onSubmit: SubmitHandler<any> = (data, event) => {
    event?.preventDefault();
    const message = inputValue.trim();
    if (!message) return;
    dispatch(resetChatBotAns());
    setMessageList((prevMessages) => [
      ...prevMessages,
      { userQuery: message, modelResponse: "" },
    ]);
    setIsLoading(true);
    const reqPayload: chatSubmitProps = { q: message, session_id };
    reqPayload.q = message;
    dispatch(addchatBotData(reqPayload));
    setInputValue("");
  };

  const expandHandler = () => {
    setExpand(!expand);
  };

  const handleDrawerClose = () => {
    onClose();
    setExpand(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      variant="persistent"
      PaperProps={{
        sx: {
          width: expand ? 568 : 700,
          height: expand ? "100%" : 520,
          position: "fixed",
          bottom: 0,
          top: "unset",
          border: "2px solid #71AB2B",
          borderRadius: "12px 12px 0 12px",
          boxShadow: "0px 8px 16px 0px #64A70B36",
          transition: "height 0.3s",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "24px",
          backgroundColor: "#ffffff",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2.5}
        >
          <Box display="flex" alignItems="center" gap="12px">
            <Box onClick={expandHandler} sx={{ cursor: "pointer" }}>
              {expand ? <NormalExpandChatBot /> : <ExpandChatBot />}
            </Box>
            <Typography variant="h3">I am AILA</Typography>
          </Box>
          <IconButton onClick={handleDrawerClose} disableRipple>
            <ModalCloseIcon />
          </IconButton>
        </Box>

        <Box
          ref={messageListRef}
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            marginBottom: 2.5,
            backgroundColor: "#F9FAFD",
            borderRadius: "8px",
          }}
        >
          <StyleList>
            <MessageContainer>
              <LogoBox>
                <ChatAnswerLogo />
              </LogoBox>
              <SpeechBubble>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "25.6px",
                    color: "#fff",
                  }}
                >
                  Hi, can I help you with anything?
                </p>
              </SpeechBubble>
            </MessageContainer>

            {messageList?.map((item: any, index: any) => (
              <Stack
                key={index}
                display="flex"
                flexDirection="column"
                gap="24px"
              >
                {item.userQuery && (
                  <MessageContainer
                    sx={{ gap: "20px !important", justifyContent: "flex-end" }}
                  >
                    <SpeechBubble className="userQuestion">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight: "25.6px",
                          color: "#3B3939",
                        }}
                      >
                        {item?.userQuery}
                      </p>
                    </SpeechBubble>
                    <LogoBox>
                      <ChatQuesLogo />
                    </LogoBox>
                  </MessageContainer>
                )}
                <MessageContainer>
                  <LogoBox>
                    <ChatAnswerLogo />
                  </LogoBox>
                  <SpeechBubble>
                    {isLoading && index === messageList.length - 1 ? (
                      <BouncingDotsLoader />
                    ) : (
                      <div
                        style={{
                          overflowX: "auto",
                          width: "100%",
                          maxWidth: "700px",
                        }}
                      >
                        <ReactMarkdown
                          className="markdown-content"
                          remarkPlugins={[remarkGfm]}
                        >
                          {item?.modelResponse}
                        </ReactMarkdown>
                      </div>
                    )}
                  </SpeechBubble>
                </MessageContainer>
              </Stack>
            ))}
          </StyleList>
        </Box>

        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
        <Box className= "chatInputBox">
          
            <CustomLabel inputLabel = "Type here"  Asterisk={false}/>
             <Box display="flex" alignItems= "center">              
            <InputField            
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              placeholder="Enter Your Questions Here..."
              classes="chatInput"
              
            />
          
            <IconButton type="submit" className="SendBtn" disableRipple disabled={inputValue === ""}>
            <ChatSendIcon  />
            </IconButton>
            </Box>
            </Box>
          </form>
       
      </Box>
    </Drawer>
  );
};

export default ChatBot;
