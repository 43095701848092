import React from "react";
import {
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { CommonModalProps } from "./interface";
import { CustomIconButton } from "../StatusModal/style";
import { CustomDialog, Wrapper } from "./style";
import ModalCloseIcon from "../icons/modalCloseIcon";


const CommonModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  children,
  width,
  minWidth,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose(); // Close without arguments
        }
      }}
      width={width}
      minWidth={minWidth}
    >
      <DialogTitle>
      <Typography variant="h3">{title}</Typography>
        <CustomIconButton aria-label="close" sx={{ p: "8px 0"}} disableRipple onClick={onClose}>
          <ModalCloseIcon />
        </CustomIconButton>
      </DialogTitle>     
      <Wrapper>
        <DialogContent dividers>{children}</DialogContent>
      </Wrapper>
    </CustomDialog>
  );
};

export default CommonModal;
