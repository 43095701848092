export const ComponentConstants = {
  label: {
    componentId: "Component ID",
    equipmentId: "Equipment Id",
    componentType: "Component Type",
    cluster: "Cluster",
    equipmentCluster: "Cluster",
    timeToAddressLeakDays: "Time To Address Leak (days)",
  },
  name: {
    componentId: "componentId",
    componentType: "componentType",
    equipmentId: "equipmentId",
    cluster: "cluster",
    equipmentCluster: "equipmentCluster",
    timeToAddressLeakDays: "timeToAddressLeakDays",
  },
  headers: {
    component: "Component Master",
    addComponent: "Add Component",
    editComponent: "Edit Component",
  },
  placeholder: {
    componentId: "Enter Component ID",
    equipmentId: "Select Equipment ID",
    componentType: "Select Component Type",
    cluster: "Select Cluster",
    equipmentCluster: "Select Cluster",
    timeToAddressLeakDays: "Enter Value",
  },
  button: {
    addComponent: "Add Component",
    filter: "FILTER BY",
    submit: "Submit",
    editComponent: "Edit Component",
  },
  text: {
    addComponentAlert: "Component added successfully.",
    editComponentAlert: "Component updated successfully.",
  },
};
