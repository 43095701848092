// @ts-nocheck
import { Box, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SelectField from "../../../components/atoms/SelectField/SelectField";
import { useForm } from "react-hook-form";
import TextArea from "../../../components/atoms/textarea/textarea";
import { PrimaryButton } from "../../../components/atoms/button/button";
import SearchableSelect from "../../../components/atoms/searchableSelect/searchableSelect";
import {
  assetMasterSelector,
  getAssetList,
} from "../../../store/slices/assetMaster";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks";
import { convertArrayToObject, isValidFloat } from "../../../utils/helper";
import {
  getSiteData,
  siteMasterSelector,
} from "../../../store/slices/siteMaster";
import {
  equipmentMasterSelector,
  getEmissionSubCategoryBySite,
  getEmissionVolume,
  getEquipmentMasterData,
  getEquipmentSubCategoryBySiteOrEquip,
} from "../../../store/slices/equipmentMaster";
import {
  componentMasterSelector,
  getComponentData,
  setComponentList,
} from "../../../store/slices/componentMaster";
import {
  getNotificationMasterData,
  notificationSelector,
  verifyEmissionNotification,
} from "../../../store/slices/notificationSlice";
import {
  emissionMasterSelector,
  getEmissionSubCategoryList,
} from "../../../store/slices/emissionMaster";
import { loaderSelector, setLoderVisibility } from "../../../store/slices/loaderSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validation";
import CrudTable from "../../../components/atoms/DataTable/CrudTable";
import TableColumn from "./tableColumn";
import InputField from "../../../components/atoms/InputField/InputField";
import { ErrorMessage } from "../../../components/atoms/ErrorMessage/errorMessage";
import "../VerifyEmission.scss"
import { InfoText } from "../../../components/atoms/InfoText/InfoText";
import CircularLoader from "../../../components/atoms/CircularLoader/Loader";

export default function VerifyEmission({ id, successCB }: any) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      equipmentId: {},
      emissionSubcategoryId: "",
      assetId: "",
      siteId: "",
      componentId: {},
    },
    resolver: yupResolver(validations),
  });
  const { siteMasterData } = useSelector(siteMasterSelector);
  const { assetMasterData, total }: any = useSelector(assetMasterSelector);
  const { componentMasterData } = useSelector(componentMasterSelector);
  const { notificationMasterData } = useSelector(notificationSelector);
  const dispatch: any = useAppDispatch();
  const { equipmentMasterData, volumePercentage } = useSelector(
    equipmentMasterSelector
  );
  const [rowData, setRowData] = useState([]);
  const [emissionSubCategoryList, setEmissionSubCategoryList] = useState([]);
  const [equipmentMasterDataWithAll, setEquipmentMasterDataWithAll] = useState(
    []
  );
  const [assetLoaded, setAssetLoaded] = useState(false)
  const [siteLoaded, setSiteLoaded] = useState(false)
  const [equipmentLoaded, setEquipmentLoaded] = useState(false)
  const [subCategoryLoaded, setSubCategoryLoaded] = useState(false)
  const subCategoryRef = useRef(null);
  const emissionVolumeRef = useRef(null);
  const emissionVolumeSumRef = useRef(null);

  let initialData = []

  useEffect(() => {
    dispatch(setLoderVisibility({ isShow: true, component: "notificationData" }))
    let params = "?limit=9999";
    dispatch(getAssetList(params, false, assetCb));
    if (id) {
      dispatch(getNotificationMasterData(id, false));
    }
  }, []);


  useEffect(() => {
    if (assetLoaded && siteLoaded & equipmentLoaded, subCategoryLoaded) {
      dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }))

    }

  }, [assetLoaded, siteLoaded, equipmentLoaded, subCategoryLoaded])

  const assetCb = () => {
    setAssetLoaded(true)

  }

  useEffect(() => {
    let siteParams = "?limit=9999";
    if (watch("assetId")) {
      setEquipmentMasterDataWithAll([])
      siteParams += `&asset_id=${watch("assetId")}`;
      dispatch(getSiteData(siteParams, false, siteCB));
    }

  }, [watch("assetId")]);

  const siteCB = () => {
    setSiteLoaded(true)
  }

  useEffect(() => {
    let equipmentParams = "?limit=9999";
    if (watch("siteId")) {
      equipmentParams += `&site_id=${watch("siteId")}`;
      dispatch(getEquipmentMasterData(equipmentParams, false, equipmentCB));
    } else {
      dispatch(setComponentList([]))
    }

  }, [watch("siteId")]);

  const equipmentCB = () => {
    setEquipmentLoaded(true)
  }

  useEffect(() => {
    let componentParams = "?limit=9999";
    if (watch("equipmentId")?.value) {
      if (watch("equipmentId")?.value !== "all") {
        componentParams += `&equipment_id=${watch("equipmentId")?.value}`;
        dispatch(getComponentData(componentParams, false));
        dispatch(
          getEquipmentSubCategoryBySiteOrEquip(
            watch("siteId"),
            callBack,
            `?equipment_id=${watch("equipmentId")?.value}`
          )
        );
      } else {
        equipmentMasterData.forEach((element) => {
          componentParams += `&equipment_id=${element?.equipmentId}`;
          if (!watch(`emissionVolume-${element?.equipmentId}`)) {
            setValue(`emissionVolume-${element?.equipmentId}`, 0)
          }
        });
        dispatch(getComponentData(componentParams, false));
        dispatch(
          getEquipmentSubCategoryBySiteOrEquip(watch("siteId"), callBack, `?`)
        );
      }
    }
    if (watch("siteId") && !watch("equipmentId")?.value) {
      dispatch(setComponentList([]))
    }
  }, [watch("equipmentId"), watch("siteId")]);


  const dataCallBack = (key: string) => {
    initialData.push(key)
    setIntialLoadData(initialData)
  }


  useEffect(() => {
    let temp = convertArrayToObject(
      equipmentMasterData,
      "equipmentId",
      "equipmentId"
    );
    if (temp?.length > 1) {
      setEquipmentMasterDataWithAll([
        ...temp,
        { label: "All Equipment Selected", value: "all" },
      ]);
    } else {
      setEquipmentMasterDataWithAll([...temp]);
    }
  }, [equipmentMasterData]);

  useEffect(() => {
    if (Object.keys(notificationMasterData)?.length) {
      setData();
    }
  }, [notificationMasterData]);

  const callBack = (data: any) => {
    if (data?.length) {
      const emissionSubCategory = data[0]?.equipmentType?.subcategories;
      setEmissionSubCategoryList(emissionSubCategory);
      setRowData(data);
    }
    setSubCategoryLoaded(true)
  };

  const setData = () => {
    setRowData(notificationMasterData?.notificationEmissions);
    for (let key in notificationMasterData) {
      const notificationEmission_ =
        notificationMasterData?.notificationEmissions;
      if (!notificationEmission_.length) {
        setEquipmentLoaded(true)
        setSubCategoryLoaded(true)
      }
      if (!notificationMasterData?.siteId?.siteId) {
        setSiteLoaded(true)
      }
      if (key === "notificationEmissions" && notificationEmission_?.length && notificationMasterData?.siteId?.siteId) {

        if (notificationEmission_?.length > 1) {
          setValue("equipmentId", {
            label: "All Equipment Selected",
            value: "all",
          });
        } else {
          setValue(
            "componentId",
            notificationEmission_[0]["componentId"]?.componentId
          );
          if (notificationEmission_[0]["equipmentId"]) {
            let equipment_ = {
              label: notificationEmission_[0]["equipmentId"]?.equipmentId,
              value: notificationEmission_[0]["equipmentId"]?.equipmentId,
            };
            setValue("equipmentId", equipment_);
          }
        }
        notificationEmission_?.map((ele: any, index: any) => {
          if (ele["emissionSubcategoryId"]) {
            setValue(
              `emissionSubcategoryId-${ele["equipmentId"]?.equipmentId}`,
              ele["emissionSubcategoryId"]?.id
            );
          }

          if (ele["emissionPercentage"]) {
            setValue(
              `emissionVolume-${ele["equipmentId"]?.equipmentId}`,
              parseFloat(ele["emissionPercentage"])
            );
          }
        });
      } else if (key === "assetId" && notificationMasterData[key]?.assetId) {
        setValue(key, notificationMasterData[key]?.assetId);
      } else if (key === "siteId" && notificationMasterData[key]?.siteId) {
        setValue(key, notificationMasterData[key]?.siteId);
      }
    }
  };


  const handleClick = (dataType: string) => {
    if (dataType === "asset") {
      setValue("componentId", "");
      setValue("equipmentId", {});
      setValue("siteId", "");
      setValue("emissionSubcategoryId", "");
    }

    if (dataType === "site") {
      setValue("componentId", "");
      setValue("equipmentId", {});
      setValue("emissionSubcategoryId", "");
    }
  };

  const handleChange = (option: any) => {
    setValue("equipmentId", option);
    setValue("componentId", "");
    setValue("emissionSubcategoryId", "");
    clearErrors()
  };

  const callbackFun = (res: any) => {
    let statuses = rowData.map((row) => {
      res.filter((ele: any) => {
        if (ele?.equipmentId === row?.equipmentId) {
          setValue(`emissionVolume-${row?.equipmentId}`, ele?.emissionFactor);
        }
      });
    });
  };

  const onSubmit = async (payload: any) => {
    let equipmentPay = [];
    let sum = 0;
    subCategoryRef.current = null;
    emissionVolumeSumRef.current = null;
    emissionVolumeRef.current = null;
    if (rowData?.length) {
      const result = await new Promise((resolve) => {
        let emission_volume_ = true
        rowData.map((row: any, index: number) => {
          if (!watch(`emissionSubcategoryId-${row?.equipmentId}`)) {
            setError(`emissionSubcategoryId-${row?.equipmentId}`, {
              type: "manual",
              message: "Please select an emission sub-category",
            });
            resolve(false);
          } else if (
            rowData?.length > 1 &&
            (watch(`emissionVolume-${row?.equipmentId}`) === null ||
              watch(`emissionVolume-${row?.equipmentId}`) === undefined ||
              watch(`emissionVolume-${row?.equipmentId}`) === "")
          ) {
            setError(`emissionVolume-${row?.equipmentId}`, {
              type: "manual",
              message: "Please enter emission volume",
            });
            console.log("volume ")
            emission_volume_ = false
            resolve(false);
          } else if (rowData?.length > 1) {
            if (isValidFloat(watch(`emissionVolume-${row?.equipmentId}`))) {
              sum += parseFloat(watch(`emissionVolume-${row?.equipmentId}`));
            } else {
              setError(`emissionVolume-${row?.equipmentId}`, {
                type: "manual",
                message: "Please enter valid emission volume",
              });
              resolve(false)
            }

          }
          equipmentPay.push({
            equipmentId: row?.equipmentId,
            equipmentType: row?.equipmentType?.id,
            emissionSubcategoryId: watch(
              `emissionSubcategoryId-${row?.equipmentId}`
            ),
            emissionVolume:
              rowData?.length === 1
                ? 100
                : parseFloat(watch(`emissionVolume-${row?.equipmentId}`)),
          });
          if (index === rowData?.length - 1 && emission_volume_) {
            resolve(true)
          }

        });

      })

      if (!result) {
        return
      }
    }
    if (rowData?.length > 1 && Math.ceil(sum) !== 100) {
      emissionVolumeSumRef.current = "The emission volume should be 100%";
      return;
    }
    const reqPayload = {};
    reqPayload["assetId"] = { assetId: payload?.assetId };
    reqPayload["siteId"] = { siteId: payload?.siteId };
    reqPayload["comment"] = payload?.comment;
    if (
      !!payload?.componentId &&
      Object.entries(payload?.componentId)?.length
    ) {
      reqPayload["componentId"] = { componentId: payload?.componentId };
    }
    const equipmentType = equipmentMasterData.filter(
      (item: any) => item?.equipmentId === payload?.equipmentId?.value
    );

    reqPayload["equipment"] = equipmentPay;
    reqPayload["reverify"] = notificationMasterData?.status;
    dispatch(verifyEmissionNotification(id, reqPayload, successCB));
  };


  const handleApiCall = (event: any) => {
    let emissionDetails = [];
    rowData.map((row) => {
      const subCategory = watch(`emissionSubcategoryId-${row?.equipmentId}`);
      const value = watch(`emissionVolume-${row?.equipmentId}`);
      emissionDetails.push({
        equipmentId: row?.equipmentId,
        equipmentTypeId: row?.equipmentType?.id,
        emissionSubcategoryId: subCategory,
      });
    });
    const payload = {
      id: id,
      emissionDetails: [...emissionDetails],
    };

    dispatch(getEmissionVolume(payload, callbackFun));
  };

  const areAllSelectFieldsSelected = (watch: UseFormWatch<any>) => {
    subCategoryRef.current = null;
    let statuses = rowData.map((row) => {
      const value = watch(`emissionSubcategoryId-${row?.equipmentId}`);
      return value !== undefined && value !== null && value !== "";
    });
    if (statuses.every((row) => row === true)) {
      handleApiCall();
    }
  };

  const handleEmissionInput = () => {
    emissionVolumeSumRef.current = null;
  };


  const EmissionCategory = (row: any, id: string) => {
    return (
      <SelectField
        placeholder={"Select Emission Sub-Category"}
        options={convertArrayToObject(
          row?.equipmentType?.subcategories,
          "name",
          "id"
        )}
        name={`emissionSubcategoryId-${id}`}
        register={register}
        value={watch(`emissionSubcategoryId-${id}`) || ""}
        onClick={(e: any) => areAllSelectFieldsSelected(watch)}
        errorText={errors[`emissionSubcategoryId-${id}`]?.message}
        className="tableSelect"
      />
    )
  }

  const EmissionVolume = (row: any) => {
    return (
      <>
        {rowData?.length > 1 ? (
          <Box className="EmissionVolume">
            <InputField
              type="text"
              placeholder="Enter Volume"
              register={register}
              name={`emissionVolume-${row?.equipmentId}`}
              value={watch(`emissionVolume-${row?.equipmentId}`)}
              onChange={() => handleEmissionInput()}
              errorText={errors[`emissionVolume-${row?.equipmentId}`]?.message}
            />
          </Box>
        ) : (
          100
        )}
      </>
    );
  }

  useEffect(() => {
    getSiteValue(convertArrayToObject(siteMasterData, "siteId", "siteId"))
  }, [siteMasterData])

  const getSiteValue = (siteData) => {
    const siteValue = siteData?.find((site) => site?.value === watch("siteId"))?.value
    setValue("siteId", siteValue)
    if (!siteValue) {
      setValue("equipmentId", {})
    }
  }


  const columns = new TableColumn({ EmissionCategory, EmissionVolume });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={0} columnSpacing={"30px"}>
        <Grid item xs={6} md={6}>
          <SelectField
            selectlabel={"Asset"}
            placeholder={"Select Asset"}
            options={convertArrayToObject(
              assetMasterData,
              "assetName",
              "assetId"
            )}
            name="assetId"
            onChange={(e: any) => {
              setValue("assetId", e?.target?.value)
              handleClick("asset")
            }}
            value={watch("assetId")}
            errorText={errors["assetId"]?.message}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <SelectField
            selectlabel={"Site"}
            placeholder={"Select Site"}
            options={convertArrayToObject(siteMasterData, "siteName", "siteId")}
            name="siteId"
            onChange={(e: any) => {
              setValue("siteId", e?.target?.value)
              clearErrors("siteId")
              handleClick("site")
            }}
            value={watch("siteId")}
            Asterisk={true}
            errorText={errors["siteId"]?.message}
          />
        </Grid>
        <Divider
          sx={{ borderColor: "#0000001A", m: "15px 0", width: "100%" }}
        />

        <Grid item xs={6} md={6}>
          <SearchableSelect
            selectlabel="Equipment"
            buttonPlaceholder={"Select Equipment"}
            options={equipmentMasterDataWithAll}
            onSelectChange={handleChange}
            value={watch("equipmentId")}
            Asterisk={true}
            errorText={errors["equipmentId"]?.message}
            disabled={!watch("siteId")}
          />
        </Grid>

        {watch("equipmentId")?.value ? (

          <Grid item md={12} xs={12}>
            {rowData.length > 1
              &&
              <Box sx={{ marginTop: "15px" }}>
                <InfoText Text="Emission volume is proportionally distributed as per standard emission factor." />
              </Box>
            }
            <Box sx={{ mt: "15px" }} className="verifyEmission-table">
              <CrudTable
                rows={rowData}
                columns={columns.column}
                showCheckbox={false}
                showSearch={false}
                seachField={false}
                removePagination={true}
              />
            </Box>
            {emissionVolumeSumRef?.current && (
              <ErrorMessage message={emissionVolumeSumRef?.current} />
            )}
          </Grid>

        ) : null}

        <Divider
          sx={{ borderColor: "#0000001A", m: "15px 0", width: "100%" }}
        />

        {watch("equipmentId")?.value !== "all" ? (
          <Grid item xs={6} md={6} sx={{ mb: "20px" }}>
            <SelectField
              selectlabel={"Component"}
              placeholder={"Select Component"}
              options={equipmentMasterDataWithAll?.length ? convertArrayToObject(
                componentMasterData,
                "componentId",
                "componentId"
              ) : []}
              name="componentId"
              register={register}
              value={watch("componentId")}
              Asterisk={false}
              errorText={errors["componentId"]?.message}
              disabled={!watch("equipmentId")?.value}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={12}>
          <TextArea
            label="Comments"
            name="comment"
            minRows={5}
            register={register}
            placeholder="Enter Comments"
            Asterisk={false}
            maxLength={200}
          />
        </Grid>
        <Grid item xs={12} md={12} className="flex-center" sx={{ mt: "20px" }}>
          <PrimaryButton
            label="Verify"
            variant="contained"
            color="primary"
            width="358px"
            type={"submit"}
          />
        </Grid>
      </Grid>
    </form>
  );
}
