import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import { AbatementConstants } from "./constants";
import EyeOpenIcon from "../../components/atoms/icons/eyeopenIcon";
import ViewIcon from "../../components/atoms/icons/viewIcon";
import { roundOffNumber } from "../../utils/helper";

interface Payload {
  handleIconButtonClick: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    const { columnName } = AbatementConstants;
    this.column = [
      {
        id: "id",
        label: columnName.assetId,
        sortable: false,
        width: "9%",
        render: (row: Row) => row?.assetId?.assetId,
      },
      {
        id: "initiative",
        label: columnName.initiative,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.initiativeId?.name,
      },
      {
        id: "emission-sub-cat",
        label: columnName.emissionSubCat,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.emissionSubcategoryId?.name,
      },
      {
        id: "volume",
        label: columnName.volume,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.volumneAbatedtOfCh4,
      },
      {
        id: "abated",
        label: columnName.abated,
        sortable: false,
        width: "8%",
        render: (row: Row) =>
          roundOffNumber((row?.percentageAbated * 100).toPrecision(3)) + "%",
      },
      {
        id: "capex",
        label: columnName.capex,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.capexMusd,
      },
      {
        id: "abatementCost",
        label: columnName.abatementCost,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.abatementCost,
      },
      {
        id: "status",
        label: columnName.status,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.status,
      },
      {
        id: "comments",
        label: columnName.comments,
        sortable: false,
        width: "8%",
        render: (row: Row) => row?.comments,
      },
      {
        id: "edit",
        label: "Edit",
        sortable: false,
        align: "center",
        width: "5%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box
              className="downloadIcon flex-center"
              onClick={() => payload.handleIconButtonClick(row)}
            >
              <EditIcon />
            </Box>
          </Box>
        ),
      },
    ];
  }
}

export default TableColumn;
