export const forgetPasswordConstants = {
  modalText: "To change or reset your password please contact your admin.",
};
export const loginConstants = {
  label: {
    email: "email id",
    password: "password",
  },
  placeholder: {
    email: "Enter your Email ID",
    password: "Enter your password",
  },
  button: {
    forgotpassword: "forgot password",
    login: "login",
  },
  text: {
    errorText: "Incorrect Email ID or Password entered.",
    copyrightText: "SOCAR Copyright © 2024",
    logoText: "Methane Lite Platform",
  },
};
