import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";

type IntialState = {
  isLoader: any,
  timeToExpire: number,
}


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState: IntialState = {
  isLoader: { isShow: false, component: "trendline" },
  timeToExpire: 1000,
};

const loaderSlice: any = createSlice({
  name: "loader",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    setLoderVisibility: (state, { payload }) => {
      state.isLoader = payload
      state.timeToExpire = 1000
    },

  }
});

export const {
  clearMasterState,
  setLoderVisibility
} = loaderSlice.actions;

// Action creators are generated for each case reducer function
export const loaderSelector = (state: any) => state.loader;

export default loaderSlice.reducer;

