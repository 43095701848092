


export const ogmpData = [
  {
    "assetName": "Dummy Asset",
    "assetId": "ASSET001",
    "assetType": "Production onshore",
    "assetDescription": "Description for asset one",
    "assetCountry": "Azerbaijan",
    "assetLatitude": "12.345678",
    "assetLongitude": "98.765432",
    "assetOperated": "NO",
    "assetOperator": "Tagiyev",
    "asseteQuityPercentage": "5000.00",
    "assetEquityType": "Working Interest",
    "assetGeom": "0101000020E6100000F6D37FD6FCB05840B4E4F1B4FCB02840",
    "totalEmisson": 58,
    "uncertainty": null,
    "comments": null,
    "commentsZeroEmissions": null,
    "commentsOnVariation": null,
    "investments": null,
    "Fugitive Component and Equipment Leaks": {
      "totalMethaneEmission": 34,
      "assetReportingLevel": "Level 1",
      "uncertainty": null,
      "L4Method": null
    },
    "Venting": {
      "Venting Reciprocating compressor emissions": {
        "totalMethaneEmission": 12,
        "assetReportingLevel": "Level 1",
        "uncertainty": null,
        "L4Method": null
      },
      "Venting - Other": {
        "totalMethaneEmission": 12,
        "assetReportingLevel": "Level 1",
        "uncertainty": null,
        "L4Method": null
      },
      "Venting Centrifugal Compressor seal emission": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Venting: Pneumatics": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Venting: Well casinghead": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Venting: Liquids unloading": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Venting: Unstabilized liquid tanks": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Venting: Monoethylene glycol emissions": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      },
      "Hydraulic fracture completions": {
        "totalMethaneEmission": null,
        "assetReportingLevel": null,
        "uncertainty": null,
        "L4Method": null
      }
    },
    "Other Sources": {
      "totalMethaneEmission": null,
      "assetReportingLevel": null,
      "uncertainty": null,
      "L4Method": null,
      "others": null
    },
    "Level 5 - Total emissions": {
      "totalMethaneEmission": null,
      "uncertainty": null,
      "additionalInformation": null
    },
    "Stationary combustion": {
      "totalMethaneEmission": null,
      "assetReportingLevel": null,
      "uncertainty": null,
      "L4Method": null
    },
    "Flaring (Incomplete Combustion)": {
      "totalMethaneEmission": null,
      "assetReportingLevel": null,
      "uncertainty": null,
      "L4Method": null
    }
  }
]