import React from "react";
import { Box, Typography } from "@mui/material";
import { ErrorMessageProps } from "./interface";
import { styled } from "@mui/system";
import ErrorIcon from "../icons/errorIcon";

// Styled components
const ErrorBox = styled(Box)(({}) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginTop: "15px",
}));

const ErrorText = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "16.94px",
  color: "#E4002B",
}));

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  icon = <ErrorIcon />,  
}) => {
  return (
    <ErrorBox>
      {icon}
      <ErrorText variant="body2">{message}</ErrorText>
    </ErrorBox>
  );
};


const FormErrorText = styled(Typography)(() => ({
  fontSize: "12px", 
  lineHeight: "14.52px",
  color: "#E4002B",
  marginTop: "8px",
}));

export const FormError: React.FC<ErrorMessageProps> = ({
  message,
}) => {
  return (
    <FormErrorText variant="body2">{message}</FormErrorText>
  );
};
