import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import { useSelector } from "react-redux";
import {
  assetMasterSelector,
  getAssetList,
} from "../../store/slices/assetMaster";
import TableColumn from "./tableColumn";
import {
  FilterButton,
  PrimaryButton,
} from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import PlusIcon from "../../components/atoms/icons/plusIcon";
import { useNavigate } from "react-router-dom";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import { GasFactorConstants } from "./constants";
import AddGasFactor from "./addGasFactor";
import {
  gasMasterSelector,
  getGasList,
} from "../../store/slices/gasFactorMaster";
import FilterGasFactor from "./FilterGasFactor";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";

export default function GasMaster() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assetMasterData }: any = useSelector(assetMasterSelector);
  const { gasFactorMasterData, total, gasTypes } = useSelector(gasMasterSelector);
  const { headers, button, text } = GasFactorConstants;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [alertType, setAlertType] = useState(true);
  const [addGasVisibility, setAddGasVisibility] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addGasFactor);
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [editGasFactorData, setEditGasFactorData] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [assetFilter, setAssetFilter] = useState<any>([])
  const [gasFilter, setGasFilter] = useState<any>([])
  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    let params = `?page=${page}&limit=${pageSize}&order=DESC`
    if (assetFilter?.length) {
      assetFilter?.forEach((item: any) => {
        params += `&asset_id=${item}`
      });
    }
    if (gasFilter?.length) {
      gasFilter?.forEach((item: any) => {
        params += `&gas_type=${item}`
      });
    }
    dispatch(getGasList(params, true));
  }, [page, pageSize, assetFilter, gasFilter]);

  const toggleAddGasFactor = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string, page?: number, pageSize?: number) => {
      setAddGasVisibility(status);
      setPageTitle(headers.addGasFactor);
      if (type === "edit") {
        setAlertType(false);
      } else {
        setEditGasFactorData(null);
      }

      if (showSuccessModal) {
        if (page) setPage(page);
        setAlertModalVisibility(true);
        dispatch(getGasList(`?page=${page}&limit=${pageSize}`, false));
      }
    },
    []
  );

  const handleIconButtonClick = (row: any) => {
    setEditGasFactorData(row);
    setPageTitle(headers.editGasFactor);
    setAddGasVisibility(true);
  };

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={button.addGasFactor}
        variant="contained"
        onClick={() => toggleAddGasFactor(true)}
        startIcon={<PlusIcon />}
        // width="219px"
        className="small-btn"
      />
    </Stack>
  );

  const columns: any = new TableColumn({ handleIconButtonClick });

  const handleFilterVisibility = (state: boolean) => {
    setOpen(state);
  };

  return (
    <>
      {
        (isLoader?.isShow && isLoader.component === "gasFactorList") && <CircularLoader />
      }
      {addGasVisibility ? (
        <AddGasFactor
          pageTitle={pageTitle}
          onBack={(
            status: boolean,
            modalStatus?: boolean,
            alertStatus?: string
          ) => toggleAddGasFactor(status, modalStatus, alertStatus, page, pageSize)}
          editGasData={editGasFactorData}
        />
      ) : (
        <DefaultLayout
          renderHeaderButtons={renderHeaderButtons}
          pageTitle="Gas Factor Master"
        // backHandler={() => false}
        >
          <Box>
            <CrudTable
              rows={gasFactorMasterData}
              columns={columns.column}
              initialRowsPerPage={5}
              showCheckbox={false}
              showSearch={true}
              page={page}
              total={total}
              rowsPerPage={pageSize}
              setPage={setPage}
              setRowsPerPage={setPageSze}
              searchApi={getGasList}
              searchPlaceholder="Search Gas Type..."
              renderButtons={renderButtons}
              seachField={false}
            />
          </Box>

          <FilterGasFactor
            open={open}
            assetData={assetMasterData}
            gasFactorData={gasFactorMasterData}
            onClose={() => {
              handleFilterVisibility(false);
            }}
            filterCB={(val1: any, val2: any) => {
              setAssetFilter(val2);
              setGasFilter(val1);
              setPage(1)
            }}
          />

          <StatusModal
            type="success"
            bodyText={
              alertType ? text?.addGasFactorAlert : text?.editGasFactorAlert
            }
            open={alertModalVisibility}
            onClose={() => setAlertModalVisibility(false)}
          />
        </DefaultLayout>
      )}
    </>
  );
}
