import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../../api/services/authService";


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  access_token: null,
};

const authenticationSlice: any = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearAuthenticationState: () => initialState,
    setToken: (state, { payload }) => {
      state.access_token = payload;
    },
  },
});

export const {
  clearAuthenticationState,
  setToken,
} = authenticationSlice.actions;

// Action creators are generated for each case reducer function
export const authenticationSeletor = (state: any) => state.authentication;

export default authenticationSlice.reducer;

export const loginAction = (payload: any, callBack: any) => {
  return async (dispatch: any) => {
    try {
      const res = await AuthService.loginService(payload);
      if (res.status === 201) {
        dispatch(setToken(res.data.token.accessToken));
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
    }

  };
}

