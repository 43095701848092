// @ts-nocheck
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { AddAssetProps, AddAssetSubmitProps } from "./interface";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { AbatementConstants } from "./constants";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../../components/atoms/InputField/InputField";
import SelectField from "../../components/atoms/SelectField/SelectField";
import { PrimaryButton } from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { abatementMasterSelector } from "../../store/slices/abatementMaster";
import {
  addAbatement,
  editAbatement,
  getAssetList,
  getInitiativeList,
  getEmissionList,
} from "../../store/slices/abatementMaster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validations";
import ArrowRight from "../../components/atoms/icons/arrowRight";
import TextArea from "../../components/atoms/textarea/textarea";
import { FieldArray } from "./AddAbatementField";
import CustomLabel from "../../components/atoms/label/label";
import { roundOffNumber } from "../../utils/helper";

const AddAbatement = React.memo(
  ({ pageTitle, onBack, editAbatementData }: AddAbatementProps) => {
    const dispatch: any = useAppDispatch();
    const {
      assetTypes,
      equityTypes,
      countries,
      operators,
      assetList,
      initiativeList,
      emissionList,
    } = useSelector(abatementMasterSelector);

    const { label, placeholder, button, name } = AbatementConstants;
    const { isLoader } = useSelector(loaderSelector);
    // Initialize react-hook-form
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
    } = useForm({
      defaultValues: {
        assetList: "",
        initiativeList: "",
        emissionList: "",
      },
      resolver: yupResolver(validations),
    });

    const [isEdit, setEdit] = useState(editAbatementData ? true : false);

    useEffect(() => {
      // Fetch asset types, countries, operators, and equity types
      Promise.all([
        dispatch(getAssetList()),
        dispatch(getInitiativeList()),
        dispatch(getEmissionList()),
      ]);
    }, []);

    useEffect(() => {
      setEditData();
    }, [
      assetTypes,
      equityTypes,
      countries,
      operators,
      assetList,
      initiativeList,
      emissionList,
    ]);

    const setEditData = () => {
      if (editAbatementData) {
        const editData = { ...editAbatementData };
        const assetTypeId = assetList.filter(
          (val) => val.assetId === editData.assetId.assetId
        );
        for (var key in editData) {
          if (key === "assetId" && assetTypeId.length) {
            setValue(name[key], assetTypeId[0].assetId);
          }
          if (key === "initiativeId") {
            setValue("initiative", editData.initiativeId.id);
          }
          if (key === "emissionSubcategoryId") {
            setValue("emission", editData?.emissionSubcategoryId?.id);
          }
          if (key === "percentageAbated") {
            setValue(
              "percentAbated",
              roundOffNumber((editData?.percentageAbated * 100).toPrecision(3))
            );
          }
          if (key === "volumneAbatedtOfCh4") {
            setValue("volume", editData?.volumneAbatedtOfCh4);
          }
          if (key === "abatementCost") {
            setValue(name[key], editData?.abatementCost);
          }
          if (key === "capexMusd") {
            setValue("capex", editData?.capexMusd);
          }
          if (key === "comments") {
            setValue("comments", editData?.comments);
          }
          if (key === "status") {
            setValue("status", editData?.status);
          }
        }
      }
    };

    const onSubmit: SubmitHandler<any> = (payload: AddAssetSubmitProps) => {
      const payloadData = {
        assetId: payload?.assetId,
        emissionSubcategoryId: parseFloat(payload?.emission),
        initiativeId: parseFloat(payload?.initiative),
        volumneAbatedtOfCh4: parseFloat(payload?.volume),
        percentageAbated: parseFloat(payload?.percentAbated / 100),
        abatementCost: parseFloat(payload?.abatementCost),
        capexMusd: parseFloat(payload?.capex),
        comments: payload?.comments,
        status: payload?.status,
      };
      if (editAbatementData) {
        dispatch(editAbatement(payloadData, editAbatementData.id, successCB));
      } else {
        dispatch(addAbatement(payloadData, successCB));
      }
    };

    const successCB = (alert: boolean) => {
      onBack(false, alert || false, editAbatementData?.assetId);
    };

    const renderHeaderButtons = () => (
      <Stack direction="row" spacing={2}>
        <PrimaryButton
          label={button.submit}
          variant="contained"
          type={"submit"}
          // endIcon={<ArrowRight sx={{ width: "7px" }} />}
          width="95px"
        />
      </Stack>
    );

    const { fieldArray } = new FieldArray({
      emissionList,
      initiativeList,
      assetList,
      isEdit,
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoader?.isShow && isLoader.component === "abatement" && (
          <CircularLoader />
        )}
        <DefaultLayout
          pageTitle={pageTitle}
          // renderHeaderButtons={renderHeaderButtons}
          backHandler={() => onBack(false)}
        >
          <Grid container spacing={"30px"} sx={{ p: "10px 0 10px" }}>
            {fieldArray.map((field: any) => (
              <>
                {field.type === "input" && (
                  <Grid item xs={6} md={4}>
                    <InputField
                      label={label[field.fieldName]}
                      type={field?.inputType || "text"}
                      disabled={field.disabled}
                      name={name[field.fieldName]}
                      register={register}
                      placeholder={placeholder[field.fieldName]}
                      errorText={errors[field.fieldName]?.message}
                    />
                  </Grid>
                )}
                {field.type === "dropdown" && (
                  <Grid item xs={6} md={4}>
                    <SelectField
                      selectlabel={label[field.fieldName]}
                      placeholder={placeholder[field.fieldName]}
                      options={field?.options}
                      name={name[field.fieldName]}
                      register={register}
                      value={watch(name[field.fieldName]) || ""}
                      errorText={errors[field.fieldName]?.message}
                    />
                  </Grid>
                )}

                {field.type === "textArea" && (
                  <Grid item xs={12} md={12}>
                    <Divider sx={{ borderColor: "#0000001A", mb: "30px" }} />
                    <TextArea
                      label={label[field.fieldName]}
                      type="text"
                      name={name[field.fieldName]}
                      minRows={5}
                      Asterisk={false}
                      register={register}
                      placeholder={placeholder[field.fieldName]}
                      errorText={errors[field.fieldName]?.message}
                      maxLength={200}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
          <Stack direction="row" spacing={2} sx={{ marginTop: '20px' }}>
            <PrimaryButton
              label={button.submit}
              variant="contained"
              type={"submit"}
              // endIcon={<ArrowRight sx={{ width: "7px" }} />}
              width="95px"
            />
          </Stack>
        </DefaultLayout>
      </form>
    );
  }
);

export default AddAbatement;
