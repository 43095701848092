import styled from "@emotion/styled";
import { Tabs } from "@mui/material";
import { StyledTabsProps } from "./ineterface";

export const StyledTabs = styled(Tabs)<StyledTabsProps>`
  min-height: 0;

  .MuiTabs-indicator {
    background-color: #64a70b; // Custom indicator color   
    top: ${({ size }) => (size === "large" ? 0 : "unset")};
    bottom:  ${({ size }) => (size === "large" ? "unset" : 0)}; 
    height: ${({ size }) => (size === "large" ? "4px" : "unset")};   
    border-radius: ${({ size }) => (size === "large" ? "12px 12px 0 0" : "unset")};    
   
  }

  .MuiTabs-flexContainer {
    gap: 25px;
    border-bottom: 1px solid #0000001a;
    

    .MuiTab-root {
      font-size: ${({ size }) => (size === "large" ? "20px" : "16px")};
      font-weight: ${({ size }) => (size === "large" ? "700" : "500")};
      line-height: 19.36px;
      color: ##615D5F;
      padding: 0;
      padding-bottom: ${({ size }) => (size === "large" ? "0px" : "11px")};
      padding-top: ${({ size }) => (size === "large" ? "16px" : "0px")};
      min-width: unset;
      min-height: 0;
      text-transform: none; // Change text to lowercase

      &:hover {
        color: #3C8200;
      }

      &.Mui-selected {
        color: #3C8200;
        border-bottom: none;
      }
    }
  }
`;
