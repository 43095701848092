// CommonInput.tsx
import React, { useState } from "react";
import { CommonInputProps } from "./interface";
import {
  TextField,
  FormControl,
  styled,
  InputAdornment,
  IconButton,
  Box,
  OutlinedInput,
} from "@mui/material";
import CustomLabel from "../label/label";
import EyeOpenIcon from "../icons/eyeopenIcon";
import EyeCloseIcon from "../icons/eyeCloseIcon";
import { FormError } from "../ErrorMessage/errorMessage";

const CustomFormControl = styled(FormControl)<{ width?: number }>(
  ({ theme, width }) => ({
    margin: theme.spacing(0, 0),
    width: width ? `${width}px` : "100%",
  })
);

const CustomTextField = styled(OutlinedInput)<{ serachBox?: boolean }>(
  ({ serachBox }) => ({
    borderRadius: "10px",
    "& .MuiInputBase-input": {
      fontSize: "16px",
      fontWeight: 500,
      color: "#4D4B4C",
      padding: "0 12px",
      height: "3rem",
      "&::placeholder": {
        color: serachBox ? "#8F8CA1" : "#898587",
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        opacity: 1,
      },
    },
    "& fieldset": {
      border: serachBox ? "1px solid #B3B0B1" : "1px solid #B3B0B1",
    },
    ".MuiInputAdornment-positionStart": {
      marginRight: 0,
    },
  })
);

const InputField: React.FC<CommonInputProps> = ({
  label,
  type,
  value,
  placeholder,
  onChange,
  endAdornment,
  variant,
  min,
  max,
  register,
  startIcon,
  classes,
  serachBox = false,
  width,
  labelHelperText,
  name,
  errorText,
  Asterisk,
  disabled,
  ...rest
}) => {
  let registerProps = {};
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    if (
      type === "number" &&
      (e.key === "e" || e.key === "E" || e.key === "+")
    ) {
      e.preventDefault();
    }
    if (
      type === "number" &&
      !["latitude", "longitude"].includes(e.target.name) &&
      e.key === "-"
    ) {
      e.preventDefault();
    }
    if (e.key === " " && e?.target?.value?.length === 0) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text");
    const isValid = /^[^eE+\-]*$/.test(pasteData);
    const nonExponential = /^[^eE+]*$/.test(pasteData);

    if (
      type === "number" &&
      !isValid &&
      !["latitude", "longitude"].includes(e.target.name)
    ) {
      e.preventDefault();
    }
    if (
      type === "number" &&
      !nonExponential &&
      ["latitude", "longitude"].includes(e.target.name)
    ) {
      e.preventDefault();
    }
  };

  if (register) {
    registerProps = register(name);
  }

  return (
    <CustomFormControl
      width={width}
      fullWidth
      margin="normal"
      disabled={disabled}
      error={Boolean(errorText)}
    >
      {label &&
        (labelHelperText ? (
          <Box className="flex spacebetween">
            <CustomLabel inputLabel={label} Asterisk={Asterisk} />
            {labelHelperText()}
          </Box>
        ) : (
          <CustomLabel inputLabel={label} Asterisk={Asterisk} />
        ))}

      <CustomTextField
        type={type === "password" && showPassword ? "text" : type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        serachBox={serachBox}
        startAdornment={
          startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null
        }
        endAdornment={
          endAdornment ||
          (type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disableRipple
                disabled={disabled}
              >
                {showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
              </IconButton>
            </InputAdornment>
          ))
        }
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        {...registerProps}
        {...rest}
        className={classes}
        inputProps={{ step: "any" }}
        autoComplete="off"
      />

      {errorText && <FormError message={errorText} />}
    </CustomFormControl>
  );
};

export default InputField;
