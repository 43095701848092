
import { Accordion, Pagination, Stack, TableBody, TableHead, styled } from "@mui/material";

export const StyledTableHead = styled(TableHead)(() => ({
  borderBottom: "1px solid #DDE3EC",

  "& .MuiTableCell-head": {
    padding: "8px 10px 23px",
    borderBottom: 0,
    "& .MuiTableSortLabel-root": {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "16.94px",
      color: "#2E2E2E",
    },
  },
}));

export const StyledTableBody = styled(TableBody)(() => ({
  "& .MuiTableCell-body": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16.94px",
    color: "#585962",
    padding: "10px 0",
    borderBottom: 0,
  },  
  
  "& .Collapsible-nodata:nth-of-type(odd)": {
    backgroundColor: "#ffffff", // light grey color for odd rows
  },
  "& .Collapsible-nodata:nth-of-type(even)": {
    backgroundColor: "#F9FAFD", // white color for even rows
  },
  
}));

export const StylePagination = styled(Pagination)(() => ({
  "& .MuiPagination-ul": {
    display: "flex",
    gap: "6px",
    "& li:first-child": {
      "& .MuiPaginationItem-root": {
        rotate: "180deg",
      }
    },
    "& .MuiPaginationItem-root": {
      display: "flex",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16.94px",
      color: "#636262b2",
      padding: "2px",
      minWidth: "20px",
      height: "20px",
      "&:hover": {
        backgroundColor: "#EFF1F7",
        borderRadius: "3px",
       
      },
      "&.Mui-selected": {
        backgroundColor: "#64a70b",
        borderRadius: "3px",
        color: "#fff",
      },
    },
  },
}));


export const StyleRowPerPage = styled(Stack)(() => ({
  alignItems: "center",
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    color: "#636262",
  },
}));


export const StyleAccodian = styled(Accordion)(() => ({
  backgroundColor: "#F9FAFD",
  
  borderRadius: "10px !important",
  boxShadow: "none",
  "& .accordianSummaryBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    minHeight: "54px",
   
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F1F9E6",
    borderRadius: "10px 0 15px 0",
    padding: "0 10px",  
    margin: "0px",  
    flexDirection: "row-reverse",
    minHeight: "unset",
    gap: "4px",    
    "& .MuiAccordionSummary-content": {
      margin: "0px",
      "& .MuiTableCell-root": {
        padding: "10.54px 0",
      "& .accordian-header": {
        display: "flex",
        gap: "10px",
        "& .duplicates-record": {
          fontSize: "14px",
          fontWeight: 500,
          color: "#29292A", 
          letterSpacing: "0.037px"
          
        }     
      }
    }
    },
  },
  "& .Mui-expanded": {
    minHeight: "unset",
  },
  
  "& .MuiButton-root": {
    textTransform: "capitalize",
    fontSize: "14px",   
    fontWeight: 400,  
    letterSpacing: "0.01px", 
  }
},
  "& .MuiAccordionDetails-root": {
    padding: 0,
    marginTop: "10px",
    marginBottom: "10px",
    "& .MuiTableRow-root": {
      borderBottom: "1px solid #DDE3EC",
      "& .MuiTableCell-root": {
        padding: "21.5px 10px",
      }
    }
  },
}));
