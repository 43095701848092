import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";
import { convertDataToGroup } from "../../utils/helper";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  equipmentMasterData: [],
  equipmentTypeList: [],
  equipmentModelList: [],
  equipmentSubTypeList: [],
  typeOfSealsList: [],
  typeOfWellList: [],
  agentProcessedList: [],
  engineTypeList: [],
  productionMethodList: [],
  equipmentClusterList: [],
  equipmentSubCategorySiteList: [],
  equipmentListByType: [],
  volumePercentage: [],
  total: 0,
  totalEquipmentType: 0
};

const equipmentMasterSlice: any = createSlice({
  name: "equipmentMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    setEquimentMasterList: (state, { payload }) => {
      state.equipmentMasterData = payload.data;
      state.total = payload?.meta?.itemCount
    },
    setMasterList: (state: any, { payload }) => {
      state[payload.master] = payload.data;
    },
    setEquipmentSubCategoryListSite: (state, { payload }) => {
      state.equipmentSubCategorySiteList = payload;
    },
    setVolumePercentage: (state, { payload }) => {
      state.volumePercentage = payload;
    },
    setEquipmentListByType: (state: any, { payload }) => {
      state.totalEquipmentType = payload?.length
      state.equipmentListByType = payload?.length ? convertDataToGroup(payload, "equipmentStatic", "equipmentType") : [];;
    },
  },
});

export const {
  clearMasterState,
  setEquimentMasterList,
  setMasterList,
  setEquipmentSubCategoryListSite,
  setVolumePercentage,
  setEquipmentListByType,
} = equipmentMasterSlice.actions;

// Action creators are generated for each case reducer function
export const equipmentMasterSelector = (state: any) => state.equipmentMaster;

export default equipmentMasterSlice.reducer;

export const getEquipmentMasterData = (params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "equipmentList" }));
    }
    try {
      const res = await MasterService.getEquipmentMaster(params)
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }));
        }
        
        dispatch(setEquimentMasterList(res.data))
        if (callBack) {
          callBack("equipment")
        }
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }));
    }
  };
}

export const addEquipment = (data: any, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "equipmentList" }))
    try {
      const res = await MasterService.addEquipment(data)
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }))
        successCB(true)
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }))
      dispatch(setErrorMessage(error))
    }
  };
}

export const updateEquipment = (id: string, data: any, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "equipmentList" }))
    try {
      const res = await MasterService.editEquipment(data, id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }))
        successCB(true)
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }))
      dispatch(setErrorMessage(error))
    }
  };
}

export const updateEquipmentStatus = (id: string, data: any, successCB: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.updateEquipmentStatus(data, id)
      if (res.status === 200) {
        successCB(true)
      }
    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  };
}

export const getEquipmentDetails = (id: string, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "equipmentDetails" }));
    try {
      const res = await MasterService.getEquipmentDetail(id)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "equipmentDetails" }));
        successCB(res.data)
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "equipmentDetails" }));
    }
  };
}


export const getEquipmentTypeList = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentTypeList()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "equipmentTypeList" }))
      }
    } catch (error) {
      console.log("error - ", error)
    }
  };
}


export const getEquipmentTypeListBySubCategory = (id: string | number) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentTypeByCategory(id)
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data, master: "equipmentTypeList" }))
      }
    } catch (error) {
      console.log("error - ", error)
    }
  };
}


export const getEquipmentModel = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentModel()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "equipmentModelList" }))
      }
    } catch (error) {
    }
  };
}


export const getEquipmentSubType = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentSubType()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "equipmentSubTypeList" }))
      }
    } catch (error) {
    }
  };
}



export const getTypeOfSeals = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getTypeOfSeals()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "typeOfSealsList" }))
      }
    } catch (error) {
    }
  };
}




export const getTypeOfWell = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getTypeOfWell()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "typeOfWellList" }))
      }
    } catch (error) {
    }
  };
}





export const getAgentProcessed = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getAgentProcessed()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "agentProcessedList" }))
      }
    } catch (error) {
    }
  };
}

export const getEngineType = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEngineType()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "engineTypeList" }))
      }
    } catch (error) {
    }
  };
}

export const getProductionMethod = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getProductionMethod()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "productionMethodList" }))
      }
    } catch (error) {
    }
  };
}

export const getEquipmentClusterList = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentClusterList()
      if (res.status === 200) {
        dispatch(setMasterList({ data: res.data.data, master: "equipmentClusterList" }))
      }
    } catch (error) {
    }
  };
}

export const getEquipmentSubCategoryBySiteOrEquip = (id: string, callBack: any, params?: string) => {
  return async (dispatch: any) => {
    // dispatch(setLoderVisibility({ isShow: true, component: "fetchVerifyData" }));
    try {
      const res = await MasterService.getEquipmentSubCategoryBySiteOrEquip(id, params)
      if (res.status === 200) {
        // dispatch(setLoderVisibility({ isShow: false, component: "fetchVerifyData" }));
        callBack(res.data)
      }
    } catch (error) {
      // dispatch(setLoderVisibility({ isShow: false, component: "fetchVerifyData" }));
    }
  };
}


export const getEmissionSubCategoryBySite = (params: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getEquipmentBySite(params)
      if (res.status === 200) {
        dispatch(setEquipmentSubCategoryListSite(res?.data))
      }

    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  };
}

export const getEmissionVolume = (params: any, callBack: any) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getNotificationProportional(params)
      if (res.status === 200) {
        console.log("rrr", res?.data);
        callBack(res?.data);
        // dispatch(setVolumePercentage(res?.data))
      }

    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  };
}

export const getEquipmentMasterDataByType = (id: string | undefined, params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setEquipmentListByType([]))
      dispatch(setLoderVisibility({ isShow: true, component: "equipmentList" }));
    }
    try {
      const res = await MasterService.getEquipmentMasterListByType(id, params);
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }));
        }
        dispatch(setEquipmentListByType(res.data))
        if (callBack) {
          callBack("equipment")
        }
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "equipmentList" }));
    }
  };
}