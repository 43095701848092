export const masterOptions = [
  { value: "asset", label: "Asset Master" },
  { value: "site", label: "Site Master" },
  { value: "equipment-static", label: "Equipment Master" },
  { value: "component", label: "Component Master" },
  { value: "gas-factor", label: "Gas Factor Master" },
  { value: "emission-factor", label: "Emission Factor Master" },
];



export const detectionOptions = [
  { value: "satellite-detection", label: "Satellite" },
  { value: "drone-detection", label: "Drone" },
  { value: "bottom-up-detection", label: "Bottom-up" },
  { value: "manual-detection", label: "Manual Input" },
];

export const operationalOptions = [
  {
    value: "repair-and-maintenance-operational",
    label: "Repair and Maintenance Master",
  },
  {
    value: "equipment-operational",
    label: "Equipment Operational Master",
  },
  { value: "maintenance-schedule-operational", label: "Maintenance Schedule" },
  { value: "abatement-data", label: "Abatement Data" },
  { value: "intensity-calculation-operational", label: "Production Data" },
];

export const tabsInformation = [
  {
    id: 0,
    placeHolder: "Select Detection Method",
    dropDownLabel: "SELECT DETECTION METHOD",
    dropDownOptions: detectionOptions,
    labelToSend: "detection"
  },
  {
    id: 1,
    placeHolder: "Select Master",
    dropDownLabel: "SELECT MASTER",
    dropDownOptions: masterOptions,
    labelToSend: "masters"
  },
  {
    id: 2,
    placeHolder: "Select Operational Data",
    dropDownLabel: "SELECT OPERATIONAL DATA",
    dropDownOptions: operationalOptions,
    labelToSend: "operational"
  },
];

export const tabs = [
  { label: "Detection Data", value: "detection" },
  { label: "Masters", value: "masters" },
  { label: "Operational Data", value: "operational" },
];

export const headers = {
  pageTitle: "Upload Data"
}

export const browseButtonTitle = "BROWSE AND UPLOAD";
export const downloadTemplateTitle = "DOWNLOAD TEMPLATE";

export const mandatoryField: any = {
  "asset": [
    "Asset ID",
    "Asset Name",
    "Asset Type",
    "Asset Description",
    "Country",
    "Latitude",
    "Longitude",
    "Operator",
    "Operated?",
    "% Equity (Note- Ownership of emissions)",
    "Reporting Level",
    "Specify type of equity",
  ],
  "site": [
    "Site ID",
    "Site Name",
    "Site Type",
    "Asset ID",
    "Latitude",
    "Longitude"
  ],
  "equipment-static": [
    "Equipment type",
    "Equipment cluster",
    "Number of relevant equipment",
    "Equipment model",
    "Site ID",
    "Equipment ID",
    "Residual pressure (atm)",
    "Time to address leak (days)",
    "Volume of pig receiver (m3)",
    "Length (m)",
    "Decommissioned",
    "Perform casing head venting applicable?",
    "Standby factor (%)",
    "Agent processed",
    "Number of pistons",
    "Physical volume of maintained equipment (m3)",
    "Physical volume of engine (m3)"
  ],
  "component": [
    "Component ID",
    "Component Type",
    "Equipment ID",
    "Cluster",
    "Time to address leak (days)"
  ],
  "gas-factor": [
    "Asset ID",
    "Gas Type",
    "Mass content of CH4 (%)",
    "Density of gas (Kg/M3)"
  ],
  "emission-factor": [
    "Type Hierarchy",
    "Emission Sub-category",
    "Equipment/Component Type",
    "Cluster",
    "Units",
    "Emission factor",
    "OGMP level"
  ],
  "bottom-up-detection": [
    "LEC (Leak Equipment Code)",
    "Site ID",
    "Detection Technology",
    "Latitude",
    "Longitude",
    "2σ Uncertainty (%)",
    "Emission Rate (kg/hr)",
    "Emission Rate (kg/yr)",
    "Equipment ID",
    "Date"
  ],
  "manual-detection": [
    "Detection Method",
    "Detection Number",
    "Date",
    "Equipment ID",
    "Emission Rate (kg/h)",
    "Emission Subcategory",
  ],
  "maintenance-schedule-operational": [
    "Asset ID",
    "Equipment type",
    "Equipment cluster",
    "Date",
    "Type of scheduled intervention",
    "Number of interventions within period",
    "Aggregation level"
  ],
  "repair-and-maintenance-operational": [
    "Asset ID",
    "Equipment type",
    "Equipment ID",
    "LEC / Leak ID",
    "Date of intervention",
    "Maintenance type"
  ],
  "equipment-operational": [
    "Equipment ID",
    "Asset ID",
    "Equipment type",
    "Cluster",
    "Date",
    "Monthy total Liquid production/ throughput (bbl)",
    "Monthy total Gas production/thoughput (nm3)",
    "Monthly total Gas combusted (nm3)",
    "Monthly total hours in operation",
    "Monthly total hours in standby (pressurized)",
    "Monthly total hours in repair (unpressurized)",
    "Monthly total electricity consumption (kwH)",
    "Aggregation Level"
  ],
  "abatement-data": [
    "Asset ID",
    "Initiative",
    "Emission Sub-category",
    "Volume abated (t of CH4)",
    "% Abated",
    "Abatement Cost (USD/t CH4)",
    "CAPEX (MUSD)",
    "Status"
  ],
  "intensity-calculation-operational": [
    "Asset ID",
    "Date",
    "Monthly total Gas production/throughput (nm3)"
  ],
  "drone-detection": [
    "Source Hierarchy",
    "Site/Equipment ID",
    "DATE",
    "From Time",
    "Emission Rate (kg/hr)",
    "2σ Uncertainty (%)",
    "Latitude",
    "Longitude"
  ],
  "satellite-detection": [
    "Date",
    "Time (AZT)",
    "Emission Rate (kg/hr)",
    "2σ Uncertainty (%)",
    "Latitude",
    "Longitude"
  ]
};

export const fileTemplateNames: any = {
  "site": "site_template.csv",
  "asset": "asset_template.csv",
  "equipment-static": "equipment_template.csv",
  "component": "component_template.csv",
  "gas-factor": "gas_template.csv",
  "emission-factor": "emission_template.csv",
  "manual-detection": "manual_template.csv",
  "bottom-up-detection": "bottomUp_template.csv",
  "maintenance-schedule-operational": "maintenance_template.csv",
  "repair-and-maintenance-operational": "repair_and_maintenance_operational_template.csv",
  "equipment-operational": "equipment_operational.csv",
  "abatement-data": "abatement_operational.csv",
  "intensity-calculation-operational": "intensity_calculation_template.csv",
  "drone-detection": "drone_template.csv",
  "satellite-detection": "satellite_template.csv"
}

export const errorMessages = {
  fileMissmatch: "Incorrect file uploaded, please check the file and upload again",
  emptySheet: 'Empty sheet uploaded, please upload again',
  fileDownload: "File is being downloaded. Please update the file and re-upload again."
}

export const bottomUpArray = [["LEC (Leak Equipment Code)", "Video #", "Site ID", "Detection Technology", "Latitude", "Longitude", "Component ID", "Component Type", "2σ Uncertainty (%)", "Emission Rate (kg/hr)", "Emission Rate (kg/yr)", "Date", "Time", "Sub-type", "Equipment ID", "Equipment Type", "Emission Subcategory", "Flow(Ipm)", "Av. conc. HFS(ppm)", "Losses (m³/h)", "Losses (ft³/h)"],]

export const droneArray = [
  ["Source Hierarchy",
    "Site/Equipment ID",
    "DATE",
    "From Time",
    "To Time",
    "Emission Rate (kg/hr)",
    "FLIGHT ID",
    "Site Name/Equipment Type",
    "Flight Altitude Low (m)",
    "Flight Altitude High (m)",
    "2σ Uncertainty (%)",
    "Latitude",
    "Longitude",
    "Video"
  ],]

export const satelliteArray = [[
  "Source Hierarchy",
  "Site/Asset ID",
  "Detection Technology",
  "Date",
  "Time (AZT)",
  "Emission Rate (kg/hr)",
  "2σ Uncertainty (%)",
  "Latitude",
  "Longitude",
  "Video"
],]