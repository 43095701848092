import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";

type IntialState = {
  error: any,
  timeToExpire: number,
  type: string
}


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState: IntialState = {
  error: null,
  timeToExpire: 6000,
  type: "error"
};

const errorSlice: any = createSlice({
  name: "error",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    setError: (state, { payload }) => {
      state.error = payload.message
      state.type = payload?.type || "error"
    },

  }
});

export const {
  clearMasterState,
  setError
} = errorSlice.actions;

// Action creators are generated for each case reducer function
export const errorSelector = (state: any) => state.error;

export default errorSlice.reducer;

export const setErrorMessage = (error: any, type?: string) => {
  return async (dispatch: any) => {
    if (error) {
      if (error?.response?.status === 422) {
        dispatch(setError({ message: error?.response?.statusText, type }))
      } else {
        let errorMessage = ""
        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message
        } else if (error?.message) {
          errorMessage = error?.message
        } else {
          errorMessage = "Something went wrong."
        }
        dispatch(setError({ message: errorMessage, type }))
      }
    }
  };
}

