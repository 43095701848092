import React from "react";
export default function ChatAnswerLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
<path d="M6.62774 3.55574C6.62774 9.92889 1.28906 11.1216 1.28906 17.2473C1.28906 19.6387 2.79614 21.7097 4.57503 21.9571C4.44521 21.521 4.40262 21.1843 4.40262 20.7137C4.40262 16.2919 11.51 14.9207 11.51 7.37922C11.51 3.77277 8.96645 0.724129 5.99894 0C5.99894 0 6.62571 1.95738 6.62571 3.55371L6.62774 3.55574Z" fill="#FF2F2B"/>
<path d="M6.17969 20.9821C6.17969 22.2336 7.06609 23.5663 8.22226 24.0003C8.16141 23.8157 8.13301 23.6535 8.13301 23.3817C8.13301 20.9172 15.423 20.1322 15.423 13.6921C15.423 11.9112 14.6786 9.97818 13.2039 8.71045C12.206 16.0308 6.17969 17.0389 6.17969 20.9821Z" fill="#6EC248"/>
<path d="M2.35919 2.04492C2.35919 2.04492 2.7101 2.67575 2.7101 3.37959C2.7101 5.64123 0.574219 6.50329 0.574219 8.80143C0.574219 9.88661 1.16448 10.7771 1.99814 11.2943C2.02045 11.0976 2.04073 10.913 2.09144 10.7588C2.59042 9.16248 4.93116 7.39577 4.93116 4.89073C4.93116 3.38973 3.68574 2.15445 2.35919 2.04492Z" fill="#33ADE1"/>
</svg>
  );
}
