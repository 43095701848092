import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import "./login.scss";
import LoginBackground from "../../components/layout/LoginBackground";
import CustomLabel from "../../components/atoms/label/label";
import InputField from "../../components/atoms/InputField/InputField";
import { PrimaryButton } from "../../components/atoms/button/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./validation";
import { loginAction } from "../../store/slices/authenticationSlice";
import { useAppDispatch } from "../../store/hooks";
import ForgetPassword from "./ForgetPassword";
import { useNavigate } from "react-router-dom";
import ForwordArrowIcon from "../../components/atoms/icons/forwordArrowIcon";
import { Box, Divider } from "@mui/material";
import { ErrorMessage } from "../../components/atoms/ErrorMessage/errorMessage";
import { loginConstants } from "./constant";

const { label, placeholder, button, text } = loginConstants;

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validations), // Using yupResolver to validate form inputs
  });

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (watch("email") || watch("password")) {
      setError(false);
    }
  }, [watch("email"), watch("password")]);

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    dispatch(
      loginAction(payload, (status: boolean) => {
        if (status) {
          navigate("/dashboard");
        } else {
          setError(true);
        }
      })
    ); // Dispatching login action with payload and success callback
  };

  const labelHelperText = () => {
    return (
      <p className="forgetPassword" onClick={handleForgetPassword}>
        {button.forgotpassword}
      </p>
    );
  };

  const handleForgetPassword = () => {
    setOpen(!open);
  };

  return (
    <LoginBackground>
      <Box className="loginBox">
        <Box className="loginHeader flex-center">
          <span className="flex">
            <img
              src={require("../../assets/images/logo.svg")}
              width={110}
              alt="SOCAR"
            />{" "}
            {/* Displaying logo */}
          </span>
          <Divider orientation="vertical" sx={{ mx: 1.5 }} flexItem />
          <span className="MLP-text">{text.logoText}</span>{" "}
          {/* Displaying platform name */}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="loginForm">
            <InputField
              label={label.email}
              type="text"
              name="email"
              register={register}
              placeholder={placeholder.email}
              errorText={errors?.email?.message}
            />
            <Box>
              <InputField
                label={label.password}
                type="password"
                placeholder={placeholder.password}
                name="password"
                register={register}
                labelHelperText={labelHelperText}
                errorText={errors?.password?.message}
              />
            </Box>

            <PrimaryButton
              label="login"
              variant="contained"
              color="primary"
              endIcon={<ForwordArrowIcon />} // Displaying forward arrow icon
              width={"100%"}
              height={"56px"}
              type="submit"
            />
          </Box>
          {error && <ErrorMessage message={text.errorText} />}
        </form>
        <Divider sx={{ borderColor: "#0000001A" }} />
        <Box className="login-copyright flex-center">{text.copyrightText}</Box>
      </Box>
      <ForgetPassword open={open} handleClose={handleForgetPassword} />
    </LoginBackground>
  );
}
