import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  Persistor,
} from "redux-persist";
import { thunk } from "redux-thunk";
// @ts-ignore
import { encryptTransform } from "redux-persist-transform-encrypt";
import authenticationReducer from "./slices/authenticationSlice";
import siteMaster from "./slices/siteMaster";
import assetMaster from "./slices/assetMaster";
import abatementMaster from "./slices/abatementMaster";
import equipmentMaster from "./slices/equipmentMaster";
import uploadData from "./slices/uploadData";
import gasFactorMaster from "./slices/gasFactorMaster";
import componentMaster from "./slices/componentMaster";
import emissionMaster from "./slices/emissionMaster";
import cluster from "./slices/cluster";
import error from "./slices/errorSlice";
import notification from "./slices/notificationSlice";
import loader from "./slices/loaderSlice";
import dashboard from "./slices/dashboardSlice";
import chatBot from "./slices/chatBot";
import twoColumn from "./slices/twoColumnLayout";


// we need to import the reducer function from the counter slice and add it to our store.
// By defining a field inside the reducer parameter, we tell the store to use this slice reducer function to handle all updates to that state.
const reducer = {
  authentication: authenticationReducer,
  siteMaster,
  assetMaster,
  abatementMaster,
  uploadData,
  equipmentMaster,
  gasFactorMaster,
  emissionMaster,
  componentMaster,
  cluster,
  error,
  notification,
  loader,
  dashboard,
  chatBot,
  twoColumn

};

const persistConfig = {
  key: "root",
  storage,
  // blacklist: [''],
  transforms: [
    encryptTransform({
      secretKey: "Sq@r3G@m3-$3cr3t-k3y",
      onError: function (error: any) {
        console.log(error, "redux encryption error");
      },
    }),
  ],
};

const rootReducers = combineReducers(reducer);
const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store) as Persistor;

export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
