import styled from "@emotion/styled";
import { AppBar, Box, Button, Menu } from "@mui/material";

export const CustomAppBar = styled(AppBar)({
  // position: "static",
  backgroundColor: "#ffffff",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  boxShadow: "0px 8px 16px 0px #58596214",
  width: '100%',
  left: '0',
  top: '0'
});

export const CustomButton = styled(Button)(({ theme }) => ({
  display: "flex",
  fontSize: "1rem",
  fontWeight: 500,
  color: "#757172",
  padding: "8px 0",
  letterSpacing: "normal",
  textTransform: "capitalize",
  transition: "all 0.3s, color 0.3s",
  gap: "4px",

  "&:hover": {
    color: "#615D5F",
    backgroundColor: "transparent",
    "& svg": {
      "& path": {
        // stroke: "#615D5F",
      },
    },
  },
  "&:focus": {
    outline: "none",
  },
  // Update styles for activeMenu class
  "&.activeMenu": {
    color: "#3C8200", // Example style change

    "& .MuiSvgIcon-root": {
      "& path": {
        stroke: "#3C8200",
      },
    },
  },
}));
export const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    marginTop: "8px",
    borderRadius: "10px",
    border: "1px solid #EAEEF326",
    boxShadow: "0px 10px 30px 0px #10182833",
    minWidth: "160px",
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      margin: "5px",
      padding: 0,

      "& .MuiMenuItem-root": {
        padding: "11px",
        borderRadius: "6px",

        "& .MuiTypography-body1": {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16.94px",
          color: "#29292A",
        },
        "&:hover": {
          backgroundColor: "#EFF1F7",
        },
      },
    },
  },
});

export const RightNav = styled(Box)({
  flexGrow: 0,
  display: "flex",
  gap: "10px",
  "& .MuiButton-root": {
    backgroundColor: "#F9FAFD",
    padding: "7px 8px",
    borderRadius: "8px",
    color: "#559700",

    "& .MuiSvgIcon-root": {
      "& path": {
        stroke: "#559700",
      },
    },
  },

  "& .activeMenu": {
    "& .MuiButton-root": {
      backgroundColor: "#EFF6E4",
    },


  },

});