import * as yup from "yup";
import { Messages } from "../../constants/form.validations.message";
import { yupResolver } from "@hookform/resolvers/yup";

export const validations = yup.object().shape({
  email: yup
    .string()
    .required(Messages.email)
    .email(Messages.emailFormat)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, Messages.emailFormat),

  password: yup.string().required(Messages.password),
})
