// CommonSelect.tsx
import React, { useState } from 'react';
import Select, { defaultTheme, StylesConfig } from 'react-select';
import { Box, FormControl } from '@mui/material';
import { CommonSelectProps, Option } from './interface';
import { Blanket, CustomOption, selectStyles, StyledButton, StyledMenu, StyleOption } from './style';
import ArrowDown from '../icons/arrowDown';
import CustomLabel from '../label/label';
import { FormError } from '../ErrorMessage/errorMessage';
import SelectSearchIcon from '../icons/selectSearchIcon';

const { colors } = defaultTheme;



const CustomOptionWithIndex = (props: any) => {
  const { data, innerProps, isFocused } = props;


  return (
    <CustomOption
      {...innerProps}
      style={{
        padding: "5px 5px 4px",
        borderBottom: "1px solid #EAEAEA",

      }}
      className='custom-option'

    >
      <StyleOption className="select-option" style={{ backgroundColor: isFocused ? '#EFF1F7' : 'transparent' }}>
        <span>{data.label}</span>
      </StyleOption>
    </CustomOption>
  );
};

const SearchableSelect: React.FC<CommonSelectProps> = ({
  options,
  formControlProps,
  buttonPlaceholder,
  Asterisk,
  selectlabel,
  errorText,
  menuPlacement = 'bottom',
  placeholder = "Search...",
  register,
  width,
  height,
  bgcolor,
  name,
  value,
  disabled,
  onSelectChange,
  filterBy = 'label' // New prop to determine filtering method
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const handleSelectChange = (selectedOption: Option | null) => {  
    // @ts-ignore
    onSelectChange(selectedOption);
    toggleOpen();
  };

  const filterOption = (option: Option, inputValue: string) => {
    const searchValue = filterBy === 'value' ? option.value : option.label;
    return searchValue.toLowerCase().includes(inputValue.toLowerCase());
  };


  let registerProps = {};
  if (register) {
    registerProps = register(name);
  }

  return (
    <>
      <FormControl fullWidth variant="outlined" {...formControlProps} error={Boolean(errorText)}>
        {selectlabel && (
          <CustomLabel inputLabel={selectlabel} Asterisk={Asterisk} className={isOpen ? 'Mui-focused' : ''}  disabled={disabled} />
        )}
        <Dropdown
          isOpen={isOpen}
          onClose={toggleOpen}
          target={
            <StyledButton
              onClick={toggleOpen}
              disableRipple
              disabled={disabled}
              bgcolor={bgcolor}
              sx={{ 
                border: errorText ? '1px solid #E4002B' : isOpen ? '1px solid #64A70B' : '',
                height: height
                }}>
              <span className={value ? "selectValue" : "placeholder"}>
                {value?.label ? value?.label : buttonPlaceholder}
              </span>
              <ArrowDown
                sx={{
                  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                  position: "absolute",
                  right: "10px",
                  fontSize: "14px",                  
                }}
              />
            </StyledButton>
          }
        >
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOptionWithIndex }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={handleSelectChange}
            options={options}
            placeholder={placeholder}
            styles={selectStyles}
            tabSelectsValue={false}
            value={value || ""}
            {...registerProps}
            menuPlacement={menuPlacement} // Automatically adjust placement
             filterOption={filterOption}

          />
        </Dropdown>
      </FormControl>
      {errorText && <FormError message={errorText} />}
    </>

  );
};



const Dropdown: React.FC<{ children: React.ReactNode; isOpen: boolean; target: React.ReactNode; onClose: () => void }> = ({ children, isOpen, target, onClose }) => (
  <Box sx={{ position: 'relative' }}>
    {target}
    {isOpen ? <StyledMenu>{children}</StyledMenu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Box>
);



const DropdownIndicator: React.FC = () => (
  <Box className="searchIcon" sx={{ color: colors.neutral20, height: 13, width: 13, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <SelectSearchIcon />
  </Box>
);


export default SearchableSelect;
