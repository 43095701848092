import React, { useEffect, useState } from 'react';
import FilterModal from '../../components/atoms/FilterModal/FilterModal';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import MultiSelectField from '../../components/atoms/MultiSelect/MultiSelectField';
import { AssetConstants } from './constants';

const FilterAsset = ({ open, onClose, assetData, filterCB, clearFilter }: any) => {
  // Destructure constants from SiteConstants
  const { label, placeholder, name } = AssetConstants;
  const [clearDisabled, setClearDisabled] = useState(false)
  const [filterDisabled, setFilterDisabled] = useState(false)

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      segment: []
    }
  });

  useEffect(() => {
    localStorage.removeItem("siteFilter")
  }, [])

  useEffect(() => {
    if (watch("segment")) {
      const val: any = watch("segment")
      if (!val?.length) {
        setClearDisabled(true)
        setFilterDisabled(true)
      } else {
        setClearDisabled(false)
        setFilterDisabled(false)
      }
      localStorage.setItem("siteFilter", JSON.stringify(val))
    }
  }, [watch("segment")])

  // Clear filters and reset form values
  const handleClearFilters = () => {
    localStorage.removeItem("siteFilter")
    reset({ segment: [] });
    onClose();
    clearFilter()
  }


  // Handle form submission
  const onSubmit = () => {
    filterCB(watch("segment"))
    onClose();
  }

  return (
    <div>
      {/* Render FilterModal component */}
      <FilterModal
        onClose={onClose}
        open={open}
        title='FILTER BY'
        width='475px'
        clearDisabled={clearDisabled}
        filterDisabled={filterDisabled}
        clearFilters={handleClearFilters}
        applyFilters={handleSubmit(onSubmit)}
      >
        <Grid container spacing={"20px"}>
          <Grid item md={12}>
            {/* Render SelectField component */}
            <MultiSelectField
              selectlabel={"Segment Type"}
              options={[{ label: "Upstream", value: "Upstream" }, { label: "Midstream", value: "Midstream" }]}
              placeholder={"Select Segment Type"}
              name={"segment"}
              register={register}
              value={watch("segment") || []}
            />
          </Grid>
        </Grid>
      </FilterModal>
    </div>
  );
};

export default FilterAsset;