import * as fs from "fs";
import Papa from "papaparse";
import { errorMessages, mandatoryField } from "../pages/UploadData/staticData";

/**
 * Method will set session storage and will encrypt selected keys only.
 * @param {string} key
 * @param {any} data
 */
export const setSessionData = async (key: string, data: any) => {
  if (key) {
    const newString = JSON.stringify(data);
    sessionStorage.setItem(key, newString);
  } else {
    setSessionData(key, data);
  }
};

/**
 *
 * @param {string} key
 * @return {any}
 */
export const getSessionData = (key: string): any => {
  let data;
  const storageData = sessionStorage.getItem(key) || "";
  try {
    const value = storageData;
    data = JSON.parse(value);
  } catch (e) {
    data = storageData;
  }
  return data ? data : null;
};

/**
 * Method will remove given session storage item.
 * @param {string} key
 */
export const removeSessionKey = (key: string) => {
  if (key) {
    sessionStorage.removeItem(key);
  }
};

/**
 * Method will clear session storage.
 * @param {string} key
 */
export const clearSession = () => {
  sessionStorage.clear();
};

/**
 * Method to parse a CSV file and validate its header with a given header list.
 * @param {string} filePath - The path to the CSV file.
 * @param {string[]} headerList - The list of headers to validate.
 * @param {string[]} mandatoryFields - The list of mandatory fields.
 * @returns {Promise<boolean>} - Returns true if the header is valid, false otherwise.
 */

export const parseAndValidateCSV = async (
  filePath: string,
  mandatoryFields: string[]
): Promise<boolean> => {
  try {
    return new Promise<boolean>((resolve: any, reject) => {
      const file: any = new FileReader();
      file.onload = async (event: any) => {
        const fileContent = event.target?.result as string;
        const lines = fileContent.split("\n");
        let error: any = {};
        const headers = lines[0].split(",").map((header) => header.trim());
        for (const field of mandatoryFields) {
          if (!headers.includes(field)) {
            error = { isInvalid: true };
            resolve(error);
          }
        }

        const duplicates = findDuplicateHeaders(fileContent);
        if (duplicates?.length) {
          error = {
            isDuplicate: true,
            message: `Duplicate header ${duplicates.join(", ")} found in the file`,
          };
          resolve(error);
        }

        const getResult = await checkFile(filePath);
        if (getResult) {
          error = { isEmpty: getResult };
          resolve(error);
        }
        resolve({ data: true });
      };
      file.onerror = (event: any) => {
        reject(event.target?.error);
      };
      file.readAsText(filePath);
    });
  } catch (error) {
    console.error("Error parsing and validating CSV:", error);
    return false;
  }
};

// Function to identify duplicate headers in a CSV
function findDuplicateHeaders(csvContent: any) {
  // Split the CSV content by new lines
  const lines = csvContent.split("\n");

  // Get the first line which contains headers
  const headers = lines[0].split(",");

  // Initialize an object to keep track of header counts
  const headerCount: any = {};

  // Initialize an array to store duplicate headers
  const duplicates = [];

  // Loop through each header, trim spaces and count occurrences
  headers.forEach((header: any) => {
    const trimmedHeader = header.trim();

    // If header already exists, it's a duplicate
    if (headerCount[trimmedHeader]) {
      headerCount[trimmedHeader] += 1;
    } else {
      headerCount[trimmedHeader] = 1;
    }
  });

  // Find headers with count more than 1
  for (let header in headerCount) {
    if (headerCount[header] > 1) {
      if (header) {
        duplicates.push(header);
      }
    }
  }

  // Return the array of duplicate headers
  return duplicates;
}

const checkFile = async (filePath: string) => {
  return new Promise((resolve: any, reject: any) => {
    Papa.parse(filePath, {
      complete: (results) => {
        resolve(checkIfEmpty(results.data));
      },
      error: (err: any) => {
        console.log("papa parse error", err);
      },
      header: true,
    });
  });
};

const checkIfEmpty = (data: any) => {
  if (data.length === 0) {
    return true;
  } else {
    const hasData = data.some((row: any) =>
      Object.values(row).some((value) => value !== "")
    );
    return !hasData;
  }
};
/**
 * Method to convert JSON data to CSV format and download it.
 * @param {string} fileName - The name of the downloaded file.
 * @param {any[]} jsonData - The JSON data to convert.
 */
export const convertJsonToCsvAndDownload = (
  fileName: string,
  jsonData: any
) => {
  const csvData = convertJsonToCsv(
    jsonData?.["errorText"],
    jsonData?.["dataType"]
  );
  if (!csvData) return;
  downloadCsv(fileName, csvData);
};

/**
 * Method to convert JSON data to CSV format.
 * @param {any[]} jsonData - The JSON data to convert.
 * @returns {string} - The CSV data.
 */
const convertJsonToCsv = (jsonData: any[], dataType: any): string => {
  if (!jsonData.length) return "";
  // Filter out the mandatory fields present in jsonData[0]
  let headers: any = [];
  // Append "Error message" key to the headers if it exists in the jsonData
  for (let key in jsonData[0]) {
    if (key !== "Error message") {
      headers.push(key);
    }
  }
  if (jsonData?.[0]["Error message"]?.length > 0) {
    headers.push("Error message");
  }

  // Map the rows to match the order of headers
  const rows = jsonData.map((obj) =>
    headers.map((header: any) => obj[header] || "")
  );
  // Combine headers and rows
  const csvArray = [headers, ...rows];
  // Convert the array of arrays into CSV format
  return csvArray.map((row) => row.map(escapeCSVValue).join(",")).join("\n");
};

function escapeCSVValue(value: any) {
  // Convert the value to a string
  const stringValue = value.toString();

  // If the value contains a comma, wrap it in double quotes
  if (stringValue.includes(",") || stringValue.includes('"')) {
    // Escape any double quotes in the value
    const escapedValue = stringValue.replace(/"/g, '""');
    return `"${escapedValue}"`;
  }
  return stringValue;
}

/**
 * Method to download CSV data as a file.
 * @param {string} fileName - The name of the downloaded file.
 * @param {string} csvData - The CSV data to download.
 */
const downloadCsv = (fileName: string, csvData: string) => {
  // Add BOM to ensure UTF-8 encoding is recognized by various editors and programs
  const bom = "\uFEFF";

  // Write the CSV file with UTF-8 encoding and BOM
  // fs.writeFileSync(filePath, bom + csvContent, { encoding: 'utf8' });

  const blob = new Blob([bom + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};

/**
 * Function to convert an array of objects to label-value pairs using id and name properties.
 * @param {any[]} data - The array of objects to convert.
 * @returns {any[]} - The converted array of label-value pairs.
 */
export const convertArrayToObject = (
  data: any[],
  label?: string,
  value?: string
): any[] => {
  return data?.map((obj) => ({
    label: obj[label || "name"],
    value: obj[value || "id"],
  }));
};

export const convertArrToObjWithAssetKey = (
  data: any[],
  label?: string,
  value?: string,
  foreignKey?: string
): any[] => {
  return data?.map((obj) => ({
    label: obj[label || "name"],
    value: obj[value || "id"],
    foreignKey: obj.assetId.assetId ? obj.assetId.assetId : obj.siteId.siteId,
  }));
};

export const convertArrToObjWithSiteKey = (
  data: any[],
  label?: string,
  value?: string,
  foreignKey?: string
): any[] => {
  return data?.map((obj) => ({
    label: obj[label || "name"],
    value: obj[value || "id"],
    foreignKey: obj.siteId.siteId,
  }));
};

export function addOrUpdateSearchParamsInUrl(params: any) {
  // Create a URL object from the given urlString
  const searchParams = new URLSearchParams();

  // Loop through the params object and set each key-value pair
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (params[key]) {
        searchParams.set(key, params[key]);
      }
    }
  }

  // Return the updated URL string
  return searchParams.toString();
}

export function roundOffNumber(number: any) {
  // Check if the number is NaN
  if (isNaN(number)) return null;
  // Check if the number has a decimal part of zero
  if (!number) return null;
  if (number % 1 === 0) {
    return Math.round(number);
  }

  return number;
}

export function removeTrailingZeros(numString: any) {
  // Remove trailing zeros after the decimal point
  return numString
    .toString()
    .replace(/(\.\d*?[1-9])0+$/, "$1")
    .replace(/\.0$/, "");
}

// DD/MM/YYYY
export function getDateFromDatetime(dateTimeStr: string) {
  const dateObj = new Date(dateTimeStr);

  // Extract year, month, and day
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1; // Months are zero-based
  const day = dateObj.getDate();

  return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
}

// HH:MM:SS
export function getTimeFromDatetime(dateTimeStr: string) {
  // Create a Date object
  const dateObj = new Date(dateTimeStr);
  const bakuOffset = 4 * 60; // in minutes

  // Calculate the time in UTC
  const utcTime = new Date(dateObj.getTime() - bakuOffset * 60 * 1000); // Convert UTC time to local time

  // Extract hours, minutes, and seconds
  const hours = utcTime.getHours(); // Use getHours() if you want local time
  const minutes = utcTime.getMinutes(); // Use getMinutes() if you want local time
  const seconds = utcTime.getUTCSeconds(); // Use getSeconds() if you want local time

  // Format as HH:MM:SS
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

// Get number with 2 digits after decimal
export function getTwoDecimalDigit(num: any) {
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  if (typeof num === "number" && !isNaN(num)) {
    // Check if the number has decimal values
    if (num % 1 !== 0) {
      return num.toFixed(2);
    } else {
      return num.toString(); // Return as a string if no decimal part
    }
  }

  return ""; // Return an empty string for invalid inputs
}

export const formateDate = (inputDate: string) => {
  const [day, month, year] = inputDate.split("/"); // Split the string by "/"
  const formattedDate = `${year}-${month}-${day}`; // Reformat to YYYY-MM-DD
  return formattedDate;
};

export function downloadCSV(filename = "file.csv") {
  const data = [
    "LEC (Leak Equipment Code)",
    "Site ID",
    "Detection Technology",
    "Latitude",
    "Longitude",
    "Component Type",
    "2σ Uncertainty (%)",
    "Emission Rate (kg/hr)",
    "Emission Rate (kg/yr)",
    "Component ID",
    "Equipment ID",
    "Date",
    "Time",
  ];
  // Define the BOM (Byte Order Mark) for UTF-8
  const BOM = "\uFEFF";

  // Convert data array to CSV string
  let csvContent = data.join(",");

  // Add BOM at the beginning of the CSV content
  let blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a link element for download
  let link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append the link to the document body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Remove the link from the document after the download
  document.body.removeChild(link);
}

export function isValidFloat(input: any) {
  // Regex to allow only valid floats and restrict alphabets and special characters
  const regex = /^[0-9]*\.?[0-9]+$/;

  // Test the input against the regex
  return regex.test(input);
}

/* get color value acoording to the background color */

export const adjustFontColor = (background: any) => {
  var threshold = 130; // Adjust this threshold as needed

  // Attempt to match the regex
  var match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(background);
  // Check if match is successful
  if (match) {
    // Convert background color to RGB
    var r = parseInt(match[1], 16);
    var g = parseInt(match[2], 16);
    var b = parseInt(match[3], 16);

    // Calculate luminance
    var luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    // Determine whether to use black or white font color
    var fontColor = luminance > threshold ? "#000000" : "#FFFFFF";
    return fontColor;
  }
};

export function countDecimalPlaces(number: any) {
  // Convert the number to a string
  const numberString = number.toString();

  // Check if there is a decimal point in the number
  if (numberString.includes(".")) {
    // Split the number at the decimal point and return the length of the fractional part
    return numberString.split(".")[1].length;
  } else {
    // No decimal point means zero decimal places
    return 0;
  }
}

// Helper function to generate dynamic ticks
export const generateDynamicTicks = (min: any, max: any) => {
  const ticks = [];

  if (max < 1) {
    ticks.push(1); // Ensure "1 MT" is displayed on the y-axis
  }

  let current = 1;

  while (current <= max) {
    ticks?.push(current);
    if (current >= 100000) {
      current *= 10;
    } else if (current >= 10000) {
      current *= 10;
    } else if (current == 1000) {
      current = 1000 * 10; // Increment by 1000 when values are large
    } else {
      current *= 10; // Otherwise, multiply by 10 for logarithmic progression
    }
  }
  return ticks;
};

export const roundToLogarithmicMultiple = (value: any) => {
  if (value <= 0) return 0; // Handle zero or negative values, if needed

  // Compute the logarithmic base 10 value
  const logValue = Math.log10(value);

  // Floor the logarithmic value to get the lower multiple of 10
  const roundedLog = Math.floor(logValue);

  // Get the corresponding rounded value
  const roundedValue = Math.pow(10, roundedLog);

  return roundedValue;
};

export const convertDataToGroup = (data: any, column: string, type: string) => {
  let dataset = data.map((item: any) => {
    return {
      type: item[type],
      columns: item[column],
    };
  });
  return dataset;
};

export const searchMasterList = (
  dataset: any,
  query: any,
  searchKeys: string[]
) => {
  const searchQuery = query.toLowerCase();

  // Filter segments by whether they contain matching assets
  const results = dataset
    .map((entry: any) => {
      // Filter assets within the segment that match the query in any of the search keys
      const filteredAssets = entry?.columns?.filter((data: any) =>
        searchKeys.some((key) => data[key]?.toLowerCase().includes(searchQuery))
      );

      // Return the segment only if it has matching assets
      if (filteredAssets.length > 0) {
        return {
          ...entry,
          columns: filteredAssets,
        };
      }
      return null; // Return null for segments with no matching assets
    })
    .filter((entry: any) => entry !== null); // Remove null entries

  return results;
};

// Function to filter data by multiple segments
export function filterByGroup(dataset: any, segmentQueries: any) {
  const searchSegments = segmentQueries.map((segment: any) =>
    segment.toLowerCase()
  );

  // Filter dataset by matching segments
  const results = dataset.filter((entry: any) =>
    searchSegments.includes(entry?.type?.toLowerCase())
  );

  return results;
}
