import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";
// import { setLoderVisibility } from "./loaderSlice";


// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  notificationData: [],
  notVerified: null,
  notificationMasterData: {},
  notificationChangeLogs: [],
  duplicateNotifications: [],
  notVerifiedData: null,
  duplicateEmissionCount: 0,
  total: 0
};

const notificationSlice: any = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearState: () => initialState,
    setNotificationList: (state, { payload }) => {
      state.notificationData = payload.data;
      state.total = payload?.meta?.itemCount
    },
    setNotVerifiedCount: (state, { payload }) => {
      state.notVerified = payload
    },

    setNotificationMasterData: (state, { payload }) => {
      state.notificationMasterData = payload;
    },

    setNotificationChangeLogs: (state, { payload }) => {
      state.notificationChangeLogs = payload.data;
    },

    setDuplicateNotifications: (state, { payload }) => {
      state.duplicateNotifications = payload.data;
      state.total = payload?.meta?.itemCount;
    },

    setNotVerifiedData: (state, { payload }) => {
      state.notVerifiedData = payload
    },

    setDuplicateEmissionCount: (state, { payload }) => {
      state.duplicateEmissionCount = payload.count;
    }
  },
});

export const {
  clearState,
  setNotificationList,
  setNotVerifiedCount,
  setNotificationMasterData,
  setNotificationChangeLogs,
  setDuplicateNotifications,
  setNotVerifiedData,
  setDuplicateEmissionCount
} = notificationSlice.actions;

// Action creators are generated for each case reducer function
export const notificationSelector = (state: any) => state.notification;

export default notificationSlice.reducer;

export const getNotificationData = (params: any, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "notificationData" }))
    }

    try {
      const res = await MasterService.getNotifications(params)
      if (res.status === 200) {
        dispatch(setNotificationList(res.data))
        if (loaderShow) dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }))
      }
    } catch (error) {
      dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }))
    }
  };
}

export const getNotificationNotVerified = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getNotificationNotVerified()
      if (res.status === 200) {
        dispatch(setNotVerifiedCount(res?.data?.message))
        dispatch(setNotVerifiedData(res?.data))
      }
    } catch (error) {

    }
  };
}


export const getNotificationMasterData = (params?: string, loaderShow = true) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "notificationData" }))
    }

    try {
      const res = await MasterService.getNotificationEmissionDetails(params)
      if (res.status === 200) {
        dispatch(setNotificationMasterData(res.data))
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }))
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setErrorMessage(error))
        dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }))
      }
    }
  };
}


export const verifyEmissionNotification = (id: string | number, payload: any, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "notificationData" }));
    try {
      const res = await MasterService.verifyNotification(id, payload)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }));
        successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "notificationData" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const getNotificationChangeLogData = (params: number) => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getNotificationChangeLogs(params)
      if (res.status === 200) {
        dispatch(setNotificationChangeLogs(res.data))
      }
    } catch (error: any) {
      if (error) {
        dispatch(setErrorMessage(error))
      }
    }
  };

}

export const getDuplicateNotifications = (params: string) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "duplicateEmissions" }))

    try {
      const res = await MasterService.getDuplicateNotifications(params)
      if (res.status === 200) {
        dispatch(setDuplicateNotifications(res.data))
        dispatch(setLoderVisibility({ isShow: false, component: "duplicateEmissions" }))
      }
    } catch (error: any) {
      if (error) {
        dispatch(setErrorMessage(error))
        dispatch(setLoderVisibility({ isShow: false, component: "duplicateEmissions" }))
      }
    }
  };
}

export const getDuplicateEmissionsCount = () => {
  return async (dispatch: any) => {
    try {
      const res = await MasterService.getDuplicateEmissionsCount()
      if (res.status === 200) {
        dispatch(setDuplicateEmissionCount(res.data))
      }
    } catch (error: any) {
      if (error) {
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const fixDuplicateEmissions = (id: number) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "fixDuplicates" }));
    try {
      let payload = { id }
      const res = await MasterService.fixDuplicateEmissions(id, payload)
      if (res.status === 200) {
        // successCB()
        dispatch(setLoderVisibility({ isShow: false, component: "fixDuplicates" }));
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "fixDuplicates" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

export const keepDuplicateEmissions = (id: number) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "keepDuplicates" }));
    try {
      let payload = { id }
      const res = await MasterService.keepDuplicateEmissions(id, payload)
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "keepDuplicates" }));
        // successCB()
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "keepDuplicates" }));
        dispatch(setErrorMessage(error))
      }
    }
  };
}

