// TablePage.tsx
import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import DefaultLayout from "../../../components/layout";
import Toast from "../../../components/atoms/Toast/Toast";
import { Column, Row } from "../../../components/atoms/CollapsibleTable/interface"
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks";
import CollapsibleTable from "../../../components/atoms/CollapsibleTable/CollapsibleTable";
import { FixErrorChip } from "../../../components/atoms/chip/chip";
import { getNotificationNotVerified, notificationSelector, getDuplicateNotifications, getDuplicateEmissionsCount, fixDuplicateEmissions, keepDuplicateEmissions } from "../../../store/slices/notificationSlice";
import { loaderSelector } from "../../../store/slices/loaderSlice";
import "../Notification.scss"
import StatusModal from "../../../components/atoms/StatusModal/StatusModal";
import { useNavigate } from "react-router-dom";
import CircularLoader from "../../../components/atoms/CircularLoader/Loader";


const DuplicateEmissions = () => {
  const dispatch = useAppDispatch();
  let { notVerified, duplicateNotifications, total, duplicateEmissionCount, notVerifiedData } = useSelector(notificationSelector)
  const { isLoader } = useSelector(loaderSelector)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [statusModal, setStatusModal] = useState(false);
  const [keepDuplicateStatus, setKeepDuplicateStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetDuplicateData();
  }, [page, pageSize])

  const columns = [
    {
      id: "id",
      label: "Detection ID",
      sortable: true,
      width: "20%",
      class: "linkData",
      // render: (row: any) => <p className="ddd">{row.detectionId}</p>
    },

    { id: "detectionMethod", label: "Detection Method", sortable: true, width: "15%" },
    { id: "sourceName", label: "Source Name", sortable: true, width: "15%" },
    { id: "emissionRate", label: "Emission Rate (kg/hr)", sortable: true, width: "15%" },
    { id: "subcategoryName", label: "Emission Sub-Category", sortable: true, width: "15%" },
    { id: "detectionDate", label: "Detection Date & Time", sortable: true, width: "20%" },
  ];

  const fetDuplicateData = () => {
    let params = `?page=${page}&limit=${pageSize}&order=DESC`;
    dispatch(getDuplicateNotifications(params))
    dispatch(getDuplicateEmissionsCount())
    dispatch(getNotificationNotVerified())
  }

  const renderButtons = () => (
    (notVerifiedData && notVerifiedData?.isDuplicate) ? (<Stack direction="row" className='spacebetween alignItems' width={"100%"}>
      {notVerifiedData && <FixErrorChip Size="large" icon="duplicate" cursor="default" label={` ${notVerifiedData?.duplicateMessage}`} height={"48px"} />}
    </Stack>) : ''

  );

  const keepDuplicateEmissionsValues = (id: number) => {

    dispatch(keepDuplicateEmissions(id)).then(() => {
      setKeepDuplicateStatus(true);
      setTimeout(() => {
        setKeepDuplicateStatus(false);
      }, 6000)
      fetDuplicateData();
    });
  }

  const fixDuplicateEmissionsValues = (id: number) => {

    dispatch(fixDuplicateEmissions(id)).then(() => {
      setStatusModal(true);
      setTimeout(() => {
        setStatusModal(false);
      }, 6000)
      fetDuplicateData();
    });
  }

  const handleCloseStatusModal = () => {
    setStatusModal(false);
  }

  return (
    <>
    {(isLoader?.isShow && isLoader?.component === "duplicateEmissions") && <CircularLoader />}  

    <DefaultLayout
      pageTitle={"Duplicate Emissions"}
      backHandler={() => navigate(-1)}
    >
      <Box>
        <CollapsibleTable
          rows={duplicateNotifications}
          columns={columns}
          initialRowsPerPage={5}
          showCheckbox={false}
          showSearch={true}
          page={page}
          total={total}
          rowsPerPage={pageSize}
          seachField={false}
          renderButtons={renderButtons}
          setPage={setPage}
          setRowsPerPage={setPageSze}
          searchApi={getDuplicateNotifications}
          fixDuplicateEmissions={fixDuplicateEmissionsValues}
          keepDuplicateEmissions={keepDuplicateEmissionsValues}
        />
        {statusModal && <Toast message={"Duplicates removed successfully"} type={"info"} />}
        {keepDuplicateStatus && <Toast message={"Detections Kept successfully"} type={"info"} />}
      </Box>
    </DefaultLayout>
    </>

  );
};

export default DuplicateEmissions;
