// @ts-nocheck
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../components/layout';
import { AddEmissionFactorProps, AddEmissionFactorSubmitProps, EquipmentTypePayload } from './interface';
import { Grid, Stack } from '@mui/material';
import { EmissionConstants, OGMPLevelOptions, maxLimit, minLimit } from './constants';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputField from '../../components/atoms/InputField/InputField';
import SelectField from '../../components/atoms/SelectField/SelectField';
import { PrimaryButton } from '../../components/atoms/button/button';
import ForwordArrowIcon from '../../components/atoms/icons/forwordArrowIcon';
import { useAppDispatch } from '../../store/hooks';
import { addEmissionFactor, editEmissionFactor, emissionMasterSelector } from '../../store/slices/emissionMaster';
import { getEquipmentTypeList, equipmentMasterSelector, getEquipmentTypeListBySubCategory } from '../../store/slices/equipmentMaster'
import { clusterSelector, getClusterData } from "../../store/slices/cluster";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { assetMasterSelector, getAssetList } from '../../store/slices/assetMaster';
import { convertArrayToObject, countDecimalPlaces, removeTrailingZeros, roundOffNumber } from '../../utils/helper';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { validations } from './validations';
import ArrowRight from '../../components/atoms/icons/arrowRight';
import CircularLoader from "../../components/atoms/CircularLoader/Loader";

const AddEmissionFactor = React.memo(({ pageTitle, onBack, editEmissionFactorData }: AddEmissionFactorProps) => {
  const ogmpLevel = OGMPLevelOptions;
  const dispatch: any = useAppDispatch();
  const { assetMasterData } = useSelector(assetMasterSelector);
  const { emissionSubCategoryList, equipmentType } = useSelector(emissionMasterSelector);
  const { equipmentTypeList } = useSelector(equipmentMasterSelector)
  const { clusterData } = useSelector(clusterSelector);
  const { isLoader } = useSelector(loaderSelector)
  const [isDisabled, setDisabled] = useState(editEmissionFactorData ? true : false)
  const [type, setType] = useState("equipmentType");
  const [equipmentComponentList, setEquipmentComponentList] = useState(equipmentTypeList)

  const { label, placeholder, button, name } = EmissionConstants;

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      emissionSubCategoryId: "",
      equipmentTypeId: "",
      typeId: "",
      clusterId: "",
      clusterDetail: "",
      units: "",
      emissionFactor: "",
      ogmpLevel: "",
      assetId: "",
      typeHierarchy: ""
    },
    resolver: yupResolver(validations)
  });

  useEffect(() => {
    if (watch(name.emissionSubCategoryId)) {
      let equpTypeList = equipmentTypeList.map((item) => {
        return {
          ...item,
          id: item.id + "-" + item.identifier
        }
      })
      setEquipmentComponentList(equpTypeList)
    } else {
      setEquipmentComponentList(equipmentTypeList)
    }
  }, [watch(name.emissionSubCategoryId), equipmentTypeList])

  useEffect(() => {
    dispatch(getClusterData(""));
  }, [])


  useEffect(() => {
    setEditData();
  }, [editEmissionFactorData, equipmentTypeList, assetMasterData])

  useEffect(() => {
    if (watch(name.equipmentTypeId)) {
      let selectedEquipmentType = equipmentTypeList.find((equipmentType: EquipmentTypePayload) => equipmentType.id === watch(name.equipmentTypeId));
      setValue(name.typeHierarchy, selectedEquipmentType?.identifier);
    }
  }, [watch(name.equipmentTypeId)]);

  useEffect(() => {
    if (watch(name.emissionSubCategoryId)) {
      setValue('equipmentTypeId', "")
      setType("type");
      dispatch(getEquipmentTypeListBySubCategory(watch(name.emissionSubCategoryId)))
    }
  }, [watch(name.emissionSubCategoryId)]);

  useEffect(() => {
    if (watch(name.ogmpLevel)) {
      setValue(name.assetId, "");
      let params = `?limit=9999&reporting_level=${watch(name.ogmpLevel)}`
      dispatch(getAssetList(params))
    }
  }, [watch(name.ogmpLevel)]);


  const setEditData = () => {
    if (editEmissionFactorData) {
      const editData = { ...editEmissionFactorData };
      for (var key in editData) {
        if (name[key] || name[key] === "") {
          if (key === "assetId") {
            setValue(name[key], editData?.assetId?.assetId);
          }
          else if (key === "emissionFactor") {
            let emissionFactor = roundOffNumber(removeTrailingZeros(editData[key]));
            setValue(name[key], emissionFactor);
          } else if (key === "equipmentTypeId" && editData?.typeHierarchy === "Component") {
            setValue(name[key], editData?.componentTypeId?.id + "-" + editData?.typeHierarchy);
          } else if (key === "equipmentTypeId" && editData?.typeHierarchy === "Equipment") {
            setValue(name[key], editData?.equipmentTypeId + "-" + editData?.typeHierarchy);
          }
          else {
            setValue(name[key], editData[key]);
          }
        }
      }
    }
  };


  const onSubmit: SubmitHandler<any> = (payload: AddEmissionFactorSubmitProps) => {
    const reqPayload = { ...payload };
    let equpData = reqPayload?.equipmentTypeId?.split("-")
    reqPayload["emissionCategoryId"] = getCategoryId(reqPayload.emissionSubCategoryId);
    reqPayload["emissionSubcategoryId"] = parseInt(reqPayload.emissionSubCategoryId);
    reqPayload["clusterId"] = parseInt(reqPayload.clusterId);
    reqPayload["typeId"] = parseInt(equpData[0]);
    reqPayload["emissionFactor"] = parseFloat(reqPayload.emissionFactor);
    reqPayload["typeHierarchy"] = equpData[1];
    delete reqPayload?.emissionSubCategoryId;

    if (editEmissionFactorData) {
      dispatch(editEmissionFactor(reqPayload, editEmissionFactorData.id, successCB));
    } else {
      dispatch(addEmissionFactor(reqPayload, successCB));
    }
  };

  const getCategoryId = (subCatId: number) => {
    const catObject = emissionSubCategoryList.find((subCatObj) => subCatObj.id == subCatId);
    return catObject?.emissionCategory?.id;
  }

  const successCB = () => {
    const alertType = editEmissionFactorData ? "edit" : "add";
    onBack(false, true, alertType);
  };

  const handleChange = (e: any) => {
    if (!isDisabled) setValue(name.equipmentTypeId, "");
  }

  const handleOGMPChange = (e: any) => {
    if (!isDisabled) setValue(name.assetId, "");
  }

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label={button.submit}
        variant="contained"
        type={"submit"}
        // endIcon={<ArrowRight sx={{ width: "7px" }} />}
        width="128px"
      />
    </Stack>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        (isLoader?.isShow && isLoader.component === "emission") && <CircularLoader />
      }
      <DefaultLayout
        pageTitle={pageTitle}
        // renderHeaderButtons={renderHeaderButtons}
        backHandler={() => onBack(false)}
      >
        <Grid container spacing={"30px"} sx={{ p: "10px 0 27px" }}>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.emissionSubCategory}
              placeholder={placeholder.emissionSubCategory}
              options={convertArrayToObject(emissionSubCategoryList)}
              name={name.emissionSubCategoryId}
              register={register}
              disabled={isDisabled}
              onChange={handleChange}
              value={watch(name.emissionSubCategoryId)}
              errorText={errors?.emissionSubCategoryId?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.equipmentType}
              placeholder={placeholder.equipmentType}
              options={convertArrayToObject(equipmentComponentList, type, "id")}
              name={name.equipmentTypeId}
              register={register}
              disabled={isDisabled}
              value={watch(name.equipmentTypeId)}
              errorText={errors?.equipmentTypeId?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.cluster}
              placeholder={placeholder.cluster}
              options={convertArrayToObject(clusterData, "equipmentCluster", "id")}
              name={name.clusterId}
              register={register}
              disabled={isDisabled}
              value={watch(name.clusterId)}
              errorText={errors?.clusterId?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.clusterDetails}
              type="string"
              max={maxLimit}
              name={name.clusterDetail}
              register={register}
              Asterisk={false}
              placeholder={placeholder.clusterDetails}
              errorText={errors?.clusterDetail?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.units}
              type="string"
              max={maxLimit}
              name={name.units}
              register={register}
              placeholder={placeholder.units}
              errorText={errors?.units?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <InputField
              label={label.emissionFactor}
              type="number"
              max={maxLimit}
              name={name.emissionFactor}
              register={register}
              placeholder={placeholder.emissionFactor}
              errorText={errors?.emissionFactor?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.OGMPLevel}
              placeholder={placeholder.OGMPLevel}
              options={convertArrayToObject(ogmpLevel, "label", "value")}
              name={name.ogmpLevel}
              value={watch(name.ogmpLevel)}
              register={register}
              onChange={handleOGMPChange}
              disabled={isDisabled}
              errorText={errors?.ogmpLevel?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              selectlabel={label.assetId}
              placeholder={placeholder.assetId}
              options={convertArrayToObject(assetMasterData, "assetId", "assetId")}
              name={name.assetId}
              value={watch(name.assetId)}
              register={register}
              disabled={isDisabled}
              errorText={errors?.assetId?.message}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2}>
          <PrimaryButton
            label={button.submit}
            variant="contained"
            type={"submit"}
            // endIcon={<ArrowRight sx={{ width: "7px" }} />}
            width={"95px"}
          />
        </Stack>
      </DefaultLayout>
    </form>
  );
});

export default AddEmissionFactor;
