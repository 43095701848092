import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  abatementMasterData: [],
  assetList: [],
  initiativeList: [],
  emissionList: [],
  total: 0,
};

const abatementMasterSlice: any = createSlice({
  name: "abatementMaster",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
    abatementMasterList: (state, { payload }) => {
      state.abatementMasterData = payload.data;
      state.total = payload?.meta?.itemCount;
    },
    assetList: (state, { payload }) => {
      state.assetList = payload;
    },
    initiativeList: (state, { payload }) => {
      state.initiativeList = payload;
    },
    emissionList: (state, { payload }) => {
      state.emissionList = payload;
    },
  },
});

export const {
  clearMasterState,
  abatementMasterList,
  assetList,
  initiativeList,
  emissionList,
} = abatementMasterSlice.actions;

// Action creators are generated for each case reducer function
export const abatementMasterSelector = (state: any) => state.abatementMaster;

export default abatementMasterSlice.reducer;

export const getAbatementList = (
  params: any,
  loaderShow = true,
  callBack?: any
) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(
        setLoderVisibility({ isShow: loaderShow, component: "abatementList" })
      );
    }

    try {
      const res = await MasterService.getAbatementList(params);
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(
            setLoderVisibility({ isShow: false, component: "abatementList" })
          );
        }
        dispatch(abatementMasterList(res.data));
        if (callBack) {
          callBack("abatement");
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(
          setLoderVisibility({ isShow: false, component: "abatementList" })
        );
        dispatch(setErrorMessage(error));
      }
    }
  };
};

export const addAbatement = (payload: any, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "abatement" }));
    try {
      const res = await MasterService.addAbatement(payload);
      if (res.status === 201) {
        dispatch(setLoderVisibility({ isShow: false, component: "abatement" }));
        successCB(true);
      }
    } catch (error: any) {
      dispatch(setLoderVisibility({ isShow: false, component: "abatement" }));
      dispatch(setErrorMessage(error));
    }
  };
};

export const editAbatement = (payload: any, id: string, successCB?: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: true, component: "abatement" }));
    try {
      const res = await MasterService.editAbatement(payload, id);
      if (res.status === 200) {
        dispatch(setLoderVisibility({ isShow: false, component: "abatement" }));
        successCB(true);
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "abatement" }));
        dispatch(setErrorMessage(error));
      }
    }
  };
};

export const getAbatementDetails = (id: string, successCB: any) => {
  return async (dispatch: any) => {
    dispatch(
      setLoderVisibility({ isShow: true, component: "abatementDetails" })
    );
    try {
      const res = await MasterService.getAbatementDetail(id);
      if (res.status === 200) {
        dispatch(
          setLoderVisibility({ isShow: false, component: "abatementDetails" })
        );
        successCB(res.data);
      }
    } catch (error: any) {
      if (error) {
        dispatch(
          setLoderVisibility({ isShow: false, component: "abatementDetails" })
        );
        dispatch(setErrorMessage(error));
      }
    }
  };
};

export const getAssetList = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getAssetList(
        `?page=${1}&limit=${99}&order=DESC`,
        false
      );
      if (res.status === 200) {
        dispatch(assetList(res.data.data));
      }
    };
  } catch (error) {}
};
//getEmissionSubCategoryList

export const getInitiativeList = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getInitiativeList(
        `?page=${1}&limit=${99}&order=DESC`,
        false
      );
      if (res.status === 200) {
        dispatch(initiativeList(res.data.data));
      }
    };
  } catch (error) {}
};

export const getEmissionList = () => {
  try {
    return async (dispatch: any) => {
      const res = await MasterService.getEmissionSubCategoryList();
      if (res.status === 200) {
        dispatch(emissionList(res.data.data));
      }
    };
  } catch (error) {}
};
