import styled from "@emotion/styled";
import { Dialog, IconButton } from "@mui/material";
import { PathString } from "react-hook-form";

export const Wrapper = styled("div")(() => ({
  padding: "0 24px",
}));

export const Footer = styled("div")({
  position: "absolute",
  bottom: 0,
  width: "100%",
  '& .MuiDialogActions-root': {
    padding: "24px",
  },
});

export const CustomDialog = styled(Dialog)<{ width?: PathString }>(
  ({ width }) => ({
    "& .MuiDialog-paper": {
      position: "fixed",
      right: 0,
      minHeight: "100vh",
      margin: 0,
      borderRadius: 0,
      width: width || "auto",
      minWidth: "20.125rem",
      padding: "0px",
      boxShadow: "unset",
      "& .MuiDialogTitle-root": {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "21.78px",
        color: "#29292A",
        padding: "24px",
        textTransform: "uppercase",
      },
      "& .MuiDialogContent-root": {
        border: "none",
        padding: 0,
      },

    },
  })
);

export const CustomIconButton = styled(IconButton)((theme) => ({
  position: "absolute",
  right: "30px",
  top: "22px",
}));
