import { createSlice } from "@reduxjs/toolkit";
import MasterService from "../../api/services/masterService";
import { AddSiteSubmitProps } from "../../pages/Site/interface";
import { setErrorMessage } from "./errorSlice";
import { setLoderVisibility } from "./loaderSlice";

// Redux Toolkit's createSlice and createReducer APIs use Immer inside to
// allow us to write "mutating" update logic that becomes correct immutable updates.
export const initialState = {
  loader: false,
  chatBotData: [],  
  chatBotAns: '',
 

};

const chatBotSlice: any = createSlice({
  name: "chatBot",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    clearMasterState: () => initialState,
   

    setChatList: (state, { payload }) => {      
      state.chatBotData = payload;
      console.log(`payload`, payload)
    },


    getChatbotAns: (state, { payload }) => {      
      state.chatBotAns = payload;
    },
    resetChatBotAns(state) {
      state.chatBotAns = ''; // Reset chatBotAns
  },
    


  },
});

export const {
  clearMasterState,
  setChatList,
  getChatbotAns,
  resetChatBotAns
 
} = chatBotSlice.actions;

// Action creators are generated for each case reducer function
export const chatBotSelector = (state: any) => state.chatBot;

export default chatBotSlice.reducer;

export const getchatBotData = (params: any, loaderShow = true, callBack?: any) => {
  return async (dispatch: any) => {
    if (loaderShow) {
      dispatch(setLoderVisibility({ isShow: true, component: "siteList" }));
    }
    try {
      const res = await MasterService.getchatList(params)
      
      if (res.status === 200) {
        if (loaderShow) {
          dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        }
        dispatch(setChatList(res.data))
        if (callBack) {
          callBack("site")
        }
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "siteList" }));
        dispatch(setErrorMessage(error))
      }
    }
  };

}


export const addchatBotData = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setLoderVisibility({ isShow: false, component: "chatbot" }))
    try {
      const res = await MasterService.sendChat(payload)
      if (res.status === 200) {        
        dispatch(setLoderVisibility({ isShow: false, component: "chatbot" }))        
        dispatch(getChatbotAns(res.data))
        
      }
    } catch (error: any) {
      if (error) {
        dispatch(setLoderVisibility({ isShow: false, component: "chatbot" }))
        dispatch(setErrorMessage(error))
      }
    }

  };

}


