export const AssetConstants = {
  label: {
    assetId: "Asset ID",
    assetName: "Asset Name",
    assetType: "Asset Type",
    assetDescription: "Asset Description",
    country: "Country",
    latitude: "Latitude",
    longitude: "Longitude",
    operated: "Operated?",
    operator: "Operator",
    equityPercentage: `% Equity`,
    equityType: "Type of equity",
    reportingLevel: "Reporting Level",
    hierachy: "Hierachy"
  },
  headers: {
    addAsset: "Add Asset",
    editAsset: "Edit Asset"
  },
  placeholder: {
    assetId: "Enter Asset ID",
    assetName: "Enter Asset Name",
    assetType: "Select Asset Type",
    assetDescription: "Enter Description",
    country: "Select Country",
    latitude: "Enter Latitude",
    longitude: "Enter Longitude",
    operated: "Select Operated?",
    operator: "Select Operator",
    equityPercentage: `Enter Value`,
    equityType: "Select Specify type of equity",
    reportingLevel: "Select Reporting Level"
  },
  button: {
    submit: "Submit",
    addAsset: "Add Asset",
    editAsset: "Edit Asset"
  },
  text: {
    addAssetAlert: "Asset added successfully.",
    editAssetAlert: "Asset updated successfully."
  },
  name: {
    assetId: "assetId",
    assetName: "assetName",
    assetType: "assetType",
    assetDescription: "assetDescription",
    country: "country",
    latitude: "latitude",
    longitude: "longitude",
    operated: "operated",
    operator: "operator",
    equityPercentage: `equityPercentage`,
    equityType: "equityType",
    reportingLevel: "reportingLevel"
  },
  // columnName: {
  //   assetId: "Asset ID",
  //   assetName: "Asset Name",
  //   assetType: "Asset Type",
  //   assetDescription: "Description",
  //   country: "Country",
  //   latitude: "Latitude",
  //   longitude: "Longitude",
  //   operated: "Operated",
  //   operator: "Operator",
  //   equityPercentage: `%Equity`,
  //   specificEquity: "Equity Type",
  //   reportingLevel: "Reporting Level"
  // }
  columnName: {
    hierachy: 'Hierarchy',
    assetId: "Asset ID",
    assetName: "Asset Name",
    assetType: "Asset Type",
    assetDescription: "Asset Description",
    country: "Country",
    latitude: "Latitude",
    longitude: "Longitude",
    operated: "Operated",
    operator: "Operator",
    equityPercentage: `%Equity`,
    specificEquity: "Equity Type",
    reportingLevel: "Reporting Level",

  }
}