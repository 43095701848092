import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import { FilterButton } from "../../components/atoms/button/button";
import { useAppDispatch } from "../../store/hooks";
import { getEquipmentMasterData } from "../../store/slices/equipmentMaster";
import { useSelector } from "react-redux";
import TableColumn from "./tableColumn";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import FilterEquipment from "./Filter";
import { EquipmentConstants } from "./constant";
import "./Notification.scss"
import { loaderSelector } from "../../store/slices/loaderSlice";

import { ButtonChip, FixErrorChip } from "../../components/atoms/chip/chip";
import CommonModal from "../../components/atoms/modal/modal";
import {
  getNotificationData,
  getNotificationNotVerified,
  notificationSelector,
  setNotificationMasterData,
} from "../../store/slices/notificationSlice";
import { assetMasterSelector } from "../../store/slices/assetMaster";
import { boolean } from "yup";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import VerifyEmission from "./Verification";
import { useNavigate } from "react-router-dom";
import { formateDate } from "../../utils/helper";
import moment from 'moment';
import CircularLoader from "../../components/atoms/CircularLoader/Loader";


export default function NotificationCenter() {
  const dispatch: any = useAppDispatch();
  const { headers, text } = EquipmentConstants;
  const { notificationData, total, notVerified, notVerifiedData } =
    useSelector(notificationSelector);
  const [page, setPage] = useState(1);
  const { assetMasterData } = useSelector(assetMasterSelector);
  const [statusFilter, setStatusFilter] = useState<any>(null);
  const [detectionMethodFilter, setDetectionMethodFilter] = useState<any>([]);
  const [emissionSubcategoryIdFilter, setEmissionSubcategoryIdFilter] =
    useState<any>([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number | null>(null)
  const [alertModalVisibility, setAlertModalVisibility] = useState(false)
  const [dateFilter, setDate] = useState<any>(null)
  const navigate = useNavigate();
  const { isLoader } = useSelector(loaderSelector)
  const [isFilterActive, setFilterActive] = useState(false);

  useEffect(() => {
    getData(true)
  }, [
    page,
    pageSize,
    statusFilter,
    detectionMethodFilter,
    emissionSubcategoryIdFilter,
    dateFilter
  ]);


  const getData = (loader: boolean) => {
    let params = `?page=${page}&limit=${pageSize}&order=DESC`;
    if (statusFilter) {
      let status: any =
        statusFilter === "verified"
          ? true
          : statusFilter === "not-verified"
            ? false
            : "";
      params += `&status=${status}`;
    }

    if (detectionMethodFilter?.length) {
      detectionMethodFilter?.map((item: any) => {
        params += `&detection_method=${item}`;
      });
    }
    if (emissionSubcategoryIdFilter?.length) {
      emissionSubcategoryIdFilter?.map((item: any) => {
        params += `&emission_subcategory_id=${item}`;
      });
    }
    if (dateFilter) {
      params += `&date=${dateFilter}`;
    }
    if (isFilterActive) dispatch(getNotificationData(params, loader));
  }


  useEffect(() => {
    dispatch(getNotificationNotVerified());
  }, []);

  const handleVerifyEmission = (id: string | number) => {
    setSelectedId(id)
    setShowModal(true);
  };

  const handleClose = () => {
    dispatch(setNotificationMasterData({}))
    setShowModal(false)
  }

  const VerifyEmissionBtn = (row: any) => {
    return (
      !row?.status ?
        <ButtonChip label="Verify Source" type="Verify" onClick={() => handleVerifyEmission(row?.id)} /> :
        <ButtonChip label="Re-Verify" type="Re-Verify" onClick={() => handleVerifyEmission(row?.id)} />

    );
  };

  const handleFilterVisibility = (state: boolean) => {
    setOpen(state);
  };

  const successCB = () => {
    getData(false)
    dispatch(setNotificationMasterData({}))
    dispatch(getNotificationNotVerified());
    setAlertModalVisibility(true)
    setShowModal(false)
  }

  const fixDuplicates = () => {
    navigate(`/duplicate-emissions`);
  }


  const renderButtons = () => (
    <Stack direction="row" className="spacebetween alignItems" width={"100%"}>
      <Stack direction="row" className="alignItems" width={"100%"} gap="12px">
        {notificationData?.length > 0 && (
          !notVerifiedData?.isAllVerified && (
            <FixErrorChip
              Size="large"
              label={`${notVerifiedData?.notVerifiedMessage}`}
              height="48px"
              cursor="default"

            />
          )
        )}

        <Box className="notificationChip"
          sx={{
            bgcolor: "#FFEBEF80",
            // ml: 2.5,
          }}
        >
          {notificationData?.length ? (
            <>
              {notVerifiedData?.isDuplicate &&
                <FixErrorChip
                  Size="large"
                  label={`${notVerifiedData?.duplicateMessage}`}
                  height={"48px"}
                  icon="duplicate"
                  bgcolor="transparent"
                  cursor="default"
                />
              }

              {
                notVerifiedData?.isDuplicate && <ButtonChip onClick={() => fixDuplicates()} Size="large" label="Fix Duplicates" type="Re-Verify" width={146} height={33} />
              }
            </>
          ) : null}
        </Box>
      </Stack>

      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const columns: any = new TableColumn({ VerifyEmissionBtn });

  return (
    <>
      {(isLoader?.isShow && isLoader?.component === "notificationData") && <CircularLoader />}
      <DefaultLayout pageTitle="Notification Center">
        <Box>
          <CrudTable
            rows={notificationData}
            columns={columns.column}
            initialRowsPerPage={5}
            showCheckbox={false}
            showSearch={true}
            seachField={false}
            page={page}
            total={total}
            rowsPerPage={pageSize}
            setPage={setPage}
            setRowsPerPage={setPageSize}
            renderButtons={renderButtons}
          />
        </Box>
        <FilterEquipment
          open={open}
          assetData={assetMasterData}
          onClose={() => {
            handleFilterVisibility(false);
          }}
          filterCB={(val1: any, val2: any, val3: any, val4: any) => {
            setStatusFilter(val1);
            setDetectionMethodFilter(val2);
            setEmissionSubcategoryIdFilter(val3);
            if (val4) {
              const selectedDate_ = new Date(val4)
              setDate(moment(selectedDate_).format("YYYY-MM-DD"))
            } else {
              setDate(null)
            }
            setFilterActive(true);
            setPage(1);
          }}
        />

        <CommonModal
          open={showModal}
          onClose={handleClose}
          title={headers.modalTile}
          minWidth="800px"
        >
          <VerifyEmission id={selectedId} successCB={successCB} />
        </CommonModal>
        <StatusModal
          type="success"
          bodyText={"Emission Verified Successfully."}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />
      </DefaultLayout>
    </>
  );
}

