// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, } from 'react-redux';
import './styles/font.css';
import './styles/global.css'
import App from './App';

import { store, persistor } from './store'; // Import the persistor from your store
import { PersistGate } from 'redux-persist/integration/react';


const rootElement = document.getElementById('root');
if (rootElement) {

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}
