import React, { ReactElement } from 'react';
import { ButtonChipProps, errorChipProps, StatusChipProps } from './interface';
import { StyledStatusChip, StyledButtonChip, StylederrorChip } from './style';
import ErrorIcon from '../icons/errorIcon';
import ArrowRight from '../icons/arrowRight';
import { Stack } from '@mui/material';
import DuplicateIcon from '../icons/duplicate';




export const StatusChip: React.FC<StatusChipProps> = ({ label, type, icon, width, height = 25, ...props }) => {
  return (
    <StyledStatusChip
      type={type}
      {...props}
      label={label}
      icon={icon}
      sx={{ width, height }}
    />
  );
};


export const ButtonChip: React.FC<ButtonChipProps> = ({
  label,
  type,
  width,
  height = 31,
  Size = 'small',
  onClick,
  icon = <ArrowRight />,
  showIcon = "true",
  ...props }) => {
  return (
    <StyledButtonChip
      {...props}
      type={type}
      Size={Size}
      showIcon={showIcon}
      label={label}
      icon={showIcon === 'true' ? <ArrowRight /> : undefined}
      onClick={onClick}
      sx={{ width, height }}



    />
  );
};


export const FixErrorChip: React.FC<errorChipProps> = ({
  label = 'Fix Errors',
  width,
  height = "32px",
  Size = 'small',
  bgcolor = "#FFEBEF80",
  icon = "error",
  cursor = "pointer",
  ...props
}) => {
  return (
    <StylederrorChip
      {...props}
      Size={Size}
      sx={{ width, height }}
      bgcolor={bgcolor}
      cursor={cursor}
      className='fixErrorChip'
      label={
        <Stack direction="row" className="flex-alignCenter" spacing={"4px"}>
          <span className="errorIcon">{icon === "duplicate" ? <DuplicateIcon /> : <ErrorIcon />}</span>
          <Stack direction="row" className="flex-alignCenter" alignSelf="self-end" spacing={"4px"}>
            <span>{label}</span>
            <ArrowRight className='arrowRight' />
          </Stack>
        </Stack>
      }

    />
  );
};


