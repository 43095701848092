import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import "./style.scss";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../components/atoms/button/button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { getAssetDetail } from "../../store/slices/assetMaster";
import AddAsset from "./AddAsset";
import { AssetConstants } from "./constants";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import { roundOffNumber } from "../../utils/helper";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";
import { useSelector } from "react-redux";

const AssetDetails = () => {
  const dispatch: any = useAppDispatch()
  const { headers, button, text } = AssetConstants
  const navigate = useNavigate()
  const [details, setDetails] = useState<any>({})
  const [editAssetOpen, setEditAssetOpen] = useState(false)
  const [alertModalVisibility, setAlertModalVisibility] = useState<any>(false)
  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    if (id) {
      dispatch(getAssetDetail(id, successCB))
    }

  }, [])

  const successCB = (responseData: any) => {
    setDetails(responseData)
  }

  const handleEdit = () => {
    setEditAssetOpen(true)
  }

  const toggleAddAsset = useCallback((status: boolean, showSuccessModal?: boolean, type?: any) => {
    setEditAssetOpen(status)
    setAlertModalVisibility(showSuccessModal)
    if (showSuccessModal) {
      dispatch(getAssetDetail(type, successCB))
    }
  }, []);

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <SecondaryButton
        label="Edit Details"
        variant="contained"
        onClick={handleEdit}
        width="127px"
      />
    </Stack>
  );


  if (editAssetOpen) {
    return (
      <AddAsset pageTitle={headers.editAsset} onBack={toggleAddAsset} editAssetData={details} />
    )
  }


  return (
    <>
      {
        (isLoader?.isShow && isLoader.component === "assetDetails") && <CircularLoader />
      }
      <DefaultLayout
        pageTitle="Asset Details"
        renderHeaderButtons={renderHeaderButtons}
        backHandler={() => navigate(-1)}
        activeTab={"Assets"}
      >
        <Box className="detailsMain">
          <Box className="details">
            <Grid item md={12} container sx={{ marginBottom: '20px' }} spacing={"0px"}>
              <Grid item md={3} >
                <span className="assetLable">Asset ID</span>
                <span className="asssetValue">{details.assetId}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Latitude</span>
                <span className="asssetValue">{details?.latitude}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Longitude</span>
                <span className="asssetValue">{details?.longitude}</span>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "#0000001A", }} />
            <Grid item md={12} container spacing={"0px"} sx={{ margin: '20px 0' }}>
              <Grid item md={3} >
                <span className="assetLable">Asset Name</span>
                <span className="asssetValue">{details?.assetName}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Asset Type</span>
                <span className="asssetValue">{details?.assetType?.assetType}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Country</span>
                <span className="asssetValue">{details?.country?.name}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Operator</span>
                <span className="asssetValue">{details?.operator?.name}</span>
              </Grid>

            </Grid>
            <Grid item md={12} container spacing={"0px"} sx={{ margin: '20px 0' }}>
              <Grid item md={3} >
                <span className="assetLable">Operated?</span>
                <span className="asssetValue">{details?.operated}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">% Equity</span>
                <span className="asssetValue">{roundOffNumber((details?.equityPercentage * 100).toPrecision(3))}%</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Equity Type</span>
                <span className="asssetValue">{details?.equityType?.name}</span>
              </Grid>
              <Grid item md={3} >
                <span className="assetLable">Reporting Level</span>
                <span className="asssetValue">{details?.reportingLevel}</span>
              </Grid>


            </Grid>
            <Divider sx={{ borderColor: "#0000001A", }} />
            <Grid item md={12} container sx={{ marginTop: '20px' }} spacing={"0px"}>
              <Grid item md={12} >
                <span className="assetLable">Description</span>
                <span className="asssetValue">{details?.assetDescription}</span>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <StatusModal
          type="success"
          bodyText={text.editAssetAlert}
          open={alertModalVisibility}
          onClose={() => setAlertModalVisibility(false)}
        />

      </DefaultLayout>
    </>
  );
};

export default AssetDetails;
