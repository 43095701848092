export const Messages = {
  email: "This field is Required.",
  password: "This field is Required.",
  required: "This field is Required.",
  validNumber: "Must be a valid number",
  emailFormat: 'Invalid email format',
  alphanumeric: 'Only alphanumeric characters, spaces, underscores, and hyphens are allowed',
  positiveNumber: 'This field must be greater than or equal to 0',
  integerNumber: 'Must be integer and less than or equal to 999',
  maxLengthExceed: 'Max length Exceeded',
  maxLength: (length: number) => `This field must have less than or equal to ${length} characters`,
  minLength: (length: number) => `This field must have at least ${length} characters`,
  decimalMaxLengthExceed: (numberPrecision: number, length: number) => `This field must have up to ${numberPrecision + length} digits total with up to ${length} after the decimal`,
}