import React from "react";
export default function InfoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
<g clip-path="url(#clip0_3259_36578)">
<path d="M8.49195 9.42084L8.32428 9.99201C7.82127 10.1575 7.41947 10.2834 7.12027 10.37C6.82075 10.4568 6.47272 10.5 6.07618 10.5C5.46724 10.5 4.9936 10.3757 4.65578 10.1288C4.31797 9.88088 4.14901 9.56674 4.14901 9.18566C4.14901 9.03814 4.16127 8.88641 4.18676 8.7318C4.21247 8.57702 4.25334 8.4027 4.30926 8.20786L4.93789 6.35372C4.99381 6.17617 5.04135 6.00795 5.07942 5.84886C5.11803 5.69076 5.13664 5.5453 5.13664 5.41436C5.13664 5.17758 5.07781 5.01195 4.96069 4.91883C4.84356 4.82598 4.62008 4.77884 4.28829 4.77884C4.12578 4.77884 3.95876 4.80053 3.78851 4.84247C3.61751 4.8846 3.47135 4.92528 3.34863 4.96311L3.51673 4.39148C3.92854 4.25176 4.32217 4.13211 4.6987 4.03281C5.07523 3.93323 5.431 3.8834 5.76752 3.8834C6.37227 3.8834 6.83892 4.0052 7.16662 4.2488C7.49433 4.49258 7.65812 4.80859 7.65812 5.19801C7.65812 5.27858 7.64737 5.42055 7.62424 5.62346C7.60166 5.82681 7.55961 6.01314 7.4983 6.18262L6.87237 8.02933C6.82107 8.17766 6.77493 8.34723 6.73481 8.53804C6.69341 8.72759 6.67362 8.87242 6.67362 8.96958C6.67362 9.21479 6.73922 9.38221 6.87076 9.4712C7.00326 9.5602 7.23148 9.60448 7.55563 9.60448C7.70781 9.60448 7.88096 9.58198 8.07337 9.5378C8.26524 9.49361 8.40516 9.4548 8.49195 9.42084ZM8.65069 1.6678C8.65069 1.98955 8.50518 2.26434 8.21275 2.49028C7.92108 2.71703 7.56961 2.8305 7.15845 2.8305C6.746 2.8305 6.39367 2.71703 6.09866 2.49028C5.80419 2.26425 5.65664 1.98955 5.65664 1.6678C5.65664 1.34668 5.80419 1.07144 6.09866 0.842633C6.39313 0.614181 6.74611 0.5 7.15845 0.5C7.5695 0.5 7.92108 0.61445 8.21275 0.842633C8.50539 1.07144 8.65069 1.34677 8.65069 1.6678Z" fill="#00438B"/>
</g>
<defs>
<clipPath id="clip0_3259_36578">
<rect width="12" height="10" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
  );
}
