export const EquipmentConstants = {
  label: {
    equipmentId: "Equipment Id",
    equipmentModel: "Equipment Model",
    siteId: "Site Id",
    timeToAddressLeakDays: "Time to address leak (days)",
    cumulativeOperatingHours: "Cumulative operating hours",
    agentProcessed: "Agent processed",
    inletOperationalPressureATM: "Inlet/operational pressure (ATM)",
    ageYears: "Age (YEARS)",
    equipmentType: "Equipment Type",
    equipmentSubtype: "Equipment SubType",
    equipmentCluster: "Equipment Cluster",
    numberOfPistons: "Number of Pistons",
    typeOfSeal: "Type of Seal",
    physicalVolumeOfMaintainedEquipmentM3: "Physical volume of maintained equipment (M³)",
    physicalVolumeOfEngineM3: "Physical volume of engine (M³)",
    residualPressureATM: "Residual pressure (ATM)",
    diameterCM: "Diameter (CM)",
    volumeOfPigReceiverM3: "Volume of pig receiver (M³)",
    lengthM: "Length (M)",
    typeOfWell: "Type of well",
    numberOfRelevantEquipment: "Number of relevant equipment",
    decommissioned: "Decommissioned",
    productionMethod: "Production method",
    lowPressure: "Low pressure?",
    performCasingHeadVentingApplicable: "Perform casing head venting applicable?",
    flaringEfficiency: "Flaring efficiency (%)",
    standbyFactor: "Standby factor (%)",
    typeOfGasBurnt: "Type of gas burnt",
    engineType: "Engine type",
    thicknessOfAWallMM: "Thickness of a wall (MM)",
    productivityM3PerHour: "Productivity (M³/HOUR)",
    powerRatingKW: "Power rating (KW)",
    outletPressureATM: "Outlet pressure (ATM)",
    waterCut: "Water cut (%)",
    latitude: "Latitude",
    longitude: "Longitude",
    otherDimensions: "Other (Dimension related data field)",
    filterSite: "Site ID"
  },
  headers: {
    addEquipment: "Add Equipment",
    editEquipment: "Edit Equipment"
  },
  placeholder: {
    equipmentId: "Enter Equipment ID",
    equipmentType: "Select Equipment type",
    equipmentSubtype: "Select Equipment Subtype",
    equipmentModel: "Select Equipment Model",
    siteId: "Select Site ID",
    timeToAddressLeakDays: "Enter Value",
    cumulativeOperatingHours: "Enter Value",
    agentProcessed: "Select Agent Processed",
    inletOperationalPressureATM: "Enter Value",
    ageYears: "Enter Value",
    numberOfPistons: "Enter Value",
    typeOfSeal: "Select Type of Seal",
    physicalVolumeOfMaintainedEquipmentM3: "Enter Value",
    physicalVolumeOfEngineM3: "Enter Value",
    residualPressureATM: "Enter Value",
    diameterCM: "Enter Value",
    volumeOfPigReceiverM3: "Enter Value",
    lengthM: "Enter Value",
    typeOfWell: "Select Type of Well",
    numberOfRelevantEquipment: "Enter Value",
    decommissioned: "Select Status",
    productionMethod: "Select Production method",
    lowPressure: "Select Value",
    performCasingHeadVentingApplicable: "Select Value",
    flaringEfficiency: "Enter Value",
    standbyFactor: "Enter Value",
    typeOfGasBurnt: "Select Type of Gas Burnt",
    engineType: "Select Engine Type",
    thicknessOfAWallMM: "Enter Value",
    productivityM3PerHour: "Enter Value",
    powerRatingKW: "Enter Value",
    outletPressureATM: "Enter Value",
    waterCut: "Enter Value",
    latitude: "Enter Value",
    longitude: "Enter Value",
    otherDimensions: "Enter Value",
    filterSite: "Select Site ID",
    filterEquipmentType: "Select Equipment Type",
    equipmentCluster: "Select Equipment Cluster"

  },
  button: {
    submit: "Submit",
    addEquipment: "Add Equipment",
    ediEquipment: "Edit Equipment"
  },
  text: {
    addEquipmentAlert: "Equipment added successfully.",
    editEquipmentAlert: "Equipment updated successfully.",
    searchPlaceHolder: "Search Equipment ID or Type..."
  },
  name: {
    equipmentId: "equipmentId",
    equipmentType: "equipmentType",
    equipmentSubtype: "equipmentSubtype",
    equipmentCluster: "equipmentCluster",
    equipmentModel: "equipmentModel",
    siteId: "siteId",
    timeToAddressLeakDays: "timeToAddressLeakDays",
    cumulativeOperatingHours: "cumulativeOperatingHours",
    agentProcessed: "agentProcessed",
    inletOperationalPressureATM: "inletOperationalPressureATM",
    ageYears: "ageYears",
    numberOfPistons: "numberOfPistons",
    typeOfSeal: "typeOfSeal",
    physicalVolumeOfMaintainedEquipmentM3: "physicalVolumeOfMaintainedEquipmentM3",
    physicalVolumeOfEngineM3: "physicalVolumeOfEngineM3",
    residualPressureATM: "residualPressureATM",
    diameterCM: "diameterCM",
    volumeOfPigReceiverM3: "volumeOfPigReceiverM3",
    lengthM: "lengthM",
    typeOfWell: "typeOfWell",
    numberOfRelevantEquipment: "numberOfRelevantEquipment",
    decommissioned: "decommissioned",
    productionMethod: "productionMethod",
    lowPressure: "lowPressure",
    performCasingHeadVentingApplicable: "performCasingHeadVentingApplicable",
    flaringEfficiency: "flaringEfficiency",
    standbyFactor: "standbyFactor",
    typeOfGasBurnt: "typeOfGasBurnt",
    engineType: "engineType",
    thicknessOfAWallMM: "thicknessOfAWallMM",
    productivityM3PerHour: "productivityM3PerHour",
    powerRatingKW: "powerRatingKW",
    outletPressureATM: "outletPressureATM",
    waterCut: "waterCut",
    latitude: "latitude",
    longitude: "longitude",
    otherDimensions: "otherDimensions",
    otherDimensionsUnit: "otherDimensionsUnit",
    otherDimensionsDescription: "otherDimensionsDescription",
    otherDimensionsValue: "otherDimensionsValue",
    otherDimensionsUnitValue: "otherDimensionsUnitValue",
  },
  columnName: {
    equipmentId: "Equipment ID",
    equipmentType: "Equipment Type",
    equipmentModel: "Equipment Model",
    siteId: "Site ID",
    timeToAddressLeakDays: "Time to Address Leak (Days)",
    cumulativeOperatingHours: "Cumulative Operating Hours",
    agentProcessed: "Agent Processed",
    inletOperationalPressureATM: "Inlet/Operational Pressure (ATM)",
    ageYears: "Age (Years)",
  }
}
