import React, { ReactNode } from "react";
import { Column, Row } from "../../components/atoms/DataTable/interface";
import { Box } from "@mui/material";
import EditIcon from "../../components/atoms/icons/editIcon";
import Hierachy from "../../components/atoms/icons/heirarchy";


interface Payload {
  handleIconButtonClick: any;
  handleHierarchyClick: any;
}

class TableColumn {
  private column: Column[];

  constructor(payload: Payload) {
    this.column = [
      {
        id: "id",
        label: 'Hierarchy',
        sortable: false,
        width: "6%",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleHierarchyClick(row)}>           
              <Hierachy />
            </Box>
          </Box>
        ),
      },
      {
        id: "siteId",
        label: "Site ID",
        sortable: false,
        width: "14%",
        render: (row: Row) => row.siteId,
      },
      {
        id: "assetId",
        label: "Asset ID",
        sortable: false,
        width: "14%",
        render: (row: Row) => row.assetId,
      },
      {
        id: "name",
        label: "Site Name",
        sortable: false,
        width: "20%",
        render: (row: Row) => row.siteName,
      },
      {
        id: "latitude",
        label: "Latitude",
        sortable: false,
        width: "14%",
        render: (row: Row) => row.latitude,
      },
      {
        id: "longitude",
        label: "Longitude",
        sortable: false,
        width: "14%",
        render: (row: Row) => row.longitude,
      },
      {
        id: "edit",
        label: "Edit",
        sortable: false,
        width: "10%",
        align: "center",
        render: (row: Row) => (
          <Box className="flex-center">
            <Box className="downloadIcon flex-center" onClick={() => payload.handleIconButtonClick(row)}>
              <EditIcon />
            </Box>
          </Box>
        ),
      },
    ]
  }
}

export default TableColumn;