// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Loader/loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".loader-container {\n  height: 100vh;\n  width: 100vw;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
