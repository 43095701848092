import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "../../components/layout";
import { Box, Stack } from "@mui/material";
import CrudTable from "../../components/atoms/DataTable/CrudTable";
import {
  FilterButton,
  PrimaryButton,
} from "../../components/atoms/button/button";
import FilterIcon from "../../components/atoms/icons/filterIcon";
import PlusIcon from "../../components/atoms/icons/plusIcon";
import TableColumn from "./tableColumn";
import FilterEmissionFactor from "./FilterEmissionFactor";
import { EmissionConstants } from "./constants";
import AddEmissionFactor from "./AddEmissionFactor";
import { useAppDispatch } from "../../store/hooks";
import AlertModal from "../../components/atoms/AlertModal.tsx";
import {
  getEmissionMasterData,
  getEmissionSubCategoryList,
  emissionMasterSelector,
} from "../../store/slices/emissionMaster";
import { equipmentMasterSelector } from '../../store/slices/equipmentMaster'
import { useSelector } from "react-redux";
import {
  assetMasterSelector,
  getAssetList,
} from "../../store/slices/assetMaster";
import StatusModal from "../../components/atoms/StatusModal/StatusModal";
import CircularLoader from "../../components/atoms/CircularLoader/Loader";
import { loaderSelector } from "../../store/slices/loaderSlice";

export default function EmissionMaster() {
  const dispatch: any = useAppDispatch();
  const { headers, text, button } = EmissionConstants;
  const { emissionMasterData, total } = useSelector(emissionMasterSelector);
  const { equipmentMasterData } = useSelector(equipmentMasterSelector);
  const [editEmissionFactorData, setEditData] = useState<any>(null);
  const [alertType, setAlertType] = useState(true);
  const [open, setOpen] = useState(false);
  const [addEmissionFactorVisibility, setAddEmissionFactorVisibility] = useState(false);
  const [pageTitle, setPageTitle] = useState(headers.addEmissionFactor);
  const [alertModalVisibility, setAlertModalVisibility] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSze] = useState(10);
  const [filterText, setFilterText] = useState<any>([])
  const [componentTypeText, setComponentTypeText] = useState<any>([])

  const { isLoader } = useSelector(loaderSelector);

  useEffect(() => {
    dispatch(getEmissionSubCategoryList("limit=9999&order=DESC"));
  }, []);

  useEffect(() => {
    let params = `?page=${page}&limit=${pageSize}&order=DESC`
    if (filterText?.length) {
      filterText?.forEach((item: any) => {
        params += `&equipment_type=${item}`
      });
    }
    if (componentTypeText?.length) {
      componentTypeText?.forEach((item: any) => {
        params += `&component_type=${item}`
      });
    }
    dispatch(getEmissionMasterData(params, true));

  }, [page, pageSize, filterText, componentTypeText])


  const toggleAddEmissionFactor = useCallback(
    (status: boolean, showSuccessModal?: boolean, type?: string, page?: number, pageSize?: number) => {

      setAddEmissionFactorVisibility(status);
      setPageTitle(headers.addEmissionFactor);
      if (type === "edit") {
        setAlertType(false);
      } else {
        setAlertType(true);
        setEditData(null);
      }
      if (showSuccessModal) {
        if (page) setPage(page as number);
        setAlertModalVisibility(true);
        dispatch(getEmissionMasterData(`?page=${page}&limit=${pageSize}&order=DESC`, false));
      }
    },
    []
  );

  const handleIconButtonClick = (row: any) => {
    row = {
      ...row,
      emissionCategoryId: row?.emissionCategoryId?.id,
      clusterId: row?.clusterId?.id,
      emissionSubCategoryId: row?.emissionSubCategoryId?.id,
      equipmentTypeId: row?.equipmentTypeId?.id,
    }
    setEditData(row);
    setPageTitle(headers.editEmissionFactor);
    setAddEmissionFactorVisibility(true);
  };

  const handleFilterVisibility = (state: boolean) => {
    setOpen(state);
  };

  const renderButtons = () => (
    <Stack>
      <FilterButton
        label="Filter"
        variant="contained"
        color="primary"
        onClick={() => {
          handleFilterVisibility(true);
        }}
        startIcon={<FilterIcon />}
      />
    </Stack>
  );

  const renderHeaderButtons = () => (
    <Stack direction="row" spacing={2}>
      <PrimaryButton
        label="Add Emission Factor"
        variant="contained"
        onClick={() => toggleAddEmissionFactor(true)}
        startIcon={<PlusIcon />}
        // width="264px"
        className="small-btn"
      />
    </Stack>
  );

  const columns: any = new TableColumn({ handleIconButtonClick });

  return (
    <>
      {
        (isLoader?.isShow && isLoader.component === "emissionMasterList") && <CircularLoader />
      }
      {addEmissionFactorVisibility ? (
        <AddEmissionFactor
          pageTitle={pageTitle}
          onBack={(
            status: boolean,
            modalStatus?: boolean,
            alertStatus?: string
          ) => { toggleAddEmissionFactor(status, modalStatus, alertStatus, page, pageSize); }}
          editEmissionFactorData={editEmissionFactorData}

        />
      ) : (
        <DefaultLayout
          renderHeaderButtons={renderHeaderButtons}
          pageTitle="Emission Factor Master"
        // backHandler={() => false}
        >
          <Box>
            <CrudTable
              rows={emissionMasterData}
              columns={columns.column}
              initialRowsPerPage={5}
              showCheckbox={false}
              showSearch={true}
              page={page}
              total={total}
              rowsPerPage={pageSize}
              setPage={setPage}
              setRowsPerPage={setPageSze}
              searchApi={getEmissionMasterData}
              renderButtons={renderButtons}
              searchPlaceholder=""
              seachField={false}
            />
          </Box>
          <FilterEmissionFactor
            open={open}
            equipmentData={equipmentMasterData}
            onClose={() => {
              handleFilterVisibility(false);
            }}
            filterCB={(equipmentType: string, componentType: string) => {
              setPage(1);
              setFilterText(equipmentType);
              setComponentTypeText(componentType);
            }}
          />

          <StatusModal
            type="success"
            bodyText={alertType ? text.addEmissionFactorAlert : text.editEmissionFactorAlert}
            open={alertModalVisibility}
            onClose={() => setAlertModalVisibility(false)}
          />
        </DefaultLayout>
      )}
    </>
  );
}
